﻿module.exports = ['$cookies', '$http', '$location', '$q', '$rootScope', '$timeout', 'config', identityService];

function identityService($cookies, $http, $location, $q, $rootScope, $timeout, config, context) {
    var qPreload = $q.defer();
    var identity = {
        allBusinessUnitIds: [],
        getBusinessUnitsForPermission: getBusinessUnitsForPermission,
        getBusinessUnitsForPermissions: getBusinessUnitsForPermissions,
        hasPermission: hasPermission,
        hasPermissionForBusinessUnit: hasPermissionForBusinessUnit,
        hasAllPermissions: hasAllPermissions,
        isAuthenticated: false,
        permissions: {
            all: [],
            businessUnits: []
        },
        preloaded: qPreload.promise,
        reloadUserInfo: getUserInfo,
        set: set,
        token: {
            tokenValue: null,
            tokenExpiresOn: null,
            tokenIssuedOn: null
        },
        userInfo: null
    };

    function getBusinessUnitsForPermission(permissionValue) {
        
        return getBusinessUnitsForPermissions([permissionValue]);
    }

    function getBusinessUnitsForPermissions(permissionValues) {
        var units = _.map(_.filter(identity.permissions.businessUnits, function (businessUnit) {
            return _.some(businessUnit.roles, function (role) {
                return _.some(role.permissions, function (permission) { return permissionValues.indexOf(permission.internalName) > -1; });
            });
        }), function (item) { return item.businessUnit; });

        if (units && units.indexOf(0) > -1)
            return identity.allBusinessUnitIds;

        return units;
    }

    function getFromCookie() {
        var token = $cookies.getObject('authToken');
        if (token !== undefined && token !== null && token !== '') {
            identity.isAuthenticated = true;
            identity.token = token;
            getUserInfo().then(function () {
                qPreload.resolve();
            });
        }
        else
            qPreload.resolve();
    };

    function getUserInfo() {
        var deferred = $q.defer();

        var authHeader = {};

        if (identity.token.tokenValue) {
            authHeader['Authorization'] = 'Bearer ' + identity.token.tokenValue;
        }

        var req = $http({ method: 'GET', url: config.apiHostEasyDock + '/account/userinfo', headers: authHeader })
            .then(function (result) {
                if (result.data.externalAccountId)
                    identity.isAuthenticated = true;

                identity.userInfo = result.data;

                var needWaitResolve = false;
                if (identity.userInfo && identity.userInfo.roles) {
                    processPermissions(identity.userInfo.roles);

                    if (_.some(identity.userInfo.roles, function (role) { return role.businessUnit === 0; })) {
                        needWaitResolve = true;
                        $http({ method: 'GET', url: config.apiHostEasyDock + '/businessunit/user', headers: authHeader })
                            .then(function (businessunitResult) {
                                if (businessunitResult && businessunitResult.data && businessunitResult.data.length > 0) {
                                    identity.allBusinessUnitIds = _.pluck(businessunitResult.data, 'id');
                                }
                                deferred.resolve(result.data); // resolve userinfo result
                            }, function (error) {
                                    deferred.resolve(result.data); // resolve userinfo result
                            });
                    }
                }

                if (needWaitResolve === false)
                    deferred.resolve(result.data);

            }, function (error) {
                if (error && error.status && error.status === 401) {
                    identity.set(null);
                    $timeout(function () {
                        $location.path('/account/login');
                    }, 1000);

                }

                deferred.reject(error);
            });

        return deferred.promise;
    }

    function hasPermission(name) {
        if (!name)
            return false;
        name = name.toLowerCase();
        let inverseResult = false;

        if (name.indexOf('!') === 0) {
            inverseResult = true;
            name = name.substr(1, name.length - 1);
        }

        name = name.replace('[', '').replace(']', '');

        if (name.indexOf(',') > -1) {
            var names = name.split(',');
            for (var i = 0; i < names.length; i++) {
                if (_.indexOf(identity.permissions.all, names[i]) > -1) {
                    return true;
                }
            }
            return false;
        }
        else
            return inverseResult ? !(_.indexOf(identity.permissions.all, name) > -1) : _.indexOf(identity.permissions.all, name) > -1;
    }

    function hasAllPermissions(name) {
        if (!name)
            return false;
        name = name.toLowerCase();
        let inverseResult = false;

        if (name.indexOf('!') === 0) {
            inverseResult = true;
            name = name.substr(1, name.length - 1);
        }

        if (name.indexOf(',') > -1) {
            var numberOfPermissions = 0;
            var names = name.split(',');
            for (var i = 0; i < names.length; i++) {
                if (_.indexOf(identity.permissions.all, names[i]) > -1) {
                    numberOfPermissions++;
                }
            }
            return inverseResult ? numberOfPermissions === 0 : numberOfPermissions === names.length;
        }
        else
            return inverseResult ? !(_.indexOf(identity.permissions.all, name) > -1) : _.indexOf(identity.permissions.all, name) > -1;
    }

    function hasPermissionForBusinessUnit(name, businessUnit) {
        return getBusinessUnitsForPermission(name).indexOf(businessUnit) > -1;
    }

    function processPermissions(functionRoles) {
        identity.permissions.businessUnits = [];
        identity.permissions.all = [];
        if (!functionRoles)
            return;

        identity.permissions.businessUnits = functionRoles;

        for (var a = 0; a < functionRoles.length; a++) {
            for (var i = 0; i < functionRoles[a].roles.length; i++) {
                var permissions = _.map(functionRoles[a].roles[i].permissions, function (item) { return item.internalName; });
                identity.permissions.all = _.union(identity.permissions.all, permissions);
            }
        }
    };

    function set(userName, data, rememberMe) {
        if (!data) {
            identity.isAuthenticated = false;
            identity.permissions.all = [];
            identity.permissions.businessUnits = [];
            identity.token.tokenValue = null;
            identity.token.tokenExpiresOn = null;
            identity.token.tokenIssuedOn = null;
            identity.userInfo = null;
            $rootScope.$emit('identity:clear');
            $cookies.remove('authToken');
        }
        else {
            identity.token = {
                tokenValue: data.access_token.token,
                tokenExpiresOn: data.access_token.expiryDate,
                tokenIssuedOn: data.access_token.issueDate
            };

            $cookies.putObject('authToken', identity.token);

            getUserInfo().then(function (userInfo) {
                identity.userInfo = userInfo;
                if (userInfo && userInfo.roles)
                    processPermissions(userInfo.roles);
                identity.isAuthenticated = true;
                $rootScope.$emit('identity:set');
            }, function () {
                identity.isAuthenticated = true;
                $rootScope.$emit('identity:set');
            });
        }
    };

    getFromCookie();

    return identity;
};
