﻿module.exports = ['invoiceDataService', 'invoiceModelsService', '$routeParams', 'widgetModals', 'constantsService', addCreditInvoiceController];

function addCreditInvoiceController(invoiceDataService, invoiceModels, $routeParams, widgetModals, constantsService) {

    var action = {
        createFullCredit: "createFullCredit",
        createCredit: "createCredit",
        createCreditForNewInvoice: "createCreditForNewInvoice",
        createFullCreditAndNew: "createFullCreditAndNew"
    };


    var vm = {
        attached: attached,
        changeExisting: changeExisting,
        createFullCredit: createFullCredit,
        createNew: createNew,
        closeInvoice: closeInvoice,
        currentAction: null,
        getPreview: getPreview,
        confirmNew: confirmNew,
        creditInvoices: [],
        discount: {
            amount: null,
            description: null,
            type: 'perc'
        },
        errors: [],
        newInvoice: null,
        originalInvoice: null,
        invoicePreview: null,
        send: send,
        showRemarksDialog: showRemarksDialog,
        isProforma: false,
        invoiceType: null,
        allowEditOriginalInvoiceLines: false
    };

    function attached() {
        vm.currentAction = null;
        if ($routeParams.invoiceId)
            loadInvoice($routeParams.invoiceId);
    }

    function changeExisting() {
        var newInvoice = new invoiceModels.invoiceModel();
        newInvoice.invoiceItems = [];
        _.extend(newInvoice, vm.originalInvoice);

        // when the invoice is tourist tax, when the quantity is incorrect, fix it
        vm.allowEditOriginalInvoiceLines = (vm.invoiceType !== undefined && vm.invoiceType !== null && vm.invoiceType.systemCode !== undefined && vm.invoiceType.systemCode !== null && vm.invoiceType.systemCode.startsWith("TOURIST_TAX_"));
        newInvoice.invoiceItems = _.map(vm.originalInvoice.invoiceItems, function (item) {
            var newInvoiceItem = _.extend({}, item);

            if (vm.allowEditOriginalInvoiceLines && (newInvoiceItem.totalAmount / newInvoiceItem.unitaryTariff) > newInvoiceItem.quantity) {
                newInvoiceItem.quantity = (newInvoiceItem.totalAmount / newInvoiceItem.unitaryTariff);
            }

            return newInvoiceItem;
        });

        vm.currentAction = action.createCredit;
        vm.newInvoice = newInvoice;
    }

    function createFullCredit() {
        vm.currentAction = action.createFullCredit;
        showRemarksDialog(action.createFullCredit);
    }

    function createNew() {
        var newInvoice = new invoiceModels.invoiceModel();
        newInvoice.invoiceItems = [];
        newInvoice.invoiceItems.push(new invoiceModels.invoiceItemModel());

        vm.newInvoice = newInvoice;
    }

    function closeInvoice() {
        location.href = '/#!/invoices';
    }

    function getPreview() {
        invoiceDataService.getPreview(vm.originalInvoice.realisationId, vm.originalInvoice.invoiceTypeId)
            .then(function (invoice) {
                vm.invoicePreview = invoice.invoice;
                if (invoice.invoice && invoice.invoice.invoiceItems && invoice.invoice.invoiceItems.length > 0) {
                    var items = [];
                    for (var i = 0; i < invoice.invoice.invoiceItems.length; i++) {
                        var item = invoice.invoice.invoiceItems[i];
                        if ((item.description === null || item.description === "") && item.totalAmount === 0 && item.externalItemCode === null && item.quantity === 1 && (item.validationErrors === null || item.validationErrors.length === 0)) {
                           // assume it is an empty item, skip this
                            continue;
                        }
                        items.push(item);
                    }
                    vm.invoicePreview.invoiceItems = items;
                }
            }, function (error) {

            });
    }

    function confirmNew() {
        vm.currentAction = action.createFullCreditAndNew;
        vm.invoicePreview.originalInvoiceId = vm.originalInvoice.id;
        if (vm.originalInvoice.hasCreditInvoice) {
            invoiceDataService.create(vm.invoicePreview, vm.originalInvoice.invoiceTypeId)
                .then(function (result) {
                    vm.invoicePreview = null;
                    loadInvoice(vm.originalInvoice.id);
                });
        } else {

            showRemarksDialog(action.createFullCreditAndNew);

        }
    }

    function hasDiscount() {
        return (vm.discount.amount && parseFloat(vm.discount.amount) > 0) ||
            (vm.discount.description && vm.discount.description !== '');
    }

    function loadCreditInvoice(invoiceId) {
        invoiceDataService.getCredit(invoiceId)
            .then(function (data) {
                vm.creditInvoices = data;
            });
    }

    function loadInvoice(invoiceId) {
        invoiceDataService.getInvoice(invoiceId)
            .then(function (result) {
                vm.originalInvoice = result;
                vm.isProforma = (vm.originalInvoice.status === 'PROFORMA');
                if (vm.originalInvoice.hasCreditInvoice)
                    loadCreditInvoice(vm.originalInvoice.id);
                
                constantsService.preloaded.then(function () {
                    vm.invoiceType = _.find(constantsService.invoiceTypes, function (invoiceType) { return invoiceType.id === result.invoiceTypeId; });
                });
            });
    }

    function send() {
        vm.errors = validateNew();

        if (vm.errors.length > 0) {
            return;
        }

        if (hasDiscount())
            vm.newInvoice.invoiceItems.push({
                costCentre: '',
                description: vm.discount.description,
                discount: parseFloat(vm.discount.amount),
                discountType: 0,
                externalItemCode: '',
                quantity: 1,
                tariffId: null,
                totalAmount: 0,
                unitaryTariff: 0
            });

        __confirmRemarksDialog({ action: action.createCredit });
    }

    function showRemarksDialog(action) {

        widgetModals.remarksConfirmStatus({ title: "Invoice remarks", action: action, invoiceTypes: [vm.invoiceType] })
            .result
            .then(__confirmRemarksDialog, function() {});
    }

    function validateNew() {
        var result = [];

        if (!vm.newInvoice || !vm.newInvoice.invoiceItems || vm.newInvoice.invoiceItems.length === 0)
            result.push('Please add one or more lines to the invoice');

        for (var i = 0; i < vm.newInvoice.invoiceItems.length; i++) {
            var line = vm.newInvoice.invoiceItems[i];
            if (!line.tariffId || line.tariffId === '')
                result.push('Line ' + (i + 1) + ': No tariff type selected');
            if (!line.description || line.description === '')
                result.push('Line ' + (i + 1) + ': No description entered');
            if (line.unitaryTariff === null || line.unitaryTariff === undefined || parseFloat(line.unitaryTariff < 0))
                result.push('Line ' + (i + 1) + ': No tariff entered');
            if (line.quantity === null || line.quantity === undefined || parseFloat(line.quantity < 0))
                result.push('Line ' + (i + 1) + ': No quantity entered');

            // required remarks only for new line
            if ((!line.id || line.id) < 1 && (line.remarks === undefined || line.remarks === null || line.remarks.trim() === ''))
                result.push("Please select/enter a (discount) remarks");
        }

        if (hasDiscount()) {
            if (!vm.discount.amount || isNaN(parseFloat(vm.discount.amount)))
                result.push('Please add a valid discount amount, or remove the discount description');
            if (!vm.discount.description || vm.discount.description === '')
                result.push('Please add a discount description, or remove the discount amount');
        }

        return result;
    }

    function __confirmRemarksDialog(response) {
        var remarks = null;
        if (response.remarks) {

            if (response.remarks instanceof Array) {
                if (response.remarks.length > 0) {
                    remarks = response.remarks[0];
                }
            } else {
                remarks = response.remarks;
            }
        }

        if (response.action) {
            switch (response.action) {
                case action.createFullCreditAndNew:
                    vm.invoicePreview.remarks = remarks;
                    vm.originalInvoice.remarks = remarks;

                    invoiceDataService.createFullCredit(vm.originalInvoice.id, vm.originalInvoice)
                        .then(function (result) {
                            invoiceDataService.create(vm.invoicePreview, vm.originalInvoice.invoiceTypeId)
                                .then(function (result) {
                                    vm.invoicePreview = null;
                                    loadInvoice(vm.originalInvoice.id);
                                });
                        });
                    break;
                case action.createFullCredit:
                    vm.originalInvoice.remarks = remarks;

                    invoiceDataService.createFullCredit(vm.originalInvoice.id, vm.originalInvoice)
                        .then(closeInvoice);
                    break;
                case action.createCredit:

                    vm.newInvoice.remarks = remarks;
                    vm.newInvoice.debtorNumber = vm.originalInvoice.debtorNumber;
                    invoiceDataService.createCredit(vm.originalInvoice.id, vm.newInvoice)
                        .then(function (result) {
                            vm.newInvoice = null;
                            loadInvoice(vm.originalInvoice.id);
                        });
                    break;
                case action.createCreditForNewInvoice:

                    vm.invoicePreview.remarks = remarks;
                    invoiceDataService.createCredit(vm.originalInvoice.id, vm.invoicePreview)
                        .then(function (result) {
                            vm.invoicePreview = null;
                            loadInvoice(vm.originalInvoice.id);
                        });
                    break;
            }
        }
    }

    return vm;
}
