﻿angular.module('poa.rprp.arrivals', [])
    .config(['$routeProvider', routeConfig])
    // Controller
    .controller('arrivalsController', require('./controllers/arrivals'))
    .controller('arrivalsReviewController', require('./controllers/arrivals-review'))
    .controller('arrivalsReviewGroupedController', require('./controllers/arrivals-review-grouped'))
    .controller('reviewRealisationController', require('./controllers/review'))
    .controller('modalImportArrivalsController', require('./modals/import-arrivals'))
    // Services
    .factory('arrivalsModelService', require('./services/arrivals-models'))
    .factory('arrivalsDataService', require('./services/arrivals-data'))
    //Filters
    .filter('arrivalsDateDisplay', require('./filters/arrivals-date-display'))
    .filter('arrivalsStatusDisplay', require('./filters/arrivals-status-display'))
    //directives
    .directive('arrivalsList', require('./directives/arrivals-list'))
    .directive('newArrival', require('./directives/new-arrival'))
    .directive('arrivalsReceipt', require('./directives/arrivals-receipt'))
    .directive('timelineView', require('./directives/timeline-view'))
    .directive('arrivalsStatusLabel', require('./directives/arrivals-status-label'));

function routeConfig($routeProvider) {
    $routeProvider
        .when('/arrivals/review', {
            templateUrl: 'templates/arrivals/controllers/arrivals-review.html'
        })
        .when('/arrivals/review/:flowName', {
            templateUrl: 'templates/arrivals/controllers/arrivals-review.html'
        })
        .when('/arrivals/review/ship/:shipId', {
            templateUrl: 'templates/arrivals/controllers/arrivals-review-grouped.html'
        })
        .when('/arrivals', {
            templateUrl: 'templates/arrivals/controllers/arrivals.html'
        })
        .when('/arrivals/:arrivalId', {
            templateUrl: 'templates/arrivals/controllers/arrivals.html'
        });
}
