﻿angular.module('poa.rprp.users', [])
    .config(['$routeProvider', routeConfig])
// Controllers
    .controller('adminUserOverviewController', require('./controllers/admin-user-overview'))
    .controller('userOverviewController', require('./controllers/user-overview'))
// Directives
    .directive('adminUserForm', require('./directives/admin-user-form'))
    .directive('userForm', require('./directives/user-form'))
// Services
    .factory('userDataService', require('./services/user-data'))
    .factory('userModelsService', require('./services/user-models'))
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/users', {
            templateUrl: 'templates/users/controllers/admin-user-overview.html'
        })
        .when('/users/:userId', {
            templateUrl: 'templates/users/controllers/admin-user-overview.html'
        });
};
