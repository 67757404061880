﻿'use strict';
module.exports = [reservationDateDisplayFilter];

function reservationDateDisplayFilter() {
    return function (reservation, startField, endField) {
        if (!reservation)
            return null;

        if (startField === undefined || startField === null)
            startField = 'eta';

        if (endField === undefined || endField === null)
            endField = 'etd';

        if (!reservation[startField] || !reservation[endField])
            return null;

        var eta = moment(reservation[startField]);
        var etd = moment(reservation[endField]);

        if (eta.year() === etd.year()) {
            if (eta.month() === etd.month()) {
                if (eta.dayOfYear() === etd.dayOfYear()) {
                    return etd.format('D MMM YYYY') + ' | ' + eta.format('HH:mm') + ' - ' + etd.format('HH:mm');
                }
                return eta.format('D') + ' - ' + etd.format('D MMM YYYY') + ' | ' + eta.format('HH:mm') + ' - ' + etd.format('HH:mm');
            }
            return eta.format('D MMM') + ' - ' + etd.format('D MMM YYYY') + ' | ' + eta.format('HH:mm') + ' - ' + etd.format('HH:mm');
        }
        return eta.format('D MMM YYYY') + ' - ' + etd.format('DD MMM YYYY') + ' | ' + eta.format('HH:mm') + ' - ' + etd.format('HH:mm');
    };
};
