﻿module.exports = ['$routeParams', 'berthDataService', berthMainController];

function berthMainController($routeParams, berthData) {
    var vm = {
        activeBerth: null,
        berths: [],
        onBerthSaved: onBerthSaved,
        onBerthDeleted: onBerthDeleted,
        initialActiveBerthFromUrl: initialActiveBerthFromUrl
    }

    init();

    return vm;

    function init() {
        loadBerths();
        initialActiveBerthFromUrl();
    }

    function onBerthSaved(savedBerth, isNew) {
        if (isNew) {
            vm.berths.push(savedBerth);
        }

        vm.activeBerth = null;
        setBerths(vm.berths);
    }
    function onBerthDeleted(deletedBerth) {
        var idxBerth = _.findIndex(vm.berths, function (item) { return item.id === deletedBerth.id; });
        if (idxBerth >= 0)
            vm.berths.splice(idxBerth, 1);

        vm.activeBerth = null;
    }

    function loadBerths() {
        berthData.getBerths()
            .then(function (result) {
                setBerths(result);
                vm.activeBerth = null;
            });
    }

    function setBerths(value){
        vm.berths = _.sortBy(_.map(value, function (berth) {
            berth.isActive = (!berth.availableUntil || moment(berth.availableUntil).isAfter()) &&
            (!berth.availableFrom || moment(berth.availableFrom).isBefore());
            return berth;
        }), 'name');
    }

    function initialActiveBerthFromUrl() {
        var berthId = $routeParams.berthId;
        if (typeof (berthId) === "number" && berthId > 0) {
        }
    }
}