﻿'use strict';
module.exports = ['toastr', 'coreDataService', 'berthDataService', berthCommercialGoalFormDirective];

function berthCommercialGoalFormDirective(toastr, coreDataService, berthDataService) {
    var directive = {
        controller: berthCommercialGoalFormController,
        controllerAs: 'ctrlBCGF',
        restrict: 'E',
        scope: {
            berthId: '@'
        },
        bindToController: true,
        link: berthCommercialGoalFormLink,
        templateUrl: 'templates/berth/directives/berth-commercialgoal-form.html'
    };
    return directive;

    function berthCommercialGoalFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () { return ctrl.berthId; },
            function (newValue, oldValue) {
                if (!newValue) return;

                ctrl.getBerthCommercialgoals();
            });
    }

    function berthCommercialGoalFormController() {
        var vm = {
            commercialgoals: [],
            berthCommercialgoals:[],
            selectedBerthCommercialgoalIds: [],
            getBerthCommercialgoals: getBerthCommercialgoals,
            save: save
        };

        init();

        return vm;

        function init() {
            coreDataService.getCommercialgoals().then(function (data) {
                vm.commercialgoals = data;
            });
        }

        function save() {
            var berthId = parseInt(vm.berthId) || 0;
            var commercialgoalIdsToAdd = vm.selectedBerthCommercialgoalIds;
            var commercialgoalIdsToDel = [];

            if (vm.berthCommercialgoals.length > 0) {

                commercialgoalIdsToDel = vm.berthCommercialgoals.filter(function (item) {
                    return vm.selectedBerthCommercialgoalIds.indexOf(item.id) === -1;
                });
                commercialgoalIdsToDel = _.map(commercialgoalIdsToDel, function (item) { return item.id });

                commercialgoalIdsToAdd = vm.selectedBerthCommercialgoalIds.filter(function (itemId) {
                    var orgIds = _.map(vm.berthCommercialgoals, function (item) { return item.id });
                    return orgIds.indexOf(itemId) === -1;
                });
            }

            if (berthId > 0 && (commercialgoalIdsToDel.length > 0 || commercialgoalIdsToAdd.length > 0)) {
                berthDataService.updateBerthCommercialgoals(berthId, commercialgoalIdsToAdd, commercialgoalIdsToDel)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
                   
                }

                function successResult(result) {
                    vm.berthCommercialgoals = result;
                }
            }
            toastr.success("Changes Saved");
        }

        function getBerthCommercialgoals() {
            var berthId = parseInt(vm.berthId) || 0;
            if (berthId > 0) {
                berthDataService.getBerthCommercialgoals(berthId)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
                }

                function successResult(result) {
                    vm.berthCommercialgoals = result;
                    vm.selectedBerthCommercialgoalIds = _.map(vm.berthCommercialgoals, function (item) { return item.id });
                }
            }
        }
    }
}
