﻿'use strict';

module.exports = ['reportDataService', fleetLengthPerFleetDirective];

function fleetLengthPerFleetDirective(reportData) {
    var directive = {
        bindToController: true,
        controller: fleetLengthPerFleetController,
        controllerAs: 'ctrlFLPF',
        restrict: 'E',
        scope: {
        },
        templateUrl: 'templates/report/directives/fleet-length-per-fleet.html'
    };

    return directive;

    function fleetLengthPerFleetController() {
        var vm = {
            labels: [],
            data: [],
            options: null
        }

        init();

        return vm;

        function init() {
            loadFleetLengthPerFleet();
        }

        function loadFleetLengthPerFleet() {
            reportData.getFleetLengthPerFleet()
            .then(successResult)
            .catch(errorResult);
            function errorResult(response) {
            }

            function successResult(result) {
                vm.labels = _.map(result, function (item) { return item.label });
                vm.data = _.map(result, function (item) { return item.dataPoint });
            }
        }
    }
}