﻿module.exports = ['common', 'dataContext', planningDataService];

function planningDataService(common, context) {

    var service = {
        addPlanning: addPlanning,
        addPlanningItem: addPlanningItem,
        cancelReservation: cancelReservation,
        communicateStatus: communicateStatus,
        resendConfirmation: resendConfirmation,
        deletePlanningItem: deletePlanningItem,
        getPlanned: getPlanned,
        getPlannedById: getPlannedById,
        getSanityCheck: getSanityCheck,
        getStatus: getStatus,
        getSuggestion: getSuggestion,
        getSuggestions: getSuggestions,
        sendISPSNotification: sendISPSNotification,
        setStatus: setStatus,
        updatePlanning: updatePlanning,
        updatePlanningItem: updatePlanningItem,

        easyDockBusinessUnits: [1],
        cruiseDockBusinessUnit: [2, 3, 4, 5],
        easyDockFlows: [1, 6],
        cruiseDockFlows: [2, 3, 4, 5, 7, 8, 23],
        cruiseDockObstructionsBusinessRuleId: 3,
        getCruiseDockBerthGroups: getCruiseDockBerthGroups,
        getCruiseDockObstructions: getCruiseDockObstructions
    };

    return service;

    function addPlanning(planning) {
        if (planning.reservationId && !planning.reservation)
            planning.reservation = { id: planning.reservationId };

        var deferred = common.$q.defer();

        var req = context.post('/planning', planning)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function addPlanningItem(planningItem) {
        var deferred = common.$q.defer();

        var req = context.post('/planning/' + planningItem.reservationId + '/item', planningItem)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function cancelReservation(reservationId, reason) {
        var deferred = common.$q.defer();

        var url = '/planning/' + reservationId;

        if (reason !== undefined && reason !== null) {
            url = url + '?reason=' + reason;
        }

        var req = context.del(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function communicateStatus(reservationId, status, remarks) {
        var deferred = common.$q.defer();

        var args = {
            remarks: remarks,
            status: status
        };

        var req = context.post('/planning/' + reservationId + '/status', args)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function resendConfirmation(reservationId) {
        var deferred = common.$q.defer();

        var req = context.post('/planning/' + reservationId + '/confirmation')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deletePlanningItem(planningItemId) {
        var deferred = common.$q.defer();

        var req = context.del('/planning/item/' + planningItemId)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getPlanned(dateFrom, dateUntil, businessUnits, flows) {
        var deferred = common.$q.defer();

        if (!dateFrom || !dateUntil)
            deferred.reject('Please supply valid dates');

        dateFrom = (moment.isMoment(dateFrom) ? dateFrom : moment(dateFrom)).format('YYYY-MM-DD');
        dateUntil = (moment.isMoment(dateUntil) ? dateUntil : moment(dateUntil)).format('YYYY-MM-DD');

        var argsEasyDock = [];
        argsEasyDock.push('dateFrom=' + dateFrom);
        argsEasyDock.push('dateUntil=' + dateUntil);

        var argsCruiseDock = Object.assign([], argsEasyDock);

        if (businessUnits && businessUnits.length > 0) {
            var EDBusinessUnits = _.intersection(businessUnits, service.easyDockBusinessUnits);
            if (EDBusinessUnits && EDBusinessUnits.length > 0) {
                argsEasyDock.push('businessUnits=' + EDBusinessUnits.join(','));
            }

            var CDBusinessUnits = _.intersection(businessUnits, service.cruiseDockBusinessUnits);
            if (CDBusinessUnits && CDBusinessUnits.length > 0) {
                argsCruiseDock.push('businessUnits=' + CDBusinessUnits.join(','));
            }
        }

        if (flows && flows.length > 0) {
            var EDFlows = _.intersection(flows, service.easyDockFlows);
            if (EDFlows && EDFlows.length > 0) {
                argsEasyDock.push('flows=' + EDFlows.join(','));
            }

            var CDFlows = _.intersection(flows, service.cruiseDockFlows);
            if (CDFlows && CDFlows.length > 0) {
                argsCruiseDock.push('flows=' + CDFlows.join(','));
            }
        }

        var reqEasyDock = context.get('/planning?' + argsEasyDock.join('&'));
        var reqCruiseDock = context.get('/planning?' + argsCruiseDock.join('&'), 'cruisedock');

        common.$q.all([reqEasyDock, reqCruiseDock]).then(function (data) {

            var easydockData = data[0] !== null && data[0] !== undefined ? data[0] : [];
            var cruisedock = data[1] !== null && data[1] !== undefined ? data[1] : [];

            easydockData.forEach(element => element.reservation.apiSource = 'easydock');
            cruisedock.forEach(element => element.reservation.apiSource = 'cruisedock');

            var combinedData = easydockData.concat(cruisedock);
            deferred.resolve(combinedData);
        }, function (error) {
            if (error.config.url.includes('cruisedock')) {
                reqEasyDock.then(function (data) {
                    var easydockData = data !== null && data !== undefined ? data : [];
                    deferred.resolve(easydockData.forEach(element => element.reservation.apiSource = 'easydock'));
                });
            } else {
                deferred.reject(error);
            }
        });

        return deferred.promise;
    }

    function getPlannedById(reservationId) {
        var deferred = common.$q.defer();

        var req = context.get('/planning/' + reservationId)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getStatus(reservationId, includeUserInfo) {
        var deferred = common.$q.defer();

        var args = [];
        if (includeUserInfo === true) {
            args.push('includeUserInfo=' + includeUserInfo);
        }

        var req = context.get('/planning/' + reservationId + '/status?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getSanityCheck(plannings, dateFrom, dateUntil, businessUnits, flows) {
        var deferred = common.$q.defer();

        var esayDockPlannings = _.filter(plannings, function (planning) { return _.contains(service.easyDockFlows, planning.processFlowId); });
        var cruiseDockPlannings = _.filter(plannings, function (planning) { return _.contains(service.cruiseDockFlows, planning.processFlowId); });

        var argsEasyDock = [];
        argsEasyDock.push('startperiod=' + dateFrom);
        argsEasyDock.push('endperiod=' + dateUntil);

        var argsCruiseDock = [];
        argsCruiseDock.push('startperiod=' + dateFrom);
        argsCruiseDock.push('endperiod=' + dateUntil);

        if (businessUnits && businessUnits.length > 0) {
            var EDBusinessUnits = _.intersection(businessUnits, service.easyDockBusinessUnits);
            if (EDBusinessUnits && EDBusinessUnits.length > 0) {
                argsEasyDock.push('businessUnits=' + EDBusinessUnits.join(','));
            }

            var CDBusinessUnits = _.intersection(businessUnits, service.cruiseDockBusinessUnits);
            if (CDBusinessUnits && CDBusinessUnits.length > 0) {
                argsCruiseDock.push('businessUnits=' + CDBusinessUnits.join(','));
            }
        }

        if (flows && flows.length > 0) {
            var EDFlows = _.intersection(flows, service.easyDockFlows);
            if (EDFlows && EDFlows.length > 0) {
                argsEasyDock.push('flows=' + EDFlows.join(','));
            }

            var CDFlows = _.intersection(flows, service.cruiseDockFlows);
            if (CDFlows && CDFlows.length > 0) {
                argsCruiseDock.push('flows=' + CDFlows.join(','));
            }
        }

        var reqEasyDock = context.post('/planning/sanitycheck?' + argsEasyDock.join('&'), esayDockPlannings);

        common.$q.all([reqEasyDock]).then(function (data) {
            var easydockData = data[0] !== null ? data[0] : { berthSanityIssues: [], planningSanityIssues: [] };
            var result = {
                berthSanityIssues: easydockData.berthSanityIssues,
                planningSanityIssues: easydockData.planningSanityIssues
            };
            deferred.resolve(result);
        }, function (error) {
            deferred.reject(error);
        });

        return deferred.promise;
    }

    function getSuggestion(reservationId, processFlowId) {
        var deferred = common.$q.defer();

        var args = [reservationId];

        var request;
        if (!processFlowId || _.contains(service.easyDockFlows, processFlowId)) {
            request = context.post('/planning/suggestion', args);
        } else {
            request = context.post('/planning/suggestion', args, 'cruisedock');
        }

        var req = request
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getSuggestions(dateFrom, dateUntil, businessUnits, flows) {
        var deferred = common.$q.defer();

        var endDate = moment(dateUntil, 'YYYY-MM-DD');

        var argsEasyDock = [];
        argsEasyDock.push('dateFrom=' + dateFrom);
        argsEasyDock.push('dateUntil=' + endDate.add(1, 'days').format('YYYY-MM-DD'));

        var argsCruiseDock = Object.assign([], argsEasyDock);

        if (businessUnits && businessUnits.length > 0) {
            var EDBusinessUnits = _.intersection(businessUnits, service.easyDockBusinessUnits);
            if (EDBusinessUnits && EDBusinessUnits.length > 0) {
                argsEasyDock.push('businessUnits=' + EDBusinessUnits.join(','));
            }

            var CDBusinessUnits = _.intersection(businessUnits, service.cruiseDockBusinessUnits);
            if (CDBusinessUnits && CDBusinessUnits.length > 0) {
                argsCruiseDock.push('businessUnits=' + CDBusinessUnits.join(','));
            }
        }

        if (flows && flows.length > 0) {
            var EDFlows = _.intersection(flows, service.easyDockFlows);
            if (EDFlows && EDFlows.length > 0) {
                argsEasyDock.push('flows=' + EDFlows.join(','));
            }

            var CDFlows = _.intersection(flows, service.cruiseDockFlows);
            if (CDFlows && CDFlows.length > 0) {
                argsCruiseDock.push('flows=' + CDFlows.join(','));
            }
        }

        var reqEasyDock = context.get('/planning/suggestion?' + argsEasyDock.join('&'));
        var reqCruiseDock = context.get('/planning/suggestion?' + argsCruiseDock.join('&'), 'cruisedock');

        common.$q.all([reqEasyDock, reqCruiseDock]).then(function (data) {

            var easydockData = data[0] !== null ? data[0] : [];;
            var cruisedockData = data[1] !== null ? data[1] : [];;

            easydockData.forEach(element => element.planning.reservation.apiSource = 'easydock');
            cruisedockData.forEach(element => element.planning.reservation.apiSource = 'cruisedock');

            var data = easydockData.concat(cruisedockData);
            deferred.resolve(data);
        }, function (error) {
            deferred.reject(error);
        });

        return deferred.promise;
    }

    function sendISPSNotification(reservationId) {
        var deferred = common.$q.defer();

        var req = context.post('/planning/' + reservationId + '/ispsnotification', {})
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function setStatus(reservationId, status) {
        var deferred = common.$q.defer();

        var req = context.post('/planning/' + reservationId + '/status', status)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updatePlanning(planning) {
        var deferred = common.$q.defer();

        var req = context.put('/planning/' + planning.reservationId, planning)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updatePlanningItem(planningItem) {
        var deferred = common.$q.defer();

        var req = context.put('/planning/item/' + planningItem.id, planningItem)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCruiseDockBerthGroups() {
        var deferred = common.$q.defer();
        var url = '/berthgroup/details/flows/' + service.cruiseDockFlows.join(',')

        context.get(url, 'cruisedock')
            .then(function (data) {
                data.sort((a, b) => (a.sortOrder || Number.MAX_VALUE) - (b.sortOrder || Number.MAX_VALUE));
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCruiseDockObstructions(dateFrom, dateUntil) {
        var deferred = common.$q.defer();
        var url = '/berth/businessrule?type=' + service.cruiseDockObstructionsBusinessRuleId + '&dateFrom=' + dateFrom + '&dateUntil=' + dateUntil;

        context.get(url, 'cruisedock')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getByType(businessRuleId, dateFrom, dateUntil) {
        var deferred = common.$q.defer();
        var req = context.get('/berth/businessrule?type=' + businessRuleId + '&dateFrom=' + dateFrom + '&dateUntil=' + dateUntil)
            .then(function (data) {
                deferred.resolve(_.map(data, businessruleModels.businessruleModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}
