﻿module.exports = ['constantsService', shiptypeDisplayFilter];

function shiptypeDisplayFilter(constants) {
    return function (shipTypeId) {
        if (!shipTypeId)
            return null;
        var shipType = _.find(constants.shipTypes, function (item) {
            return item.id === shipTypeId;
        });

        if (shipType)
            return shipType.name;

        return shipTypeId;
    };
};
