﻿module.exports = ['common', 'dataContext', reservationDataService];

function reservationDataService(common, context) {

    var service = {
        addMessage: addMessage,
        addReservations: addReservations,
        countUnplanned: countUnplanned,
        getDetails: getDetails,
        getMessages: getMessages,
        getMessagesForCustomer: getMessagesForCustomer,
        getUnplanned: getUnplanned,
        getReservationsForCustomer: getReservationsForCustomer,
        getReservationPlanning: getReservationPlanning,
        getReservationHistory: getReservationHistory,
        compareHours: compareHours,
        updateUCRNByRealisation: updateUCRNByRealisation,
        updateReservation: updateReservation
    };

    return service;

    function addMessage(reservationId, text, isIntern, customerId, status) {
        var deferred = common.$q.defer();

        var args = {
            isIntern: isIntern,
            status: status,
            text: text,
            customerId: customerId
        };

        var req = context.post('/reservation/' + reservationId + '/message', args)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function addReservations(reservations, shipTypeId, remarks) {
        var deferred = common.$q.defer();

        var arr = _.map(reservations, (res) => {
            if (!res.ship)
                res.ship = {};

            if (!res.ship.shipTypeId)
                res.ship.shipTypeId = shipTypeId;

            return {
                reservation: res,
                remarks: remarks
            };
        });

        var req = context.post('/reservation', arr)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function countUnplanned(businessUnits) {
        var deferred = common.$q.defer();

        var args = [];
        if (businessUnits && businessUnits.length > 0)
            args.push('businessUnits=' + businessUnits.join(','));

        var req = context.get('/reservation/pending/count?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getDetails(reservationId, apiSource) {
        var deferred = common.$q.defer();
        var req = context.get('/reservation/' + reservationId, apiSource)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getMessages(reservationId) {
        var deferred = common.$q.defer();
        var req = context.get('/reservation/' + reservationId + '/message')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }


    function getMessagesForCustomer(reservationId, customerId) {
        var deferred = common.$q.defer();
        var req = context.get('/reservation/' + reservationId + '/message/' + customerId)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getUnplanned(pageIndex, pageSize, businessUnits, status, searchTerm, flows) {
        var deferred = common.$q.defer();
        
        var args = [];
        args.push('pindex=' + pageIndex);
        args.push('psize=' + pageSize);

        args.push('flows=1,6');
        args.push('businessUnits=1');

        if (status)
            args.push('status=' + status);

        if (searchTerm)
            args.push('query=' + searchTerm);

        var req = context.get('/reservation/pending?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getReservationsForCustomer(customerId) {
        var deferred = common.$q.defer();
        var url = '/reservation/customer/' + customerId;

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getReservationPlanning(id) {
        var deferred = common.$q.defer();
        var req = context.get('/planning/' + id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getReservationHistory(id, dateFrom) {
        var deferred = common.$q.defer();

        var args = [];
        if (dateFrom)
            args.push('dateFrom=' + dateFrom);

        var req = context.get('/audit/reservation/' + id + '?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function compareHours(date1, date2) {
        let difference = moment(date1).diff(moment(date2), 'hours');
        if (difference !== 0) {
            return false;
        } else {
            return true;
        }
    }

    function updateUCRNByRealisation(reservationId) {
        var deferred = common.$q.defer();
        var req = context.put('/reservation/' + reservationId + '/crn')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updateReservation(reservation, remarks) {
        var deferred = common.$q.defer();

        var req = context.put('/reservation/' + reservation.id, { reservation: reservation, remarks: remarks })
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}
