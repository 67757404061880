﻿module.exports = ['common', planningReceiptDirective];

function planningReceiptDirective(common) {

    var directive = {
        bindToController: true,
        controller: planningReceiptController,
        controllerAs: 'ctrlPlanningReceipt',
        link: function (ctrl) {
        },
        restrict: 'E',
        scope: {
            planningData: '='
        },
        templateUrl: 'templates/planning/directives/planning-receipt.html'
    };
    return directive;

    function planningReceiptController() {
        var ctrlPlanningReceipt = this;
        ctrlPlanningReceipt.datePickerOptions = {
            altInputFormats: [
                'd-M-yyyy',
                'd-M-yyyy H:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yyyy',
                'dd-MM-yyyy H:mm',
                'dd-MM-yyyy HH:mm',
                'yyyy-MM-ddTHH:mm:ss'
            ]
        };

        ctrlPlanningReceipt.getFacilitiesDescription = function (facilities) {
            return _.map(facilities, function (item) {
                return item.facility.name;
            }).join(', ');
        }
    }

};