﻿module.exports = ['common', 'dataContext', searchDataService];

function searchDataService(common, context) {

    var service = {
        find: find,
        searchResuls: [],
        searchQuery: null,
        searchLocation: null,
        returnToSearchResults: returnToSearchResults
    };

    return service;

    function find(searchText) {
        var dfr = common.$q.defer();

        context
            .get('/search?q=' + searchText)
            .then(function (result) {
                dfr.resolve(result);
            }, function (error) {
                dfr.reject(error);
            });

        return dfr.promise;
    }
    function returnToSearchResults(q, l) {
        
    }
}