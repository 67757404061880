﻿module.exports = ['$uibModalInstance', 'modalArguments', 'userDataService',  resetPasswordModalController];

function resetPasswordModalController($uibModalInstance, args, userData) {
    var vm = {
        args: args,
        cancel: cancel,
        ok: ok,
        isSaving: false,
        newPassword: null,
        repeatedPassword: null,
        sendConfirmation: false,
        errors: []
    };

    function cancel() {
        $uibModalInstance.close(false);
    }

    function ok() {
        vm.isSaving = true;
        if (validatePasswordReset() !== true) {
            vm.isSaving = false;
            return;
        }

        userData.resetPassword(args.userEmail, vm.newPassword, vm.sendConfirmation)
            .then(function (data) {
                vm.isSaving = false;
                $uibModalInstance.close(true);
            });
    }

    function validatePasswordReset() {
        var errors = [];

        if (vm.newPassword === null || vm.newPassword === '')
            errors.push('Please specify a password');
        else if (vm.repeatedPassword === null || vm.repeatedPassword === '')
            errors.push('Please confirm your password');
        else if (vm.newPassword !== vm.repeatedPassword)
            errors.push('The passwords do not match');

        vm.errors = errors;
        return errors.length > 0 ? false : true;
    }

    return vm;
};