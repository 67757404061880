﻿'use strict';
module.exports = ['toastr', 'coreDataService', 'berthDataService', berthShipTypeFormDirective];

function berthShipTypeFormDirective(toastr ,coreDataService, berthDataService) {
    var directive = {
        bindToController: true,
        controller: berthShipTypeFormController,
        controllerAs: 'ctrlBSF',
        restrict: 'E',
        scope: {
            berthId: '@'
        },
        link: berthShipTypeFormLink,
        templateUrl: 'templates/berth/directives/berth-shiptype-form.html'
    };
    return directive;

    function berthShipTypeFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () { return ctrl.berthId; },
            function (newValue, oldValue) {
                if (!newValue) return;

                ctrl.getBerthShiptypes();
            });
    }

    function berthShipTypeFormController() {
        var vm = {
            shiptypes: [],
            berthShiptypes: [],
            selectedBerthShiptypeIds:[],
            getBerthShiptypes: getBerthShiptypes,
            save: save
        };

        init();

        return vm;

        function init() {
            coreDataService.getShiptypes().then(function (data) {
                vm.shiptypes = data;
            });
        }

        function save() {
            var berthId = parseInt(vm.berthId) || 0;
            var shiptypeIdsToDel = [];
            var shiptypeIdsToAdd = vm.selectedBerthShiptypeIds;

            if (vm.berthShiptypes.length > 0) {

                shiptypeIdsToDel = vm.berthShiptypes.filter(function (item) {
                    return vm.selectedBerthShiptypeIds.indexOf(item.id) === -1;
                });
                shiptypeIdsToDel = _.map(shiptypeIdsToDel, function (item) { return item.id });

                shiptypeIdsToAdd = vm.selectedBerthShiptypeIds.filter(function (itemId) {
                    var orgIds = _.map(vm.berthShiptypes, function (item) { return item.id });
                    return orgIds.indexOf(itemId) === -1;
                });
            }

            if (berthId > 0 && (shiptypeIdsToDel.length > 0 || shiptypeIdsToAdd.length > 0)) {
                berthDataService.updateBerthShiptypes(berthId, shiptypeIdsToAdd, shiptypeIdsToDel)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
                 
                }

                function successResult(result) {
                    vm.berthShiptypes = result;
                }
            }
            toastr.success("Changes Saved");
        }

        function getBerthShiptypes() {
            var berthId = parseInt(vm.berthId) || 0;
            if (berthId > 0) {
                berthDataService.getBerthShiptypes(berthId)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
                  
                }

                function successResult(result) {
                    vm.berthShiptypes = result;
                
                    vm.selectedBerthShiptypeIds = _.map(vm.berthShiptypes, function (item) { return item.id });
                }
            }
        }
    }
}