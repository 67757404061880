﻿module.exports = ['common', 'dataContext', messageDataService];

function messageDataService(common, context) {

    var service = {
        addMessageToConversation: addMessageToConversation,
        getMessages: getMessages,
        getConversationMessages: getConversationMessages,
        getCustomerMessagesForCustomer: getCustomerMessagesForCustomer,
        getMessageInbox: getMessageInbox,
        getMessageInboxForCustomer: getMessageInboxForCustomer,
        getCustomerMessageInboxForCustomer: getCustomerMessageInboxForCustomer,
        getPendingMessageCount: getPendingMessageCount,
        getReservationMessages: getReservationMessages,
        getLatestMessages: getLatestMessages,
        addCustomerMessage: addCustomerMessage
    };

    function addMessageToConversation(conversationId, text, isInternal, status) {
        var deferred = common.$q.defer();

        var args = {
            isIntern: isInternal,
            status: status,
            text: text
        };

        var req = context.post('/message/' + conversationId, args)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getConversationMessages(conversationId) {
        var deferred = common.$q.defer();
        context.get('/message/' + conversationId)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getMessageInbox(pageSize, pageIndex, filter) {
        if (pageIndex === null || pageIndex === undefined)
            pageIndex = 0;
        if (pageSize === null || pageSize === undefined)
            pageSize = 10;

        var deferred = common.$q.defer();
        context.get('/message/inbox?pindex=' + pageIndex + '&psize=' + pageSize + '&filter=' + filter)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getMessageInboxForCustomer(customerId, pageSize, pageIndex) {
        if (pageIndex === null || pageIndex === undefined)
            pageIndex = 0;
        if (pageSize === null || pageSize === undefined)
            pageSize = 10;

        var deferred = common.$q.defer();
        var req = context.get('/message/customer/inbox/' + customerId + '?pindex=' + pageIndex + '&psize=' + pageSize)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCustomerMessageInboxForCustomer(customerId, pageSize, pageIndex) {
        if (pageIndex === null || pageIndex === undefined)
            pageIndex = 0;
        if (pageSize === null || pageSize === undefined)
            pageSize = 10;

        var deferred = common.$q.defer();
        var req = context.get('/message/customer-messages/inbox/' + customerId + '?pindex=' + pageIndex + '&psize=' + pageSize)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getMessages(pageSize, pageIndex) {
        if (pageIndex === null || pageIndex === undefined)
            pageIndex = 0;
        if (pageSize === null || pageSize === undefined)
            pageSize = 10;

        var deferred = common.$q.defer();
        var req = context.get('/message?pindex=' + pageIndex + '&psize=' + pageSize)
            .then(function (data) {
                messagesToLocalTime(data);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCustomerMessagesForCustomer(customerId) {
        var deferred = common.$q.defer();
        var req = context.get('/message/customer-messages/' + customerId)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getPendingMessageCount() {
        var deferred = common.$q.defer();
        context.get('/message/pending/count')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getReservationMessages(reservationId) {
        var deferred = common.$q.defer();
        var req = context.get('/reservation/' + reservationId + '/message')
            .then(function (data) {
                messagesToLocalTime(data);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getLatestMessages() {
        var deferred = common.$q.defer();

        var req = context.get('/message/latest')
            .then(function (data) {
                messagesToLocalTime(data);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function addCustomerMessage(text, isIntern, customerId) {
        var deferred = common.$q.defer();

        var args = {
            isIntern: isIntern,
            text: text
        };

        var req = context.post('/customer/' + customerId + '/message', args)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function messagesToLocalTime(data) {
        return _.map(data, function (item) {
            item.createdOn = moment(item.createdOn).add(moment().utcOffset(), 'minutes').format('DD MMMM YYYY HH:mm');
            return item;
        });
    }

    return service;

}