﻿module.exports = ['constantsService', 'invoiceRemarksDataService', 'invoiceRemarksModelsService', 'widgetModals', invoiceRemarksFormDirective];

function invoiceRemarksFormDirective(constants, invoiceRemarksDataService, invoiceRemarksModelsService, widgetModals) {
    var directive = {
        bindToController: true,
        controller: invoiceRemarksFormController,
        controllerAs: 'ctrlInvoiceRemarksForm',
        restrict: 'E',
        scope: {
            invoiceRemarksData: '=',
            onInvoiceRemarksUpdate: '&'
        },
        link: invoiceRemarksFormLink,
        templateUrl: 'templates/invoice-remarks/directives/invoice-remarks-form.html'
    };

    return directive;

    function invoiceRemarksFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () {
            return ctrl.invoiceRemarksData;
        }, function (newValue, oldValue) {
            if (!newValue) {
                return;
            }
            var invoiceRemarksId = newValue.id || 0;
            ctrl.isNewInvoiceRemarks = invoiceRemarksId <= 0;
                if (ctrl.isNewInvoiceRemarks && ctrl.invoiceRemarksData.systemCode === null) {
                    ctrl.invoiceRemarksData.systemCode = ctrl.systemCodeOption[0];
                }
        }, true);
    }

    function invoiceRemarksFormController() {
        var vm = {
            deleteInvoiceRemarks: deleteInvoiceRemarks,
            saveInvoiceRemarks: saveInvoiceRemarks,
            isNewInvoiceRemarks: true,
            invoiceRemarksGroups: [],
            systemCodeOption:['DEFAULT','NO_INVOICE','FULL_DISCOUNT'],
            invoiceTypesOption: [],
            status: {
                errors: []
            },
        };

        function init() {
            constants.preloaded
                .then(function () {
                    vm.invoiceTypesOption = constants.invoiceTypes;
                }, function () { });
        }

        init();

        return vm;

        function saveInvoiceRemarks() {
            if(vm.isNewInvoiceRemarks){
                invoiceRemarksDataService.addInvoicesRemark(vm.invoiceRemarksData)
                    .then(successResult)
                    .catch(errorResult);
            }else{
                invoiceRemarksDataService.updateInvoicesRemark(vm.invoiceRemarksData)
                    .then(successResult)
                    .catch(errorResult);
            }

            function errorResult(response) {
            }

            function successResult(result) {
                vm.onInvoiceRemarksUpdate();
            }
        }

        function deleteInvoiceRemarks() {
            widgetModals.areYouSure('You want to delete this remark?').result
                .then(function (result) {
                    if (result === true) {
                        invoiceRemarksDataService.deleteInvoicesRemark(vm.invoiceRemarksData)
                            .then(successResult)
                            .catch(errorResult);
                    }
                });
            function errorResult(response) {
            }

            function successResult(result) {
                vm.onInvoiceRemarksUpdate();
            }
        }
    }
}
