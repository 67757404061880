﻿angular.module('poa.rprp.facility', [])
    .config(['$routeProvider', routeConfig])
    .controller('facilityOverviewController', require('./controllers/facility-overview'))
    .directive('facilityForm', require('./directives/facility-form'))
    .factory('facilityDataService', require('./services/facility-data'))
    .factory('facilityModelsService', require('./services/facility-models'))
    .factory('activityFacilitiesDisplayFilter', require('./filters/activity-facilities-display'))
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/facility', {
            templateUrl: 'templates/facility/controllers/facility-overview.html'
        })
        .when('/facility/:facilityId', {
            templateUrl: 'templates/facility/controllers/facility-overview.html'
        });
};