﻿module.exports = ['$uibModalInstance', 'common', '$filter', 'arrivalsDataService', modalImportArrivalsController];

function modalImportArrivalsController($uibModalInstance, common, $filter, arrivalsDataService) {

    var vm = {
        cancel: cancel,
        ok: ok,
        error: null,
        finalizeRealisations: false,
        fileArray: [],
        hasResult: false,
        isLoading: false,
        result: null,
        downloadReport: downloadReport
    };

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function ok() {

        var file = vm.fileArray[0];

        if (!file) {
            return;
        }
        
        vm.error = null;
        vm.isLoading = true;
        vm.hasResult = false;

        arrivalsDataService.importArrivals(file, vm.finalizeRealisations)
            .then(function (result) {
                if (result) {
                    vm.isLoading = false;
                    vm.hasResult = true;
                    vm.result = result;
                } else {
                    vm.isLoading = false;
                    vm.hasResult = false;
                }
            }, function (error) {
                vm.isLoading = false;
                vm.error = error;
            });
    }

    function downloadReport() {
        var csvOutput = 'TOTAL ROWS;TOTAL VISIT;NR VISIT IN ED;NR VISIT BERTH(GROUP) NOT IN ED\r\n';
        csvOutput +=
            vm.result.totalRows + ';' +
            vm.result.totalVisit + ';' +
            vm.result.nrPublicBerthVisits + ';' +
            vm.result.nrNoPublicBerthVisits + '\r\n\r\n';

        var resultWithReservation = '';
        if (vm.result.visitWithReservation) {
            for (var urcnWithR in vm.result.visitWithReservation) {

                var realisations = vm.result.visitWithReservation[urcnWithR];
                for (var i = 0; i < realisations.length; i++) {
                    resultWithReservation +=
                        realisations[i].realisation.customReference + ';' +
                            realisations[i].shipName + ';' +
                            realisations[i].ata.replace('T', ' ') + ';' +
                            realisations[i].atd.replace('T', ' ') + ';' +
                            ($filter('arrivalsStatusDisplay')(realisations[i].realisation.status)) + ';' +
                            realisations[i].isNew + ';' +
                            realisations[i].remarks === undefined || realisations[i].remarks === null ? '' : realisations[i].remarks.toString() + '\r\n';
                }
            }
             
            // make sure "visitWithReservation" contains any data before add the header
            if (resultWithReservation.length > 0) {
                csvOutput += 'Match found, arrivals data\r\n' + 'UCRN;SHIP;ATA;ATD;STATUS;IS NEW;REMARKS\r\n' + resultWithReservation + '\r\n';
            }
        }

        var resultPWithoutR = '';
        if (vm.result.processedVisitWithoutReservation) {
            for (var ucrnPWithoutR in vm.result.processedVisitWithoutReservation) {
                var realisationsWithoutR = vm.result.processedVisitWithoutReservation[ucrnPWithoutR];
                for (var i = 0; i < realisationsWithoutR.length; i++) {
                    resultPWithoutR +=
                        realisationsWithoutR[i].realisation.customReference + ';' +
                        realisationsWithoutR[i].shipName + ';' +
                        realisationsWithoutR[i].ata.replace('T', ' ') + ';' +
                        realisationsWithoutR[i].atd.replace('T', ' ') + ';' +
                            ($filter('arrivalsStatusDisplay')(realisationsWithoutR[i].realisation.status)) + ';' +
                            realisationsWithoutR[i].isNew + ';' +
                            realisationsWithoutR[i].remarks === undefined || realisationsWithoutR[i].remarks === null
                                ? ''
                                : realisationsWithoutR[i].remarks.toString() + '\r\n';
                }
            }

            // make sure "processedWithoutReservation" contains any data before add the header
            if (resultPWithoutR.length > 0) {
                csvOutput += 'No reservation found, but could create arrivals data\r\n' + 'UCRN;SHIP;ATA;ATD;STATUS;IS NEW;REMARKS\r\n' + resultPWithoutR + '\r\n';
            }
        }

        var resultWithoutReservation = '';
        if (vm.result.visitWithoutReservation) {
            for (var urcnWithoutR in vm.result.visitWithoutReservation) {

                var visitItems = vm.result.visitWithoutReservation[urcnWithoutR];
                for (var j = 0; j < visitItems.length; j++) {
                    resultWithoutReservation +=
                        visitItems[j].ucrn + ';' +
                        visitItems[j].name + ';' +
                        visitItems[j].ataDateTime.replace('T', ' ') + ';' +
                        visitItems[j].atdDateTime.replace('T', ' ') + '\r\n';
                }
            }

            // make sure "visitWithoutReservation" contains any data before add the header
            if (resultWithoutReservation.length > 0) {
                csvOutput += 'No reservations found and could not create without reservation, visit data\r\n' + 'UCRN;SHIP;ATA;ATD\r\n' + resultWithoutReservation;
            }
        }

        common.exportToCSV(csvOutput,'import-result-' + moment().format('YYYYMMDDHHmm'));

    }

    return vm;

}