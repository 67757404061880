﻿'use strict';
module.exports = ['tariffDataService', 'constantsService', berthgroupInvoiceTypeFormDirective];

function berthgroupInvoiceTypeFormDirective(tariffDataService, constantsService) {
    var directive = {
        controller: berthgroupInvoiceTypeFormController,
        controllerAs: 'ctrlBGFF',
        link: function (scope) {
        },
        restrict: 'E',
        scope: {
            berthgroupData: '='
        },
        bindToController: true,
        templateUrl: 'templates/berthgroup/directives/berthgroup-invoicetype-form.html'
    };

    return directive;

    function berthgroupInvoiceTypeFormController() {
        var vm = {
            invoiceTypes: []
        };
        
        constantsService.preloaded.then(function () {
            vm.invoiceTypes = constantsService.invoiceTypes;
        });

        return vm;
    }

}
