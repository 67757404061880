﻿module.exports = [ '$route', 'widgetModals', 'userDataService', 'userModelsService', 'userModals', 'common', 'toastr', userDetailsdirective];

function userDetailsdirective($route, widgetModals, userData, userModels, userModals, common, toastr) {
    var directive = {
        bindToController: true,
        controller: userDetailsController,
        controllerAs: 'ctrlUserDetails',
        restrict: 'E',
        scope: {
            user: '=',
            status: '=',
            users: '=',
            customer: '='
        },
        link: function (scope) {
            //console.log('users scope');
            //console.log(scope);
        },
        templateUrl: 'templates/customer/directives/user/user-details.html'
    };

    return directive;

    function userDetailsController() {
        var vm = {
            deactivateUser: deactivateUser,
            user: this.user,
            users: this.users,
            status: this.status,
            setAddNew: setAddNew,
            setEdit: setEdit,
            getDescription: getDescription,
            saveUser: saveUser,
            deleteUser: deleteUser,
            activeDisabledUser: activeDisabledUser,
            addUser: addUser,
            newUser: new userModels.userRegistrationModel(),
            customer: this.customer,
            resetPassword: resetPassword
        };
        function saveUser() {
            vm.status.errors = [];

            var validation = userModels.validateUser(vm.user);
            if (validation !== true) {
                _.map(validation, function (item) {
                    vm.status.errors.push(item);
                });
                return;
            }

            vm.status.saving = true;
            userData.saveUser(vm.user)
                .then(successResult, errorResult);

            function successResult(result) {
                toastr.success('Changes saved');
                vm.user = null;
                vm.status.completed = true;
                vm.status.saving = false;
            }

            function errorResult(response) {
                vm.status.errors = common.errorToText(response);
                vm.status.saving = false;
            }
        }
        function deactivateUser() {
            vm.status.errors = [];

            widgetModals.areYouSure('Do you want to deactivate this user?').result
                .then(function (result) {
                    if (result === true) {
                        vm.status.saving = true;
                        userData.deactivateUser(vm.user)
                            .then(deactivateUserSuccessResult, deactivateUserErrorResult);
                    }
                });

            function deactivateUserSuccessResult(response) {
                vm.user.isActive = response.isActive;
                vm.user = null;
                vm.status.saving = false;
            }

            function deactivateUserErrorResult(response) {
                vm.status.errors = common.errorToText(response);
                vm.status.saving = false;
            }
        }
        function deleteUser() {
            vm.status.errors = [];

            widgetModals.areYouSure('Do you want to delete this user?').result
                .then(function (result) {
                    if (result === true) {
                        vm.status.saving = true;
                        userData.deleteUser(vm.user)
                            .then(deleteUserSuccessResult, deleteUserErrorResult);
                    }
                });

            function deleteUserSuccessResult(response) {
                vm.user.isActive = response.isActive;
                vm.user = null;
                vm.status.saving = false;
                var iUser = _.findIndex(vm.users, function (item) { return item.id === vm.user.id; });
                vm.users.splice(iUser, 1);
            }

            function deleteUserErrorResult(response) {
                vm.status.errors = common.errorToText(response);
                vm.status.saving = false;
            }
        }

        function activeDisabledUser() {
            vm.status.errors = [];

            widgetModals.areYouSure('Do you want to active this user?').result
                .then(function (result) {
                    if (result === true) {
                        vm.status.saving = true;

                        userData.activeDisabledUser(vm.user)
                            .then(activeDisabledUserSuccessResult, activeDisabledUserErrorResult);
                    }
                });

            function activeDisabledUserSuccessResult(response) {
                vm.user.isActive = response.isActive;
                vm.user = null;
                vm.status.saving = false;
            }

            function activeDisabledUserErrorResult(response) {
                vm.status.errors = common.errorToText(response);
                vm.status.saving = false;
            }
        }

        function addUser() {
            var validation = userModels.validateUserRegistration(vm.newUser);
            if (validation !== true) {
                vm.status.errors = validation;
                return;
            }

            vm.newUser.customerId = vm.customer.id;
            vm.status.saving = true;

            userData.addUser(vm.newUser)
                .then(successResult, errorResult);
            function successResult(result) {
                vm.users.push(result);
                vm.newUser = new userModels.userRegistrationModel();
                vm.status.saving = false;
                vm.status.showAddNew = false;
                $route.reload();
            }

            function errorResult(response) {
                vm.status.errors = common.errorToText(response)[0];
                vm.status.saving = false;
            }

        }
        function setAddNew() {
            vm.status.errors = [];
            vm.user = null;
            vm.status.completed = false;
            vm.status.showAddNew = true;
            vm.status.showEdit = false;
        }
        function setEdit(user) {
            vm.user = user;
            vm.status.completed = false;
            vm.status.errors = [];
            vm.status.showAddNew = false;
            vm.status.showEdit = true;
        }

        function getDescription(user) {
            var result = '';
            if (user === undefined || user === null) return result;

            if (user.emailAddress !== null && user.emailAddress !== undefined && user.emailAddress.trim() !== '')
                result = user.emailAddress.trim();


            if (user.phoneNumber !== null && user.phoneNumber !== undefined && user.phoneNumber.trim() !== '') {
                if (result === '')
                    result = user.phoneNumber.trim();
                else
                    result = result + ', ' + user.phoneNumber.trim();
            }

            return result;
        }

        function resetPassword() {
            var dlgResult = userModals.resetPassword({
                userEmail: vm.user.emailAddress
            }).result.then(function (result) {
                if (result) {
                    toastr.success("Password successfully changed.");
                }
            });
        }

        return vm;
    }
}