﻿module.exports = [tariffModelsService];

function tariffModelsService() {

    var service = {
        tariffModel: tariffModel,
        tariffGroupModel: tariffGroupModel,
        tariffPendingModel: tariffPendingModel,
        validateTariff: validateTariff
    };

    return service;

    function tariffModel(tariffData) {
        var tariffModel = {
            id: null,
            tariffGroupId: null,
            tariffGroup: null,
            description: null,
            systemCode: null,
            startsOn: null,
            endsOn: null,
            unitaryTariff: null,
            externalItemCode: null,
            costCentre: null,
            rangeStartsFrom: null,
            approvedOn: null,
            approvedBy: null,
            approvedByUserId: null,
            businessLogic: null
        };

        if (tariffData === undefined || tariffData === null)
            return tariffModel;

        tariffModel.id = tariffData.id;
        tariffModel.tariffGroupId = tariffData.tariffGroupId;
        tariffModel.tariffGroup = tariffGroupModel(tariffData.tariffGroup);
        tariffModel.description = tariffData.description;
        tariffModel.systemCode = tariffData.systemCode;
        tariffModel.startsOn = moment(tariffData.startsOn).toDate();
        tariffModel.endsOn = moment(tariffData.endsOn).toDate();
        tariffModel.unitaryTariff = tariffData.unitaryTariff; 
        tariffModel.externalItemCode = tariffData.externalItemCode;
        tariffModel.costCentre = tariffData.costCentre;
        tariffModel.rangeStartsFrom = tariffData.rangeStartsFrom;
        tariffModel.approvedOn = tariffData.approvedOn;
        tariffModel.approvedBy = tariffData.approvedBy;
        tariffModel.approvedByUserId = tariffData.approvedByUserId;
        tariffModel.businessLogic = tariffData.businessLogic;

        return tariffModel;
    }

    function tariffGroupModel(tariffGroupData) {
        var tariffGroupModel = {
            id: null,
            description: null,
            businessLogic: null,
            shipTypeId: null
        };

        if (tariffGroupData === undefined || tariffGroupData === null)
            return tariffGroupModel;

        tariffGroupModel.id = tariffGroupData.id;
        tariffGroupModel.description = tariffGroupData.description;
        tariffGroupModel.businessLogic = tariffGroupData.businessLogic;
        tariffGroupModel.invoiceTypes = tariffGroupData.invoiceTypes;

        return tariffGroupModel;
    }

    function tariffPendingModel(tariffPenidingData) {
        var tariffPendingModel = {
            id: null,
            tariffId: null,
            startsOn: null,
            endsOn: null,
            externalItemCode: null,
            costCentre: null
        };

        if (tariffPenidingData === undefined || tariffPenidingData === null)
            return tariffPendingModel;

            tariffPendingModel.id = tariffPenidingData.id;
            tariffPendingModel.tariffId = tariffPenidingData.tariffId;
            tariffPendingModel.startsOn = tariffPenidingData.startsOn;
            tariffPendingModel.endsOn = tariffPenidingData.endsOn;
            tariffPendingModel.externalItemCode = tariffPenidingData.externalItemCode;
            tariffPendingModel.costCentre = tariffPenidingData.costCentre;

        return tariffPendingModel;
    }


    function validateTariff(tariff) {
        var errors = [];

        if (tariff.endsOn && moment().diff(tariff.endsOn, 'days') > 0) {
            errors.push('Please specify a valid End date');
        }

        if (tariff.endsOn && moment(tariff.endsOn).diff(tariff.startsOn, 'minutes') < 0) {
            errors.push('End date cannot be before Start date');
        }

        if (tariff.unitaryTariff !== undefined && tariff.unitaryTariff !== null && isNaN(tariff.unitaryTariff)) {
            errors.push('Unitary Tariff must be a number');
        }

        return errors.length > 0 ? errors : true;
    }
}
