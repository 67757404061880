﻿module.exports = ['common', 'reservationDataService', 'messageDataService', sendMessageDirective];

function sendMessageDirective(common, reservationData, messageData) {

    var directive = {
        bindToController: true,
        controller: sendMessageController,
        controllerAs: 'ctrlSendMessage',
        restrict: 'E',
        scope: {
            conversationId: '=',
            messageSentCallback: '=',
            reservationId: '=',
            customerId: '=',
            isCustomerMessage: '='
        },
        templateUrl: 'templates/messages/directives/send-message.html'
    };

    return directive;

    function sendMessageController() {
        var vm = {
            customerId: this.customerId,
            newMessage: {
                isIntern: false,
                status: '3',
                text: null
            },
            saveMessage: saveMessage
        };

        return vm;

        function saveMessage() {
            if (!vm.newMessage.text || vm.newMessage.text === '')
                return;

            if (vm.conversationId) {
                messageData.addMessageToConversation(vm.conversationId, vm.newMessage.text, vm.newMessage.isIntern, vm.newMessage.status)
                    .then(function (result) {
                        vm.newMessage.isIntern = false;
                        vm.newMessage.status = '1';
                        vm.newMessage.text = null;

                        if (vm.messageSentCallback)
                            vm.messageSentCallback(result);
                    });
            } else if (vm.isCustomerMessage) {
                messageData.addCustomerMessage(vm.newMessage.text, vm.newMessage.isIntern, vm.customerId)
                    .then(function (result) {
                        vm.newMessage.isIntern = false;
                        vm.newMessage.status = '1';
                        vm.newMessage.text = null;

                        if (vm.messageSentCallback)
                            vm.messageSentCallback(result);
                    });
            } else {
                reservationData.addMessage(vm.reservationId, vm.newMessage.text, vm.newMessage.isIntern, vm.customerId)
                    .then(function (result) {
                        vm.newMessage.isIntern = false;
                        vm.newMessage.status = '1';
                        vm.newMessage.text = null;

                        if (vm.messageSentCallback)
                            vm.messageSentCallback(result);
                    });
            }
        }
    }

};
