﻿module.exports = ['$location', '$rootScope', '$scope', 'constantsService', 'identity', 'userAccountService', loginController];

function loginController($location, $rootScope, $scope, constantsService, identity, userAccountService) {
    var vm = this;

    vm.loginModel = {
        password: null,
        rememberMe: false,
        username: null
    };
    vm.loginStatus = {
        error: null,
        loading: false
    };
    vm.registerModel = {
        companyName: null,
        confirmPassword: null,
        email: null,
        password: null
    }
    vm.registerStatus = {
        error: null,
        loading: false
    };

    function load() {
        // If the user is authenticated anyway, redirect to '/'
        if (identity.isAuthenticated) {
            $location.path('/');
        }
    };

    vm.login = function (redirect) {
        vm.loginStatus.error = null;

        var req = userAccountService.loginUser(vm.loginModel, vm.loginModel.rememberMe)
            .then(loginSuccess, loginError);

        function loginSuccess(data, status, headers, config) {
            vm.loginStatus.loading = false;
            constantsService.init();

            $rootScope.$on('identity:set', function () {
                if (redirect !== undefined && redirect !== null)
                    $location.path(redirect);
                else
                    $location.path('/');
            });
        };

        function loginError(error, status) {
            vm.loginStatus.loading = false;
            vm.loginStatus.error = error;
        };
    };

    vm.register = function () {
        vm.registerStatus.error = [];

        if (vm.registerModel.companyName === null || vm.registerModel.companyName === '')
            vm.registerStatus.error.push('Please specify a company name');

        if (vm.registerModel.email === null || vm.registerModel.email === '')
            vm.registerStatus.error.push('Please specify an email address');

        if (vm.registerModel.password === null || vm.registerModel.password === '')
            vm.registerStatus.error.push('Please specify a password');
        else if (vm.registerModel.confirmPassword === null || vm.registerModel.confirmPassword === '')
            vm.registerStatus.error.push('Please confirm your password');
        else if (vm.registerModel.password !== vm.registerModel.confirmPassword)
            vm.registerStatus.error.push('The passwords do not match');

        if (vm.registerStatus.error.length === 0)
            vm.registerStatus.error = null;
        else
            return;

        vm.registerStatus.loading = true;
        var req = userAccountService.registerUser(vm.registerModel);

        req.then(registerSuccess, registerError);

        function registerSuccess(data, status, headers, config) {
            vm.registerStatus.loading = false;

            if (data.succeeded === false) {
                vm.registerStatus.error = data.errors;
            } else {
                // Now log in
                vm.loginModel.username = vm.registerModel.email,
                vm.loginModel.password = vm.registerModel.password
                vm.loginModel.rememberMe = true;
                vm.login('/account');
            }
        };

        function registerError(error, status) {
            vm.registerStatus.loading = false;
            vm.registerStatus.error = error;
        };
    };

    load();
};
