﻿// The common service exports all services that will be used a lot in different other services and controllers
module.exports = ['$location', '$q', '$timeout', 'config', 'formatting', 'identity', 'language', commonService];

function commonService($location, $q, $timeout, config, formatting, identity, language) {
    var service = {
        $location: $location,
        $q: $q,
        $timeout: $timeout,
        config: config,
        copyObject: copyObject,
        errorToText: errorToText,
        formatting: formatting,
        identity: identity,
        language: language,
        exportToCSV: exportToCSV
    };

    function copyObject(source) {
        if (source === undefined)
            return undefined;

        return JSON.parse(JSON.stringify(source));
    };

    function errorToText(httpResponse) {
        var result = [];

        if (httpResponse.status)
            switch (httpResponse.status) {
                case 400:
                    if (httpResponse.data && httpResponse.data.errors && httpResponse.data.errors !== '' && httpResponse.data.errors.length > 0)
                        result.push(httpResponse.data.errors);
                    else if (httpResponse.data && httpResponse.data.length > 0)
                        result.push(httpResponse.data);
                    else
                        result.push("An error has occured");
                    break;
                case 401:
                    result.push("You are not authorized to perform this action");
                    break;
                case 500:
                    if (httpResponse.data && httpResponse.data.exceptionMessage && httpResponse.data.exceptionMessage !== '')
                        result.push(httpResponse.data.exceptionMessage);
                    else if (httpResponse.data && httpResponse.data.message && httpResponse.data.message !== '')
                        result.push(httpResponse.data.message);
                    else if (httpResponse.statusText && httpResponse.statusText !== '')
                        result.push(httpResponse.statusText);
                    else
                        result.push("An error has occured");
                    break;
                default:
                    result.push("An error has occured");
                    break;
            }

        return result;
    };

    function exportToCSV(csvOutput, csvName){
        var file = new Blob(["\ufeff", csvOutput], { type: 'application/csv;charset=utf-8' });
        var filename = csvName + '.csv';

        if (navigator.appVersion.toString().indexOf('.NET') > 0)
            window.navigator.msSaveBlob(file, filename);
        else {
            var a = document.createElement("a");
            document.body.appendChild(a);

            var fileURL = window.URL.createObjectURL(file);

            a.href = fileURL;
            a.download = filename;
            a.click();
        }
    }

    return service;
};
