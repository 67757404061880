﻿module.exports = ['$uibModal', securityModalsService];

function securityModalsService($uibModal) {

    var service = {
        addPermission: addPermission
    };

    return service;

    function addPermission() {
        addPermissionController.$inject = ['$uibModalInstance', 'permissionModelService'];

        function addPermissionController($uibModalInstance, permissionModels) {
            var vm = {
                cancel: cancel,
                errors: [],
                permission: {
                    description: null,
                    internalName: null,
                    name: null
                },
                ok: ok
            };

            return vm;

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            };

            function ok() {
                var validationResult = permissionModels.validatePermission(vm.permission);
                if (validationResult === true)
                    $uibModalInstance.close(vm.permission);
                else
                    vm.errors = validationResult;
            };
        };

        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/security/modals/add-permission.html',
            controllerAs: 'vm',
            controller: addPermissionController
        });

    };

};