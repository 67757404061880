﻿'use strict';
angular.module('poa.rprp.fleet', [])
    .config(['$routeProvider', routeConfig])
    .controller('modalShipPropertiesController', require('./modals/ship-properties-modal'))
    .controller('fleetController', require('./controllers/fleet'))
    .controller('fleetIssuesController', require('./controllers/fleet-issues'))
    .directive('shipForm', require('./directives/ship-form'))
    .directive('shipPropertiesForm', require('./directives/ship-properties-form'))
    .factory('fleetDataService', require('./services/fleet-data'))
    .factory('fleetModelService', require('./services/fleet-models'))
    .filter('vesselDisplayFilter', require('./filters/vessel-display'))
    .filter('shipTypeDisplay', require('./filters/shiptype-display'))
    .factory('shipPropertyService', require('./services/ship-property-data'))
    .factory('fleetModals', require('./modals/modals'));

function routeConfig($routeProvider) {
    $routeProvider
        .when('/fleet/issues', {
            templateUrl: 'templates/fleet/controllers/fleet-issues.html'
        })
        .when('/fleet/:customerId', {
            templateUrl: 'templates/customer/controllers/customer-overview.html'
        });
}
