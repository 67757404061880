﻿module.exports = ['$location', '$scope', 'common', 'constantsService', 'arrivalsDataService', 'dashboardStatusDataService', 'messageDataService', 'sailingReportDataService', dashboardControllerNew];

function dashboardControllerNew($location, $scope, common, constants, arrivalsDataService, dashboardStatusDataService, messageDataService, sailingReportDataService) {

    var vm = {
        attached: attached,
        businessUnits: [],
        getLatestMessages: getLatestMessages,
        messageItems: [],
        onMessageSelect: onMessageSelect,
        refreshTimeout: 30000,
        statuses: {
            arrivalTodo: null,
            nextYear: null,
            reservations: [],
            reviews: [],
            touristTaxIssues: null,
            touristTaxLegal: null,
            thisYear: null
        },
        timers: {
            arrivalTodos: null,
            messages: null,
            reservationStatus: null,
            review: null
        }
    };

    attached();

    return vm;

    function attached() {
        // ensure the processflows are loaded before load the permissions
        constants.preloaded.then(function () {
            checkPermissions();
            loadReviews();
        });

        vm.statuses.nextYear = moment().add(1, 'y').format('YYYY');
        vm.statuses.thisYear = moment().format('YYYY');

        loadArrivalTodos();
        loadFleetIssues();
        loadReservationStatuses();
        loadSailingReportIssues();

        $scope.$on('$destroy', function () {
            if (vm.timers.arrivalTodos) {
                common.$timeout.cancel(vm.timers.arrivalTodos);
            }
            if (vm.timers.messages) {
                common.$timeout.cancel(vm.timers.messages);
            }
            if (vm.timers.reservationStatus) {
                common.$timeout.cancel(vm.timers.reservationStatus);
            }
            if (vm.timers.review) {
                common.$timeout.cancel(vm.timers.review);
            }
        });
    }

    function checkPermissions() {
        // Check if the user can see any of the dashboard boxes
        var hasAnyPermissionForDashboard = common.identity.hasPermission('customer_admin,invoice_admin,message,message_admin,planning_admin');

        // If not then we'll redirect to any applicable next page
        if (!hasAnyPermissionForDashboard) {
            if (common.identity.hasPermission('planning')) {
                common.$location.path('/planning');
            }
            else if (common.identity.hasPermission('realisation,realisation_admin,realisation_create')) {
                common.$location.path('/arrivals');
            }
        }

        // copy the business units and extend with extra properties
        vm.businessUnits = _.map(constants.businessUnits, function (b) {
            b.hasPlanningPermission = _.contains(common.identity.getBusinessUnitsForPermissions(["planning", "planning_admin"]), b.id);
            b.hasInvoicePermission = _.contains(common.identity.getBusinessUnitsForPermission("invoice_admin"), b.id);
            b.show_res = false;
            b.show_todo = false;
            b.show_review = false;
            return b;
        });

        vm.businessUnits = _.filter(vm.businessUnits, 'isActive');

        _.each(constants.processFlows, function (flow) {
            var businessUnit = _.find(vm.businessUnits, function (b) { return b.id === flow.businessUnitId; });
            if (businessUnit && flow.configuration) {
                businessUnit.show_res = Boolean(businessUnit.show_res | flow.configuration.ui_db_res);
                businessUnit.show_todo = Boolean(businessUnit.show_todo | flow.configuration.ui_db_arr_todo);
                businessUnit.show_review = Boolean(businessUnit.show_review | flow.configuration.ui_db_review);
            }
        });

    }

    function getLatestMessages() {
        if (!common.identity.hasPermission('message,message_admin')) {
            return;
        }

        messageDataService.getLatestMessages()
            .then(function (results) {
                vm.messageItems = results;
                vm.timers.messages = common.$timeout(getLatestMessages, vm.refreshTimeout);
            });
    }

    function loadFleetIssues() {
        dashboardStatusDataService.getFleetIssues()
            .then(function (result) {
                vm.statuses.fleet = result.count;
            });
    }

    function loadReservationStatuses() {
        dashboardStatusDataService.getReservationStatus()
            .then(function (result) {
                vm.statuses.reservations = result;
                vm.timers.reservationStatus = common.$timeout(loadReservationStatuses, vm.refreshTimeout);
            });
    }

    function loadReviews() {
        for (var i = 0; i < vm.businessUnits.length; i++) {
            var businessUnit = vm.businessUnits[i];
            if (businessUnit.hasInvoicePermission && businessUnit.show_review) {
                loadReview(businessUnit.id);
            }
        }
        vm.timers.review = common.$timeout(loadReviews, vm.refreshTimeout);
    }

    function loadReview(id) {
        arrivalsDataService.getToReview(0, 1, id)
            .then(function (result) {
                vm.statuses.reviews[id] = result.count;
            });
    }

    function loadArrivalTodos() {
        arrivalsDataService.getToDoCounts()
            .then(function (result) {
                vm.statuses.arrivalTodo = result;

                vm.timers.arrivalTodos = common.$timeout(loadArrivalTodos, vm.refreshTimeout);
            });
    }

    function loadSailingReportIssues() {
        sailingReportDataService.getLegalTodoCount()
            .then(function (result) {
                vm.statuses.touristTaxLegal = result.count;
            });
        sailingReportDataService.getIssueReportCounts()
            .then(function (result) {
                vm.statuses.touristTaxIssues = result.count;
            });
    }

    function onMessageSelect(item) {
        // when the reservation is set, always use the reservation. if not fallback on Customer
        if (item.message !== null && item.message.conversationId !== null) {
            $location.path('/messages/' + item.message.conversationId);
        } else if (item.reservationId !== null) {
            $location.path('/messages/reservation/' + item.reservationId);
        } else if (item.message !== null) {
            $location.path('/messages/customer/' + item.message.customerId);
        }
    }
}
