﻿'use strict';

module.exports = ['common', 'dataContext', invoiceRemarksDataService];

function invoiceRemarksDataService(common, context) {

    var vm = {
        getInvoiceRemarks: getInvoiceRemarks,
        getInvoiceRemarksByUser: getInvoiceRemarksByUser,
        deleteInvoicesRemark: deleteInvoicesRemark,
        addInvoicesRemark: addInvoicesRemark,
        updateInvoicesRemark: updateInvoicesRemark
    };

    return vm;

    function getInvoiceRemarks() {
        var deferred = common.$q.defer();

        context.get('/invoiceRemark')
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function getInvoiceRemarksByUser() {
        var deferred = common.$q.defer();

        context.get('/invoiceRemark/user')
            .then(function (data) {
                deferred.resolve(data);
            },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function addInvoicesRemark(invoiceRemark) {
        var deferred = common.$q.defer();

        context.post('/invoiceRemark',invoiceRemark)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function updateInvoicesRemark(invoiceRemark) {
        var deferred = common.$q.defer();

        context.put('/invoiceRemark/' + invoiceRemark.id, invoiceRemark)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function deleteInvoicesRemark(invoiceRemark) {
        var deferred = common.$q.defer();

        context.del('/invoiceRemark/' + invoiceRemark.id)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }
}