﻿'use strict';

module.exports = ['common', 'dataContext', systemMessageDataService];

function systemMessageDataService(common, context) {
    var vm = {
        getSystemMessages: getSystemMessages,
        saveSystemMessage: saveSystemMessage,
        deleteSystemMessage: deleteSystemMessage
    };

    return vm;

    function getSystemMessages() {
        var deferred = common.$q.defer();
        var req = context.get('/system-messages')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveSystemMessage(systemMessage) {
        var deferred = common.$q.defer();
        var url = '/system-messages';
        var req;
        if (systemMessage.id) {
            url = url + '/' + systemMessage.id;
            req = context.put(url, systemMessage);
        } else {
            req = context.post(url, systemMessage);
        }

        req.then(function (data) {
            deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteSystemMessage(systemMessage) {
        var deferred = common.$q.defer();
        var url = '/system-messages/' + systemMessage.id;
        var req = context.del(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}
