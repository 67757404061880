﻿module.exports = ['$routeParams', 'constantsService', 'reportDataService', 'planningDataService', reportFlowsController];

function reportFlowsController($routeParams, constantsService, reportData, planningData) {

    var vm = {
        allowedShipTypes: [],
        attached: attached,
        filter: {
            endDate: null,
            startDate: null
        },
        flows: [],
        loading: true,
        selectedShipType: null,
        reload: reload,
        reservationStatusHistory: null,
        showStatusHistory: showStatusHistory
    };

    function attached() {
        _.each(constantsService.processFlows, function (flow) {
            var canShow = flow.configuration && flow.configuration.ui_rep_flow;
            if (flow.shipTypes) {
                _.each(flow.shipTypes, function (shipType) {
                    var foundShipType = _.find(vm.allowedShipTypes, function (type) { return type.id === shipType.id; });
                    if (!foundShipType) {
                        vm.allowedShipTypes.push({ id: shipType.id, name: shipType.name, show: canShow });
                    } else {
                        foundShipType.show = Boolean(foundShipType.show & canShow);
                    }
                });
            }
        });

        vm.allowedShipTypes = _.filter(vm.allowedShipTypes, function (shipType) { return shipType.show; });

        // default set Rivercruise in the dropdown
        vm.selectedShipType = vm.allowedShipTypes[0];

        vm.filter.startDate = moment().add(-6, 'days');
        vm.filter.endDate = moment();

        reload(true);
    }

    function loadFlows() {
        if (vm.selectedShipType) {
            vm.loading = true;
            reportData.getReportFlows(vm.filter.startDate.format('YYYY-MM-DD'), vm.filter.endDate.format('YYYY-MM-DD'), vm.selectedShipType.id)
                .then(function (result) {
                    vm.flows = _.map(result,
                        function (item) {
                            item.invoiceReference = item.invoices === null || item.invoices.length === 0
                                ? null
                                : _.last(item.invoices).reference;
                            return item;
                        });
                    vm.loading = false;
                });
        }
    }

    function reload(clearAll) {
        if (clearAll && clearAll == true)
            vm.flows = [];
        
        loadFlows();
    }

    function showStatusHistory(reservationId) {
        if (vm.reservationStatusHistory && vm.reservationStatusHistory.id === reservationId) {
            vm.reservationStatusHistory = null;
        } else {
            planningData.getStatus(reservationId, true)
                .then(function (result) {
                        vm.reservationStatusHistory = { id: reservationId, statuses: _.sortBy(result, 'communicatedOn') };
                    },
                    function () {
                        vm.reservationStatusHistory = null;
                    });
        }
    }

    return vm;
}