﻿angular.module('poa.rprp.berth', [])
    .config(['$routeProvider', routeConfig])
    .controller('berthMainController', require('./controllers/berth-main'))
    .directive('berthForm', require('./directives/berth-form'))
    .directive('berthGeneralForm', require('./directives/berth-general-form'))
    .directive('berthOverview', require('./directives/berth-overview'))
    .directive('berthShiptypeForm', require('./directives/berth-shiptype-form'))
    .directive('berthBerthgroupForm', require('./directives/berth-berthgroup-form'))
    .directive('berthCommercialgoalForm', require('./directives/berth-commercialgoal-form'))
    .directive('berthFacilityForm', require('./directives/berth-facility-form'))
    .directive('berthNauticalgoalForm', require('./directives/berth-nauticalgoal-form'))
    .directive('berthBusinessrules', require('./directives/berth-businessrules'))
    .factory('berthDataService', require('./services/berth-data'))
    .factory('berthModelsService', require('./services/berth-models'));

function routeConfig($routeProvider) {
    $routeProvider
        .when('/berth', {
            templateUrl: 'templates/berth/controllers/berth-main.html'
        })
        .when('/berth/:berthId', {
            templateUrl: 'templates/berth/controllers/berth-main.html'
        });
}