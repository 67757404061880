﻿'use strict';
module.exports = ['$anchorScroll', '$routeParams', 'common', 'constantsService', 'fleetDataService', 'fleetModelService', 'widgetModals', fleetController];

function fleetController($anchorScroll, $routeParams, common, constants, fleetData, fleetModels, widgetModals) {
    var vm = {
        addShip: addShip,
        constants: constants,
        deleteShip: deleteShip,
        editingVessel: null,
        loadVessels: loadVessels,
        newVessel: new fleetModels.vesselModel(),
        saveShip: saveShip,
        status: {
            completed: false,
            errors: [],
            setAddNew: setAddNew,
            setEdit: setEdit,
            showAddNew: false
        },
        vessels: []
    };

    function addShip() {
        fleetData.addShip(vm.newVessel)
            .then(function (result) {
                vm.newVessel = new fleetModels.vesselModel();
                vm.status.showAddNew = false;
                vm.vessels.push(result);
            });
    }

    function deleteShip(ship) {
        widgetModals.areYouSure('You want to delete this vessel from your fleet?').result
            .then(function (result) {
                if (result === true) {
                    fleetData.deleteShip(vm.editingVessel)
                    .then(
                        function (response) {
                            var iVessel = _.findIndex(vm.vessels, function (item) { return item.id === vm.editingVessel.id; });
                            vm.vessels.splice(iVessel, 1);
                            vm.editingVessel = null;
                        },
                        function (response, status, headers, config) {
                        }
                    );
                }
            });
    }

    function loadVessels() {
        fleetData.getFleet()
            .then(function (result) {
                vm.vessels = result;
            });

        var shipId = $routeParams.shipId;
        if (shipId !== undefined && shipId !== null && shipId !== '' && shipId > 0) {
            fleetData.getFleetById(shipId)
            .then(function (result) {
                if (result !== undefined && result !== null) {
                    vm.status.setEdit(result);
                }
            });
        }
    }

    function saveShip(ship) {
        vm.status.errors = [];

        var validation = fleetModels.validateVessel(vm.editingVessel);
        if (validation !== true) {
            vm.status.errors = validation;
            return;
        }

        vm.status.saving = true;
        fleetData.saveShip(vm.editingVessel)
            .then(successResult, errorResult);

        function errorResult(response) {
            vm.status.errors = common.errorToText(response);
            vm.status.saving = false;
        }

        function successResult(result) {
            vm.status.completed = true;
            vm.status.saving = false;
        }
    }

    function setAddNew() {
        vm.status.completed = false;
        vm.status.showAddNew = true;
        vm.editingVessel = null;
    }

    function setEdit(ship) {
        vm.status.completed = false;
        vm.status.showAddNew = false;
        vm.editingVessel = ship;
        $anchorScroll();
    }

    return vm;

}