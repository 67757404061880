﻿module.exports = ['common', 'dataContext', roleDataService];

function roleDataService(common, context) {

    var service = {
        add: addRole,
        get: getRoles,
        getAll: getAllRoles,
        getAdmin: getAdminRoles,
        update: updateRole
    };

    function addRole(role) {
        var deferred = common.$q.defer();
        var req = context.post('/role', role)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getAdminRoles() {
        var deferred = common.$q.defer();
        var req = context.get('/role/admin')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getAllRoles() {
        var deferred = common.$q.defer();
        var req = context.get('/role/all')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getRole(roleId) {
        var deferred = common.$q.defer();
        var req = context.get('/role/' + roleId)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getRoles() {
        var deferred = common.$q.defer();
        var req = context.get('/role')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updateRole(role) {
        var deferred = common.$q.defer();
        var req = context.put('/role/' + role.id, role)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    return service;
};