﻿module.exports = [
    '$anchorScroll',
    '$filter',
    '$routeParams',
    '$window',
    'common',
    'constantsService',
    'toastr',
    'berthgroupDataService',
    'customerDataService',
    'businessruleDataService',
    'planningCalendarService',
    'planningDataService',
    'planningModals',
    'reservationDataService',
    'reportDataService',
    'widgetModals',
    'communicatedStatusService',
    'constantsService', planningGenericInboxController];

function planningGenericInboxController($anchorScroll, $filter, $routeParams, $window, common, constants, toastr, berthGroupData, customerData, businessRuleData, planningCalendar, planningData, planningModals, reservationData, reportData, widgetModals, communicatedStatusService, constantsService) {
    var vm = {
        attached: attached,
        selectedReservation: null,
        selectReservation: selectReservation,
        selectedPlanning: null,
        selectPlanning: selectPlanning,
        selectChangedPlanning: selectChangedPlanning,
        cancelSelection: cancelSelection,
        loadData: loadData,
        allFlows: [],
        allFlowIds: [],
        currentFlowConfig: null,
        display: {
            changeMinimumDate: null,
            isAdmin: false,
            isFiltered: isFiltered,
            planningMenuItem: 'messages',
            showReservations: false,
            reservationWithSelectedFlowCount: reservationWithSelectedFlowCount,
            reservationHasSelectedFlow: reservationHasSelectedFlow,
            textFilter: null,
            textFilterType: 'reservation',
            unrespondedMessages: 0
        },
        planning: {
            berths: [],
            berthDetails: [],
            calendar: planningCalendar,
            endDate: null,
            exceptions: [],
            lastOverviewEndDate: null,
            lastOverviewStartDate: null,
            sanityChecks: [],
            startDate: null,
            flowIds: []
        },
        previousPlanning: {
            endDate: null,
            startDate: null,
            flowIds: []
        },
        loading: {
            planned: false,
            unplanned: false
        },
        reservations: {
            canLoadMore: true,
            data: [],
            filterText: null,
            pageIndex: 0,
            pageSize: 20,
            status: null,
            updateSearch: updateSearch,
            loadMore: loadMoreUnplanned
        },
        customers: [],
        reservationItems: [],
        splitReservations: [],
        reservationMotivation: null,
        reservationErrors: [],
        reservationUnlocked: false,
        getRowsForBerth: getRowsForBerth,
        setPlanningFlow: setPlanningFlow,
        setSelectedFlow: setSelectedFlow,
        showShipDimensions: showShipDimensions,
        markPlanningItemAsDeleted: markPlanningItemAsDeleted,
        markBlockadeAsDeleted: markBlockadeAsDeleted,
        saveMessage: saveMessage,
        saveSelection: saveSelection,
        saveAndConfirmSelection: saveAndConfirmSelection,
        addNewPlanningRow: addNewPlanningRow,
        addNewBerthBlocked: addNewBerthBlocked,
        sendISPSNotification: sendISPSNotification,
        selectProcessFlows: selectProcessFlows,
        loadCustomers: loadCustomers,
        openSplitReservationDateDialog: openSplitReservationDateDialog,
        removeSplitReservation: removeSplitReservation,
        lockReservation: lockReservation,
        editReservation: editReservation,
        validateReservation: validateReservation,
        communicatedStatus: [],
        sailingReports: [],
        messages: [],
        newMessage: {
            isIntern: false,
            status: '1',
            text: null
        },
        planningErrors: [],
        queue: [],
        queueBlockades: [],
        shipPropertyTypesToShow: [],
        cruiseDockBerthGroups: []
    };

    function attached() {
        if (common.identity.hasPermission('planning_admin')) {
            vm.display.isAdmin = true;
        }

        vm.display.showReservations = common.$location.$$path.indexOf('/inbox') > -1;

        if ($routeParams.reservationId) {
            vm.initialReservation = parseInt($routeParams.reservationId);
            selectChangedPlanning($routeParams.reservationId);
        }

        setAvailableFlows();

        
        var getEasyDockBerthGroups = berthGroupData.getBerthgroupsDetails(null, vm.allFlowIds);
        var getCruiseDockBerthGroups = planningData.getCruiseDockBerthGroups().catch(() => []);

            
        vm.planning.startDate = moment();
        vm.planning.endDate = moment().add(6, 'days');
        vm.planning.flowIds = _.pluck(_.filter(vm.allFlows, 'selected'), 'id');

        Promise.all([getEasyDockBerthGroups, getCruiseDockBerthGroups])
            .then(function(results) {
                var sortedResult = _.chain(results[0]).sortBy(function (bg) {
                    return bg.name;
                }).sortBy(function (bg) {
                    return bg.sortOrder === undefined || bg.sortOrder === null ? Number.MAX_VALUE : bg.sortOrder;
                }).value();

                sortedResult.push({ id: -1, name: 'NO BERTH', berths: [{ id: -1, name: 'NO BERTH' }] });

                vm.planning.berths = sortedResult;
                vm.cruiseDockBerthGroups = results[1];
                calculateBerths();
                loadData(true);
            });
    }

    function loadBerths() {
        berthGroupData.getBerthgroupsDetails(null, vm.planning.flowIds)
            .then(function (result) {
                result.push({ id: -1, name: 'NO BERTH', berths: [{ id: -1, name: 'NO BERTH' }] });

                vm.planning.berths = result;
                calculateBerths();
            });
    }

    function loadCustomers() {
        if(vm.customers.length == 0) {
            customerData.getCustomers().then(result => {
                // const shipTypeId = vm.selectedReservation.ship ? vm.selectedReservation.ship.shipTypeId : null;
                const filteredResult = _.sortBy(_.filter(result, x => !x.customerPortAuthority || x.customerPortAuthority.active), 'name');
                
                // vm.customers = shipTypeId ? _.where(filteredResult, { defaultShipTypeId: shipTypeId }) : filteredResult;
                vm.customers = filteredResult;
            });
        }
    }

    function setAvailableFlows() {
        vm.allFlows =
            _.map(constants.processFlows, function (m) {
                return {
                    id: m.id,
                    name: m.name,
                    selected: true,
                    config: m.planningConfiguration,
                    businessUnitId: m.businessUnitId
                };
            });

        vm.allFlowIds = _.map(vm.allFlows, function (m) {
            return m.id;
        });
    }

    function setPlanningFlow() {
        vm.planning.flowIds = _.map(_.filter(vm.allFlows, function (f) {
            return f.selected;
        }), function (m) {
            return m.id;
        });
        if (vm.planning.flowIds && vm.planning.flowIds.length > 0) {
            loadData();
        }
    }

    function calculateBerths() {
        var berths = [];
        for (var a = 0; a < vm.planning.berths.length; a++) {
            for (var b = 0; b < vm.planning.berths[a].berths.length; b++) {
                berths.push(vm.planning.berths[a].berths[b]);
            }
        }

        vm.planning.berthDetails = berths;
        planningCalendar.calculateBerths(vm.planning.berthDetails, null, 'easydock');
    }

    function setPlanningDates() {
        vm.planning.lastOverviewStartDate = vm.planning.startDate;
        vm.planning.lastOverviewEndDate = vm.planning.endDate;
        vm.display.changeMinimumDate = vm.planning.startDate.format('DD-MM-YYYY');

        vm.previousPlanning.startDate = vm.planning.startDate;
        vm.previousPlanning.endDate = vm.planning.endDate;

        planningCalendar.calculateDays({
            endDate: vm.planning.endDate,
            startDate: vm.planning.startDate
        });
    }

    function loadData(clearAll) {
        common.$timeout(function () {
            if (clearAll || vm.previousPlanning.flowIds != vm.planning.flowIds) {
                vm.previousPlanning.flowIds = vm.planning.flowIds;

                vm.reservations.data = [];
                planningCalendar.clearUnplannedItems();
                loadUnplannedReservations(true);
            }
            if (clearAll || !vm.previousPlanning.startDate ||
                !vm.previousPlanning.endDate ||
                (moment(vm.planning.startDate).isBefore(vm.previousPlanning.startDate) ||
                    moment(vm.planning.startDate).isAfter(vm.previousPlanning.endDate) ||
                    moment(vm.planning.endDate).isBefore(vm.previousPlanning.startDate) ||
                    moment(vm.planning.endDate).isAfter(vm.previousPlanning.endDate))) {

                planningCalendar.clearOtherItems();
                vm.disableBtn = false;

                setPlanningDates();

                loadBerthObstructions();
                loadPlannedReservations();
                loadSuggestions();
            } else {
                setPlanningDates();
                planningCalendar.recalculateCalendarBlocks(planningCalendar.data.planned, null, null, 'easydock');
                planningCalendar.recalculateCalendarBlocks(planningCalendar.data.unplanned, null, null, 'easydock');
                planningCalendar.recalculateCalendarBlocks(planningCalendar.data.berthObstructions, 'availableFrom', 'availableUntil', 'easydock');
                planningCalendar.recalculateCalendarBlocks(planningCalendar.data.sanityChecks.berthSanityIssues, 'startIssue', 'endIssue', 'easydock');
            }
        }, 200);
    }


    function loadBerthObstructions() {
        const cruiseDockBerths = vm.planning.berths.flatMap(a => a.berths);
        var start = vm.planning.startDate.format('YYYY-MM-DD');
        var end = vm.planning.endDate.format('YYYY-MM-DD');
        var getEasyDockObstructions = businessRuleData.getByType(3, start, end);
        var getCruiseDockObstructions = planningData.getCruiseDockObstructions(start, end).catch(() => []);
      
        Promise.all([getEasyDockObstructions, getCruiseDockObstructions])
            .then(function(results) {
                planningCalendar.calculateBerths(vm.planning.berthDetails, results[0], 'easydock');
                planningCalendar.calculateBerths(cruiseDockBerths, results[1], 'cruisedock');

                if (vm.selectedPlanning !== null) {
                    vm.selectedPlanning.blockades = _.filter(planningCalendar.data.berthObstructions, function (obstruction) {
                        return obstruction.reservationId !== undefined && obstruction.reservationId !== null && obstruction.reservationId === (vm.selectedPlanning.id ? vm.selectedPlanning.id : vm.selectedReservation.id);
                    });
                }
            });
    }

    function loadPlannedReservations() {
        vm.loading.planned = true;
        planningData.getPlanned(vm.planning.startDate.format('YYYY-MM-DD'), vm.planning.endDate.format('YYYY-MM-DD'), null, vm.planning && vm.planning.flowIds ? vm.planning.flowIds : vm.allFlowIds)
            .then(function (result) {
                planningCalendar.calculateItems(result, 'planned');

                doSanityCheck(result, vm.planning.startDate.format('YYYY-MM-DD'), vm.planning.endDate.format('YYYY-MM-DD'));
                vm.loading.planned = false;
            }, function () {
                vm.loading.planned = false;
            });
    }

    function loadUnplannedReservations(includeSuggestions) {
        vm.loading.unplanned = true;

        reservationData.getUnplanned(vm.reservations.pageIndex, vm.reservations.pageSize, null, vm.reservations.status, vm.reservations.filterText, vm.planning && vm.planning.flowIds ? vm.planning.flowIds : vm.allFlowIds)
            .then(function (result) {

                if (vm.reservations.pageIndex === 0)
                    vm.reservations.data = result;
                else {
                    vm.reservations.data = vm.reservations.data.concat(_.sortBy(result, 'eta'));
                }

                if (includeSuggestions === true) {
                    loadSuggestions();
                }

                vm.loading.unplanned = false;
                vm.reservations.canLoadMore = result.length === vm.reservations.pageSize;
            }, function () {

                if (includeSuggestions === true) {
                    loadSuggestions();
                }
                vm.loading.unplanned = false;
            });
    }

    function loadSuggestions() {
        vm.berthMessages = {};
        planningData.getSuggestions(vm.planning.startDate.format('YYYY-MM-DD'), vm.planning.endDate.format('YYYY-MM-DD'), null, vm.planning && vm.planning.flowIds ? vm.planning.flowIds : vm.allFlowIds)
            .then(function (result) {
                var suggestions = _.map(result, function (item) {
                    return item.planning;
                });

                vm.suggestionsUnplanned = suggestions;

                if (vm.selectedReservation !== null && result) {
                    var berthMessages = _.map(result, function (item) {
                        return item.berthMessages === undefined || item.berthMessages === null ? [] : item.berthMessages;
                    });

                    _.each(_.flatten(berthMessages, true), function (item) {
                        if (item !== null && item.reservationId === vm.selectedReservation.id) {
                            if (vm.berthMessages[item.berthId] === undefined) {
                                vm.berthMessages[item.berthId] = [];
                            }

                            vm.berthMessages[item.berthId].push(item);
                        }
                    });
                }
                planningCalendar.calculateItems(suggestions, 'unplanned');
            });
    }

    function doSanityCheck(items, startDate, endDate) {
        planningData.getSanityCheck(items, startDate, endDate, null, vm.planning && vm.planning.flowIds ? vm.planning.flowIds : vm.allFlowIds)
            .then(function (result) {
                planningCalendar.setSanityChecks(result);
            });
    }

    function selectReservation(id, apiSource) {
        vm.reservationUnlocked = false;
        vm.display.planningMenuItem = 'planning';
        vm.communicatedStatus = [];
        vm.dateTimeNow = moment().format();

        if (!id) {
            vm.selectedReservation = null;
            vm.selectedPlanning = null;
            loadData();
            return;
        } else if (vm.selectedPlanning && vm.selectedPlanning.id != id) {
            vm.selectedPlanning = null;
        }

        reservationData.getDetails(id, apiSource)
            .then(function (reservation) {
                reservation.apiSource = apiSource;
                vm.selectedReservation = reservation;

                vm.loadCustomers();
                vm.lockReservation();

                var flows = _.filter(vm.allFlows, function (flow) {
                    return flow.id == reservation.processFlowId;
                });

                vm.display.isAdmin = _.some(constants.getFlowsForPermissions(['planning_admin']), function (flow) { return flow.id == reservation.processFlowId; });

                if (flows && flows.length > 0) {
                    vm.currentFlowConfig = flows[0].config;
                }

                vm.shipPropertyTypesToShow = [];
                if (reservation && reservation.ship && reservation.ship.shipTypeId) {
                    vm.shipPropertyTypesToShow = _.filter(constants.shipPropertyTypes, function (shipPropertyType) {
                        // show property type has this shiptype, but skip show length and width
                        return shipPropertyType.systemCode !== 'length' && shipPropertyType.systemCode !== 'width' && _.some(shipPropertyType.shipTypes, function (shipType) { return shipType.id === reservation.ship.shipTypeId; });
                    });
                }

                if (!vm.selectedPlanning) {
                    convertReservarionToPlanning(reservation).then(function (result) {
                        result.planningItems = _.map(result.planningItems, function (m) {
                            m.etaDisplay = (m.etaDisplay !== undefined && m.etaDisplay !== null) ? m.etaDisplay : moment(m.eta).format('DD-MM-YYYY HH:mm');
                            m.etdDisplay = (m.etdDisplay !== undefined && m.etdDisplay !== null) ? m.etdDisplay : moment(m.etd).format('DD-MM-YYYY HH:mm');
                            return m;
                        });
                        vm.selectedPlanning = result;
                        configureSelectedPlanning();
                    });
                } else {
                    configureSelectedPlanning();
                }

                vm.planning.startDate = moment(reservation.eta);
                if (moment(reservation.plannedEta).isBefore(moment(reservation.eta)))
                    vm.planning.startDate = moment(reservation.plannedEta);

                vm.planning.endDate = moment(reservation.etd);
                if (moment(reservation.plannedEtd).isAfter(moment(reservation.etd)))
                    vm.planning.endDate = moment(reservation.plannedEtd);

                if (!vm.planning.flowIds)
                    vm.planning.flowIds = [reservation.processFlowId];

                if (vm.selectedPlanning)
                    reservation.etaOriginal = vm.selectedPlanning.eta;
                if (vm.selectedPlanning)
                    reservation.etdOriginal = vm.selectedPlanning.etd;

                loadData();
            });
        getStatus(id);
        //getSailingReports(id);
        $anchorScroll();
        getMessages(id, apiSource);
    }

    function configureSelectedPlanning() {
        if (!vm.selectedPlanning.processFlowId)
            vm.selectedPlanning.processFlowId = vm.selectedReservation.processFlowId;

        vm.selectedPlanning.blockades = _.filter(planningCalendar.data.berthObstructions, function (obstruction) {
            return obstruction.reservationId !== undefined && obstruction.reservationId !== null && obstruction.reservationId === (vm.selectedPlanning.id ? vm.selectedPlanning.id : vm.selectedReservation.id);
        });
    }

    function convertReservarionToPlanning(reservation) {
        var q = common.$q.defer();

        var planning = {
            planningItems: [],
            reservationId: reservation.id,
            ship: reservation.ship
        };

        var existing = _.filter(planningCalendar.data.unplanned, function (unplanned) {
            return unplanned.reservationId === reservation.id;
        });

        if (existing && existing.length > 0) {
            planning.planningItems = existing;
            q.resolve(planning);
        } else {
            planningData.getSuggestion(reservation.id, reservation.processFlowId)
                .then(function (result) {
                    if (result) {
                        q.resolve(result[0].planning);
                    }
                    else if (reservation.reservationItems && reservation.reservationItems.length > 0) {
                        for (var a = 0; a < reservation.reservationItems.length; a++) {
                            planning.planningItems.push({
                                assignedToBerthId: null,
                                etaDisplay: moment(reservation.reservationItems[a].startsOn).format('DD-MM-YYYY HH:mm'),
                                etdDisplay: moment(reservation.reservationItems[a].endsOn).format('DD-MM-YYYY HH:mm')
                            });
                        }

                        if (planning.planningItems && planning.planningItems.length > 0) {
                            // Always set the first ETA to the reservation ETA
                            planning.planningItems[0].etaDisplay = moment(reservation.eta).format('DD-MM-YYYY HH:mm');
                            // Always set the last ETD to the reservation ETD
                            planning.planningItems[planning.planningItems.length - 1].etdDisplay = moment(reservation.etd).format('DD-MM-YYYY HH:mm');
                        }
                        q.resolve(planning);
                    }
                    else {
                        planning.planningItems.push({
                            assignedToBerthId: null,
                            etaDisplay: moment(reservation.eta).format('DD-MM-YYYY HH:mm'),
                            etdDisplay: moment(reservation.etd).format('DD-MM-YYYY HH:mm')
                        });
                        q.resolve(planning);
                    }
                });
        }

        return q.promise;
    }

    function selectPlanning(id, apiSource) {
        var _plan = common.copyObject(
            _.map(
                _.filter(planningCalendar.data.planned, function (r) {
                    return r.reservationId == id; // don't use ===, because the property 'reservationId' is a string and input 'id' could be a number
                }), function (m) {
                    m.etaDisplay = (moment.isMoment(m.eta) ? m.eta : moment(m.eta)).format('DD-MM-YYYY HH:mm');
                    m.etdDisplay = (moment.isMoment(m.etd) ? m.etd : moment(m.etd)).format('DD-MM-YYYY HH:mm');
                    return m;
                }));

        if (_plan && _plan.length > 0) {
            vm.selectedPlanning = {
                eta: _plan[0].eta,
                etd: _plan[_plan.length - 1].etd,
                id: id,
                planningItems: _plan,
                reservationId: id
            };
        }
        selectReservation(id, apiSource);
    }

    function selectChangedPlanning(id, apiSource) {
        // If the plannig is already loaded, just treat it as a normal planning selection
        if (_.find(planningCalendar.data.planned, function (item) {
            return item.reservationId == id; // don't use ===, because the property 'reservationId' is a string and input 'id' could be a number
        }) !== undefined) {
            selectPlanning(id, apiSource);
            return;
        }
        // Otherwise load it from the API
        planningData.getPlannedById(id)
            .then(function (result) {
                if (result && result.planningItems && result.planningItems.length > 0) {
                    planningCalendar.calculateItems([result], 'planned', true);
                    selectPlanning(id, apiSource);
                }
                else {
                    selectReservation(id, apiSource);
                }
            });
        return;
    }

    function updateSearch(needTimeout) {
        // use time out, give the user some time to finish enter the filter
        vm.reservations.pageIndex = 0;
        vm.reservations.pageSize = 10;

        var timer = needTimeout === true ? 2000 : 0;
        common.$timeout(function () {
            loadUnplannedReservations(true);
        }, timer);
    }

    function loadMoreUnplanned() {
        if (!vm.reservations.canLoadMore)
            return;

        vm.reservations.pageIndex += 1;
        loadUnplannedReservations();
    }

    function isFiltered(fieldToFilter, valueToFilter) {
        // Hide berths that are not active at the moment
        if (fieldToFilter === 'berth') {
            if (valueToFilter.availableUntil && moment(valueToFilter.availableUntil).isBefore(vm.planning.startDate)) {
                return false;
            }
            else if (valueToFilter.availableFrom && moment(valueToFilter.availableFrom).isAfter(vm.planning.endDate)) {
                return false;
            }
        } else if (fieldToFilter === 'berthgroup') {
            if (!valueToFilter.berths || valueToFilter.berths.length === 0) {
                return false;
            }

            if (!(_.some(valueToFilter.processFlowIds, function (r) { return _.contains(vm.planning.flowIds, r); }) || valueToFilter.id == -1))
                return false;

            if (_.filter(valueToFilter.berths, function (berth) { return isFiltered('berth', berth); }).length === 0) {
                return false;
            }
        } else if (fieldToFilter === 'planned') {
            if (!_.contains(vm.planning.flowIds, valueToFilter.processFlowId))
                return false;
        }

        if (!fieldToFilter || fieldToFilter === '' || !valueToFilter || !vm.display.textFilter || vm.display.textFilter === '')
            return true;

        if (vm.display.textFilterType === 'berth') {
            if (fieldToFilter === 'berth') {
                if (valueToFilter.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1) {
                    return true;
                }
            }
            else if (fieldToFilter === 'berthgroup') {
                if (valueToFilter.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
                    return true;
                return _.some(valueToFilter.berths, function (berth) { return isFiltered('berth', berth); });
            }
            else if (fieldToFilter === 'planned')
                return true;
            return false;
        }
        else if (vm.display.textFilterType === 'reservation') {
            if (fieldToFilter === 'berth') {
                return _.some(vm.planning.calendar.data.planned, function (planned) {
                    return planned.assignedToBerthId === valueToFilter.id &&
                        (
                            (planned.reservationReference && planned.reservationReference.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1) ||
                            (planned.ship && planned.ship.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
                        );
                });
            }
            else if (fieldToFilter === 'berthgroup') {
                return _.some(valueToFilter.berths, function (berth) { return isFiltered('berth', berth); });
            }
            else if (fieldToFilter === 'planned') {
                if (valueToFilter.ship.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
                    return true;
                if (valueToFilter.reservationReference && valueToFilter.reservationReference.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1) {
                    return true;
                }

                return false;
            }
        }

        return true;
    }

    function getRowsForBerth(berth, apiSource) {
        var items = $filter('calendarItems')(vm.planning.calendar.data.planned.filter(x => x.apiSource == apiSource || x.apiSource === undefined || x.apiSource === null), berth);
        items = items.concat($filter('calendarItems')(vm.planning.calendar.data.unplanned.filter(x => x.apiSource == apiSource || x.apiSource === undefined || x.apiSource === null), berth));

        if (!items || items.length === 0)
            return 1;

        var result = _.max(items, function (item) { return item.display.row + item.display.extraRows; });

        return result ? result.display.row + result.display.extraRows + 1 : 1;
    }

    function getMessages(reservationId, apiSource) {
        vm.display.unrespondedMessages = 0;

        reservationData.getMessages(reservationId, apiSource)
            .then(function (result) {
                vm.messages = result;
                vm.messages.forEach(element => element.apiSource = apiSource);

                for (var i = vm.messages.length - 1; i >= 0; i--) {
                    if (vm.messages[i].createdBy.portAuthorityId !== null) {
                        if (vm.messages[i].isIntern)
                            continue;
                        else
                            break;
                    }

                    vm.display.unrespondedMessages++;

                }
            });
    }

    function getSailingReports(reservationId) {
        reportData.getSailingReports(reservationId)
            .then(function (result) {
                vm.sailingReports = result;
            });
    }

    function getStatus(reservationId) {
        planningData.getStatus(reservationId)
            .then(function (result) {
                vm.communicatedStatus = result;
            });
    }

    function reservationHasSelectedFlow(reservation) {
        if (reservation.processFlowId)
            return _.contains(vm.planning.flowIds, reservation.processFlowId);
        else
            return false;
    }

    function reservationWithSelectedFlowCount() {
        var count = _.filter(vm.reservations.data, function (x) { return _.contains(vm.planning.flowIds, x.processFlowId) }).length;
        return count;
    }

    function showShipDimensions(ship) {
        if (ship !== undefined && ship !== null) {
            var values = [];
            if (ship.length !== undefined && ship.length !== null) {
                values.push(ship.length + " (L)");
            }

            if (ship.width !== undefined && ship.width !== null) {
                values.push(ship.width + " (W)");
            }

            if (ship.height !== undefined && ship.height !== null) {
                values.push(ship.height + " (H)");
            }

            if (values.length > 0) {
                return values.join(" x ");
            }
        }

        return "";
    }


    function markPlanningItemAsDeleted(itemId) {
        var itemIndex = _.findIndex(vm.selectedPlanning.planningItems, function (i) {
            return i.id === itemId;
        });

        if (itemIndex === -1)
            return;

        var item = vm.selectedPlanning.planningItems[itemIndex];
        if (item.id) {
            item.isDeleted = true; // item already saved, so mark als delete
        } else {
            vm.selectedPlanning.planningItems.splice(itemIndex, 1); // item is new, so remove the list
        }
    }

    function markBlockadeAsDeleted(itemId) {
        var itemIndex = _.findIndex(vm.selectedPlanning.blockades, function (i) {
            return i.id === itemId;
        });

        if (itemIndex === -1)
            return;

        var item = vm.selectedPlanning.blockades[itemIndex];
        if (item.id) {
            item.isDeleted = true; // item already saved, so mark als delete
        } else {
            vm.selectedPlanning.blockades.splice(itemIndex, 1); // item is new, so remove the list
        }
    }

    function saveMessage() {
        if (!vm.newMessage.text || vm.newMessage.text === '')
            return;

        var reservationId = null;
        if (vm.selectedReservation && vm.selectedReservation.id)
            reservationId = vm.selectedReservation.id;
        else if (vm.selectedPlanning && vm.selectedPlanning.planningItems && vm.selectedPlanning.planningItems.length > 0)
            reservationId = vm.selectedPlanning.planningItems[0].reservationId;

        reservationData.addMessage(reservationId, vm.newMessage.text, vm.newMessage.isIntern, null, vm.newMessage.status)
            .then(function (result) {
                vm.newMessage.isIntern = false;
                vm.newMessage.status = '1';
                vm.newMessage.text = null;

                getMessages(reservationId);
            });

    }

    function saveNextPlanningItem(confirm) {
        if (vm.queue.length === 0) {
            saveNextBlockade();
            return;
        }

        var nextItem = vm.queue.splice(0, 1)[0];

        if (nextItem.isDeleted === true) {
            planningData.deletePlanningItem(nextItem.id)
                .then(function () { saveNextPlanningItem(confirm); });
        } else if (nextItem.id) {
            if (confirm === true) {
                planningData.setStatus(nextItem.reservationId, { status: 3 })
                    .then(function () { });
            }
            planningData.updatePlanningItem(nextItem)
                .then(function () { saveNextPlanningItem(confirm); });
        } else {
            if (confirm === true) {
                planningData.setStatus(nextItem.reservationId, { status: 3 })
                    .then(function () { });
            }
            planningData.addPlanningItem(nextItem)
                .then(function () { saveNextPlanningItem(confirm); });
        }

    }

    function saveNextBlockade() {
        if (vm.queueBlockades.length === 0) {
            selectReservation();
            toastr.success("Changes Saved");
            loadData(true);
            return;
        }

        var nextItem = vm.queueBlockades.splice(0, 1)[0];

        if (nextItem.isDeleted === true) {
            if (nextItem.id) {
                businessRuleData.del(nextItem)
                    .then(saveNextBlockade);
            } else {
                saveNextBlockade();
            }
        } else if (nextItem.id) {
            businessRuleData.update(nextItem)
                .then(saveNextBlockade);
        } else {
            businessRuleData.add(nextItem)
                .then(saveNextBlockade);
        }
    }

    function saveSelection(confirm) {
        if (!vm.selectedPlanning.planningItems || vm.selectedPlanning.planningItems.length === 0)
            return;

        vm.selectedPlanning.planningItems = _.map(vm.selectedPlanning.planningItems, function (planningItem) {
            planningItem.eta = moment(planningItem.etaDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            if (planningItem.eta === 'Invalid date:00')
                planningItem.eta = null;
            planningItem.etd = moment(planningItem.etdDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            if (planningItem.etd === 'Invalid date:00')
                planningItem.etd = null;
            return planningItem;
        });

        vm.selectedPlanning.blockades = _.map(vm.selectedPlanning.blockades, function (blockade) {
            blockade.availableFrom = moment(blockade.etaDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            if (blockade.availableFrom === 'Invalid date:00')
                blockade.availableFrom = null;
            blockade.availableUntil = moment(blockade.etdDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            if (blockade.availableUntil === 'Invalid date:00')
                blockade.availableUntil = null;
            return blockade;
        });

        // Validate the items
        var errors = [];

        _.each(vm.selectedPlanning.planningItems, function (item, index) {
            if (item.isDeleted === true) return; // don't validate when the item needs to delete

            if (!item.eta)
                errors.push('No ETA specified for item ' + (index + 1));
            if (!item.etd)
                errors.push('No ETD specified for item ' + (index + 1));
            if (!item.assignedToBerthId || item.assignedToBerthId === -1)
                errors.push('No berth specified for item ' + (index + 1));

            if (item.eta && item.etd && moment(item.eta).isSameOrAfter(moment(item.etd))) {
                errors.push('Starts on cannot later than ends on ' + (index + 1));
            }
        });
        _.each(vm.selectedPlanning.blockades, function (blockade, index) {
            if (blockade.isDeleted === true) return; // don't validate when the item needs to delete

            if (!blockade.availableFrom)
                errors.push('No ETA specified for blockade ' + (index + 1));
            if (!blockade.availableUntil)
                errors.push('No ETD specified for blockade ' + (index + 1));
            if (!blockade.assignedToBerthId || blockade.assignedToBerthId === -1)
                errors.push('No berth specified for blockade ' + (index + 1));

            if (blockade.availableFrom && blockade.availableUntil && moment(blockade.availableFrom).isSameOrAfter(moment(blockade.availableUntil))) {
                errors.push('Blockade starts on cannot later than ends on ' + (index + 1));
            }
        });
        if (errors.length > 0) {
            vm.planningErrors = errors;
            return;
        }

        vm.queueBlockades = _.map(vm.selectedPlanning.blockades, function (blockade) {
            var obj = common.copyObject(blockade);
            obj.berthId = blockade.assignedToBerthId;
            obj.businessRuleId = 3; // Berth blocked
            obj.startsOn = blockade.availableFrom;
            obj.endsOn = blockade.availableUntil;
            obj.remarks = vm.selectedReservation.id + ':' + vm.selectedReservation.ship.name;
            return obj;
        });

        vm.planningErrors = []; // reset errors
        if (vm.selectedPlanning.planningItems[0].id === null || vm.selectedPlanning.planningItems[0].id <= 0) {
            planningData.addPlanning(vm.selectedPlanning)
                .then(function (result) {
                    if (confirm === true) {
                        planningData.setStatus(result.reservation.id, { status: 3 })
                            .then(function () { });
                    }
                    saveNextBlockade();
                });
        } else {
            vm.queue = common.copyObject(vm.selectedPlanning.planningItems);
            vm.disableBtn = true;
            saveNextPlanningItem(confirm);
        }

    }

    function cancelSelection() {
        widgetModals.freeTextConfirm({
            title: "Reason",
            description: "Warning! You are about to cancel the reservation for this customer. Please enter a reason",
            required: true
        })
            .result
            .then(function (result) {
                if (result && result.value !== undefined && result.value !== null && result.value.length > 0) {
                    planningData.cancelReservation(vm.selectedReservation.id, result.value)
                        .then(function (cancellationResult) {
                            vm.queueBlockades = _.map(_.filter(vm.selectedPlanning.blockades, function (b) { return b.id > -1; }), function (blockade) {
                                blockade.isDeleted = true;
                                return blockade;
                            });
                            saveNextBlockade(); // remove the linked blockades
                        });
                }
            }, function () { });
    }

    function saveAndConfirmSelection() {
        saveSelection(true);
    }

    function addNewPlanningRow() {
        var newRow = {
            assignedToBerthId: null,
            eta: null,
            etd: null
        };

        if (vm.selectedPlanning.planningItems.length > 0) {
            var previousRow = vm.selectedPlanning.planningItems[vm.selectedPlanning.planningItems.length - 1];
            newRow.assignedToBerthId = previousRow.assignedToBerthId;
            newRow.reservationId = previousRow.reservationId;
            if (previousRow.etd !== null)
                newRow.eta = moment(previousRow.etd, 'DD-MM-YYYY HH:mm').add(-1, 'days').format('DD-MM-YYYY HH:mm');
        }

        vm.selectedPlanning.planningItems.push(newRow);
    }

    function addNewBerthBlocked() {
        var newRow = {
            assignedToBerthId: null,
            availableFrom: null,
            availableUntil: null
        };

        if (vm.selectedPlanning.planningItems.length > 0) {
            var previousRow = vm.selectedPlanning.planningItems[vm.selectedPlanning.planningItems.length - 1];
            newRow.assignedToBerthId = -1;
            newRow.reservationId = previousRow.reservationId;
            if (previousRow.eta !== null) {
                newRow.availableFrom = moment(previousRow.eta).format('YYYY-MM-DDTHH:mm:ss');
                newRow.etaDisplay = moment(previousRow.eta).format('DD-MM-YYYY HH:mm');
            }

            if (previousRow.etd !== null) {
                newRow.availableUntil = moment(previousRow.etd).format('YYYY-MM-DDTHH:mm:ss');
                newRow.etdDisplay = moment(previousRow.etd).format('DD-MM-YYYY HH:mm');
            }
        }

        vm.selectedPlanning.blockades.push(newRow);
    }

    function sendISPSNotification() {
        planningData.sendISPSNotification(vm.selectedPlanning.reservationId)
            .then(function (result) {
                vm.communicatedStatus = result;
                toastr.success('The ISPS notification has been sent');
            });
    }

    function selectProcessFlows() {
        widgetModals.processFlowPicker({ availableFlows: vm.allFlows })
            .result
            .then(function (result) {
                if (result) {
                    vm.planning.flowIds = _.pluck(_.filter(result, 'selected'), 'id');
                    if (vm.planning.flowIds && vm.planning.flowIds.length > 0) {
                        loadData();
                    }
                }
            });
    }

    function setSelectedFlow(flowId) {
        if (_.indexOf(vm.planning.flowIds, flowId) > -1) {
            vm.planning.flowIds.splice(_.indexOf(vm.planning.flowIds, flowId), 1);
        } else {
            vm.planning.flowIds.push(flowId);
        }
        loadBerths();
        loadData(true);
    }

    function openSplitReservationDateDialog(reservationItem) {
        const args = {
            startsOnDisplay: reservationItem.startsOn,
            endsOnDisplay: reservationItem.endsOn
        }

        widgetModals.splitReservationDate(args)
            .result
            .then(function (result) {
                if(result) {
                    vm.splitReservations.push(
                        {
                            berthId: reservationItem.berthId,
                            startsOn: result,
                            endsOn: reservationItem.endsOn,
                            customerId: reservationItem.customerId
                        }
                    );

                    if(reservationItem.id) {
                        vm.reservationItems[_.findIndex(vm.reservationItems, { id: reservationItem.id })].endsOn = result;
                    }

                    else {
                        vm.splitReservations[_.findIndex(vm.splitReservations, reservationItem)].endsOn = result;
                    }
                }
            });
    }

    function removeSplitReservation(splitReservation) {
        vm.splitReservations = _.without(vm.splitReservations, splitReservation);
    }

    function lockReservation() {
        vm.reservationItems = _.map(vm.selectedReservation.reservationItems, x => {
            return {
                id: x.id,
                berthId: x.berthId,
                startsOn: moment(x.startsOn).format('DD-MM-YYYY HH:mm'),
                endsOn: moment(x.endsOn).format('DD-MM-YYYY HH:mm'),
                customerId: vm.selectedReservation.customerId
            }
        });

        vm.splitReservations = [];
        vm.reservationUnlocked = false;
        vm.reservationMotivation = null;
    }

    function editReservation() {
        vm.reservationErrors = [];

        if(vm.validateReservation()) {
            const reservation = _.clone(vm.selectedReservation);

            _.forEach(vm.reservationItems, (reservationItem, i) => {
                const index = _.findIndex(reservation.reservationItems, { id: reservationItem.id });

                reservation.reservationItems[index].berthId = reservationItem.berthId;
                reservation.reservationItems[index].berth = _.find(vm.planning.berthDetails, x => { return x.id === reservationItem.berthId });
                reservation.reservationItems[index].startsOn = moment(reservationItem.startsOn, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:00');
                reservation.reservationItems[index].endsOn = moment(reservationItem.endsOn, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:00');

                if(i == vm.reservationItems.length - 1) {
                    reservation.primaryPreferredBerthId = reservationItem.berthId;
                    reservation.eta = moment.min(_.map(_.pluck(reservation.reservationItems, 'startsOn'), x => moment(x))).format('YYYY-MM-DDTHH:mm:00');
                    reservation.etd = moment.max(_.map(_.pluck(reservation.reservationItems, 'endsOn'), x => moment(x))).format('YYYY-MM-DDTHH:mm:00');

                    reservationData.updateReservation(reservation, vm.reservationMotivation);

                    const splitReservations = _.map(vm.splitReservations, x => {
                       return {
                           primaryPreferredBerthId: x.berthId,
                           eta: moment(x.startsOn, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:00'),
                           etd: moment(x.endsOn, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:00'),
                           customerId: x.customerId,
                           processFlowId: reservation.processFlowId,
                           reservationItems: [{
                               berthId: x.berthId,
                               startsOn: moment(x.startsOn, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:00'),
                               endsOn: moment(x.endsOn, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:00')
                           }]
                       } 
                    });

                    if (splitReservations && splitReservations.length > 0)
                        reservationData.addReservations(splitReservations, reservation.ship.shipTypeId, vm.reservationMotivation);

                    vm.loadData(true);
                    vm.selectReservation();
                }
            });
        }
    }

    function validateReservation() {
        if(!vm.reservationMotivation) {
            vm.reservationErrors.push('Motivation is required');
        }

        _.forEach(vm.reservationItems.concat(vm.splitReservations), validationItem => {
            const error = 'Berth is missing';

            if(!validationItem.berthId || validationItem.berthId < 0) {
                if(vm.reservationErrors.indexOf(error)) {
                    vm.reservationErrors.push(error);
                }
            }

            if(!moment(validationItem.startsOn, 'DD-MM-YYYY HH:mm').isValid()) {
                const error = `'Starts on' date is invalid`;

                if(vm.reservationErrors.indexOf(error)) {
                    vm.reservationErrors.push(error);
                }
            }

            if(!moment(validationItem.endsOn, 'DD-MM-YYYY HH:mm').isValid()) {
                const error = `'Ends on' date is invalid'`;
                
                if(vm.reservationErrors.indexOf(error)) {
                    vm.reservationErrors.push(error);
                }
            }

            if(moment(validationItem.startsOn, 'DD-MM-YYYY HH:mm').isValid()
                && moment(validationItem.endsOn, 'DD-MM-YYYY HH:mm').isValid()
                && !moment(validationItem.startsOn, 'DD-MM-YYYY HH:mm').isBefore(moment(validationItem.endsOn, 'DD-MM-YYYY HH:mm'))
            ) {
                const error = `'Starts on' date must be before 'ends on' date`;

                if(vm.reservationErrors.indexOf(error)) {
                    vm.reservationErrors.push(error);
                }
            }

            if(validationItem.hasOwnProperty('customerId') && validationItem.customerId < 0) {
                const error = 'Customer is missing';
                
                if(vm.reservationErrors.indexOf(error)) {
                    vm.reservationErrors.push(error);
                }
            }
        });

        return vm.reservationErrors.length == 0;
    }

    return vm;
}
