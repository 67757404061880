﻿module.exports = ['reservationDataService', reservationListDirective];

function reservationListDirective(reservationData) {
    var directive = {
        bindToController: true,
        controller: reservationListController,
        controllerAs: 'ctrlReservationList',
        restrict: 'E',
        scope: {
            reservations: '=',
            reservation: '=',
            reservationId: '=',
            status: '='
        },
        templateUrl: 'templates/customer/directives/reservation/reservation-list.html'
    };

    return directive;

    function reservationListController() {
        var vm = {
            reservations: this.reservations,
            reservationId: this.reservationId,
            reservation: this.reservation,
            status: this.status,
            customer: this.customer,
            filter: {
                showPastReservations: true,
                sortAscending: true,
                sortField: 'eta'
            },
            myFilter: myFilter,
            setViewReservation: setViewReservation
        };
        return vm;

        function myFilter (item, b, list) {
            var result = true;

            if (vm.filter.showPastReservations === false && result === true)
                result = moment().isBefore(item.etd);

            return result;
        }

        function setViewReservation(reservation) {       
            vm.reservationId = reservation.id;
            vm.status.showDetails = true;

            reservationData.getDetails(vm.reservationId)
                .then(function(result) {
                    vm.reservation = result;

                    if (vm.reservation.communicatedStatus && vm.reservation.communicatedStatus.length > 0) {
                        // since other communicatedStatus (cancelled, changed etc...) is stored into the database, the UI needs to updated
                        // need to check the impact of this changes; e.g. in reservation-duplicate-current, calendarblock etc...
                        vm.reservation.communicatedStatus = _.filter(_.sortBy(vm.reservation.communicatedStatus, 'communicatedOn'), function (obj) { return obj.status <= 3; });
                    }
                });
        }

    }

}
