﻿'use strict';

module.exports = ['tariffDataService', tariffController];

function tariffController(tariffDataService) {
    var vm = {
        tariffs: [],
        tariffPendings: [],
        allowedApprove:{
            value:null,
            isLoading:false
        },
        loadTariffs: loadTariffs,
        activeTariff: null,
        activeTariffPending: null,
        onTariffSaved: onTariffSaved,
        onTariffDeleted: onTariffDeleted,
        onTariffApproved: onTariffApproved
    };

    init();

    return vm;

    function init() {
        loadTariffs();
    }

    function onTariffSaved(savedTariff, isNew) {
        let newTariffs = _.clone(vm.tariffs);
        if (!isNew) {
            newTariffs = _.filter(newTariffs, x => x.id != savedTariff.id);
        }

        newTariffs.push(savedTariff);
        vm.tariffs = _.sortBy(newTariffs, 'description');

        loadTariffPendings();
        vm.activeTariff = null;
        vm.activeTariffPending = null;
    }

    function onTariffApproved(approvedTariff) {
        vm.activeTariff = approvedTariff;
        var idx = _.findIndex(vm.tariffs, { id: approvedTariff.id });
        var tariffGroup = vm.tariffs[idx].tariffGroup;
        vm.activeTariff.needsApproval =false;
        vm.activeTariffPending = null;

        vm.tariffs.splice(idx, 1, approvedTariff);
        vm.tariffs[idx].tariffGroup = tariffGroup;
        loadTariffs();
    }

    function onTariffDeleted(deletedTariff) {
        var idx = _.findIndex(vm.tariffs, function (item) { return item.id === deletedTariff.id; });

        if (idx >= 0) {
            vm.tariffs.splice(idx, 1);
        }

        vm.activeTariff = null;
    }

    function loadTariffs() {
        tariffDataService.getTariffs()
            .then(function (result) {
                vm.tariffs = _.sortBy(result, 'description');
                loadTariffPendings();
            });
    }

    function loadTariffPendings(){
        tariffDataService.getTariffPendings()
                .then(function (data) {
                    vm.tariffPendings = data;
                    for(let i =0;i<vm.tariffs.length;i++)
                    {
                        vm.tariffs[i].needsApproval =false;
                        var filterList = _.filter(vm.tariffPendings,function(x){return x.tariffId == vm.tariffs[i].id});
                        if(filterList.length>0){
                            vm.tariffs[i].needsApproval =true;
                        }
                        if(!vm.tariffs[i].approvedOn){
                            vm.tariffs[i].needsApproval = true;
                        }
                    }
                });
    }
}
