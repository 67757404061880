﻿module.exports = [invoiceStatusDisplayFilter];

function invoiceStatusDisplayFilter() {
    return function (status) {
        switch (status) {
            case 0:
            case "0":
                return "Preview";
            case 1:
            case "1":
                return "Original";
            case 2:
            case "2":
                return "Credited";
            case 4:
            case "4":
                return "Change";
        }
        
        return null;
    };
};
