﻿module.exports = ['$routeParams', 'common',
                  'berthgroupDataService', berthgroupMainController];

function berthgroupMainController($routeParams, common, berthgroupData) {
    var vm = {
        activeBerthgroup: null,
        berthgroups: [],
        onBerthgroupSaved: onBerthgroupSaved,
        onBerthgroupDeleted: onBerthgroupDeleted,
        initialactiveBerthgroupFromUrl: initialactiveBerthgroupFromUrl
    }

    init();

    return vm;

    function init() {
        loadBerthgroups();
        initialactiveBerthgroupFromUrl();
    }

    function onBerthgroupSaved(savedBerthgroup, isNew) {
        if (isNew) {
            vm.berthgroups.push(savedBerthgroup);
        }

        vm.activeBerthgroup = null;
    }
    function onBerthgroupDeleted(deletedBerthgroup) {
        var idxBerthgroup = _.findIndex(vm.berthgroups, function (item) { return item.id === deletedBerthgroup.id; });
        if (idxBerthgroup >= 0)
            vm.berthgroups.splice(idxBerthgroup, 1);

        vm.activeBerthgroup = null;
    }

    function loadBerthgroups() {
        berthgroupData.getBerthgroupsForAdmin()
        .then(function (result) {
            vm.berthgroups = result;
            vm.activeBerthgroup = null;
        });
    }

    function initialactiveBerthgroupFromUrl() {
        var berthgroupId = $routeParams.berthgroupId || 0;
        if (berthgroupId > 0) {

        }
    }
}