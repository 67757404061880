﻿module.exports = ['reservationDataService', 'messageDataService', messagesListDirective];

function messagesListDirective(reservationData, messageData) {
    var directive = {
        bindToController: true,
        controller: messagesListController,
        controllerAs: 'ctrlMessagesList',
        restrict: 'E',
        scope: {
            inbox: '=',
            customerInbox: '=',
            reservationId: '=',
            messages: '=',
            status: '=',
            customer: '='
        },
        templateUrl: 'templates/customer/directives/message/messages-list.html'
    };

    return directive;

    function messagesListController() {
        var vm = {
            formatStatus: formatStatus,
            inbox: this.inbox,
            reservationId: this.reservationId,
            messages: this.messages,
            customer: this.customer,
            status: this.status,
            setViewMessages: setViewMessages,
            setViewCustomerMessages: setViewCustomerMessages,
            closeMessageBox: closeMessageBox,
            isCustomerMessage: false
        };

        return vm;

        function formatStatus(status) {
            switch (status) {
                case 0:
                    return 'New';
                case 1:
                    return 'sent by customer';
                case 2:
                    return 'sent by port authority';
                case 3:
                    return 'sent by system';
                case 4:
                    return 'done';
                case 255:
                    return 'archived';
            }

            return status;
        }

        function setViewMessages(inboxItem) {
            vm.reservationId = inboxItem.reservation.id;
            vm.status.showDetails = true;
            vm.messages = inboxItem.messages;

            //reservationData.getMessagesForCustomer(vm.reservationId, vm.customer.id)
            //    .then(function (results) {
            //        vm.messages = results;
            //    });
        }

        function setViewCustomerMessages() {
            vm.status.showDetails = true;
            //vm.isCustomerMessage = true;

            //messageData.getCustomerMessagesForCustomer(vm.customer.id)
            //    .then(function (results) {
            //        vm.messages = results;
            //    });
        }

        function closeMessageBox() {
            vm.reservationId = null;
            vm.isCustomerMessage = false;
        }
    }

}
