﻿'use strict';

module.exports = ['tariffDataService', tariffGroupController];

function tariffGroupController(tariffDataService) {
    var vm = {
        tariffGroups: [],
        loadTariffGroups: loadTariffGroups,
        activeTariffGroup: null,
        onTariffGroupSaved: onTariffGroupSaved,
        onTariffGroupDeleted: onTariffGroupDeleted
    };

    init();

    return vm;

    function init() {
        loadTariffGroups();
    }

    function onTariffGroupSaved(savedTariffGroup, isNew) {
        if (isNew) {
            vm.tariffGroups.push(savedTariffGroup);
        }

        vm.activeTariffGroup = null;
    }

    function onTariffGroupDeleted(deletedTariffGroup) {
        var idx = _.findIndex(vm.tariffGroups, function (item) { return item.id === deletedTariffGroup.id; });

        if (idx >= 0) {
            vm.tariffGroups.splice(idx, 1);
        }

        vm.activeTariffGroup = null;
    }

    function loadTariffGroups() {
        tariffDataService.getTariffGroups()
            .then(function (result) {
                vm.tariffGroups = _.sortBy(result, 'description');
            });
    }
}