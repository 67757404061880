﻿'use strict';

module.exports = ['common', 'dataContext', 'goalModelsService', goalDataService];

function goalDataService(common, context, goalModels) {

    var vm = {
        getCommercialGoals: getCommercialGoals,
        getCommercialGoalsForAdmin: getCommercialGoalsForAdmin,
        getNauticalGoals: getNauticalGoals,
        deleteGoal: deleteGoal,
        saveGoal: saveGoal
    };

    return vm;

    function getCommercialGoals() {
        var deferred = common.$q.defer();
        var url = "/goal/commercial";
        var req = context.get(url)
            .then(function (data) {
                var result = _.map(data, goalModels.goalModel);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCommercialGoalsForAdmin() {
        var deferred = common.$q.defer();
        var url = "/goal/commercial/admin";
        var req = context.get(url)
            .then(function (data) {
                var result = _.map(data, goalModels.goalModel);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getNauticalGoals() {
        var deferred = common.$q.defer();
        var url = "/goal/nautical";
        var req = context.get(url)
            .then(function (data) {
                var result = _.map(data, goalModels.goalModel);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveGoal(goal) {
        var deferred = common.$q.defer();
        var url = '/goal/' + goal.goalType.toLowerCase();
        if (goal.id) var url = url + '/' + goal.id;

        var req = context.post(url, goal)
            .then(function (data) {
                var result = new goalModels.goalModel(data);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteGoal(goal) {
        var deferred = common.$q.defer();
        var url = '/goal/' + goal.goalType.toLowerCase() + '/' + goal.id;
        var req = context.del(url)
            .then(function (data) {
                var result =new  goalModels.goalModel(data);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}