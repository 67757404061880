﻿module.exports = ['common', '$filter', 'reportDataService', 'invoiceDataService', 'constantsService', reportRevenueController];

function reportRevenueController(common, $filter, reportData, invoiceData, constantsService) {

    var vm = {
        allowedInvoiceTypes: [],
        attached: attached,
        calculateTotalAmount: calculateTotalAmount,
        common: common,
        downloadReport: downloadReport,
        filter: {
            endDate: null,
            startDate: null
        },
        flows: [],
        loading: true,
        loadFlowRevenueIndex: 0,
        selectedInvoiceType: null,
        reload: reload,
        rows: [],
        totalAmount: {}
    };

    function attached() {

        constantsService.preloaded.then(function () {
            vm.allowedInvoiceTypes = constantsService.invoiceTypes;
            vm.selectedInvoiceType = vm.allowedInvoiceTypes[0];

            vm.filter.startDate = moment("20150101", "YYYYMMDD"); // select all before this month, so use 01-01-2015 as start date
            vm.filter.endDate = moment().date(1).add(-1, 'days'); // the last day of previous month

            reload();
        });
    }

    function calculateTotalAmount() {
        return _.reduce(vm.totalAmount, function (sum, value) {
            return Math.round((sum + value) * 100) / 100;
        }, 0);
    }

    function downloadReport() {
        var csvOutput = 'CUSTOMER;SHIP;ETA;ETD;RESERVATION REFERENCE;ATA;ATD;AMOUNT;STATUS;TRANSFERRED ON;COST CENTRE\r\n';

        for (var i = 0; i < vm.rows.length; i++) {
            var flow = vm.rows[i];
            csvOutput +=
                flow.customer.name + ';' +
                flow.ship.name + ';' +
                (flow.reservation === null || (flow.reservation !== null && flow.reservation.startDate === null) ? "None" : flow.reservation.startDate.replace('T', ' ')) + ';' +
                (flow.reservation === null || (flow.reservation !== null && flow.reservation.endDate === null) ? "None" : flow.reservation.endDate.replace('T', ' ')) + ';' +
                (flow.reservation !== null && flow.reservation.reservationReference !== null ? flow.reservation.reservationReference : '') + ';' +
                (flow.realisation === null || flow.realisation.startDate === null ? "None" : flow.realisation.startDate.replace('T', ' ')) + ';' +
                (flow.realisation === null || flow.realisation.endDate === null ? "None" : flow.realisation.endDate.replace('T', ' ')) + ';' +
                flow.displayInvoice.amount.toLocaleString('nl-nl', { minimumFractionDigits: 2 }) + ';' +
                ($filter('invoiceStatusDisplay')(flow.displayInvoice.status)) + ';' +
                (flow.displayInvoice.transferredOnDate === undefined || flow.displayInvoice.transferredOnDate === null ? "None" : flow.displayInvoice.transferredOnDate.replace('T', ' ')) + ';' +
                flow.displayInvoice.costCentre + '\r\n';
        }

        
        common.exportToCSV(csvOutput,'report-open-revenue-' + moment().format('YYYYMMDD'));
    }

    function loadFlows() {
        if (vm.selectedInvoiceType) {
            vm.loading = true;
            vm.loadFlowRevenueIndex = 0;
            reportData.getReportOpenRevenue(vm.filter.startDate.format('YYYY-MM-DD'), vm.filter.endDate.format('YYYY-MM-DD'), vm.selectedInvoiceType.id)
                .then(function (result) {
                    vm.flows = result;
                    vm.rows = [];
                    vm.loadFlowRevenueIndex = 0;
                    vm.totalAmount = {};
                    vm.loading = false;
                    loadOpenRevenue();
                }, function (error) {
                    vm.loading = false;
                });
        }
    }

    function handleCostCentre(flow, status, invoiceItems, remarks) {
        if (invoiceItems !== undefined && invoiceItems !== null && invoiceItems.length > 0) {
            var groups = _.map(
                _.groupBy(invoiceItems, 'costCentre'),
                function (val, key) {
                    return {
                        costCentre: key,
                        totalAmount: _.reduce(val, function (sum, obj) { return sum + obj.totalAmount; }, 0)
                    };
                });

            var invoices = _.sortBy(flow.invoices, 'transferredOnDate');
            var transferredOnDate = invoices.length === 0 || _.last(invoices).transferredOnDate === null ? null : _.first(invoices).transferredOnDate;
            for (var i = 0; i < groups.length; i++) {
                var newRow = _.clone(flow);
                newRow.displayInvoice = { status: status, amount: groups[i].totalAmount, costCentre: groups[i].costCentre, remarks: null, transferredOnDate: transferredOnDate };

                if (vm.totalAmount[groups[i].costCentre] === undefined) {
                    vm.totalAmount[groups[i].costCentre] = 0;
                }
                vm.totalAmount[groups[i].costCentre] += groups[i].totalAmount;
                vm.rows.push(newRow);
            }
        } else {
            var row = _.clone(flow);
            row.displayInvoice = { status: status, amount: 0, costCentre: null, remarks: remarks, transferredOnDate: null };
            vm.rows.push(row);
        }
    }

    function loadOpenRevenue() {
        if (vm.flows !== undefined && vm.flows !== null && vm.flows.length > 0 && vm.loadFlowRevenueIndex < vm.flows.length) {
            var flow = vm.flows[vm.loadFlowRevenueIndex];

            if (flow.invoices !== null && flow.invoices.length > 0) {

                var dateUntil = moment(vm.filter.endDate).add(1, 'days'); // need add 1 day extra, make sure the time is until the end of the chosen day (23:59)
                flow.invoices = _.filter(_.sortBy(flow.invoices, 'transferredOnDate'), function (invoice) { return invoice.transferredOnDate === null || moment(invoice.transferredOnDate) >= dateUntil; });
                if (flow.invoices.length > 0) {
                    handleCostCentre(flow, _.last(flow.invoices).status, _.reduce(flow.invoices, function (items, invoice) { return items.concat(invoice.invoiceItems === undefined || invoice.invoiceItems === null ? [] : invoice.invoiceItems); }, []), null);
                }
                vm.loadFlowRevenueIndex++;
                loadOpenRevenue();
            } else {
                if (flow.realisation !== null && flow.realisation.id > 0 && flow.realisation.startDate) { // caulculate the invoice when the realisation id is set AND startDate is set; no startdate means no realisation items, so use planning
                    invoiceData.getPreview(flow.realisation.id).then(succeedLoadedRevenue, failedLoadedRevenue);
                } else if (flow.reservation !== null && flow.reservation.id) {
                    if (flow.planning && flow.planning.status !== 2 && flow.planning.startDate !== null) {
                        invoiceData.getPreviewForPlanning(flow.reservation.id, vm.selectedInvoiceType.id).then(succeedLoadedRevenue, failedLoadedRevenue);
                    } else {
                        invoiceData.getPreviewForReservation(flow.reservation.id, vm.selectedInvoiceType.id).then(succeedLoadedRevenue, failedLoadedRevenue);
                    }
                } else { // cannot calculate the invoice
                    var remarks = flow.realisation !== null ? "Cannot calculate the invoice, realisation is incomplete." : "Cannot calculate the invoice, no reservation id is set.";
                    handleCostCentre(flow, 0, null, remarks);
                    vm.loadFlowRevenueIndex++;
                    loadOpenRevenue();
                }
            }
        } else {
            vm.loadFlowRevenueIndex = -1;
        }
    }

    function succeedLoadedRevenue(result) {
        if (vm.loading) return; // reload the flows, do nothing

        var flow = vm.flows[vm.loadFlowRevenueIndex];

        if (result !== undefined &&
            result !== null &&
            result.invoice &&
            result.invoice.invoiceItems &&
            result.invoice.invoiceItems.length > 0) {

            handleCostCentre(flow, 0, result.invoice.invoiceItems, null);
        } else {
            var remarks = result !== undefined && result !== null && result.remarks !== undefined && result.remarks !== null && result.remarks !== "" ? result.remarks : "Cannot calculate the invoice; no invoice item is set.";
            handleCostCentre(flow, 0, null, remarks);
        }
        vm.loadFlowRevenueIndex++;
        loadOpenRevenue();
    }

    function failedLoadedRevenue(error) {
        if (vm.loading) return; // reload the flows, do nothing

        var msg = "Error during calculating the invoice";
        if (error !== undefined && error !== null) {
            if (error.message !== undefined && error.message !== null) {
                msg = msg + ": " + error.message;
            } else if (error.data !== undefined && error.data !== null && error.data.exceptionMessage !== undefined && error.data.exceptionMessage !== null) {
                msg = msg + ": " + error.data.exceptionMessage;
            }
        }

        handleCostCentre(vm.flows[vm.loadFlowRevenueIndex], 0, null, msg);
        vm.loadFlowRevenueIndex++;
        loadOpenRevenue();
    }

    function reload() {
        loadFlows();
    }

    return vm;
}