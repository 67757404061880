﻿'use strict';
module.exports = [
    'common',
    '$location',
    '$routeParams',
    '$uibModal',
    'constantsService',
    'planningDataService',
    'berthgroupDataService',
    'arrivalsDataService',
    'arrivalsModelService',
    'goalDataService',
    'widgetModals',
    'common',
    arrivalsController
];

function arrivalsController(common, $location, $routeParams, $uibModal, constantsService, planningDataService, berthgroupDataService, arrivalsDataService, arrivalsModelService, goalDataService, widgetModals) {

    var vm = {
        attached: attached,
        getToDos: getToDos,
        getStatuses: getStatuses,
        newArrival: null,
        showImportButton: false,
        arrivals: {
            all: [],
            newAdded: null,
            total: null,
            flowIds: []
        },
        page: 'arrivals',
        indexCount: 0,
        arrivalsPerLoad: 500,
        loadMore: loadMore,
        realisationStatuses: [],
        berthGroups: [],
        berths: [],
        getBerthGroups: getBerthGroups,
        status: {
            completed: false,
            errors: [],
            saving: false,
            setAddNew: setAddNew,
            showAddNew: false
        },
        searchquery: null,
        getBySearch: getBySearch,
        updateSearch: updateSearch,
        deepCopy: deepCopy,
        checkPermission: checkPermission,
        permission: false,
        datePickerOptions: {
            altInputFormats: [
                'd-M-yy',
                'd-M-yyyy',
                'd-M-yy H:mm',
                'd-M-yyyy H:mm',
                'd-M-yy HH:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yy',
                'dd-MM-yyyy',
                'dd-MM-yy H:mm',
                'dd-MM-yyyy H:mm',
                'dd-MM-yy HH:mm',
                'dd-MM-yyyy HH:mm'
            ]
        },
        loading: false,
        dataCount: 0,
        availableFlows: [],
        defaultSelectedFlows: null,
        nauticalGoals: [],
        getNauticalGoals: getNauticalGoals,
        showImportDialog: showImportDialog,
        display: {
            textFilter: null
        },
        selectProcessFlows: selectProcessFlows,
        allFlows: [],
        previousArrivals: {
            flowIds: []
        }

    };

    function deepCopy(obj) {
        var b = JSON.parse(JSON.stringify(obj));
        return b;
    }

    function checkPermission() {
        vm.availableFlows = constantsService.getFlowsForPermissions(["realisation", "realisation_create", "realisation_admin"]);
        vm.availableFlows = _.filter(vm.availableFlows, 'isActive');

        let result = common.identity.hasPermission('realisation_admin');
        vm.permission = result;

        if (vm.availableFlows.length > 0) {
            vm.showImportButton = _.some(vm.availableFlows, function (flow) { return flow.businessUnitId === 1; });
        }
    }

    function attached() {
        vm.getBerthGroups();
        vm.checkPermission();

        setAvailableFlows();
    }

    function getBerthGroups() {
        constantsService.preloaded.then(function () {
            vm.berthGroups = constantsService.berthGroups;
            if (vm.berthGroups.length > 0) {
                vm.getNauticalGoals();
                vm.getStatuses();
            }
        });
    }

    function getNauticalGoals() {
        goalDataService.getNauticalGoals().then(function (data) {
            vm.nauticalGoals = data;
        });
    }

    function getStatuses() {
        arrivalsDataService.getStatuses()
            .then(function (result) {
                vm.realisationStatuses = _.filter(result, function (item) {
                    //return item.id !== 1;
                    return item;
                });
            });
    }

    function getToDos(pindex, psize, search) {
        vm.loading = true;
        arrivalsDataService.getToDos(pindex, psize, search, null, vm.arrivals.flowIds)
            .then(function (result) {
                let allArrivals = result.realisations;
                vm.arrivals.init = result;
                vm.arrivals.total = result.total;
                allArrivals.forEach(function (arrival) {
                    let upcomming = {};
                    let newItemDetails = {};
                    let planningItems = _.sortBy(arrival.planningItems, function (p) { return p.eta; });
                    let realisationItems = _.sortBy(arrival.realisationItems, function (r) { return r.ata; });
                    if (planningItems) {
                        planningItems.forEach(function (p) {
                            let today = moment().format('YYYY-MM-DDTHH:mm:ss');
                            let plannedDate = moment(p.eta).format('YYYY-MM-DDTHH:mm:ss');
                            if (plannedDate > today) {
                                arrival.inFuture = true;
                            } else {
                                arrival.inFuture = false;
                            }
                        });
                    }
                    if (arrival.realisationItems === null || arrival.realisationItems.length <= 0) {
                        if (arrival.reservationId === null) {
                            upcomming.step = 0;
                        } else {
                            upcomming = deepCopy(planningItems[0]);
                            upcomming.step = 0;
                        }
                    } else {
                        for (let i = 0; i <= arrival.realisationItems.length; i++) {
                            if (arrival.realisationItems[i]) {
                                if (arrival.realisationItems[i].atd === null) {
                                    if (arrival.realisationItems.length > planningItems.length) {
                                        newItemDetails = arrival.realisationItems[i];
                                        newItemDetails.etd = moment(arrival.realisationItems[i].ata).add(1, 'days');
                                    } else {
                                        if (planningItems[i]) {
                                            newItemDetails = planningItems[i];
                                        }
                                    }
                                    newItemDetails.step = 1;
                                    newItemDetails.id = arrival.realisationItems[i].id;
                                    break;
                                } else {
                                    if (arrival.realisationItems.length >= planningItems.length) {
                                        newItemDetails = realisationItems[realisationItems.length - 1];
                                        newItemDetails.step = 2;
                                        newItemDetails.eta = realisationItems[realisationItems.length - 1].atd;

                                    } else {
                                        if (planningItems[i + 1]) {
                                            newItemDetails = planningItems[i + 1];
                                            newItemDetails.step = 0;
                                        }
                                    }
                                }
                            }
                        }
                        upcomming = deepCopy(newItemDetails);
                    }
                    upcomming.isChanged = false;
                    upcomming.isSaving = false;
                    upcomming.arrivalId = arrival.id;
                    arrival.newStatus = arrival.status;
                    //arrival.showRemarks = false;
                    if (arrival.status === 2) {
                        arrival.hasDeparted = true;
                    } else {
                        arrival.hasDeparted = false;
                    }
                    arrival.upcommingRealisation = upcomming;
                    //if (arrival.remarks && !Array.isArray(arrival.remarks))
                    //    arrival.remarks = arrival.remarks.split('\r\n');

                });
                vm.arrivals.all = allArrivals;
                vm.loading = false;
            });

    }

    function updateSearch() {
        let query = (vm.searchquery !== null && vm.searchquery !== undefined && vm.searchquery.trim() === '') ? null : vm.searchquery;

        return getBySearch(query);
    }

    function getBySearch(searchQuery) {
        //if (searchQuery !== null) {
        vm.indexCount = 0;
        getToDos(vm.indexCount, vm.arrivalsPerLoad, searchQuery);
        //} 
    }
    
    function setAddNew() {
        vm.editingArrival = null;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = true;
    }

    function loadMore(direction) {
        let totalPages = Math.ceil(vm.arrivals.total / 200);
        if (direction === 'next') {
            vm.indexCount++;
            getToDos(vm.indexCount, vm.arrivalsPerLoad);
        } else {
            if (vm.indexCount > 0) {
                vm.indexCount--;
                getToDos(vm.indexCount, vm.arrivalsPerLoad);
            }
        }
    }

    function showImportDialog() {
        $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'templates/arrivals/modals/import-arrivals.html',
            controllerAs: 'ctrlImportArrivals',
            controller: 'modalImportArrivalsController',
            bindToController: true
        });
    }
    
    function selectProcessFlows() {
        widgetModals.processFlowPicker({availableFlows: vm.allFlows})
        .result
            .then(function (result) {
                if (result) {
                    vm.arrivals.flowIds = _.pluck(_.filter(result, 'selected'), 'id');
                    if (vm.arrivals.flowIds && vm.arrivals.flowIds.length > 0) {
                        getToDos(vm.indexCount, vm.arrivalsPerLoad);
                    }
                }
        });
    }

    function setAvailableFlows() {
        // Check if the url contains businessUnit systemcode
        var defaultFlowIds = [];
        if ($routeParams.arrivalId) {
            var flows = $routeParams.arrivalId.split(',');
            // get all flows from the business unit, but skip the party boat
            defaultFlowIds = _.pluck(_.filter(vm.availableFlows, function (flow) { return flows.indexOf(flow.businessUnit.systemCode.toLowerCase()) > -1 && flow.systemCode !== 'PARTY'; }), 'id');
        } else {
            // Default hidden Seagoing & Inland & Partyboar for F&C import if the user has permissions to see other flows then public berth and partyboat
            defaultFlowIds = _.pluck(vm.availableFlows, 'id');
            var skipFlows = defaultFlowIds.filter(id => id !== 1 && id !== 6 && id !== 3);
            if (skipFlows.length > 0) {
                defaultFlowIds = skipFlows;
            }
        }


        vm.allFlows =
            _.map(vm.availableFlows, function (m) {
                return {
                    id: m.id,
                    name: m.name,
                    selected: defaultFlowIds.indexOf(m.id) > -1,
                    config: m.planningConfiguration,
                    businessUnitId: m.businessUnitId
                };
            });

        vm.arrivals.flowIds = defaultFlowIds;
        getToDos(vm.indexCount, vm.arrivalsPerLoad)
    }

    return vm;
}