﻿module.exports = ['common', 'dataContext', permissionDataService];

function permissionDataService(common, context) {

    var service = {
        add: addPermission,
        get: getPermissions,
        update: updatePermission
    };

    function addPermission(permission) {
        var deferred = common.$q.defer();
        var req = context.post('/permission', permission)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    

    function getPermissions() {
        var deferred = common.$q.defer();
        var req = context.get('/permission')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updatePermission(Permission) {
        var deferred = common.$q.defer();
        var req = context.post('/permission/' + Permission.id, Permission)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    return service;
};