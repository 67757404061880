﻿module.exports = [removeUnusedDecimalsFilter];

function removeUnusedDecimalsFilter() {

    return function (input, decimals) {
        if (!decimals)
            decimals = 2;

        if (!input || input === '')
            return input;

        // this code assumes we use a dot as a decimal seperator
        var values = input.split('.');
        if (values.length === 1)
            return input;

        let decimalString = values[values.length - 1];
        if (decimalString.length <= decimals)
            return input;

        var removeCount = 0;
        for (var i = decimalString.length - 1; i > decimals-1; i--) {
            if (decimalString[i] === '0')
                removeCount++;
            else
                break;
        }

        return input.substr(0, input.length - removeCount);
    };
}