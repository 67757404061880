﻿module.exports = [customerAddressPreferencesModels];

function customerAddressPreferencesModels() {

    var service = {
        addressPreferencesModels: addressPreferencesModels
    };

    return service;
    
    function addressPreferencesModels(addressPreferenceData) {
        var addressPreferenceModel = {
            id: null,
            customerId: null,
            objectType: null,
            objectId: null,
            object: null,
            targetType: null,
            targetId: null,
            addressType: null,
            addressValue: null
        };

        if (addressPreferenceData === undefined || addressPreferenceData === null)
            return addressPreferenceModel;

        addressPreferenceModel.id = addressPreferenceData.id;
        addressPreferenceModel.customerId = addressPreferenceData.customerId;
        addressPreferenceModel.displayName = addressPreferenceData.displayName,
        addressPreferenceModel.objectType = addressPreferenceData.objectType;
        addressPreferenceModel.objectId = addressPreferenceData.objectId;
        addressPreferenceModel.object = addressPreferenceData.object;
        addressPreferenceModel.targetType = addressPreferenceData.targetType;
        addressPreferenceModel.targetId = addressPreferenceData.targetId;
        addressPreferenceModel.addressType = addressPreferenceData.addressType;
        addressPreferenceModel.addressValue = addressPreferenceData.addressValue;

        return addressPreferenceModel;
    }

}