﻿module.exports = ['$rootScope', '$timeout', 'common', timePickerDirective];

function timePickerDirective($rootScope, $timeout, common) {
    var directive = {
        bindToController: true,
        controller: timePickerCtr,
        controllerAs: 'ctrlTimePicker',
        restrict: 'E',
        templateUrl: 'templates/widgets/modals/time-picker.html',
        link: function ($scope, $el, $attrs) {
            $scope.$watch(function () { return $scope.ctrlTimePicker.arrivalTime },
                function (newValue, oldValue) {
                    $scope.$parent.arrival.upcommingRealisation.eta = newValue;
                });
        },
        scope: {
            timeIsActive: "=",
            arrivalTime: "="
        }
    };

    return directive;

    function timePickerCtr() {
    var vm = {
        popOverTemplateUrl: 'timePickerPopOver.html',
        popOverVisible: false,
        timeIsActive: this.timeIsActive,
        maxRadians: 2 * Math.PI,
        inc: (2 * Math.PI) / 12,
        incMinutes: (2 * Math.PI) / 60,
        hours: [],
        hours2: [],
        minutes: [],
        minutes2: [],
        bgBigCircle: {},
        bgSmCircle: {},
        selectedHours: null,
        selectedMinutes: null,
        arrivalTime: this.arrivalTime,


        init: init,
        positionHours: positionHours,
        positionMinutes: positionMinutes,
        draw: draw,
        placeElement: placeElement,
        //isIntersect: isIntersect,
        updateText: updateText,
        addText: addText,
        removeElm: removeElm,
        clear: clear,
        correctText: correctText
    };

    function init() {
        if (vm.timeIsActive) {
            vm.popOverVisible = true;
        } else {
            vm.popOverVisible = false;
            vm.clear();
        }

    }
        function positionHours(centerX, centerY, radius, radius2, radius3) {
            let j = 0;
            let radians = 0;

            for (var i = 0; i <= 11; i++) {
                let circle = {
                    r: 13,
                    id: 'h1_' + (i + 1),
                    x: centerX + (Math.cos(radians) * radius),
                    y: centerY + (Math.sin(radians) * radius),
                    hour: 6 - i,
                    class: 'sm_hours',
                    event: true
                };
                let circle2 = {
                    r: 14,
                    id: 'h2_' + (i + 1),
                    x: centerX + (Math.cos(radians) * radius2),
                    y: centerY + (Math.sin(radians) * radius2),
                    hour: 18 - i,
                    class: 'bg_hours',
                    event: true
                };
                let circle3 = {
                    r: 15,
                    id: 'm_' + (i + 1),
                    x: centerX + (Math.cos(radians) * radius3),
                    y: centerY + (Math.sin(radians) * radius3),
                    hour: 30 - j,
                    class: 'minutes',
                    event: true
                };
                if (circle.hour <= 0) {
                    circle.hour = 18;
                    circle.hour -= i;
                }
                if (circle2.hour === 12) {
                    circle2.hour = 0;
                } else if (circle2.hour < 12) {
                    circle2.hour = 30;
                    circle2.hour -= i;
                }
                 if (circle3.hour < 0) {
                    circle3.hour = 90;
                    circle3.hour -= j;
                }

                radians += vm.inc;

                vm.hours2.push(circle2);
                vm.minutes.push(circle3);
                vm.hours.push(circle);
                j += 5;
            }

        }
        function positionMinutes(centerX, centerY, radius) {
            let radians = 0;
            for (var i = 0; i <= 59; i++) {
                let circle = {
                    r: 1,
                    id: 'm2_' + (i + 1),
                    x: (centerX + 10) + (Math.cos(radians) * radius),
                    y: (centerY + 10) + (Math.sin(radians) * radius),
                    minute: 30 - i,
                    class: 'sm_minutes',
                };
                radians += vm.incMinutes;
                if (circle.minute < 0) {
                    circle.minute = 90;
                    circle.minute -= i;
                }

                vm.minutes2.push(circle);
            }
        }
        function draw() {
                let timeContainer = document.createElement('div');
                    timeContainer.width = 345;
                    timeContainer.height = 345;
                    timeContainer.setAttribute('id', 'time_container');
                    timeContainer.style.height = '345px';
                    timeContainer.style.width = '345px';
                    timeContainer.style.position = 'relative';
                    timeContainer.style.top = '0px';
                    timeContainer.style.left = '0px';

               let selectedElement = angular.element(document.querySelector('.popover-content'));
                    selectedElement.append(timeContainer);

                    vm.positionHours(timeContainer.width / 2, timeContainer.height / 2, 80, 113, 146);
                    vm.positionMinutes(timeContainer.width / 2, timeContainer.height / 2, 146);

                    //Background circles 
                    let bigHours = vm.bgBigCircle = {
                    x: timeContainer.width / 2 - 150,
                    y: timeContainer.height / 2 - 150,
                    r: 164,
                    fill: 'rgb(229, 229, 229)',
                    stroke: 'rgb(229, 229, 229)', 
                    id: 'base_circle',
                    event: false
                    };
                    let smallHours = vm.bgSmCircle = {
                    x: timeContainer.width / 2 - 113,
                    y: timeContainer.height / 2 - 113,
                    r: 128,
                    fill: 'white',
                    stroke: 'white',
                    id: 'sm_circle',
                    event: false
                    };

                vm.placeElement(bigHours, timeContainer);
                vm.placeElement(smallHours, timeContainer);

                //show minutes stripes per minute
                let minutes2 = vm.minutes2;
                minutes2.forEach(function (obj, index) {
                    if (obj.minute % 5 === 0) {
                        obj.display = 'display_none';
                    }
                    let circle = vm.correctText(obj);
                    vm.placeElement(circle, timeContainer);
                });
                //Show small hours AM
                let circles = vm.hours;
                circles.forEach(function (obj) {
                    let circle = vm.correctText(obj);
                    vm.placeElement(circle, timeContainer);
                });
                //Show late hours PM
                let circles2 = vm.hours2;
                circles2.forEach(function (obj) {
                    let circle = vm.correctText(obj);
                    vm.placeElement(circle, timeContainer);
                });
                //Show minutes
                let minutes = vm.minutes;
                minutes.forEach(function (obj) {
                    let circle = vm.correctText(obj);
                    vm.placeElement(circle, timeContainer);
                });
                vm.updateText(timeContainer);

        }
        function placeElement(obj, target) {
            let newElem = document.createElement('div');
                newElem.setAttribute('id', obj.id);
                newElem.style.height = obj.r * 2 + 'px';
                newElem.style.width = obj.r * 2 + 'px';
                newElem.style.top = obj.x + 'px';
                newElem.style.left = obj.y + 'px';
                newElem.style.backgroundColor = obj.fill;
                newElem.style.position = 'absolute';
                newElem.style.borderRadius = obj.r + 'px';
                newElem.classList.add(obj.class);
            if (obj.hour || obj.hour >= 0) {
                newElem.textContent = obj.hour;
            }
            if (obj.selectedClass) {
                newElem.classList.add(obj.selectedClass);
            }
            if (obj.display) {
                newElem.classList.add(obj.display);
            }
            if (obj.event) {
                newElem.addEventListener("click", function (e) {
                    e.stopPropagation();
                    if (e.target.classList.contains('minutes')) {
                        vm.selectedMinutes = e.target.childNodes[0].textContent;
                        vm.arrivalTime = moment(moment(vm.arrivalTime).minute(parseInt(e.target.childNodes[0].textContent))).format('YYYY-MM-DDTHH:mm:ss');
                        $rootScope.$digest();

                        if (!vm.selectedHours) {
                            vm.selectedHours = moment(vm.arrivalTime).format('HH');
                        }

                        let findMinutes = angular.element(document.getElementsByClassName('minutes'));
                        let findMinutes2 = angular.element(document.getElementsByClassName('sm_minutes'));

                        angular.forEach(findMinutes, function (div) {
                            if (div.classList.contains('select_time')){
                                div.classList.remove('select_time');
                            }
                        });
                        angular.forEach(findMinutes2, function (div) {
                            if (div.classList.contains('select_time')) {
                                div.classList.remove('select_time');
                            }
                        });

                        e.target.classList.add('select_time');
                    } else {
                        vm.selectedHours = e.target.childNodes[0].textContent;
                        vm.arrivalTime = moment(moment(vm.arrivalTime).hour(parseInt(e.target.childNodes[0].textContent))).format('YYYY-MM-DDTHH:mm:ss');
                        $rootScope.$digest();

                        if (!vm.selectedMinutes) {
                            vm.selectedMinutes = moment(vm.arrivalTime).format('mm');
                        }
                        let findHours = angular.element(document.getElementsByClassName('sm_hours'));
                        let findHours2 = angular.element(document.getElementsByClassName('bg_hours'));

                        angular.forEach(findHours, function (div) {
                            if (div.classList.contains('select_time')) {
                                div.classList.remove('select_time');
                            }
                        });
                        angular.forEach(findHours2, function (div) {
                            if (div.classList.contains('select_time')) {
                                div.classList.remove('select_time');
                            }
                        });

                        e.target.classList.add('select_time');

                    }
                    vm.updateText(target);
                });
            }
            target.append(newElem);

        }
        //function isIntersect(point, circle) {
        //    var distancesquared = (point.x - circle.x) * (point.x - circle.x) + (point.y - circle.y) * (point.y - circle.y);
        //    return distancesquared <= circle.r * circle.r;
        //}
        function updateText(timeContainer) {
            //show current time or selected time
            let currentTimeHeader = {
                id: 'current_time_header',
                class: 'show_current_time',
                x: timeContainer.width / 2,
                y: timeContainer.height / 2,
                text: 'Planned Time',
                //attr: 'ng-if',
                //attrValue: '!ctrlTimePicker.selectedHours'
            };
            let currentTime = {
                id: 'current_time',
                class: 'show_current_time',
                x: timeContainer.width / 2,
                y: timeContainer.height / 2,
                text: moment(vm.arrivalTime).format('HH : mm')
            };
            //selected time
            let selectedTimeHeader = {
                id: 'selected_time_header',
                class: 'show_selected_time',
                x: timeContainer.width / 2,
                y: timeContainer.height / 2,
                text: 'New Time',
                //attr: 'ng-if',
                //attrValue: 'ctrlTimePicker.selectedHours'
            };
            let selectedTime = {
                id: 'selected_time',
                class: 'show_selected_time',
                x: timeContainer.width / 2,
                y: timeContainer.height / 2,
                text: vm.selectedHours + ' : ' + vm.selectedMinutes
            };

            //if there is no hours or minutes selected 
            if ((!vm.selectedHours && !vm.selectedMinutes)) {
                let newObj = document.querySelector('#current_time');
                let newObj2 = document.querySelector('#current_time_header');
                if (!newObj) {
                    vm.addText(currentTimeHeader, 24, 24, timeContainer);
                    vm.addText(currentTime, 0, 20, timeContainer);
                } else {
                    newObj.textContent = currentTime.text;
                    newObj.style.display = 'block';
                    newObj2.style.display = 'block';
                }
                
            } else {
                //if cancled -  show current time!


                //else show new selected. 
                let oldObj = document.querySelector('#current_time');
                let oldObj2 = document.querySelector('#current_time_header');
                if (oldObj) {
                    oldObj.style.display = 'none';
                    oldObj2.style.display = 'none';
                } 
                let newObj = document.querySelector('#selected_time');
                let newObj2 = document.querySelector('#selected_time_header');
                if (!newObj) {
                    vm.addText(selectedTimeHeader, 24, 24, timeContainer);
                    vm.addText(selectedTime, 0, 20, timeContainer);
                } else {
                    newObj.textContent = selectedTime.text;
                    newObj.style.display = 'block';
                    newObj2.style.display = 'block';
                }

            }

        }
        function addText(obj, position1, position2, target) {
            let newElem = document.createElement('div');
                newElem.setAttribute('id', obj.id);
                newElem.style.top = (obj.x - position1 ) + 'px';
                newElem.style.left = (obj.y - position2 ) + 'px';
                newElem.style.position = 'absolute';
                newElem.classList.add(obj.class);
                newElem.textContent = obj.text;
            target.append(newElem);
        }
        function removeElm(obj) {
            var el = document.getElementById(obj.id);
            if (el) {
                el.remove();
            }
        }
        function clear() {
            //Remove small hours AM
            
            let circles = vm.hours;
            if (circles.length > 0) {
                circles.forEach(function (obj) {
                    vm.removeElm(obj);
                });
            }

            //Remove late hours PM
            let circles2 = vm.hours2;
            if (circles2.length > 0) {
                circles2.forEach(function (obj) {
                    vm.removeElm(obj);
                });
            }

            //Remove minutes
            let minutes = vm.minutes;
            if (minutes.length > 0) {
                minutes.forEach(function (obj) {
                    vm.removeElm(obj);
                });
            }
            //Remove minutes
            let minutes2 = vm.minutes2
            if (minutes2.length > 0) {
                minutes2.forEach(function (obj) {
                    vm.removeElm(obj);
                });
            }

            //Remove BG Circles
            if (Object.keys(vm.bgBigCircle).length > 0) {
                vm.removeElm(vm.bgBigCircle);
            }
            if (Object.keys(vm.bgSmCircle).length > 0) {
                vm.removeElm(vm.bgSmCircle);
            }

            vm.hours = [];
            vm.hours2 = [];
            vm.minutes = [];
            vm.bgBigCircle = {};
            vm.bgSmCircle = {};

            //Remove container
            let timeContainer = angular.element(document.getSelection('#time_container'));
            if (timeContainer) {
                timeContainer.id = 'time_container';
                vm.removeElm(timeContainer);
            }
        } 
        function correctText(obj) {
            if (obj.hour || obj.hour >= 0) {
                if (obj.hour.toString().length === 1) {
                    obj.hour = '0' + obj.hour;
                }
            }
            if (obj.minute) {
                if (obj.minute.toString().length === 1) {
                    obj.minute = '0' + obj.minute;
                }
            }

            if (obj.class === 'minutes') {
                    if (obj.hour.toString() === moment(vm.arrivalTime).format('mm')) {
                        obj.selectedClass = 'select_time';
                    }
            } else if (obj.class === 'sm_minutes') {
                    if (obj.minute.toString() === moment(vm.arrivalTime).format('mm')) {
                        obj.selectedClass = 'select_time';
                    }
            } else {
                    if (obj.hour.toString() === moment(vm.arrivalTime).format('HH')) {
                        obj.selectedClass = 'select_time';
                    }
            }
            return obj;
        }
        return vm;
    }

}

