﻿module.exports = ['$routeParams', 'common', 'reportDataService', reportArrivalAndDepartureTimeController ];

function reportArrivalAndDepartureTimeController($routeParams, common, reportData) {

    var vm = {
        attached: attached,
        common: common,
        downloadReport: downloadReport,
        filter: {
            endDate: null,
            startDate: null
        },
        arriveAndDepartureTimes: [],
        loading: true,
        reload: reload
    };

    function attached() {
        vm.filter.startDate = moment().add(-1, 'months');
        vm.filter.endDate = moment();

        reload(true);
    }

    function downloadReport() {
        var csvOutput = 'Reservation reference;Arrive or Depart;ETA;ETD;ATA;ATD;Registration Moment;Registrator;Customer\r\n';

        for (var j = 0; j < vm.arriveAndDepartureTimes.length; j++) {
            var data = vm.arriveAndDepartureTimes[j];
            csvOutput += data.reference + ';';
            csvOutput += (data.type==0?'Arrive':'Depart') + ';';
            csvOutput += (data.eta?moment(data.eta).format('DD-MM-YYYY HH:mm'):'') + ';';
            csvOutput += (data.etd?moment(data.etd).format('DD-MM-YYYY HH:mm'):'') + ';';
            csvOutput += (data.ata?moment(data.ata).format('DD-MM-YYYY HH:mm'):'') + ';';
            csvOutput += (data.atd?moment(data.atd).format('DD-MM-YYYY HH:mm'):'') + ';';
            csvOutput += moment(data.registrationMoment).format('DD-MM-YYYY HH:mm') + ';';
            csvOutput += data.registrator + ';';
            csvOutput += data.customer.name + ';';
            csvOutput += '\r\n';
        }
        common.exportToCSV(csvOutput, 'report-arrival-and-departure-time-' + moment().format('YYYYMMDDHHmm'));
    }

    function reload(clearAll) {
        if (clearAll && clearAll === true)
            vm.arriveAndDepartureTimes = [];

        loadReport();
    }

    function loadReport() {
        vm.loading = true;
        reportData.getReportArrivalAndDepartureTime(vm.filter.startDate.format('YYYY-MM-DD'), vm.filter.endDate.format('YYYY-MM-DD'))
            .then(function (result) {
                vm.arriveAndDepartureTimes = result;
                vm.loading = false;
            });
    }

    return vm;
}