﻿'use strict';

module.exports = ['constantsService', 'invoiceRemarksDataService', invoiceRemarksController];

function invoiceRemarksController(constantsService, invoiceRemarksDataService) {
    var vm =
    {
        attached: attached,
        invoiceRemarks: [],
        systemCodes: [],
        activeInvoiceRemark: null,
        isLoading: false,
        onInvoiceRemarksUpdate: onInvoiceRemarksUpdate
    };

    return vm;


    function attached() {
        vm.isLoading = true;
        retrieveInvoiceRemarksList().then(function () {
            vm.isLoading = false;
        });
    }

    function distinct(value,index,self){
        return self.indexOf(value) === index;
    }

    function initSystemCodes() {
        var systemCodeArray = _.map(vm.invoiceRemarks, function (item) { return item["systemCode"]; });
        vm.systemCodes = _.filter(systemCodeArray,distinct);
    }

    function onInvoiceRemarksUpdate() {
        retrieveInvoiceRemarksList().then(function () {
            constantsService.invoiceRemarks = _.filter(vm.invoiceRemarks, function (x) { return x.active === true; });
        });
        vm.activeInvoiceRemark = null;
    }

    function retrieveInvoiceRemarksList() {
        return invoiceRemarksDataService.getInvoiceRemarksByUser().then(function (result) {
            vm.invoiceRemarks = result;
            initSystemCodes();
        }).catch(function (error) {
            console.dir(error);
        });
    }
}