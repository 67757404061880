﻿module.exports = ['common', 'dataContext', 'userModelsService', auditDataService];

function auditDataService(common, context, userModels) {

    var service = {
        getLogs: getLogs,
        getObjectTypes: getObjectTypes,
        getSecurityLog: getSecurityLog,
        getTariffLogs: getTariffLogs
    };

    return service;

    function getLogs(page, startDate, endDate, query, objectType) {
        var deferred = common.$q.defer();
        var url = '/audit';
        var size = 50;

        url = url + '?page=' + page + '&size=' + size;

        if (startDate)
            url += '&startDate=' + startDate;
        if (endDate)
            url += '&endDate=' + endDate;
        if (query)
            url += '&query=' + query;
        if (objectType)
            url += '&objectType=' + objectType;

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getObjectTypes() {
        var deferred = common.$q.defer();
        var url = '/audit/objecttypes';

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getSecurityLog(page, startDate, endDate, query, objectType) {
        var deferred = common.$q.defer();
        var url = '/audit/security';
        var size = 50;

        url = url + '?page=' + page + '&size=' + size;

        if (startDate)
            url += '&startDate=' + startDate;
        if (endDate)
            url += '&endDate=' + endDate;
        if (query)
            url += '&query=' + query;
        if (objectType)
            url += '&objectType=' + objectType;

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }


    function getTariffLogs() {
        var deferred = common.$q.defer();
        var url = '/audit/tariff';
        
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}
