﻿module.exports = ['common', 'dataContext', fleetDataService];

function fleetDataService(common, context) {

    var service = {
        addShip: addShip,
        deleteShip: deleteShip,
        getFleet: getFleet,
        getFleetById: getFleetById,
        getIssues: getIssues,
        getShipTypes: getShipTypes,
        saveShip: saveShip,
        getAllVessels: getAllVessels,
        importExternalShips: importExternalShips
    };

    return service;

    function getCustomerFleetUrl(customerId, shipId) {
        if (typeof (shipId) !== "number")
            return '/customer/' + customerId + '/fleet';

        return '/customer/' + customerId + '/fleet/' + shipId;
    }

    function addShip(customerId, ship) {
        var deferred = common.$q.defer();
        var req = context.post(getCustomerFleetUrl(customerId), ship)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteShip(customerId, ship) {
        var deferred = common.$q.defer();
        var req = context.del(getCustomerFleetUrl(customerId, ship.id), ship)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getFleet(customerId) {
        var deferred = common.$q.defer();
        var req = context.get(getCustomerFleetUrl(customerId))
            .then(function (data) {
                //console.log(data);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getFleetById(customerId, id) {
        var deferred = common.$q.defer();
        var req = context.get(getCustomerFleetUrl(customerId, id))
            .then(function (data) {
                //console.log(data);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getIssues() {
        var deferred = common.$q.defer();
        var req = context.get('/fleet/issues')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getShipTypes() {
        var deferred = common.$q.defer();
        var req = context.get('/fleet/shiptype')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveShip(customerId, ship) {
        var deferred = common.$q.defer();
        var req = context.put(getCustomerFleetUrl(customerId, ship.id), ship)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getAllVessels() {
        var deferred = common.$q.defer();
        var req = context.get('/customer/allvessels/')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
        return deferred.promise;

    }

    function importExternalShips(shipType, file) {
        var deferred = common.$q.defer();
        var req = context.xhr('/external-ships/import/' + shipType, file)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}