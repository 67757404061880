﻿module.exports = ['common', 'constantsService', 'identity', 'sailingReportDataService', 'sailingReportModals', 'toastr', 'widgetModals', sailingReportOverviewApproveController];

function sailingReportOverviewApproveController(common, constantsService, identity, sailingReportData, sailingReportModals, toastr, widgetModals) {

    var vm = {
        attached: attached,
        reload: reload,

        // penalties box
        canShowPenalties: false,
        approvePenalty: approvePenalty,
        loadPenalties: loadPenalties,
        penalties: [],
        penaltyStatus: {
            isLoading: false,
            isSaving: false
        },
        reloadPenalties: reloadPenalties,
        setPenalty: setPenalty,
        showPenaltyTable: null,
        undoPenalty: undoPenalty,
        userId: null,

        // reports box
        approveReport: approveReport,
        checkedCount: checkedCount,
        display: { // at client side; filter in the UI
            includeApproved: false,
            isFiltered: isFiltered,
            shipType: 1,
            textFilter: null
        },
        editItem: {
            id: null,
            embarking: null,
            disembarking: null,
            transit: null
        },
        filter: { // at server side: query on this filter
            endDate: null,
            startDate: null
        },
        isAnyChanges: isAnyChanges,
        loadReports: loadReports,
        reloadReports: reloadReports,
        reports: [],
        resetEditItem: resetEditItem,
        searchQuery: null,
        selectAll: selectAll,
        selectedItems: [],
        selectedReportWithDetails: null,
        setEditItem: setEditItem,
        reportStatus: {
            isLoading: false
        },
        todoIndex: -1,
        todoList: [],
        updateItem: updateItem,
        updateSearch: updateSearch,
        selectedPenaltyWithDetails: null,
        selectedPenaltyReport: null,
        selectPenaltyReport: selectPenaltyReport,
        handlePenaltyDetailsClick: handlePenaltyDetailsClick
    };

    function attached() {
        var hasPermission = common.identity.hasPermission('sailingreport_penalty');
        if (hasPermission === true) {
            vm.userId = identity.userInfo.id;
            vm.canShowPenalties = true;
        } else {
            vm.canShowPenalties = false;
        }

        vm.filter.startDate = moment().add(-6, 'days');
        vm.filter.endDate = moment();
        reload(true);
    }

    function reload(clearAll, skipTextSearch) {
        reloadReports(clearAll, skipTextSearch);

        if (vm.canShowPenalties === true)
            reloadPenalties(clearAll);
    }

    // ---------------- Penalties ----------------

    function approvePenalty(item) {
        widgetModals.areYouSure('Are you sure to approve this penalty?').result
            .then(function (result) {
                if (result === true) {
                    vm.penaltyStatus.isSaving = item.id;

                    item.status = 4; // Approve
                    sailingReportData.setPenalty(item.id, item)
                        .then(function (result) {
                            vm.penaltyStatus.isSaving = false;
                            reloadPenalties(true);
                        }, function (error) {
                            toastr.error(JSON.stringify(error));
                            vm.penaltyStatus.isSaving = false;
                        });
                }
            });
    }

    function loadPenalties() {
        vm.penaltyStatus.isLoading = true;

        sailingReportData.getLegalTodoOverview()
            .then(function (result) {
                vm.penalties = result;
                vm.penaltyStatus.isLoading = false;

                // set this property only for the first time
                if (vm.showPenaltyTable === null) {
                    vm.showPenaltyTable = vm.penalties && vm.penalties.length > 0;
                }
            }, function (error) {
                vm.penaltyStatus.isLoading = false;
                toastr.error(JSON.stringify(error));
            });
    }

    function reloadPenalties(clearAll) {
        if (clearAll && clearAll === true)
            vm.penalties = [];

        loadPenalties();
    }

    function setPenalty(item, penaltyPercentage) {
        widgetModals.remarksConfirmStatus({
            description: "Please give a motivation for the selection you've made:",
            title: "Confirm penalty",
            required: true,
            invoiceTypes: _.where(constantsService.invoiceTypes, function (i) { return i.systemCode.indexOf("TOURIST_TAX_") !== -1; })
        })
            .result
            .then(function (modalResult) {
                if (modalResult.remarks !== undefined && modalResult.remarks !== null && modalResult.remarks.length > 0) {
                    vm.penaltyStatus.isSaving = item.id;

                    if (penaltyPercentage > -1)
                        item.amount = null; // reset amount property if the percentage is set

                    item.percentage = penaltyPercentage;
                    item.motivation = modalResult.remarks[0];
                    item.status = 3; // Confirm

                    sailingReportData.setPenalty(item.id, item)
                        .then(function (result) {
                            vm.penaltyStatus.isSaving = false;
                            reloadPenalties(true);
                        }, function (error) {
                            toastr.error(JSON.stringify(error));
                            vm.penaltyStatus.isSaving = false;
                        });
                }
            });
    }

    function undoPenalty(item) {
        widgetModals.areYouSure('Are you sure to undo this penalty?').result
            .then(function (result) {
                if (result === true) {
                    vm.penaltyStatus.isSaving = item.id;

                    item.amount = null;
                    item.percentage = 0;
                    item.status = 2; // PendingNotPaid
                    sailingReportData.setPenalty(item.id, item)
                        .then(function (result) {
                            vm.penaltyStatus.isSaving = false;
                            reloadPenalties(true);
                        }, function (error) {
                            toastr.error(JSON.stringify(error));
                            vm.penaltyStatus.isSaving = false;
                        });
                }
            });
    }

    // ---------------- Reports ----------------

    function approveReport() {
        vm.todoIndex = 0;
        vm.todoList = _.filter(vm.reports, function (item) { return vm.selectedItems.indexOf(item.id) > -1; });
        approveNextItem();
    }

    function approveNextItem() {
        if (vm.todoIndex >= vm.todoList.length) {
            clearTodoList();
            return;
        }
        else if (vm.todoList.length === 0 || vm.todoIndex < 0)
            return;

        vm.reportStatus.isSaving = true;
        sailingReportData.review(vm.todoList[vm.todoIndex].id)
            .then(function (result) {
                var foundItem = vm.todoList[vm.todoIndex];
                foundItem.checkedOn = result.checkedOn;
                vm.todoIndex++;
                approveNextItem();
                vm.reportStatus.isSaving = false;
            }, function (error) {
                toastr.error(error);
                clearTodoList();
            });
    }

    function checkedCount() {
        var seacruiseItems = _.filter(vm.reports, function (item) { return item.shipTypeId === 3; });

        var doneCount = _.filter(seacruiseItems, function (item) { return item.checkedOn !== null; }).length;

        return doneCount + '/' + seacruiseItems.length + ' (' + Math.round(seacruiseItems.length > 0 ? (doneCount / seacruiseItems.length) : 0 * 100) + '%)';
    }

    function clearTodoList() {
        vm.todoIndex = -1;
        vm.todoList = [];
        vm.selectedItems = [];
        vm.reportStatus.isSaving = false;
    }

    function isFiltered(item) {
        if (vm.display.includeApproved === false && item.checkedOn !== null) {
            return false;
        }

        if (vm.display.shipType && vm.display.shipType > 0 && item.shipTypeId !== vm.display.shipType)
            return false;

        if (!vm.display.textFilter || vm.display.textFilter === '' || vm.display.length <= 2)
            return true;

        if (item.shipName.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
            return true;

        return false;
    }

    function isAnyChanges(item) {

        var embarking = item.paxEmbarking === undefined || item.paxEmbarking === null || item.paxEmbarking === "" ? null : item.paxEmbarking;
        var disembarking = item.paxDisembarking === undefined || item.paxDisembarking === null || item.paxDisembarking === "" ? null : item.paxDisembarking;
        var transit = item.paxTransit === undefined || item.paxTransit === null || item.paxTransit === "" ? null : item.paxTransit;
        var ata = item.reviewedATA === undefined || item.reviewedATA === null || item.reviewedATA === "" ? null : item.reviewedATA;
        var atd = item.reviewedATD === undefined || item.reviewedATD === null || item.reviewedATD === "" ? null : item.reviewedATD;

        if (vm.editItem.embarking === embarking &&
            vm.editItem.disembarking === disembarking &&
            vm.editItem.transit === transit &&
            vm.editItem.reviewedATA === ata &&
            vm.editItem.reviewedATD === atd) {
            return false;
        }

        return true;
    }

    function loadReports(query) {
        vm.reportStatus.isLoading = true;

        if (query === undefined || query === null) {
            sailingReportData.getInvoicedReports(vm.filter.startDate.format('YYYY-MM-DD'), moment(vm.filter.endDate).add(1, 'days').format('YYYY-MM-DD'), 0, 500)
                .then(function (result) {
                    vm.reports = result;
                    vm.reportStatus.isLoading = false;
                    vm.resetEditItem();
                }, function (error) {
                    vm.reportStatus.isLoading = false;
                });
        } else {
            sailingReportData.getInvoicedReportsBySearch(query, 0, 500)
                .then(function (result) {
                    vm.reports = result;
                    vm.reportStatus.isLoading = false;
                    vm.resetEditItem();
                }, function (error) {
                    vm.reportStatus.isLoading = false;
                });
        }
    }

    function reloadReports(clearAll, skipTextSearch) {
        if (clearAll && clearAll === true) {
            vm.reports = [];
        }

        if (skipTextSearch) {
            loadReports();
        } else {
            loadReports(sailingReportData.searchQuery);
        }
    }

    function resetEditItem(item) {

        // restore the original values
        if (item !== undefined && item !== null) {
            item.paxEmbarking = vm.editItem.embarking;
            item.paxDisembarking = vm.editItem.disembarking;
            item.paxTransit = vm.editItem.transit;
            item.reviewedATA = vm.editItem.reviewedATA;
            item.reviewedATD = vm.editItem.reviewedATD;
        }

        vm.editItem.id = null;
        vm.editItem.embarking = null;
        vm.editItem.disembarking = null;
        vm.editItem.transit = null;
        vm.editItem.reviewedATA = null;
        vm.editItem.reviewedATD = null;
        vm.savingItem = null;
        vm.reportStatus.isSaving = false;
    }

    function selectAll() {
        var filteredItems = _.filter(_.map(vm.items, function (item) {
            if (item.checkedOn !== null)
                return null;
            return item.id;
        }), function (filter) { return filter !== null; });

        if (filteredItems.length === vm.selectedItems.length)
            vm.selectedItems = [];
        else
            vm.selectedItems = filteredItems;
    }

    function setEditItem(selectedItem) {
        vm.editItem.id = selectedItem.id;
        vm.editItem.embarking = selectedItem.paxEmbarking;
        vm.editItem.disembarking = selectedItem.paxDisembarking;
        vm.editItem.transit = selectedItem.paxTransit;
        vm.editItem.reviewedATA = selectedItem.reviewedATA;
        vm.editItem.reviewedATD = selectedItem.reviewedATD;
    }

    function updateItem(item) {

        vm.savingItem = item;
        widgetModals.remarksConfirmStatus({
                title: "Update the PAX. The legal will get the mail after updated.",
                required: true,
                invoiceTypes: _.where(constantsService.invoiceTypes, function (i) { return i.systemCode.indexOf("TOURIST_TAX_") !== -1; })
            })
            .result
            .then(function(result) {
                    if (result.remarks !== undefined && result.remarks !== null && result.remarks.length > 0) {
                        vm.reportStatus.isSaving = true;
                        vm.savingItem.internalRemarks = result.remarks[0];

                        // make sure is convert to utc
                        if (vm.savingItem.reviewedATA) {
                            var dateATA = moment(vm.savingItem.reviewedATA).format('DD-MM-YYYY HH:mm');
                            vm.savingItem.reviewedATA = moment.utc(dateATA, 'DD-MM-YYYY HH:mm');
                        }

                        if (vm.savingItem.reviewedATD) {
                            var dateATD = moment(vm.savingItem.reviewedATD).format('DD-MM-YYYY HH:mm');
                            vm.savingItem.reviewedATD = moment.utc(dateATD, 'DD-MM-YYYY HH:mm');
                        }

                        sailingReportData.updateBaseDataAndSendToLegal(vm.savingItem.id, vm.savingItem)
                            .then(function (result) {
                                    for (var i = 0; i < vm.reports.length; i++) {
                                        if (vm.reports[i].id === result.id) {
                                            vm.reports[i] = result;
                                            break;
                                        }
                                    }
                                    vm.resetEditItem();
                                    if (vm.canShowPenalties === true)
                                        reloadPenalties(true);
                                },
                                function(error) {
                                    toastr.error(error);
                                    vm.resetEditItem();
                                    if (vm.canShowPenalties === true)
                                        reloadPenalties(true);
                                });
                    }
                },
                function() {});
    }

    function updateSearch() {

        if (vm.searchQuery && vm.searchQuery.trim().length !== 0) {
            if (vm.searchQuery !== sailingReportData.searchQuery) {
                sailingReportData.searchQuery = vm.searchQuery;
                loadReports(sailingReportData.searchQuery);
            }
        } else {
            sailingReportData.searchQuery = null;
            reloadReports(true);
        }

    }

    function handlePenaltyDetailsClick(penalty) {
        if (vm.selectedPenaltyWithDetails === penalty.id) {
            vm.selectedPenaltyWithDetails = null;
            vm.selectedPenaltyReport = null;
        } else {
            vm.selectedPenaltyWithDetails = null;
            vm.selectedPenaltyReport = null;
            selectPenaltyReport(penalty);
        }
    };

    function selectPenaltyReport(penalty) {
        vm.selectedPenaltyWithDetails = penalty.id;
    
        sailingReportData.getById(penalty.sailingReportId, true).then(function(report) {
            vm.selectedPenaltyReport = report;
        }).catch(function() {
        });
    }

    return vm;

}