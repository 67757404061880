﻿module.exports = [timelineViewDirective];

function timelineViewDirective() {

    var directive = {
        bindToController: true,
        controller: timelineViewController,
        controllerAs: 'ctrlTimelineView',
        link: function (ctrl) {
        },
        restrict: 'E',
        scope: {
            planningData: '=',
            reservationData: '='
        },
        templateUrl: 'templates/arrivals/directives/timeline-view.html'
    };
    return directive;

    function timelineViewController() {
        var ctrlTimelineView = this;

        ctrlTimelineView.getFacilitiesDescription = function (facilities) {
            return _.map(facilities, function (item) {
                return item.facility.name;
            }).join(', ');
        };
    }
}