﻿module.exports = ['common', 'dataContext', 'berthModelsService', 'coreDataModels', berthDataService];

function berthDataService(common, context, berthModels, coreDataModels) {

    var service = {
        getBerths: getBerths,
        getBerth: getBerth,
        deleteBerth: deleteBerth,
        saveBerth: saveBerth,
        getBerthShiptypes: getBerthShiptypes,
        getBerthBerthgroups: getBerthBerthgroups,
        getBerthFacilities: getBerthFacilities,
        getBerthNauticalgoals: getBerthNauticalgoals,
        getBerthCommercialgoals: getBerthCommercialgoals,
        updateBerthShiptypes: updateBerthShiptypes,
        updateBerthBerthgroups: updateBerthBerthgroups,
        updateBerthFacilities: updateBerthFacilities,
        updateBerthNauticalgoals: updateBerthNauticalgoals,
        updateBerthCommercialgoals: updateBerthCommercialgoals
    };

    return service;

    function updateBerthBerthgroups(berthId, berthgroupIdsToAdd, berthgroupIdsToDel) {
        var url = '/berth/' + berthId + '/berthgroup';
        var args = {
            referenceId: berthId,
            relatedObjectsToAdd: berthgroupIdsToAdd,
            relatedObjectsToDel: berthgroupIdsToDel
        };

        var deferred = common.$q.defer();
        var req = context.post(url, args)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.berthgroupModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updateBerthShiptypes(berthId, shiptypeIdsToAdd, shiptypeIdsToDel) {
        var url = '/berth/' + berthId + '/shiptype';
        var args = {
            referenceId: berthId,
            relatedObjectsToAdd: shiptypeIdsToAdd,
            relatedObjectsToDel: shiptypeIdsToDel
        };

        var deferred = common.$q.defer();
        var req = context.post(url, args)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.shiptypeModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updateBerthFacilities(berthId, facilityIdsToAdd, facilityIdsToDel) {
        var url = '/berth/' + berthId + '/facility';
        var args = {
            referenceId: berthId,
            relatedObjectsToAdd: facilityIdsToAdd,
            relatedObjectsToDel: facilityIdsToDel
        };
        var deferred = common.$q.defer();
        var req = context.post(url, args)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.facilityModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updateBerthCommercialgoals(berthId, commercialgoalIdsToAdd, commercialgoalIdsToDel) {
        var url = '/berth/' + berthId + '/goal/commercial';
        var args = {
            referenceId: berthId,
            relatedObjectsToAdd: commercialgoalIdsToAdd,
            relatedObjectsToDel: commercialgoalIdsToDel
        };
        var deferred = common.$q.defer();
        var req = context.post(url, args)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.commercialgoalModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updateBerthNauticalgoals(berthId, nauticalgoalIdsToAdd, nauticalgoalIdsToDel) {
        var url = '/berth/' + berthId + '/goal/nautical';
        var args = {
            referenceId: berthId,
            relatedObjectsToAdd: nauticalgoalIdsToAdd,
            relatedObjectsToDel: nauticalgoalIdsToDel
        };
        var deferred = common.$q.defer();
        var req = context.post(url, args)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.nauticalgoalModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthBerthgroups(berthId) {
        var url = '/berth/' + berthId + '/berthgroup';
        var deferred = common.$q.defer();
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.berthgroupModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthCommercialgoals(berthId) {
        var url = '/berth/' + berthId + '/goal/commercial';
        var deferred = common.$q.defer();
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.commercialgoalModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthNauticalgoals(berthId) {
        var url = '/berth/' + berthId + '/goal/nautical';
        var deferred = common.$q.defer();
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.nauticalgoalModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthShiptypes(berthId) {
        var url = '/berth/' + berthId + '/shiptype';
        var deferred = common.$q.defer();
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.shiptypeModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthFacilities(berthId) {
        var url = '/berth/' + berthId + '/facility';
        var deferred = common.$q.defer();
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.facilityModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerths() {
        var deferred = common.$q.defer();
        var req = context.get('/berth')
            .then(function (data) {
                deferred.resolve(_.map(data, berthModels.BerthModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerth(id) {
        var deferred = common.$q.defer();
        var req = context.get('/berth/' + id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveBerth(berth) {
        var url = '/berth';
        if (berth.id) url = url + '/' + berth.id;

        var deferred = common.$q.defer();
        var req = context.post(url, berth)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteBerth(berth) {
        var deferred = common.$q.defer();
        var req = context.del('/berth/' + berth.id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

}