﻿module.exports = [regionModels];

function regionModels() {

    var service = {
        regionModel: regionModel
    };

    return service;

    function regionModel(regionData) {
        var result = {
            id: null,
            name: null,
            portAuthorities: null
        };

        if (regionData === undefined || regionData === null)
            return result;

        result.id = regionData.id;
        result.name = regionData.name;
        result.portAuthorities = regionData.portAuthorities;
            
        return result;
    }

}