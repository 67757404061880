﻿module.exports = ['toastr' ,'coreDataService', 'berthDataService', 'widgetModals', berthFormDirective];

function berthFormDirective(toastr, coreDataService, berthData, widgetModals) {
    var directive = {
        bindToController: true,
        controller: berthFormController,
        controllerAs: 'ctrlBF',
        restrict: 'E',
        scope: {
            berthData: '=',
            onBerthSaved: '&',
            onBerthDeleted: '&'
        },
        link: berthFormLink,
        templateUrl: 'templates/berth/directives/berth-form.html'
    };

    return directive;

    function berthFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () {
            return ctrl.berthData;
        }, function (newValue, oldValue) {
            if (!newValue) return;

            ctrl.gerneralInfoActive = true;
            ctrl.isNewBerth = newValue.id <= 0;
        }, true);
    }

    function berthFormController() {
        var vm = {
            gerneralInfoActive: true,
            deleteBerth: deleteBerth,
            saveBerth: saveBerth,
            isNewBerth: true
        };

        return vm;

        function saveBerth() {
            if (!vm.berthData)
                return;

            if (vm.berthData.availabilityStartsOn) {
                if (!moment.isMoment(vm.berthData.availabilityStartsOn)) {
                    vm.berthData.availableFrom = moment(vm.berthData.availabilityStartsOn).format('YYYY-MM-DDTHH:mm:ss');
                }
            } else {
                vm.berthData.availableFrom = null;
            }
                
            if (vm.berthData.availabilityEndsOn) {
                if (!moment.isMoment(vm.berthData.availabilityEndsOn)) {
                    vm.berthData.availableUntil = moment(vm.berthData.availabilityEndsOn).format('YYYY-MM-DDTHH:mm:ss');
                }
            } else {
                vm.berthData.availableUntil = null;
            }

            //var isNewBerth = !vm.berthData.id;
            berthData.saveBerth(vm.berthData)
            .then(successResult)
            .catch(errorResult);
            function errorResult(response) {
            }

            function successResult(result) {
                vm.onBerthSaved({ savedBerth: result, isNew: vm.isNewBerth });
            }
            toastr.success("Changes Saved");
        }

        function deleteBerth() {
            widgetModals.areYouSure('You want to delete this berth?').result
                .then(function (result) {
                    if (result === true) {
                        berthData.deleteBerth(vm.berthData)
                        .then(successResult)
                        .catch(errorResult);
                    }
                });
            function errorResult(response) {
            }

            function successResult(result) {
                vm.onBerthDeleted({ deletedBerth: result });
            }
        }
    }

}
