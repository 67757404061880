﻿'use strict';

module.exports = ['templateDataService', templateController];

function templateController(templateDataService) {
    var vm = {
        templates: [],
        loadTemplates: loadTemplates,
        activeTemplate: null,
        onTemplateSaved: onTemplateSaved
    };

    init();

    return vm;

    function init() {
        loadTemplates();
    }

    function onTemplateSaved(savedTemplate, isNew) {
        let newTemplates = _.clone(vm.templates);
    
        if (!isNew) {
            newTemplates = _.filter(newTemplates, x => x.id != savedTemplate.id);
        }
    
        newTemplates.push(savedTemplate);
    
        vm.templates = newTemplates.sort((a, b) => {
            if (a.isActive !== b.isActive) {
                return a.isActive ? -1 : 1;
            }
        
            if (a.type !== b.type) {
                return b.type - a.type;
            }
        
            return b.id - a.id;
        });
        vm.activeTemplate = null;
    }

    function loadTemplates() {
        templateDataService.getTemplates()
            .then(function (result) {
                vm.templates = result;
            });
    }
}
