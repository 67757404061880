﻿module.exports = ['$anchorScroll', '$routeParams', 'common', 'userDataService', 'userModelsService', 'widgetModals', userOverviewController];

function userOverviewController($anchorScroll, $routeParams, common, userData, userModels, widgetModals) {

    var vm = {
        addUser: addUser,
        attached: attached,
        common: common,
        deleteUser: deleteUser,
        activeDisabledUser: activeDisabledUser,
        editingUser: null,
        filteredUsers: filteredUsers,
        newUser: new userModels.userRegistrationModel(),
        saveUser: saveUser,
        getDescription: getDescription,
        search: null,
        searchquery: null,
        status: {
            activeOnly: true,
            completed: false,
            errors: [],
            saving: false,
            setAddNew: setAddNew,
            setEdit: setEdit,
            showAddNew: false
        },
        updateSearch: updateSearch,
        users: []
    };

    function addUser() {
        var validation = userModels.validateUserRegistration(vm.newUser);
        if (validation !== true) {
            vm.status.errors = validation;
            return;
        }

        vm.status.saving = true;
        userData.addUser(vm.newUser)
            .then(function (result) {
                vm.users.push(result);
                vm.newUser = new userModels.userRegistrationModel();
                vm.status.showAddNew = false;

                userData.getUsers()
                    .then(function (result) {
                        vm.users = result;
                    });
            })
            .catch(function (response) {
                vm.status.errors = common.errorToText(response);
            })
            .finally(function () {
                vm.status.saving = false;
            });
    }

    function attached() {
        userData.getUsers()
            .then(function (result) {
                vm.users = result;
            });

        var userId = $routeParams.userId;
        if (userId !== undefined && userId !== null && userId !== '' && userId > 0) {
            userData.getUser(userId)
            .then(function (result) {
                if (result !== undefined && result !== null) {
                    vm.status.setEdit(result);
                }
            });
        }
    }

    function deleteUser(user) {
        vm.status.errors = [];

        widgetModals.areYouSure('Are you sure you want to deactivate this user?').result
            .then(function (result) {
                if (result === true) {
                    vm.status.saving = true;
                    userData.deactivateUser(vm.editingUser)
                    .then(successResult, errorResult);

                    function errorResult(response) {
                        vm.status.errors = common.errorToText(response);
                        vm.status.saving = false;
                    }

                    function successResult(response) {
                        vm.editingUser.isActive = response.isActive;
                        vm.editingUser = null;
                        vm.status.saving = false;
                    }
                }
            });
    }

    function activeDisabledUser() {
        vm.status.errors = [];

        userData.activeDisabledUser(vm.editingUser)
            .then(activeDisabledUserSuccessResult, activeDisabledUserErrorResult);

        function activeDisabledUserSuccessResult(response) {
            vm.editingUser.isActive = response.isActive;
            vm.editingUser = null;
            vm.status.saving = false;
        }

        function activeDisabledUserErrorResult(response) {
            vm.status.errors = common.errorToText(response);
            vm.status.saving = false;
        }
    }

    function filteredUsers() {
        var result = vm.status.activeOnly
            ? _.filter(vm.users, function (u) { return u.isActive; })
            : vm.users;

        if (vm.searchquery !== null)
            result = _.filter(result, function (u) { return u.name.toLowerCase().indexOf(vm.searchquery.toLowerCase()) > -1; })

        return result;
    }

    function saveUser() {
        vm.status.errors = [];

        var validation = userModels.validateUser(vm.editingUser);
        if (validation !== true) {
            vm.status.errors = validation;
            return;
        }

        vm.status.saving = true;
        userData.saveUser(vm.editingUser)
            .then(successResult, errorResult);

        function errorResult(response) {
            vm.status.errors = common.errorToText(response);    
            vm.status.saving = false;
        }

        function successResult(result) {
            vm.editingUser = null;
            vm.status.completed = true;
            vm.status.saving = false;
        }
    }

    function setAddNew() {
        vm.editingUser = null;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = true;
    }

    function setEdit(user) {
        vm.editingUser = user;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = false;
        $anchorScroll();
    }

    function getDescription(user) {
        var result = '';
        if (user === undefined || user === null) return result;

        if (user.emailAddress !== null && user.emailAddress !== undefined && user.emailAddress.trim() !== '')
            result = user.emailAddress.trim();


        if (user.phoneNumber !== null && user.phoneNumber !== undefined && user.phoneNumber.trim() !== '') {
            if (result === '')
                result = user.phoneNumber.trim();
            else
                result = result + ', ' + user.phoneNumber.trim();
        }

        return result;
    }

    function updateSearch() {
        if (vm.search !== undefined && vm.search !== null && vm.search.trim() !== '')
            vm.searchquery = vm.search;
        else
            vm.searchquery = null;
    }

    return vm;
}
