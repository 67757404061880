﻿'use strict';
module.exports = ['$http', 'common', dataContextService];

function dataContextService($http, common) {
    var vm = {
        del: function (url, apiHost) { return requestWithoutData('DELETE', url, apiHost); },
        get: function (url, apiHost) { return requestWithoutData('GET', url, apiHost); },
        post: function (url, parameters, apiHost) { return requestWithData('POST', url, parameters, apiHost); },
        put: function (url, parameters, apiHost) { return requestWithData('PUT', url, parameters, apiHost); },
        xhr: function (url, parameters, apiHost) { return requestXhrWithData('POST', url, parameters, apiHost); },
        formEncode: formEncode,
        getHeaders: getHeaders
    };

    function formEncode(obj) {
        var encodedString = '';
        for (var key in obj) {
            if (encodedString.length !== 0) {
                encodedString += '&';
            }

            encodedString += key + '=' + encodeURIComponent(obj[key]);
        }
        return encodedString.replace(/%20/g, '+');
    }

    function requestWithoutData(method, url, apiHost) {
        var deferred = common.$q.defer();
        url = processUrl(url, apiHost);

        if (apiHost && apiHost === 'cruisedock') {

            var apiTokenUrl = processUrl('/account/apitoken');

            var inputData = JSON.stringify({ applicationKey: common.config.applicationKey, applicationSecret: common.config.applicationSecret });

            $http({ method: 'POST', url: apiTokenUrl, headers: getHeaders(), data: inputData })
                .then(function (response) {

                    var cruiseDockHeaders = {}
                    cruiseDockHeaders["Authorization"] = response.data;

                    return $http({ method: method, url: url, headers: cruiseDockHeaders })

                })
                .then(successCallback, errorCallback);;

        } else {

            $http({ method: method, url: url, headers: getHeaders() })
                .then(successCallback, errorCallback);
        }

        function successCallback(data, status, headers, cfg) { deferred.resolve(data.data); }
        function errorCallback(error) { deferred.reject(error); }

        return deferred.promise;
    }

    function requestWithData(method, url, parameters, apiHost) {
        var deferred = common.$q.defer();
        url = processUrl(url, apiHost);

        if (apiHost && apiHost === 'cruisedock') {

            var apiTokenUrl = processUrl('/account/apitoken');

            var inputData = JSON.stringify({ applicationKey: common.config.applicationKey, applicationSecret: common.config.applicationSecret });

            $http({ method: 'POST', url: apiTokenUrl, headers: getHeaders(), data: inputData })
                .then(function (response) {

                    var cruiseDockHeaders = {}
                    cruiseDockHeaders["Authorization"] = response.data;

                    return $http({ method: method, url: url, headers: cruiseDockHeaders, data: JSON.stringify(parameters) })

                })
                .then(successCallback, errorCallback);;

        } else {
            var config = { method: method, url: url, headers: getHeaders(), data: JSON.stringify(parameters) };
            $http(config)
                .then(successCallback, errorCallback);
        }

        function successCallback(data, status, headers, cfg) { deferred.resolve(data.data); }
        function errorCallback(error) { deferred.reject(error); }

        return deferred.promise;
    }

    function requestXhrWithData(method, url, parameters, apiHost) {
        var deferred = common.$q.defer();
        url = processUrl(url, apiHost);

        var headers = getHeaders();
        headers["Content-Type"] = undefined;

        var config = { method: method, url: url, headers: headers, data: parameters };
        config.transformRequest = angular.identity;

        $http(config)
            .then(successCallback, errorCallback, progressCallback);

        function successCallback(data, status, headers, cfg) { deferred.resolve(data.data); }
        function errorCallback(error) { deferred.reject(error); }
        function progressCallback(progress) { deferred.notify(progress); }

        return deferred.promise;
    }

    // we have to include the Bearer token with each call to the Web API controllers. 
    function getHeaders() {
        var header = {};
        if (common.identity.token && common.identity.token.tokenValue)
            header["Authorization"] = "Bearer " + common.identity.token.tokenValue;
        return header;
    }

    function processUrl(url, apiHost) {
        if (url === null || url === '')
            return url;

        var urlLower = url.toString().toLowerCase();

        if (urlLower.indexOf('http://') !== 0 && urlLower.indexOf('https://') !== 0 && (!apiHost || apiHost === 'easydock'))
            url = common.config.apiHostEasyDock + url;

        if (urlLower.indexOf('http://') !== 0 && urlLower.indexOf('https://') !== 0 && apiHost && apiHost === 'cruisedock')
            url = common.config.apiHostCruiseDock + url;

        return url;
    }

    return vm;

}
