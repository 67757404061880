﻿module.exports = ['shipPropertyService', shipPropertiesFormDirective];

function shipPropertiesFormDirective(shipPropertyService) {
    var directive = {
        bindToController: true,
        controller: shipPropertiesFormController,
        controllerAs: 'ctrlShipPropForm',
        link: function (scope) {
            if (scope.ctrlShipPropForm && scope.ctrlShipPropForm.shipProperties && scope.ctrlShipPropForm.shipProperties.length > 0) {
                scope.ctrlShipPropForm.shipProperties = _.map(scope.ctrlShipPropForm.shipProperties, function (item) { item.hasChanged = false; item.startsOnDisplay = moment(item.startsOn).format('DD-MM-YYYY'); return item; })
            }
        },
		restrict: 'E',
        scope: {
			shipId: '=',
			shipProperties: '='
		},
		templateUrl: 'templates/fleet/directives/ship-properties-form.html'
	};

    function shipPropertiesFormController() {
        var ctrl = this;

        ctrl.addNewLine = function (propertyType) {
            ctrl.shipProperties.push({
                id: -1, hasChanged: false, shipId: ctrl.shipId, property: propertyType, value: null, startsOnDisplay: moment().format('DD-MM-YYYY')
            });
        }
        ctrl.containsNewLine = function (propertyType) {
            return _.some(ctrl.shipProperties, function (item) { return item.property === propertyType && item.id === -1; });
        }
        ctrl.deleteProperty = function (shipProperty) {
            shipPropertyService.deleteProperty(shipProperty)
                .then(function (result) {
                    var index = _.findIndex(ctrl.shipProperties, function (item) { return item.id === result.id });
                    if (index > -1)
						ctrl.shipProperties.splice(index, 1);
                });
        };
        ctrl.filteredProperties = function (propertyType) {
            return _.filter(ctrl.shipProperties, function (item) { return item.property === propertyType; });
        }
        ctrl.saveProperty = function (shipProperty) {
            var prop = _.clone(shipProperty);
            prop.startsOn = moment.utc(prop.startsOnDisplay, 'DD-MM-YYYY');

            if (shipProperty.id === -1) {
                shipPropertyService.addProperty(prop)
                    .then(function (result) {
                        shipProperty.id = result.id;
                        shipProperty.hasChanged = false;
                    });
            }
            else {
                shipPropertyService.updateProperty(prop)
                    .then(function (result) {
                        shipProperty.hasChanged = false;
                    });
            }
        }
	}

    return directive;
}
