﻿module.exports = [arrivalsModels];

function arrivalsModels() {

    var service = {
        arrivalModel: arrivalModel,
        validateUCRN: validateUCRN
    };

    return service;

    function arrivalModel(arrivalData) {
        var arrivalModel = {
            id: null,
            realisationId: null,
            ata: null,
            atd: null,
            berthGroupId: null,
            reservationId: null,
            status: null,
            approvedByUserId: null, 
            approvedOn: null,
            customerId: null,
            shipId: null,
            length: null,
            remarks: null,
            customReference: null
        };

        if (arrivalData === undefined || arrivalData === null)
            return arrivalModel;

        arrivalModel.id = arrivalData.id;
        arrivalModel.realisationId = arrivalData.realisationItem.realisationId;
        arrivalModel.ata = arrivalData.realisationItem.ata;
        arrivalModel.atd = arrivalData.realisationItem.atd;
        arrivalModel.berthGroupId = arrivalData.realisationItem.berthGroupId;
        arrivalModel.reservationId = arrivalData.reservationId;
        arrivalModel.status = arrivalData.status;
        arrivalModel.approvedByUserId = arrivalData.approvedByUserId;
        arrivalModel.approvedOn = arrivalData.approvedOn;
        arrivalModel.customerId = arrivalData.customerId;
        arrivalModel.shipId = arrivalData.shipId;
        arrivalModel.length = arrivalData.length;
        arrivalModel.remarks = arrivalData.remarks;
        arrivalModel.customReference = arrivalData.customReference;

        return arrivalModel;
    }

    function validateUCRN(ucrn) {

        // Validate CRN when CRN is set
        var pattern = /(^NLAMS|^NLAMB)/i;
        if (ucrn === undefined || ucrn === null || ucrn === "" || !pattern.test(ucrn) || ucrn.length !== 13)
            return false;

        return true;
    }
}