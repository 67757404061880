﻿module.exports = ['invoiceDataService', 'invoiceStateService', 'constantsService', 'toastr', 'common', invoiceController];

function invoiceController(invoiceData, invoiceState, constantsService, toastr, common) {

    var vm = {
        attached: attached,
        availableInvoiceTypes: [],
        canCreateCredit:canCreateCredit,
        createFromRealisation: {
            create: createFromRealisation,
            isRunning: false
        },
        defaultSortField: 'invoiceDate',
        filterInvoices: filterInvoices,
        generated: false,
        generateXml: generateXml,
        generateXmlOnServer: generateXmlOnServer,
        getBackgroundColor:getBackgroundColor,
        invoices: [],
        processInvoices: processInvoices,
        reload: reload,
        searchQuery: null,
        selectAll: selectAll,
        selectedInvoice: null,
        selectedInvoices: [],
        saveDebtor: saveDebtor,
        setDebtor: setDebtor,
        sort: {
            current: null,
            default: 'invoiceDate',
            descending: true
        },
        state: invoiceState,
        status: {
            isLoading: false,
            allowCreditInvoiceType: true,
            notSentOnly: false,
        },
        sum: sumLines,
        updateSearch: updateSearch
    };

    function attached() {
        if (!vm.state.filter.startDate)
            vm.state.filter.startDate = moment().add(-6, 'days');
        if (!vm.state.filter.endDate)
            vm.state.filter.endDate = moment();
        
        constantsService.preloaded.then(function () {
            var bu = common.identity.getBusinessUnitsForPermission("invoice_admin");
            vm.availableInvoiceTypes = _.union(vm.availableInvoiceTypes, constantsService.getInvoiceTypesBasedOnBusinessUnits(bu));

            if (vm.availableInvoiceTypes.length > 0 && vm.state.filter.invoiceType === null)
                vm.state.filter.invoiceType = vm.availableInvoiceTypes[0].id;

            if (invoiceData.searchQuery) {
                vm.searchQuery = invoiceData.searchQuery;
                reload(true, true);
            } else {
                reload(true);
            }
        });
    }

    function createFromRealisation() {
        vm.createFromRealisation.isRunning = true;

        invoiceData.createFromRealisation()
            .then(function (data) {
                vm.createFromRealisation.isRunning = false;
                toastr.success(data.length + ' invoices have been generated');
            }, function () {
                vm.createFromRealisation.isRunning = false;
            });
    }

    function filterInvoices() {
        if (vm.status.notSentOnly === false) {
            // reset the sort only when current is null. otherwise the sort is changed by the user
            if (vm.sort.current === null) {
                vm.sort.current = vm.sort.default;
                vm.sort.descending = true;
            }
            vm.invoices = _.map(vm.invoices, function (invoice) {
                invoice.hide = false;
                return invoice;
            });

        } else {
            // sort by ship name and ATA
            vm.sort.current = null;
            vm.sort.descending = false;
            vm.invoices = _.chain(vm.invoices).map(function (invoice) {
                if (!invoice.transferredOn &&
                    (
                        (invoice.isCreditInvoice || invoice.originalInvoiceId === null || vm.status.allowCreditInvoiceType === false) // text not sent
                        ||
                        (!invoice.isCreditInvoice && invoice.originalInvoiceId !== null && vm.status.allowCreditInvoiceType)  // link not sent
                    )
                ) {
                    invoice.hide = false;
                } else {
                    invoice.hide = true;
                }
                return invoice;
            }).sortBy(function (invoice) {
                return (invoice.description instanceof Date) === true ? parseInt(invoice.description.toISOString().split('T')[0].replaceAll('-', '')) : '';
            }).sortBy(function (invoice) {
                var result = invoice.specificShip === null ? invoice.description : invoice.specificShip.name;
                result = result.trim();
                // check if the ship name ends with number of person, if yes remove that for sorting. e.g. TEST SHIP 100p -> TEST SHIP
                if (result !== undefined && result !== null && result !== '') {
                    var arr = result.split(' ');
                    var last = arr[arr.length-1];
                    if (last.endsWith('p') && isNaN(parseInt(last.replaceAll('p', ''))) === false) {
                        arr.pop();
                        result = arr.join(' ');
                    }
                }
                return result;
            }).value();
        }
    }

    function generateXml() {
        invoiceData.generateXml(vm.selectedInvoices)
            .then(function (result) {
                vm.generated = true;

                var filename = 'exact-export-' + moment().format('YYYYMMDDHHmmss') + '.xml';
                var contentType = 'text/xml';

                var blob = new Blob([result], { type: contentType });
                if (navigator.appVersion.toString().indexOf('.NET') > 0)
                    window.navigator.msSaveBlob(blob, filename);
                else {
                    var linkElement = document.createElement('a');
                    try {
                        var url = window.URL.createObjectURL(blob);

                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute("download", filename);

                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        linkElement.dispatchEvent(clickEvent);
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            });
    }

    function generateXmlOnServer() {
        invoiceData.generateXmlOnServer();
    }

    function loadInvoices() {
        vm.status.isLoading = true;

        invoiceData.get(vm.state.filter.startDate.format('YYYY-MM-DD'),
                vm.state.filter.endDate.format('YYYY-MM-DD'),
                vm.state.filter.pageSize,
                vm.state.filter.pageIndex,
                vm.state.filter.invoiceType)
            .then(function (data) {
                    vm.processInvoices(data);
                    vm.status.isLoading = false;
                },
                function() {
                    vm.status.isLoading = false;
                });
    }

    function loadInvoicesByTextSearch(query) {
        vm.status.isLoading = true;

        invoiceData.getInvoicesByTextSearch(query, vm.state.filter.invoiceType)
            .then(function (data) {
                vm.processInvoices(data);
                vm.status.isLoading = false;
            }, function () {
                vm.status.isLoading = false;
            });
    }

    function processInvoices(input) {
        vm.status.allowCreditInvoiceType = true;

        if (input && input.length) {
            vm.invoices = _.map(input, function (invoice) {
                if (invoice.description) {
                    const splitDescription = invoice.description.split(/ (.*)/);
                    splitDescription.pop();
                    if (splitDescription.length == 2) {
                        invoice.description = moment(splitDescription[0], 'YYMMDD').toDate();
                        if (!invoice.specificShip) {
                            invoice.specificShip = { name: splitDescription[1] };
                        }
                    }
                }
                return invoice;
            });
        } else {
            vm.invoices = input;
        }

        if (vm.availableInvoiceTypes.length > 0 && vm.state.filter.invoiceType !== null) {
            var invoiceType = _.find(vm.availableInvoiceTypes, function (it) { return it.id === vm.state.filter.invoiceType; });
            if (invoiceType && invoiceType.configuration && invoiceType.configuration.dis_crd_chg === true) {
                vm.status.allowCreditInvoiceType = false;
            }
        }

        vm.filterInvoices();
    }

    function reload(clearAll, useTextSearch) {

        if (clearAll && clearAll === true) {
            vm.invoices = [];
        }
        if (useTextSearch && vm.searchQuery ) {
            loadInvoicesByTextSearch(vm.searchQuery);
        } else {
            loadInvoices();
        }
    }

    function saveDebtor(evt, invoice) {
        evt.preventDefault();
        evt.stopPropagation();

        invoice.debtorNumber = invoice.debtorEdit;
        invoice.debtorEdit = undefined;

        invoiceData.updateDebtor(invoice)
            .then((result) => {
                toastr.success('Debtor number has been updated');
            });
    }

    function setDebtor(evt, invoice) {
        evt.preventDefault();
        evt.stopPropagation();

        invoice.debtorEdit = invoice.debtorNumber ? invoice.debtorNumber : '';
    }

    function updateSearch() {

        if (vm.state.filter.startDate)
            invoiceData.filterStart = vm.state.filter.startDate;
        if (vm.state.filter.endDate)
            invoiceData.filterEnd = vm.state.filter.endDate;
        if (vm.searchQuery && vm.searchQuery.trim().length !== 0) {
            if (vm.searchQuery !== invoiceData.searchQuery) {
                invoiceData.searchQuery = vm.searchQuery;
                loadInvoicesByTextSearch(invoiceData.searchQuery);
            } 
        } else {
            invoiceData.searchQuery = null;
            reload(true, true);
        }

    }
    function selectAll() {
        if (vm.selectedInvoices.length === vm.invoices.length)
            vm.selectedInvoices = [];
        else
            vm.selectedInvoices = _.map(vm.invoices, function (item) {
                return item.id;
            });
    }

    function sumLines(items) {
        return _.reduce(items, function (memo, item) {
            return Math.round((memo + item.totalAmount) * 100) / 100;
        }, 0);
    }

    function canCreateCredit(invoice){
        return invoice.transferredOn && !invoice.hasCreditInvoice && !invoice.isCreditInvoice && ( invoice.status == 'INVOICE' || (invoice.status == 'PROFORMA' && invoice.combinedInvoiceId == null));
    }

    function getBackgroundColor(invoice){
        return (invoice.status === 'PROFORMA')?'#eFeFeF':'';
    }

    return vm;
}