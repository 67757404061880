﻿"use strict";

module.exports = [
    "common",
    "dataContext",
    "reportModelsService",
    reportDataService,
];

function reportDataService(common, context, reportModels) {
    var vm = {
        getNumberOfReservationsPerYear: getNumberOfReservationsPerYear,
        getNumberOfReservationsPerCustomerPerYear:
            getNumberOfReservationsPerCustomerPerYear,
        getNumberOfBerthOccupationPerYear: getNumberOfBerthOccupationPerYear,
        getFleetLengthPerCustomer: getFleetLengthPerCustomer,
        getFleetLengthPerFleet: getFleetLengthPerFleet,
        getInvoiceIssuesRemarks: getInvoiceIssuesRemarks,
        getReportFlows: getReportFlows,
        getReportOpenRevenue: getReportOpenRevenue,
        getReportPlanning: getReportPlanning,
        getReportTariffs: getReportTariffs,
        getReportReservations: getReportReservations,
        getReportResponseTime: getReportResponseTime,
        getReportTurnover: getReportTurnover,
        getSailingReports: getSailingReports,
        getSailingReportRemarks: getSailingReportRemarks,
        getRealisationsBasedOnRemark: getRealisationsBasedOnRemark,
        getAllRemarks: getAllRemarks,
        getInvoiceDiscountStats: getInvoiceDiscountStats,
        getAllInvoiceWithRemarks: getAllInvoiceWithRemarks,
        getAllInvoiceDiscounts: getAllInvoiceDiscounts,
        getRealisationsPerBerth: getRealisationsPerBerth,
        getReservationsOfRealisationsPerBerth:
            getReservationsOfRealisationsPerBerth,
        getInvoicesOfRealisationsPerBerth: getInvoicesOfRealisationsPerBerth,
        getCancelledInvoicesOfRealisationsPerBerth:
            getCancelledInvoicesOfRealisationsPerBerth,
        getDiscountInvoicesOfRealisationsPerBerth:
            getDiscountInvoicesOfRealisationsPerBerth,
        getRealisationsOfRealisationsPerBerth:
            getRealisationsOfRealisationsPerBerth,
        getExemptionsOfRealisationsPerBerth: getExemptionsOfRealisationsPerBerth,
        getReportArrivalAndDepartureTime: getReportArrivalAndDepartureTime,
    };

    return vm;

    function getNumberOfReservationsPerYear() {
        var deferred = common.$q.defer();
        var url = "/report/number-of-reservations-per-year";
        var req = context.get(url).then(
            function (data) {
                var result = _.map(data, reportModels.reportModel);
                deferred.resolve(result);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getNumberOfReservationsPerCustomerPerYear() {
        var deferred = common.$q.defer();
        var url = "/report/number-of-reservations-per-customer-per-year";
        var req = context.get(url).then(
            function (data) {
                var result = _.map(data, reportModels.reportModel);
                deferred.resolve(result);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getNumberOfBerthOccupationPerYear() {
        var deferred = common.$q.defer();
        var url = "/report/number-of-berth-occupation-per-year";
        var req = context.get(url).then(
            function (data) {
                var result = _.map(data, reportModels.reportModel);
                deferred.resolve(result);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getFleetLengthPerCustomer() {
        var deferred = common.$q.defer();
        var url = "/report/fleet-length-per-customer";
        var req = context.get(url).then(
            function (data) {
                var result = _.map(data, reportModels.reportModel);
                deferred.resolve(result);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getFleetLengthPerFleet() {
        var deferred = common.$q.defer();
        var url = "/report/fleet-length-per-fleet";
        var req = context.get(url).then(
            function (data) {
                var result = _.map(data, reportModels.reportModel);
                deferred.resolve(result);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getReportFlows(dateFrom, dateUntil, shipTypeId) {
        var deferred = common.$q.defer();

        if (!dateFrom || !dateUntil) deferred.reject("Please supply valid dates");

        dateFrom = (moment.isMoment(dateFrom) ? dateFrom : moment(dateFrom)).format(
            "YYYY-MM-DD"
        );
        dateUntil = (
            moment.isMoment(dateUntil) ? dateUntil : moment(dateUntil)
        ).format("YYYY-MM-DD");

        var req = context
            .get(
                "/report/flows?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateUntil +
                "&shipTypeId=" +
                shipTypeId
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getReportOpenRevenue(dateFrom, dateUntil, invoiceTypeId) {
        var deferred = common.$q.defer();

        if (!dateFrom || !dateUntil) deferred.reject("Please supply valid dates");

        dateFrom = (moment.isMoment(dateFrom) ? dateFrom : moment(dateFrom)).format(
            "YYYY-MM-DD"
        );
        dateUntil = (
            moment.isMoment(dateUntil) ? dateUntil : moment(dateUntil)
        ).format("YYYY-MM-DD");

        var req = context
            .get(
                "/report/open-revenue?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateUntil +
                "&invoiceTypeId=" +
                invoiceTypeId
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getReportPlanning(page, dateYearFilter) {
        var deferred = common.$q.defer();
        var url = "/report/planning";
        var size = 50;

        url =
            url +
            "?page=" +
            page +
            "&size=" +
            size +
            "&dateYearFilter=" +
            dateYearFilter;

        var req = context.get(url).then(
            function (data) {
                var result = _.map(data, reportModels.reportPlanningModel);
                deferred.resolve(result);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getReportTariffs() {
        var deferred = common.$q.defer();
        var url = "/tariff";
        var req = context.get(url).then(
            function (data) {
                var result = _.map(data, reportModels.reportTariffModel);
                deferred.resolve(result);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getReportReservations(
        dateFrom,
        dateTo,
        berthGroupId,
        berthId,
        customerId
    ) {
        var deferred = common.$q.defer();
        var url = "/report/reservations?dateFrom=" + dateFrom + "&dateTo=" + dateTo;

        url += "&berthGroupId=";
        url += berthGroupId ? berthGroupId : "";
        url += "&berthId=";
        url += berthId ? berthId : "";
        url += "&customerId=";
        url += customerId ? customerId : "";

        var req = context.get(url).then(
            function (data) {
                var result = data;
                deferred.resolve(result);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getReportResponseTime(dateFrom, dateTo, reservationYear) {
        var deferred = common.$q.defer();
        var url =
            "/report/responsetime?dateFrom=" +
            dateFrom +
            "&dateTo=" +
            dateTo +
            "&reservationYear=" +
            reservationYear;

        var req = context.get(url).then(
            function (data) {
                var result = data;
                deferred.resolve(result);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getReportTurnover(
        dateFrom,
        dateTo,
        berthGroupId,
        berthId,
        customerId
    ) {
        var deferred = common.$q.defer();
        var url = "/report/turnover?dateFrom=" + dateFrom + "&dateTo=" + dateTo;

        url += "&berthGroupId=";
        url += berthGroupId ? berthGroupId : "";
        url += "&berthId=";
        url += berthId ? berthId : "";
        url += "&customerId=";
        url += customerId ? customerId : "";

        var req = context.get(url).then(
            function (data) {
                var result = data;
                deferred.resolve(result);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getSailingReports(reservationId) {
        var deferred = common.$q.defer();
        var req = context.get("/sailingreport/reservation/" + reservationId).then(
            function (data) {
                deferred.resolve(data);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getSailingReportRemarks(dateFrom, dateUntil) {
        var deferred = common.$q.defer();
        var req = context.get("/sailingreport/issues/remarks?dateFrom=" + dateFrom + "&dateUntil=" + dateUntil).then(
            function (data) {
                deferred.resolve(data);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getInvoiceIssuesRemarks(dateFrom, dateUntil, invoiceTypes) {
        var deferred = common.$q.defer();
        var req = context.get("/invoice/issues/remarks?dateFrom=" + dateFrom + "&dateUntil=" + dateUntil + "&invoiceTypes=" + invoiceTypes.join(',')).then(
            function (data) {
                deferred.resolve(data);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }

    function getRealisationsBasedOnRemark(
        dateFrom,
        dateTo,
        shipTypeId,
        customerId,
        remark
    ) {
        if (!remark) remark = "";

        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/realisations-based-on-remark?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&shipTypeId=" +
                shipTypeId +
                "&customerId=" +
                customerId +
                "&remark=" +
                remark
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getAllRemarks(dateFrom, dateTo, shipTypeId) {
        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/invoice-discount-remarks?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&shipTypeId=" +
                shipTypeId
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getInvoiceDiscountStats(
        dateFrom,
        dateTo,
        shipTypeId,
        customerId,
        remark
    ) {
        if (!remark) remark = "";

        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/invoice-remark-stats?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&shipTypeId=" +
                shipTypeId +
                "&customerId=" +
                customerId +
                "&remark=" +
                remark
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getAllInvoiceWithRemarks(
        dateFrom,
        dateTo,
        shipTypeId,
        customerId,
        remark
    ) {
        if (!remark) remark = "";

        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/invoice-remark?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&shipTypeId=" +
                shipTypeId +
                "&customerId=" +
                customerId +
                "&remark=" +
                remark
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getAllInvoiceDiscounts(
        dateFrom,
        dateTo,
        shipTypeId,
        customerId,
        remark
    ) {
        if (!remark) remark = "";

        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/invoice-discount?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&shipTypeId=" +
                shipTypeId +
                "&customerId=" +
                customerId +
                "&remark=" +
                remark
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getRealisationsPerBerth(
        dateFrom,
        dateTo,
        dateType,
        shipTypeId,
        customerId,
        berthId,
        berthGroupId
    ) {
        var isEta = dateType.toLowerCase() == "eta";
        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/realisations-per-berth?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&isEta=" +
                isEta +
                "&shipTypeId=" +
                shipTypeId +
                "&customerId=" +
                customerId +
                "&berthId=" +
                berthId +
                "&berthGroupId=" +
                berthGroupId
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getReservationsOfRealisationsPerBerth(
        dateFrom,
        dateTo,
        dateType,
        shipTypeId,
        customerId,
        berthId,
        berthGroupId
    ) {
        var isEta = dateType.toLowerCase() == "eta";
        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/realisations-per-berth/reservations?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&isEta=" +
                isEta +
                "&shipTypeId=" +
                shipTypeId +
                "&customerId=" +
                customerId +
                "&berthId=" +
                berthId +
                "&berthGroupId=" +
                berthGroupId
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getRealisationsOfRealisationsPerBerth(
        dateFrom,
        dateTo,
        dateType,
        shipTypeId,
        customerId,
        berthId,
        berthGroupId
    ) {
        var isEta = dateType.toLowerCase() == "eta";
        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/realisations-per-berth/realisations?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&isEta=" +
                isEta +
                "&shipTypeId=" +
                shipTypeId +
                "&customerId=" +
                customerId +
                "&berthId=" +
                berthId +
                "&berthGroupId=" +
                berthGroupId
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getInvoicesOfRealisationsPerBerth(
        dateFrom,
        dateTo,
        dateType,
        shipTypeId,
        customerId,
        berthId,
        berthGroupId
    ) {
        var isEta = dateType.toLowerCase() == "eta";
        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/realisations-per-berth/invoices?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&isEta=" +
                isEta +
                "&shipTypeId=" +
                shipTypeId +
                "&customerId=" +
                customerId +
                "&berthId=" +
                berthId +
                "&berthGroupId=" +
                berthGroupId
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getCancelledInvoicesOfRealisationsPerBerth(
        dateFrom,
        dateTo,
        dateType,
        shipTypeId,
        customerId,
        berthId,
        berthGroupId
    ) {
        var isEta = dateType.toLowerCase() == "eta";
        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/realisations-per-berth/cancelled-invoices?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&isEta=" +
                isEta +
                "&shipTypeId=" +
                shipTypeId +
                "&customerId=" +
                customerId +
                "&berthId=" +
                berthId +
                "&berthGroupId=" +
                berthGroupId
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getDiscountInvoicesOfRealisationsPerBerth(
        dateFrom,
        dateTo,
        dateType,
        shipTypeId,
        customerId,
        berthId,
        berthGroupId
    ) {
        var isEta = dateType.toLowerCase() == "eta";
        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/realisations-per-berth/discount-invoices?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&isEta=" +
                isEta +
                "&shipTypeId=" +
                shipTypeId +
                "&customerId=" +
                customerId +
                "&berthId=" +
                berthId +
                "&berthGroupId=" +
                berthGroupId
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getExemptionsOfRealisationsPerBerth(
        dateFrom,
        dateTo,
        dateType,
        shipTypeId,
        customerId,
        berthId,
        berthGroupId
    ) {
        var isEta = dateType.toLowerCase() == "eta";
        var deferred = common.$q.defer();
        var req = context
            .get(
                "/report/realisations-per-berth/exempt-invoices?dateFrom=" +
                dateFrom +
                "&dateUntil=" +
                dateTo +
                "&isEta=" +
                isEta +
                "&shipTypeId=" +
                shipTypeId +
                "&customerId=" +
                customerId +
                "&berthId=" +
                berthId +
                "&berthGroupId=" +
                berthGroupId
            )
            .then(
                function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

        return deferred.promise;
    }

    function getReportArrivalAndDepartureTime(dateFrom, dateTo) {
        var deferred = common.$q.defer();
        var url =
            "/report/arrivalanddeparturetime?dateFrom=" +
            dateFrom +
            "&dateTo=" +
            dateTo;

        var req = context.get(url).then(
            function (data) {
                var result = data;
                deferred.resolve(result);
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }
}
