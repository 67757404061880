﻿module.exports = [
    '$route', '$routeParams', '$location', 'common', 'customerDataService', 'userDataService', 'fleetDataService', 'reservationDataService', 'messageDataService', 'customerModelsService', 'toastr', 'widgetModals',  customerOverviewController];

function customerOverviewController($route, $routeParams, $location, common, customerData, userData, fleetData, reservationData, messageData, customerModels, toastr, widgetModals) {

    var vm = {
        // customer
        addCustomer: addCustomer,
        approveCustomer: approveCustomer,
        attached: attached,
        deleteCustomer: deleteCustomer,
        editingCustomer: null,
        filteredCustomers: filteredCustomers,
        getCustomer: getCustomer,
        newCustomer: new customerModels.customerModel(),
        shipTypesSelected: {},
        saveCustomer: saveCustomer,
        closeRefresh: closeRefresh,
        status: {
            activeOnly: true,
            completed: false,
            errors: [],
            saving: false,
            setAddNew: setAddNew,
            setEdit: setEdit,
            showAddNew: false,
            showSidebar: true
        },
        customers: [],
        searchquery: null,
        updateSearch: updateSearch,
        loadCustomers: loadCustomers,
        selectedLink: selectedLink,
        currentlySelected: false,

        // users
        editingUser: null,
        users: [],
        userStatus: {
            completed: false,
            errors: [],
            saving: false,
            showAddNew: false,
            showEdit: false
        },


        // fleet
        editingVessel: null,
        vessels: [],
        vesselStatus: {
            completed: false,
            errors: [],
            saving: false,
            showAddNew: false
        },

        // reservations
        reservations: [],
        reservationId: null,
        reservation: null,
        reservationStatus: {
            showDetails: false
        },

        // messages
        messages: [],
        //reservationId: null,
        inbox: null,
        customerInbox: null,
        messagesStatus: {
            showDetails: false
        },
        // navigation
        activeTab: 0,
        nav: {
            tabView: false,
            customerTab: true
        },
        navCustomer: navCustomer
    };

    init();

    function approveCustomer() {
        vm.status.saving = false;

        customerData.approveCustomer(vm.editingCustomer.id)
            .then(successResult, errorResult);

        function errorResult(response) {
            vm.status.errors = common.errorToText(response);
            vm.status.completed = true;
            vm.status.saving = false;
        }

        function successResult(result) {
            vm.editingCustomer = null;
            vm.status.completed = true;
            vm.status.saving = false;
            $location.path('/customers/');

            loadCustomers();
        }
    }

    function init() {
        attached();
    }
    function updateSearch() {
        customerData.searchQuery = vm.searchquery;
        if (vm.searchquery !== null && vm.searchquery !== undefined
            && vm.searchquery.trim() === '')
            return vm.loadCustomers(null);
    }
    function selectedLink(id) {
        if (vm.editingCustomer) {
            if (parseInt(id) === parseInt(vm.editingCustomer.id)) {
                vm.currentlySelected = true;
                return vm.currentlySelected;
            }
        }
    }
    function closeRefresh() {
        $location.path('/customers/');
    }
    function addCustomer() {
        vm.status.errors = [];

        if (vm.newCustomer.name === null || vm.newCustomer.name === '')
            vm.status.errors.push('Please specify a company name');

        if (vm.newCustomer.invoiceDetails === undefined || vm.newCustomer.invoiceDetails.kvk === undefined || vm.newCustomer.invoiceDetails.kvk === null || vm.newCustomer.invoiceDetails.kvk === '')
            vm.status.errors.push('Please specify a chamber of commerce number');

        if (vm.newCustomer.invoiceDetails === undefined || vm.newCustomer.invoiceDetails.btw === undefined || vm.newCustomer.invoiceDetails.btw === null || vm.newCustomer.invoiceDetails.btw === '')
            vm.status.errors.push('Please specify a VAT number');

        if (vm.status.errors.length === 0)
            vm.status.errors = null;
        else
            return;

        vm.status.saving = true;
        customerData.addCustomer(vm.newCustomer)
            .then(function (result) {
                vm.customers.push(result);
                vm.status.saving = false;
                vm.status.showAddNew = false;
                if (vm.shipTypesSelected !== undefined && vm.shipTypesSelected !== null)
                    customerData.saveCustomerShipTypes(result.id, vm.newCustomer.shipTypes);
                $location.path('/customers/');

            });
    }

    function attached() {
        if (customerData.searchQuery) {
            loadCustomers(customerData.searchQuery);
            vm.searchquery = customerData.searchQuery;
        } else {
            loadCustomers();
        }

        var customerId = $routeParams.customerId;
        if (customerId !== undefined && customerId !== null && customerId !== '' && customerId > 0) {
            if ($routeParams.userId) {
                vm.activeTab = 1;
            }
            if ($routeParams.shipId) {
                vm.activeTab = 2;
            }

            getCustomer(customerId);
        }
    }

    function deleteCustomer(user) {
        vm.status.errors = [];

        widgetModals.areYouSure('You want to delete this customer?').result
            .then(function (result) {
                if (result === true) {
                    vm.status.saving = true;
                    customerData.deleteCustomer(vm.editingCustomer)
                    .then(successResult, errorResult);
                }
            });

        function successResult(response) {
            var iUser = _.findIndex(vm.customers, function (item) { return item.id === vm.editingCustomer.id; });
            vm.customers.splice(iUser, 1);
            vm.editingCustomer = null;
            vm.status.saving = false;
            $location.path('/customers/');

        }

        function errorResult(response) {
            if (response.status === 400 && !response.data.errors) {
                vm.status.errors.push(response.data);
            } else {
                vm.status.errors = common.errorToText(response);
            }

            vm.status.saving = false;
        }
    }

    function filteredCustomers() {
        if (vm.status.activeOnly) {
            return _.filter(vm.customers, function (c) { return !c.customerPortAuthority || c.customerPortAuthority.active; });
        } else {
            return vm.customers;
        }
    }

    function getCustomer(customerId) {
        vm.users = [];
        vm.vessels = [];
        vm.reservations = [];
        vm.inbox = null;
        vm.customerInbox = null;
        vm.editingVessel = null;
        //vm.editingCustomer = null;
        vm.editingUser = null;

        customerData.getCustomer(customerId)
            .then(function (result) {
                if (result !== undefined && result !== null) {
                    result.shipTypes = [];
                    vm.status.setEdit(result);
                }

                return customerData.getCustomerShipTypes(customerId);
            })
            .then(function (result) {
                if (result)
                    vm.editingCustomer.shipTypes = result;

                return userData.getUsersForCustomer(customerId);
            })
            .then(function (result) {
                vm.users = _.sortBy(result, 'name');
                return fleetData.getFleet(customerId);
            })
            .then(function (result) {
                vm.vessels = _.sortBy(result, 'name');

                return reservationData.getReservationsForCustomer(customerId);
            })
            .then(function (result) {
                result = _.map(result, function (reservation) {
                    if (reservation.communicatedStatus && reservation.communicatedStatus.length > 0) {
                        // TODO need to fix this; the reservation.communicatedStatus should contain an array and not only one status
                        // since other communicatedStatus (cancelled, changed etc...) is stored into the database, the UI needs to updated
                        // need to check the impact of this changes; e.g. in reservation-duplicate-current, calendarblock etc...
                        var status = _.filter(_.sortBy(reservation.communicatedStatus, 'communicatedOn'), function (obj) { return obj.status <= 3; });
                        reservation.communicatedStatus = status.length > 0 ? _.last(status) : [];
                    }
                    return reservation;
                });

                vm.reservations = result;

                return messageData.getMessageInboxForCustomer(customerId, 10, 0);
            })
            .then(function (results) {
                results = _.map(results, function (result) {
                    result.reservation = null;

                    if (result.objects && result.objects.length > 0) {
                        const reservation = _.find(result.objects, function (object) {
                            return object.objectType === 'reservation';
                        });
                        if (reservation) {
                            result.reservation = reservation.objectValue;
                        }
                    }

                    return result;
                });

                vm.inbox = results;

                return messageData.getCustomerMessageInboxForCustomer(customerId, 10, 0);
            })
            .then(function (result) {
                vm.customerInbox = result;

                // select the Fleet tab when the path contains the ship id
                var shipId = parseInt($routeParams.shipId);
                if (shipId !== undefined && shipId !== null && shipId !== '' && shipId > 0) {
                    vm.activeTab = 2;
                    var found = _.find(vm.vessels, function (vessel) {
                        return vessel.id === shipId;
                    });

                    if (found !== undefined && found !== null) {
                        vm.editingVessel = found;
                    }
                }
            });

    }

    function loadCustomers(searchquery) {
        customerData.getCustomers(searchquery)
            .then(function (result) {
                vm.customers = result;
            });
    }

    function saveCustomer() {

        vm.status.errors = [];

        if (vm.editingCustomer.name === null || vm.editingCustomer.name === '')
            vm.status.errors.push('Please specify a company name');

        if (vm.status.errors.length === 0)
            vm.status.errors = null;
        else
            return;

        vm.status.saving = true;
        customerData.saveCustomer(vm.editingCustomer)
            .then(successResult, errorResult);

        if (vm.editingCustomer.shipTypes !== undefined && vm.editingCustomer.shipTypes !== null)
            customerData.saveCustomerShipTypes(vm.editingCustomer.id, vm.editingCustomer.shipTypes);

        function errorResult(response) {
            vm.status.errors = common.errorToText(response);
            vm.status.completed = true;
            vm.status.saving = false;
        }

        function successResult(result) {
            vm.editingCustomer = null;
            vm.status.completed = true;
            vm.status.saving = false;
            $location.path('/customers/');

            loadCustomers();
        }

    }

    function setAddNew() {
        vm.nav.tabView = false;
        vm.editingCustomer = null;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = true;
    }

    function setEdit(customer) {
        if (!customer || !customer.id) {
            $location.path('/customers', false);
        }
        else {
            $location.path('/customers/' + customer.id, false);
        }
        vm.nav.tabView = true;
        vm.editingCustomer = customer;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = false;
        vm.status.showSidebar = customer === null || customer === undefined;
    }

    function navCustomer(str) {
        resetTabs();
            vm.nav.customerTab = true;
    }
    function resetTabs() {
        vm.nav.customerTab = false;
    }
    return vm;
}
