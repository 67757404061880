﻿angular.module('poa.rprp.berthgroup', [])
    .config(['$routeProvider', routeConfig])
    .controller('berthgroupMainController', require('./controllers/berthgroup-main'))
    .directive('berthgroupForm', require('./directives/berthgroup-form'))
    .directive('berthgroupInvoiceTypeForm', require('./directives/berthgroup-invoicetype-form'))
    .directive('berthgroupGeneralForm', require('./directives/berthgroup-general-form'))
    .directive('berthgroupOverview', require('./directives/berthgroup-overview'))
    .directive('berthgroupShiptypeForm', require('./directives/berthgroup-shiptype-form'))
    .directive('berthgroupBerthForm', require('./directives/berthgroup-berth-form'))
    .directive('berthgroupCommercialgoalForm', require('./directives/berthgroup-commercialgoal-form'))
    .directive('berthgroupNauticalgoalForm', require('./directives/berthgroup-nauticalgoal-form'))
    .factory('berthgroupDataService', require('./services/berthgroup-data'))
    .filter('berthgroupFunctionType', require('./filters/berthgroup-function-type'))
    .filter('berthFunctionName', require('./filters/berth-function-name'))
    .filter('berthGroupFunctionName', require('./filters/berthgroup-function-name'))
    //.factory('berthgroupModelsService', require('./services/berthgroup-models'))
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/berthgroup', {
            templateUrl: 'templates/berthgroup/controllers/berthgroup-main.html'
        })
        .when('/berthgroup/:berthgroupId', {
            templateUrl: 'templates/berthgroup/controllers/berthgroup-main.html'
        });
}