﻿module.exports = ['$rootScope', '$compile', 'toastr', 'common', 'constantsService', 'arrivalsModelService', 'arrivalsDataService', 'reservationDataService', 'widgetModals', 'fleetDataService', arrivalsListDirective];

function arrivalsListDirective($rootScope, $compile, toastr, common, constantsService, arrivalsModels, arrivalsDataService, reservationData, widgetModals, fleetDataService) {

    var directive = {
        bindToController: true,
        controller: arrivalsListController,
        controllerAs: 'ctrlArrivalsList',
        restrict: 'E',
        link: function (scope) {
            //console.log(scope);
            //scope.$watch(scope.ctrlArrivalsList.elm,
            //    function (newValue, oldValue) {
            //        console.log('watcher arrivals');
            //        console.log(scope.ctrlArrivalsList.elm );
            //    }
            //);      
        },
        scope: {
            arrivals: '=',
            berths: "=",
            berthGroups: "=",
            nauticalGoals: "=",
            realisationStatuses: "=",
            page: "=",
            permission: "="
        },
        templateUrl: 'templates/arrivals/directives/arrivals-list.html'
    };

    return directive;

    function arrivalsListController() {
        var ctrlArrivalsList = this;

        var vm = {
            ataChanged: ataChanged,
            deleteItem: deleteItem,
            updateArrival: updateArrival,
            addArrival: addArrival,
            sailedArrival: sailedArrival,
            approveArrival: approveArrival,
            deleteArrival: deleteArrival,
            getInvoice: getInvoice,
            isChanged: false,
            arrivals: this.arrivals,
            berths: this.berths,
            berthGroups: this.berthGroups,
            filterBerthGroups: filterBerthGroups,
            nauticalGoals: this.nauticalGoals,
            realisationStatuses: this.realisationStatuses,
            page: this.page,
            permission: this.permission,
            deepCopy: deepCopy,
            isFieldRequired: isFieldRequired,
            reduce: reduce,
            saveAndApprove: saveAndApprove,
            setStatus: setStatus,
            saveAndStatus: saveAndStatus,
            showSaveAndApproveButton: showSaveAndApproveButton,
            timePicker: timePicker,
            enterUCRN: enterUCRN,
            updateUCRN: updateUCRN,
            validateUCRN: validateUCRN
        };
        return vm;
        function timePicker(e, arrival) {
            //console.log('binding');
            //console.log(arrival);
            let btn = e.target;
            let elmnts = document.querySelectorAll('.show_time');
            //console.log(elmnts);
            if (elmnts.length > 0) {
                for (i = 0; i < elmnts.length; i++) {
                    if (elmnts[i].dataset.timeIsActive === 'true') {
                        //elmnts[i].dataset.timeIsActive = false;
                        //console.log(elmnts[i].dataset.timeIsActive);
                        elmnts[i].remove();
                    }
                }
            }
            var overlayScope = $rootScope.$new(false); // use true here for isolate scope, false to inherit from parent
            overlayScope.arrival = arrival;
            var newDir = ('<time-picker class="show_time" data-time-is-active="true" arrival-time="arrival.upcommingRealisation.eta" ng-click="$event.stopPropagation();"></time-picker>');
            angular.element(btn.firstElementChild).append($compile(newDir)(overlayScope));
        }
        function deepCopy(obj) {
            var b = JSON.parse(JSON.stringify(obj));
            return b;
        }

        function isFieldRequired(arrival, fieldName) {
            var flow = _.find(constantsService.processFlows, function (flow) { return flow.id === arrival.processFlowId; });

            return flow
                && flow.realisationConfiguration
                && flow.realisationConfiguration.req
                && flow.realisationConfiguration.req.length > 0
                && _.contains(flow.realisationConfiguration.req, fieldName);
        }

        function reduce(arr, id) {
            let newList = arr.reduce(function (array, item) {
                if (item.id !== id) {
                    array.push(item);
                }
                return array;
            }, []);

            vm.arrivals.all = newList;
        }
        function updateArrival(arrival) {
            arrivalsDataService.updateArrival(arrival)
                .then(successResult, errorResult);

            function errorResult(response) {
                //console.log('response');
                //console.log(response);
            }

            function successResult(result) {
                let upcomming = {};
                arrival.id = result.id;
                arrival.realisationItems = result.realisationItems;
                if (arrival.planningItems) {
                    let planningItems = _.sortBy(arrival.planningItems, function (p) { return p.eta; });
                }
                if (arrival.realisationItems) {
                    let realisationItems = _.sortBy(arrival.realisationItems, function (r) { return r.ata; });
                }
                arrival.realisationItems.forEach(function (item, i) {
                    if (item.atd === null) {
                        if (arrival.planningItems) {
                            if (arrival.planningItems[i]) {
                                let p = arrival.planningItems[i];
                                upcomming = deepCopy(p);
                            }

                        } else {
                            upcomming.etd = moment(item.ata).add(1, 'days').toISOString();
                        }
                        upcomming.step = 1;
                        upcomming.id = item.id;
                    } else {
                        if (arrival.planningItems) {
                            if (arrival.planningItems[i + 1]) {
                                let p = arrival.planningItems[i + 1];
                                upcomming = deepCopy(p);
                                upcomming.step = 0;
                            } else {
                                upcomming.berthGroupId = arrival.realisationItems[arrival.realisationItems.length - 1].berthGroupId;
                                upcomming.berthId = arrival.realisationItems[arrival.realisationItems.length - 1].berthId;
                                upcomming.eta = arrival.realisationItems[arrival.realisationItems.length - 1].atd;
                                upcomming.step = 2;
                            }
                        } else {
                            upcomming.berthGroupId = arrival.realisationItems[arrival.realisationItems.length - 1].berthGroupId;
                            upcomming.berthId = arrival.realisationItems[arrival.realisationItems.length - 1].berthId;
                            upcomming.eta = arrival.realisationItems[arrival.realisationItems.length - 1].atd;
                            upcomming.step = 2;
                        }
                        upcomming.id = arrival.realisationItems[arrival.realisationItems.length - 1].id;
                    }
                });
                arrival.upcommingRealisation = upcomming;
                arrival.upcommingRealisation.isSaving = false;
            }
        }
        function addArrival(arrival, step) {
            arrival.upcommingRealisation.isSaving = true;
            if (!arrival.upcommingRealisation.berthGroupId && step === 'ata') {
                toastr.error("Berth can't be empty");
                arrival.upcommingRealisation.step = 0;
                arrival.upcommingRealisation.isSaving = false;
                return;
            } else {
                let upcomming = {};
                if (arrival.realisationItems === null) {
                    arrival.realisationItems = [];
                    arrival.realisationItems.push({
                        activity: arrival.upcommingRealisation.activity,
                        id: arrival.upcommingRealisation.id,
                        realisationId: arrival.id,
                        ata: moment(arrival.upcommingRealisation.eta).format(),
                        atd: null,
                        berthGroupId: arrival.upcommingRealisation.berthGroupId
                    });
                    //if (arrival.planningItems[1]) {
                    //    upcomming.eta = moment(arrival.planningItems[1].eta).add(1, 'hours');
                    //    upcomming = arrival.planningItems[1];

                    //    arrival.upcommingRealisation = deepCopy(upcomming);
                    //}
                    if (arrival.planningItems) {
                        upcomming = arrival.planningItems[0];
                    }
                    arrival.upcommingRealisation = deepCopy(upcomming);
                    arrival.upcommingRealisation.step = 1;
                    vm.newArrival = {
                        approvedByUserId: arrival.approvedByUserId,
                        approvedOn: arrival.approvedOn,
                        customerId: arrival.customerId,
                        realisationItems: arrival.realisationItems,
                        reservationId: arrival.reservationId,
                        shipId: arrival.shipId,
                        status: arrival.status,
                        length: arrival.length,
                        customReference: arrival.customReference,
                        processFlowId: arrival.processFlowId
                    };
                    arrivalsDataService.addArrival(vm.newArrival)
                        .then(function (result) {
                            vm.showAddNew = false;
                            arrival.id = result.id;
                            arrival.upcommingRealisation.id = result.realisationItems[0].id;
                            arrival.realisationItems = result.realisationItems;
                            arrival.upcommingRealisation.isSaving = false;
                        });
                } else {
                    if (step === 'ata') {
                        arrival.realisationItems.push({
                            realisationId: arrival.id,
                            ata: moment(arrival.upcommingRealisation.eta).format(),
                            atd: null,
                            berthGroupId: arrival.upcommingRealisation.berthGroupId
                        });
                        arrival.upcommingRealisation.step = 1;
                    } else if (step === 'atd') {
                        if (moment(arrival.upcommingRealisation.etd).isSameOrAfter(arrival.realisationItems[arrival.realisationItems.length - 1].ata)) {
                            //update the view with the atd
                            arrival.realisationItems.forEach(function(item, i) {
                                if (item.id === arrival.upcommingRealisation.id) {
                                    item.atd = moment(arrival.upcommingRealisation.etd).format();
                                    item.realisationId = arrival.id;

                                    if (vm.isFieldRequired(arrival, 'Activity')
                                        && (!arrival.upcommingRealisation.activity || arrival.upcommingRealisation.activity === "")) {
                                        item.activity = "OTHER";
                                    } else {
                                        item.activity = arrival.upcommingRealisation.activity;
                                    }
                                }
                            });
                            if (arrival.planningItems) {
                                if (arrival.realisationItems.length >= arrival.planningItems.length) {
                                    arrival.upcommingRealisation.step = 2;
                                } else {
                                    arrival.upcommingRealisation.step = 0;
                                }
                            }
                        } else {
                            arrival.upcommingRealisation.step = 1;
                            arrival.upcommingRealisation.isSaving = false;
                            arrival.upcommingRealisation.etd = null;
                            toastr.error("Departure can't be earlier then the arriving date");
                            return;
                        }


                    }
                    vm.updateArrival(arrival);
                }
            }

            arrival.upcommingRealisation.isChanged = false;
        }
        function saveAndApprove(arrival) {
            arrival.upcommingRealisation.isSaving = true;
            let realisationItems = [];
            function checkDates() {
                let passCheck;
                if (arrival.planningItems) {
                    arrival.planningItems.forEach(function (p) {
                        let today = moment().format('YYYY-MM-DDTHH:mm:ss');
                        let plannedDate = moment(p.eta).format('YYYY-MM-DDTHH:mm:ss');
                        if (plannedDate > today) {
                            //console.log('in future');
                            passCheck = false;
                        } else {
                            //console.log('in past');
                            let realisationItem = {};
                            realisationItem.ata = p.eta;
                            realisationItem.atd = p.etd;
                            realisationItem.berthGroupId = p.berthGroupId;
                            realisationItem.realisationId = 0;
                            realisationItems.push(realisationItem);
                            passCheck = true;
                        }
                    });
                }
                return passCheck;
            }
            if (arrival.realisationItems === null) {
                let checkResult = checkDates();
                if (checkResult) {
                    vm.newArrival = {
                        approvedByUserId: arrival.approvedByUserId,
                        approvedOn: arrival.approvedOn,
                        customerId: arrival.customerId,
                        realisationItems: realisationItems,
                        reservationId: arrival.reservationId,
                        shipId: arrival.shipId,
                        status: arrival.status,
                        length: arrival.length,
                        customReference: arrival.customReference,
                        processFlowId: arrival.processFlowId
                    };
                    arrivalsDataService.addArrival(vm.newArrival)
                        .then(function (result) {
                            arrival.id = result.id;
                            arrival.realisationItems = result.realisationItems;
                            // change to approve
                            vm.approveArrival(arrival);
                        });
                } else {
                    arrival.upcommingRealisation.isSaving = false;
                }
            } else {
                // change to approve
                vm.approveArrival(arrival);
            }

        }
        function saveAndStatus(arrival, status) {
            arrival.upcommingRealisation.isSaving = true;
            let memoryStatus = arrival.status;
            if (arrival.newStatus) {
                arrival.status = arrival.newStatus;
            }
            vm.realisationStatuses.forEach(function (s) {
                if (s.id === arrival.status) {
                    arrival.statusName = s.name;
                }
            });
            if (status !== 2) {
                if (arrival.realisationItems && arrival.realisationItems.length >= 1 && (status === 4 || status === 5)) {
                    arrival.status = memoryStatus;
                    arrival.isSaving = false;
                    arrival.upcommingRealisation.isSaving = false;
                    arrival.hasDeparted = false;
                } else if (status === 5 || status === 6) {
                    widgetModals.remarksConfirmStatus({shipType: arrival.shipTypeId})
                        .result
                        .then(ok, canceled);
                }else{
                    ok(null);
                }

            } else {
                if (arrival.realisationItems === null && arrival.id === 0) {
                    vm.newArrival = {
                        approvedByUserId: arrival.approvedByUserId,
                        approvedOn: arrival.approvedOn,
                        customerId: arrival.customerId,
                        realisationItems: [], //realisationItems,
                        reservationId: arrival.reservationId,
                        remarks: arrival.remarks,
                        shipId: arrival.shipId,
                        status: status,
                        length: arrival.length,
                        customReference: arrival.customReference,
                        processFlowId: arrival.processFlowId
                    };
                    arrivalsDataService.addArrival(vm.newArrival)
                        .then(function (result) {
                            let newArr = result;
                            arrival.id = newArr.id;
                            newArr.upcommingRealisation = arrival.upcommingRealisation;
                            vm.setStatus(newArr);
                        });
                } else {
                    arrival.status = status;
                    vm.setStatus(arrival);
                }
            }
            function canceled(response) {
                if (response === 'cancel') {
                    arrival.status = memoryStatus;
                    arrival.newStatus = memoryStatus;
                    arrival.remarks = ['Please specify status change'];
                    arrival.upcommingRealisation.isSaving = false;
                }
            }
            function ok(result) {
                if (arrival.realisationItems === null && arrival.id === 0) {
                    vm.newArrival = {
                        approvedByUserId: arrival.approvedByUserId,
                        approvedOn: arrival.approvedOn,
                        customerId: arrival.customerId,
                        realisationItems: [], //realisationItems
                        reservationId: arrival.reservationId,
                        remarks: result ? result.remarks : arrival.remarks,
                        shipId: arrival.shipId,
                        status: status,
                        length: arrival.length,
                        customReference: arrival.customReference
                    };
                    arrivalsDataService.addArrival(vm.newArrival)
                        .then(function (result) {
                            let newArr = result;
                            arrival.id = newArr.id;
                            newArr.upcommingRealisation = arrival.upcommingRealisation;
                            vm.setStatus(newArr);
                        });
                } else {
                    vm.setStatus(arrival, result ? result.remarks : null);
                }
            }
        }

        function showSaveAndApproveButton(arrival) {
            var flow = _.find(constantsService.processFlows, function (flow) { return flow.id === arrival.processFlowId; });

            return !arrival.realisationItems
                && vm.page === 'arrivals'
                && arrival.upcommingRealisation.isSaving === false
                && arrival.inFuture === false
                && arrival.status === arrival.newStatus
                && arrival.status !== 7
                && arrival.status !== 6
                && arrival.status !== 5
                && arrival.status !== 4
                && arrival.status !== 3
                && arrival.status !== 2
                && arrival.planningItems
                && flow
                && flow.realisationConfiguration
                && flow.realisationConfiguration.ui_show_save_appr_btn;
        }

        function sailedArrival(arrival) {
            arrivalsDataService.sailedArrival(arrival)
                .then(function (result) {
                    let sailedItem = result;
                    let itemArr = _.find(vm.arrivals.all, function (i) { return i.id === sailedItem.id; });
                    itemArr.upcommingRealisation.step = 2;
                    itemArr.hasDeparted = true;
                    itemArr.upcommingRealisation.isSaving = false;
                    itemArr.newStatus = sailedItem.status;
                    itemArr.status = sailedItem.status;
                    itemArr.remarks = sailedItem.remarks;
                    if (vm.page === 'arrivals') {
                        if (sailedItem.status === 3) {
                            vm.reduce(vm.arrivals.all, itemArr.id);
                        }
                    }

                });
        }
        function approveArrival(arrival) {
            arrival.upcommingRealisation.isSaving = true;
            arrivalsDataService.approveArrival(arrival)
                .then(function (result) {
                    if (result.realisation) {
                        let approvedItem = result.realisation;
                        let itemArr = _.find(vm.arrivals.all, function (i) { return i.id === approvedItem.id; });
                        itemArr.upcommingRealisation.step = 2;
                        vm.reduce(vm.arrivals.all, approvedItem.id);
                        arrival.upcommingRealisation.isSaving = false;
                    } else if (result.errors) {
                        arrival.remarks = result.errors;
                        arrival.newStatus = arrival.status = 7;
                        arrival.isSaving = false;
                        arrival.upcommingRealisation.isSaving = false;
                        arrival.hasDeparted = false;
                    }
                });
        }
        function setStatus(arrival, newRemarks) {
            if (newRemarks && Array.isArray(newRemarks) && newRemarks.length > 0) {
                newRemarks = newRemarks[0];
            }
            let arrivalItem = arrival;
            arrivalsDataService.setStatus(arrival.id, { status: arrival.status, remarks: newRemarks ? newRemarks : null })
                .then(function (result) {
                    if (result.realisation) {
                        let arrivalNewStatus = result.realisation;
                        let itemArr = _.find(vm.arrivals.all, function (i) { return i.id === arrivalNewStatus.id; });
                        itemArr.upcommingRealisation.step = 2;
                        itemArr.remarks = arrival.remarks;
                        itemArr.approvedByUserId = arrival.approvedByUserId;
                        itemArr.approvedOn = arrival.approvedOn;
                        itemArr.customReference = arrival.customReference;
                        if (vm.page === 'arrivals') {
                            if (arrivalNewStatus.status > 2) {
                                vm.reduce(vm.arrivals.all, arrivalNewStatus.id);
                            } else {
                                arrival.hasDeparted = true;
                            }
                            if (arrivalNewStatus.approvedByUserId !== 0 && arrivalNewStatus.approvedOn !== null) {
                                vm.reduce(vm.arrivals.all, arrivalNewStatus.id);
                            }
                        }
                        arrival.upcommingRealisation.isSaving = false;
                    } else if (result.errors) {
                        let itemArr = _.find(vm.arrivals.all, function (i) { return i.id === arrival.id; });
                        itemArr.remarks = result.errors;
                        itemArr.status = itemArr.newStatus = 7;
                        itemArr.isSaving = false;
                        itemArr.upcommingRealisation.isSaving = false;
                        itemArr.hasDeparted = false;
                    }
                });

        }
        function deleteItem(arrivalItem, arrival) {
            arrival.upcommingRealisation.isSaving = true;
            arrivalsDataService.deleteItem(arrivalItem)
                .then(function (result) {
                    let removedItem = result;
                    if (arrival) {
                        arrival.realisationItems.forEach(function (item, i) {
                            if (item.id === removedItem.id) {
                                arrival.realisationItems.splice(i, 1);
                            }
                        });
                        vm.deleteArrival(arrival);
                    }
                    arrival.upcommingRealisation.isSaving = false;
                });
        }
        function deleteArrival(arrival) {
            if (arrival.realisationItems.length === 0) {
                arrivalsDataService.deleteArrival(arrival)
                    .then(function (result) {
                        let removedArrival = result;
                        let item = _.find(vm.arrivals.all, function (i) { return i.id === removedArrival.id; });
                        item.realisationItems = null;
                        item.id = 0;
                        let upcomming = item.planningItems ? item.planningItems[0] : {};
                        let berthGroupId = arrival.upcommingRealisation.berthGroupId;
                        item.upcommingRealisation = deepCopy(upcomming);
                        item.upcommingRealisation.berthGroupId = berthGroupId;
                        item.upcommingRealisation.step = 0;
                        item.remarks = null;
                        item.status = item.newStatus = 0;
                        item.upcommingRealisation.isSaving = false;
                    });
            }
        }
        function getInvoice(arrival) {
            // get invoice 
            arrivalsDataService.getInvoice(arrival)
                .then(function (result) {
                });


        }
        function ataChanged(newAta, obj) {
            // Used for current date and time 
            if (newAta === 0) {
                obj.eta = new Date();
            }
            if (newAta === 1) {
                obj.etd = new Date();
            }
            obj.isChanged = true;
        }

        function filterBerthGroups(upcommingRealisation, shipTypeId) {
            var arrivalItem = upcommingRealisation;

            return function (item) {
                var isSupport = _.indexOf(item.shipTypeIds, shipTypeId) > -1;
                if (arrivalItem !== undefined && arrivalItem !== null && arrivalItem.berthGroupId === item.id) {
                    if (isSupport === false) {
                        arrivalItem.berthGroupId = null; // reset the berthGroupId when this is not support
                    }
                }
                return isSupport;
            };
        }
        
        function enterUCRN(arrival) {

            widgetModals.freeTextConfirm({
                title: "UCRN",
                description: "Please enter a valid UCRN for this arrival",
                required: true,
                defaultValue: arrival.customReference,
                validation: arrivalsModels.validateUCRN
            })
                .result
                .then(function (result) {
                    if (result.value !== undefined && result.value !== null && result.value.length > 0) {
                        arrival.customReference = result.value;
                        vm.updateUCRN(arrival);
                    }
                }, function () { });
        }

        function updateUCRN(arrival) {
            if (arrival.realisationItems === null && arrival.id === 0) {
                vm.newArrival = {
                    customerId: arrival.customerId,
                    realisationItems: [], //realisationItems,
                    reservationId: arrival.reservationId,
                    shipId: arrival.shipId,
                    customReference: arrival.customReference,
                    processFlowId: arrival.processFlowId
                };
                arrivalsDataService.addArrival(vm.newArrival)
                    .then(function (result) {
                        if (result) {
                            arrival.id = result.id;
                            if (result.reservationId)
                                reservationData.updateUCRNByRealisation(result.reservationId);
                        }
                    });
            } else {
                arrivalsDataService.updateArrival(arrival)
                    .then(function (result) {
                        if (result && result.reservationId) {
                            reservationData.updateUCRNByRealisation(result.reservationId);
                        }
                    });
            }
        }

        function validateUCRN(arrival, needCheckStatus) {
            if (arrival === undefined || arrival === null)
                return false;

            // check only for shiptype seacruise and seagoing
            if (vm.isFieldRequired(arrival, 'CustomReference')) {

                if (needCheckStatus === false || (needCheckStatus === true && (arrival.newStatus === 2 || arrival.newStatus === 3))) {
                    return arrivalsModels.validateUCRN(arrival.customReference);
                }
            }

            return true;
        }
    }
}
