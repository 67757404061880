﻿module.exports = ['common', 'dataContext', arrivalsDataService];

function arrivalsDataService(common, context) {
    var service = {
        approveArrival: approveArrival,
        approveArrivalDefinitively: approveArrivalDefinitively,
        arrivalStatuses: [],
        //getArrivals: getArrivals,
        getToDoCounts: getToDoCounts,
        getToDos: getToDos,
        getStatuses: getStatuses,
        updateArrival: updateArrival,
        addArrival: addArrival,
        sailedArrival: sailedArrival,
        deleteItem: deleteItem,
        deleteArrival: deleteArrival,
        getToReview: getToReview,
        getToReviewByFlow: getToReviewByFlow,
        getToReviewForShip: getToReviewForShip,
        setStatus: setStatus,
        getInvoice: getInvoice,
        importArrivals: importArrivals
    };

    function getToDoCounts() {
        var deferred = common.$q.defer();

        var req = context.get('/arrivals/todo/counts')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getToDos(pageIndex, pageSize, searchQuery, businessUnit, flowIds) {
        var deferred = common.$q.defer();
        let args = [];

        if (pageIndex !== undefined && pageIndex !== null && pageSize) {
            args.push('pindex=' + pageIndex);
            args.push('psize=' + pageSize);
        }

        if (searchQuery && searchQuery !== '') {
            args.push('q=' + searchQuery);
        }
        if (businessUnit) args.push('businessUnit=' + businessUnit);

        if (flowIds && flowIds.length > 0)
            args.push('flows=' + flowIds.join(','));

        var req = context.get('/arrivals/todo?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function getToReview(pageIndex, pageSize, bu) {
        var deferred = common.$q.defer();
        let args = [];
        //var url = '/arrivals/review';

        if (pageIndex !== undefined && pageIndex !== null && pageSize) {
            args.push('psize=' + pageSize);
            args.push('pindex=' + pageIndex);
        }
        if (bu) args.push('businessUnit=' + bu);

        //url += '?pindex=' + pageIndex + '&psize=' + pageSize;

        var req = context.get('/arrivals/review?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getToReviewByFlow(flowId, pageIndex, pageSize) {
        var deferred = common.$q.defer();
        let args = [];

        if (pageIndex && pageSize) {
            args.push('psize=' + pageSize);
            args.push('pindex=' + pageIndex);
        }
        var url = '/arrivals/review/flow/' + flowId;

        var req = context.get(url + '?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getToReviewForShip(shipId) {
        var deferred = common.$q.defer();

        var url = '/arrivals/review/ship/' + shipId;

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function getStatuses() {
        var deferred = common.$q.defer();

        if (service.arrivalStatuses.length > 0)
            deferred.resolve(service.arrivalStatuses);
        else
            context.get('/arrivals/statuses')
                .then(function (data) {
                    let statuses = data;
                    let array = [];
                    for (let i = 0; i < statuses.length; i++) {
                        array.push({
                            id: i,
                            name: statuses[i]
                        });
                    }

                    array = _.map(array, function (item) {
                        item.show = true;
                        switch (item.name) {
                            case 'None':
                                item.name = 'No Status';
                                item.show = false;
                                break;
                            case 'Open':
                                item.show = false;
                                break;
                            case 'Invoiceable':
                                item.name = 'Ready to invoice';
                                break;
                            case 'ReviewRequested':
                                item.name = 'Request review';
                                break;
                            case 'IsCancelled':
                                item.name = 'Is cancelled';
                                break;
                            case 'IsExempt':
                                item.name = 'Is exempt';
                                break;
                            case 'IsNoShow':
                                item.name = 'Didn\'t show up';
                                break;

                        }

                        return item;
                    });

                    service.arrivalStatuses = array;
                    deferred.resolve(array);
                }, function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }
    function updateArrival(arrival) {
        var deferred = common.$q.defer();
        var req = context.put('/arrivals/' + arrival.id, arrival)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function addArrival(arrival) {
        var deferred = common.$q.defer();
        var req = context.post('/arrivals/', arrival)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function sailedArrival(arrival) {
        var deferred = common.$q.defer();
        var req = context.post('/arrivals/' + arrival.id + '/sailed/', arrival)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                //console.log(error);
                deferred.reject(error);
            });
        return deferred.promise;
    }
    function approveArrival(arrival) {
        var deferred = common.$q.defer();
        var req = context.post('/arrivals/' + arrival.id + '/approve/')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                //console.log(error);
                deferred.reject(error);
            });
        return deferred.promise;
    }
    function approveArrivalDefinitively(arrival, invoice) {
        var deferred = common.$q.defer();
        var req = context.post('/arrivals/' + arrival.id + '/approve/definitive', invoice)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                //console.log(error);
                deferred.reject(error);
            });
        return deferred.promise;
    }
    function setStatus(id, obj) {
        var deferred = common.$q.defer();
        var req = context.post('/arrivals/' + id + '/status/', obj)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                //console.log(error);
                deferred.reject(error);
            });
        return deferred.promise;
    }
    function deleteItem(realizationItem) {
        var deferred = common.$q.defer();

        var req = context.del('/arrivals/' + realizationItem.id + '/item')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function deleteArrival(arrival) {
        var deferred = common.$q.defer();

        var req = context.del('/arrivals/' + arrival.id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function getInvoice(arrival) {
        var deferred = common.$q.defer();

        var req = context.post('/arrivals/getinvoice/' + arrival.id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function importArrivals(file, needToFinalize) {
        var deferred = common.$q.defer();

        var args = [];
        if (needToFinalize !== undefined && needToFinalize !== null) args.push('needToFinalize=' + needToFinalize);

        var req = context.xhr('/arrivals/import?' + args.join('&'), file)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    return service;
}