﻿module.exports = ['goalDataService', 'widgetModals', 'constantsService', goalormDirective];

function goalormDirective() {
    var directive = {
        bindToController: true,
        controller: goalFormController,
        controllerAs: 'ctrlGoalForm',
        restrict: 'E',
        scope: {
            goalData: '=',
            onGoalSaved: '&',
            onGoalDeleted: '&'
        },
        link: goalFormLink,
        templateUrl: 'templates/goal/directives/goal-form.html'
    };

    return directive;

    function goalFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () {
            return ctrl.goalData;
        }, function (newValue, oldValue) {
            if (!newValue) return;
            var goalId = newValue.id || 0;

            ctrl.isNewGoal = goalId <= 0;
        }, true);
    }

    function goalFormController(goalDataService, widgetModals, constantsService) {
        var vm = {
            deleteGoal: deleteGoal,
            saveGoal: saveGoal,
            isNewGoal: true,
            businessUnits: constantsService.businessUnits
        }

        return vm;
        function saveGoal() {
            //var isNewGoal = !vm.berthData.id;

            goalDataService.saveGoal(vm.goalData)
            .then(successResult)
            .catch(errorResult);
            function errorResult(response) {
            }

            function successResult(result) {
                vm.onGoalSaved({ savedGoal: result, isNew: vm.isNewGoal });
            }
        }

        function deleteGoal() {
            widgetModals.areYouSure('You want to delete this goal?').result
                .then(function (result) {
                    if (result === true) {
                        goalDataService.deleteGoal(vm.goalData)
                        .then(successResult)
                        .catch(errorResult);
                    }
                });
            function errorResult(response) {
            }

            function successResult(result) {
                vm.onGoalDeleted({ deletedGoal: result });
            }
        }
    }

}
