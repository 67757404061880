﻿module.exports = ['identity','constantsService','customerAddressPreferencesDataService','customerAddressPreferencesModelsService', 'toastr', invoiceAddressesDirective];

function invoiceAddressesDirective(identity,constantsService,customerAddressPreferencesDataService,customerAddressPreferencesModels, toastr) {
    var directive = {
        bindToController: true,
        controller: invoiceAddressesController,
        controllerAs: 'ctrlInvoiceAddresses',
        link: function (scope) {
//            console.log('link called');
//            scope.ctrlFinancialInformation.loadInformation();
            scope.$watch('ctrlInvoiceAddresses.customer', scope.ctrlInvoiceAddresses.loadInformation);
        },
        restrict: 'E',
        scope: {
            customer: '=',
            vessels: '='
        },
        templateUrl: 'templates/customer/directives/financial/invoice-addresses.html'
    };

    return directive;

    function invoiceAddressesController() {
        var ctrlInvoiceAddresses = this;
        ctrlInvoiceAddresses.completedInvoiceAddresses = null;
        ctrlInvoiceAddresses.requestedInvoiceAddresses = null;
        ctrlInvoiceAddresses.selectedAddressPreference = null;
        ctrlInvoiceAddresses.debitorStatus = {
            saving:false,
            error:[],
            saved:false
        };
        ctrlInvoiceAddresses.isLoading = false;
        ctrlInvoiceAddresses.getObjectLabel = getObjectLabel;
        ctrlInvoiceAddresses.getRequestedLabel = getRequestedLabel;
        ctrlInvoiceAddresses.getAddressValueLabel = getAddressValueLabel;
        ctrlInvoiceAddresses.getTargetLabel = getTargetLabel;
        ctrlInvoiceAddresses.getAdressTypeLabel = getAdressTypeLabel;
        ctrlInvoiceAddresses.customer = this.customer;
        ctrlInvoiceAddresses.identity = identity;
        ctrlInvoiceAddresses.isNew = false;
        ctrlInvoiceAddresses.objectTypeSelect = [{name:"Customer", id:0}, {name:"Ship", id:1}];
        ctrlInvoiceAddresses.targetTypeSelect = [{name:"Default", id:0}, {name:"Invoice", id:1}];
        ctrlInvoiceAddresses.invoiceTypes = [];
        ctrlInvoiceAddresses.isSeaCruise = false;

        var invoiceAddresses = null;

        ctrlInvoiceAddresses.loadInformation = function () {
            ctrlInvoiceAddresses.isLoading = true;

            var bu = identity.getBusinessUnitsForPermission("debtor_info_admin");
            ctrlInvoiceAddresses.isSeaCruise = _.some(bu, function(item){return item == 3});

            if(!bu || bu === 0){
                ctrlInvoiceAddresses.invoiceTypes = constantsService.invoiceTypes;
            }else{
                ctrlInvoiceAddresses.invoiceTypes = constantsService.getInvoiceTypesBasedOnBusinessUnits(bu);
            }

            customerAddressPreferencesDataService.getAddressPreferences(ctrlInvoiceAddresses.customer.id)
                .then(function (response) {
                    invoiceAddresses = _.filter(response,function(x){ return x.addressType == 1});
                    filterInvoiceAddress();
                    ctrlInvoiceAddresses.isLoading = false;
                }, function (error) {
                    ctrlInvoiceAddresses.isLoading = false;
                });
        };

        function filterInvoiceAddress(){
            ctrlInvoiceAddresses.completedInvoiceAddresses = _.filter(invoiceAddresses,function(x){
                return isRequested(x.addressValue) == false;
            });

            ctrlInvoiceAddresses.requestedInvoiceAddresses = _.filter(invoiceAddresses,function(x){
                return isRequested(x.addressValue) == true;
            });
        }


        function isRequested(source){
            var stringSource = ""+source;
            return stringSource.indexOf('TEMPF')!==-1;
        }

        function getObjectLabel(type,id){
            if(type == 0)
                return "Customer";
            if(type == 1){
                var shiptext = '';
                if(ctrlInvoiceAddresses.vessels!==null &&ctrlInvoiceAddresses.vessels.length!==0){
                    shiptext = _.filter(ctrlInvoiceAddresses.vessels, function(x){ return x.id == id})[0].name;
                }
                return "Ship: " + shiptext;
            }
            return "";
        }

        function getRequestedLabel(value){
            if(value.indexOf('TEMPF')!==-1){
                return "Requested";
            }
            else
            {
                return "Completed";
            }
        }

        function getAddressValueLabel(value){
            if(value.indexOf('TEMPF')!==-1){
                return value.replace('TEMPF:',"Temp ");
            }
            else
            {
                return value;
            }
        }
        
        function getTargetLabel(type,id){
            if(type == 0)
                return "Default";
            if(type == 1){
                var flowtext = '';
                if(constantsService.invoiceTypes!==null &&constantsService.invoiceTypes!==0){
                    flowtext = _.filter(constantsService.invoiceTypes, function(x){ return x.id == id})[0].name;
                }
                return "Invoice: " + flowtext;
            }
            if(type == 2){
                var flowtext = '';
                if(constantsService.shipTypes!==null &&constantsService.shipTypes!==0){
                    flowtext = _.filter(constantsService.shipTypes, function(x){ return x.id == id})[0].name;
                }
                return "Ship type: " + flowtext;
            }
            return "";
        }

        function getAdressTypeLabel(type){
            if(type == 0){
                return "Email"
            }
            if(type == 1){
                return "Debitor Number"
            }
            return "";
        }

        ctrlInvoiceAddresses.selectAddressPreference= function (addressPreference){
            ctrlInvoiceAddresses.selectedAddressPreference = addressPreference;
            ctrlInvoiceAddresses.isNew = (addressPreference == null);
            if(ctrlInvoiceAddresses.isNew){
                ctrlInvoiceAddresses.selectedAddressPreference = customerAddressPreferencesModels.addressPreferencesModels();
                ctrlInvoiceAddresses.selectedAddressPreference.objectType = 0;
                ctrlInvoiceAddresses.selectedAddressPreference.targetType = 0;
                ctrlInvoiceAddresses.selectedAddressPreference.targetId = 0;
            }
            ctrlInvoiceAddresses.debitorStatus.saving = false;
            ctrlInvoiceAddresses.debitorStatus.saved = false;
            ctrlInvoiceAddresses.debitorStatus.error = [];
        }
        
        ctrlInvoiceAddresses.saveAddressPreference = function (){
            ctrlInvoiceAddresses.debitorStatus.saving = true;
            ctrlInvoiceAddresses.debitorStatus.saved = false;
            if(ctrlInvoiceAddresses.isNew){
                
                if(ctrlInvoiceAddresses.selectedAddressPreference.objectType === 0){
                    ctrlInvoiceAddresses.selectedAddressPreference.objectId = ctrlInvoiceAddresses.customer.id;
                }

                ctrlInvoiceAddresses.selectedAddressPreference.customerId = ctrlInvoiceAddresses.customer.id;
                ctrlInvoiceAddresses.selectedAddressPreference.addressType = 1;

                if(!ctrlInvoiceAddresses.selectedAddressPreference.targetType)
                    ctrlInvoiceAddresses.selectedAddressPreference.targetType = 0;

                if(!ctrlInvoiceAddresses.selectedAddressPreference.targetId)
                    ctrlInvoiceAddresses.selectedAddressPreference.targetId = 0;

                if(ctrlInvoiceAddresses.selectedAddressPreference.objectType === 1){
                    customerAddressPreferencesDataService.getBasedOnShip(ctrlInvoiceAddresses.selectedAddressPreference.objectId).then(function (result) {
                        if(result){
                            ctrlInvoiceAddresses.selectedAddressPreference.id = result.id;
                            addOrUpdateAddressPreference();
                        }
                    },errorSave);
                    return;
                }
            }
            addOrUpdateAddressPreference();
        }
        function addOrUpdateAddressPreference(){
            if(!ctrlInvoiceAddresses.selectedAddressPreference.id){
                customerAddressPreferencesDataService.addAddressPreference(ctrlInvoiceAddresses.selectedAddressPreference).then(succesSave, errorSave);
            }else{
                customerAddressPreferencesDataService.updateAddressPreference(ctrlInvoiceAddresses.selectedAddressPreference).then(succesSave, errorSave);
            }
        }

        function succesSave(result){
            ctrlInvoiceAddresses.debitorStatus.saved = true;
            ctrlInvoiceAddresses.debitorStatus.saving = false;
            ctrlInvoiceAddresses.loadInformation();
            if(ctrlInvoiceAddresses.isNew){
                ctrlInvoiceAddresses.close();
            }

        }
        function errorSave(error){
            ctrlInvoiceAddresses.debitorStatus.error.push(error);
            ctrlInvoiceAddresses.debitorStatus.saving = false;
        }

        ctrlInvoiceAddresses.isReadOnlyBasedOnRole = function(editRoles) {
            return !identity.hasPermission(editRoles);
        };

        ctrlInvoiceAddresses.close = function() {
            ctrlInvoiceAddresses.isNew = false;
            ctrlInvoiceAddresses.selectedAddressPreference = null;
        };

        ctrlInvoiceAddresses.disableSaveButton = function() {
            return ctrlInvoiceAddresses.debitorStatus.saving ||
                !ctrlInvoiceAddresses.selectedAddressPreference.addressValue ||
                (ctrlInvoiceAddresses.selectedAddressPreference.objectType == 1 &&
                    !ctrlInvoiceAddresses.selectedAddressPreference.objectId);
        };

        ctrlInvoiceAddresses.changeTargetType = function() {
            if (ctrlInvoiceAddresses.selectedAddressPreference.targetType == 0) {
                ctrlInvoiceAddresses.selectedAddressPreference.targetId = 0;
            } else if (ctrlInvoiceAddresses.selectedAddressPreference.targetType == 1) {
                ctrlInvoiceAddresses.selectedAddressPreference.targetId = 3;
            }
        };

        ctrlInvoiceAddresses.changeObjectType = function() {
            if (ctrlInvoiceAddresses.selectedAddressPreference.objectType == 0) {
                ctrlInvoiceAddresses.selectedAddressPreference.objectId = 0;
            } else if (ctrlInvoiceAddresses.selectedAddressPreference.objectType == 1) {
                ctrlInvoiceAddresses.selectedAddressPreference.objectId = ctrlInvoiceAddresses.vessels[0].id;
            }
        };

    };

};
