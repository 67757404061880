﻿module.exports = ['common', 'coreDataService','constantsService', 'reportDataService', realisationsPerBerthController];

function realisationsPerBerthController(common, coreDataService, constant, reportData) {
    var vm = {
        attached: attached,
        isLoading: false,
        filter: {
            dateFrom: null,
            dateUntil: null,
            selectedCustomer:null,
            selectedShipType:null,
            selectedRemark:null,
            sortAscending: true,
            sortField: 'eta',
            isEta: false,
            sort: sort,
            berthGroupId:null,
            berthId:null
        },
        dashboardData:[],
        dashboardTitle:'All Berths',
        onSelectDashBoard: onSelectDashBoard,
        realisations:[],
        reservations:[],
        revenueModels:[],
        invoices:[],
        customers:[],
        berthGroups:[],
        berths:[],
        shipTypes:[],
        getAmount:getAmount,
        getGrossAmount:getGrossAmount,
        getDiscountAmount:getDiscountAmount,
        exportToCSV:exportToCSV,
        getData:getData,
        getReservationStatus:getReservationStatus,
        sortOnFromTime:sortOnFromTime,
        sortOnUntilTime:sortOnUntilTime,
        showDetails: false
    };

    var selectedDashboarditem = null;
    return vm;

    function attached() {
        vm.filter.dateUntil = moment();
        vm.filter.dateFrom = moment().date(1).add(-1, 'days');

        load();
    }

    function load() {
        vm.isLoading = true;
        vm.dashboardData.push({name:'Number Of Reservations',data:null});
        vm.dashboardData.push({name:'Number Of Realisations',data:null});
        vm.dashboardData.push({name:'Number Of Cancellations',data:null});
        vm.dashboardData.push({name:'Number Of Exemptions',data:null});
        vm.dashboardData.push({name:'Gross Revenue Realised',data:null,type:'currency'});
        vm.dashboardData.push({name:'Discount Realised',data:null,type:'currency'});
        vm.dashboardData.push({name:'Nett Revenue Realised',data:null,type:'currency'});
        
        coreDataService.getBerthgroups().then(function (data) {
            vm.berthGroups = _.filter(data,function(x){return x.functionType == 1});
        });

        coreDataService.getBerths().then(function (data) {
            vm.berths = data;
        });

        coreDataService.getCustomers().then(function (data) {
            vm.customers = data;
        });

        vm.shipTypes = _.sortBy(constant.shipTypes,'name');

        getData();
    }

    function getData(remark){
        vm.isLoading = false;
        vm.dashboardData[0].data = null;
        vm.dashboardData[1].data = null;
        vm.dashboardData[2].data = null;
        reportData.getRealisationsPerBerth(
            moment(vm.filter.dateFrom).format('YYYY-MM-DD'),
            moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
            if(result){
                vm.dashboardData[0].data = result.numberOfReservations;
                vm.dashboardData[1].data = result.numberOfRealisations;
                vm.dashboardData[2].data = result.numberOfCancellations;
                vm.dashboardData[3].data = result.numberOfExemptions;
                vm.dashboardData[4].data = result.grossRevenueRealised;
                vm.dashboardData[5].data = result.discountRealised;
                vm.dashboardData[6].data = result.nettRevenueRealised;
            }
            vm.isLoading = true;
        });

        if(selectedDashboarditem){
            onSelectDashBoard(selectedDashboarditem);
        }
    }

    function onSelectDashBoard(item){
        vm.invoices = [];
        vm.realisations = [];
        vm.revenueModels = [];

        selectedDashboarditem = item;
        switch(item.name){
            case vm.dashboardData[0].name: 
            reportData.getReservationsOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                vm.revenueModels = _.filter(result,function(x){return x!=null});
            }); break;
            
            case vm.dashboardData[1].name: 
            reportData.getRealisationsOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                vm.revenueModels = _.filter(result,function(x){return x!=null});
            });
            break;
            
            case vm.dashboardData[2].name: 
            reportData.getCancelledInvoicesOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                vm.revenueModels = _.filter(result,function(x){return x!=null});
            }); break;
            case vm.dashboardData[3].name: 
            reportData.getExemptionsOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                vm.revenueModels = _.filter(result,function(x){return x!=null});
            }); break;
            case vm.dashboardData[4].name:
            reportData.getInvoicesOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                vm.revenueModels = _.filter(result,function(x){return x!=null});
                vm.revenueModels = _.each(vm.revenueModels,function(x){ x.invoice.invoiceItems = _.filter(x.invoice.invoiceItems,function(c){ return c.totalAmount > 0})});
            }); break;
            case vm.dashboardData[5].name: 
            reportData.getDiscountInvoicesOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                vm.revenueModels = _.filter(result,function(x){return x!=null});
            }); break;
            case vm.dashboardData[6].name:
            reportData.getInvoicesOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                vm.revenueModels = _.filter(result,function(x){return x!=null});
            }); break;
        }
    }

    function getAmount(invoice){
        var sum = 0;
        if(invoice){
            for(i=0;i<invoice.invoiceItems.length;i++){
                sum += invoice.invoiceItems[i].totalAmount;
            }
            invoice.totalAmount = sum;
        }
        return Math.round(sum*100)/100;
    }
    
    function getGrossAmount(invoice){
        var sum = 0;
        if(invoice){
            for(i=0;i<invoice.invoiceItems.length;i++){
                if(invoice.invoiceItems[i].totalAmount>0)
                    sum += invoice.invoiceItems[i].totalAmount;
            }
            invoice.grossAmount = sum;
        }
        return Math.round(sum*100)/100;
    }
    
    function getDiscountAmount(invoice){
        var sum = 0;
        if(invoice){
            for(i=0;i<invoice.invoiceItems.length;i++){
                if(invoice.invoiceItems[i].totalAmount<0)
                    sum += invoice.invoiceItems[i].totalAmount;
            }
            invoice.discountAmount = sum;
        }
        return Math.round(sum*100)/100;
    }

    function sort(sortField) {
        if (vm.filter.sortField === sortField) {
            vm.filter.sortAscending = !vm.filter.sortAscending;
        } else {
            vm.filter.sortField = sortField;
            vm.filter.sortAscending = true;
        }
    }

    function getReservationStatus(reservation){
        if(reservation!=null&&reservation.reservationStatus){
            var status;
            if(reservation.reservationStatus.isCancelled){
                status = 'Cancelled'
            }
            if(reservation.reservationStatus.isChangedButNotConfirmed){
                status = 'Changed'
            }
            if(reservation.reservationStatus.isPlanningCompleted){
                status = 'Planned'
            }
            if(reservation.reservationStatus.isRejected){
                status = 'Rejected'
            }
            if(reservation.reservationStatus.isTransferred){
                status = 'Transferred'
            }
            reservation.status = status;
            return status;
        }
    }
    
    function sortOnFromTime(model)
    {
        model.forEach(function(a){
            a.fromTime = (a.ata?a.ata:a.eta)
        });
        sort('fromTime');
    }

    function sortOnUntilTime(model)
    {
        model.forEach(function(a){
            a.untilTime = (a.atd?a.atd:a.etd)
        });
        sort('untilTime');
    }

    function exportToCSV(){
        exportReservationsToCSV();
        exportRealisationToCSV();
        exportRevenueCancelledToCSV();
        exportExemptionsToCSV();
        exportGrossRevenueToCSV();
        exportDiscountToCSV();
        exportRevenueToCSV();
    }

    function exportReservationsToCSV(){
        reportData.getReservationsOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                var csvOutput = revenueModelCSVOutput(result);

                common.exportToCSV(csvOutput,'report-reservations-per-berth-'+ moment().format('YYYYMMDD'));
            });
    }

    function exportRealisationToCSV(){
        reportData.getRealisationsOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                var csvOutput = revenueModelCSVOutput(result);

                common.exportToCSV(csvOutput,'report-realisations-per-berth-'+ moment().format('YYYYMMDD'));
            });
    }

    function exportRevenueToCSV(){
        reportData.getInvoicesOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                var csvOutput = revenueModelCSVOutput(result);

                common.exportToCSV(csvOutput,'report-netto-revenue-per-berth-'+ moment().format('YYYYMMDD'));
            });
    }

    function exportGrossRevenueToCSV(){
        reportData.getInvoicesOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                result = _.each(vm.invoices,function(x){ x.invoiceItems = _.filter(x.invoiceItems,function(c){ return c.totalAmount > 0})});
                var csvOutput = revenueModelCSVOutput(result);

                common.exportToCSV(csvOutput,'report-gross-revenue-per-berth-'+ moment().format('YYYYMMDD'));
            });
    }

    function exportRevenueCancelledToCSV(){
        reportData.getCancelledInvoicesOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                var csvOutput = revenueModelCSVOutput(result);

                common.exportToCSV(csvOutput,'report-cancellations-per-berth-'+ moment().format('YYYYMMDD'));
            });
    }
    
    function exportDiscountToCSV(){
        reportData.getDiscountInvoicesOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                var csvOutput = revenueModelCSVOutput(result);

                common.exportToCSV(csvOutput,'report-discount-per-berth-'+ moment().format('YYYYMMDD'));
            });
    }

    function exportExemptionsToCSV(){
        reportData.getExemptionsOfRealisationsPerBerth(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateUntil).format('YYYY-MM-DD'),
            vm.filter.isEta?'eta':'ata',
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            vm.filter.berthId,
            vm.filter.berthGroupId).then(function(result){
                var csvOutput = revenueModelCSVOutput(result);

                common.exportToCSV(csvOutput,'report-exemptions-per-berth-'+ moment().format('YYYYMMDD'));
            });
    }

    function revenueModelCSVOutput(result){
        var csvOutput = 'ED NUMBER;CUSTOMER;SHIP;FROM;UNTIL;STATUS;GROSS TURNOVER;DISCOUNT;REMARK;NETTO TURNOVER;DESCRIPTION;COST CENTRE;UNITARY TARRIF;QUANTITY;AMOUNT;\r\n';
        for (var i = 0; i < result.length; i++) {
            var model = result[i];
            var reservation = model.reservation;
            var invoice = model.invoice;
            var length = 0;
            if(invoice){
                length = invoice.invoiceItems.length;
            }
            length = (length<=0)?1:length;
            for (var j = 0; j < length; j++) {
                var invoiceItem = null;
                if(invoice)
                    invoiceItem = invoice.invoiceItems[j];
                csvOutput +=
                reservation.reservationReference + ';' +
                reservation.customer.name + ';' +
                reservation.ship.name + ';' +
                (model.ata==null?model.eta:model.ata) + ';' +
                (model.atd==null?model.etd:model.atd) + ';' +
                getReservationStatus(reservation) + ';' +
                getGrossAmount(invoice) + ';' +
                getDiscountAmount(invoice) + ';' +
                model.remark + ';' +
                getAmount(invoice) + ';' +
                (invoiceItem? invoiceItem.description : '') + ';' +
                (invoiceItem? invoiceItem.costCentre : '') + ';' +
                (invoiceItem? invoiceItem.unitaryTariff : '') + ';' +
                (invoiceItem? invoiceItem.quantity : '') + ';' +
                (invoiceItem? invoiceItem.totalAmount  : '') + '\r\n';
            }
        }
        return csvOutput;
    }
}