﻿angular
    .module('poa.rprp.widgets', [])
    // Controllers
    .controller('freeTextConfirmCtrl', require('./modals/free-text-confirmation'))
    .controller('remarksConfirmStatusCtrl', require('./modals/remarks-confirmation'))
    .controller('processFlowPickerCtrl', require('./modals/process-flow-picker'))
    .controller('timePickerCtr', require('./modals/time-picker'))
    .controller('splitReservationDateCtrl', require('./modals/split-reservation-date'))
    // Directives
    .directive('datePeriodPicker', require('./directives/date-period-picker'))
    .directive('rprpNavbar', require('./directives/navbar'))
    .directive('rprpNavbarUserProfile', require('./directives/navbar-user-profile'))
    .directive('thSortableHeader', require('./directives/th-sortable-header'))
    .directive('timePicker', require('./modals/time-picker'))
    // Modals
    .factory('widgetModals', require('./modals/modals'))
;