﻿'use strict';
module.exports = ['berthDataService', 'berthgroupDataService', berthgroupBerthFormDirective];

function berthgroupBerthFormDirective(berthDataService, berthgroupDataService) {
    var directive = {
        controller: berthgroupBerthFormController,
        controllerAs: 'ctrlBGBF',
        restrict: 'E',
        scope: {
            berthgroupId: '@'
        },
        bindToController: true,
        link: berthgroupBerthFormLink,
        templateUrl: 'templates/berthgroup/directives/berthgroup-berth-form.html'
    };
    return directive;

    function berthgroupBerthFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () { return ctrl.berthgroupId; },
            function (newValue, oldValue) {
                if (!newValue) return;

                ctrl.getBerthgroupBerths();
            });
    }

    function berthgroupBerthFormController() {
        var vm = {
            berths: [],
            berthgroupBerths: [],
            selectedBerthgroupBerthIds: [],
            getBerthgroupBerths: getBerthgroupBerths,
            save: save
        };

        init();

        return vm;

        function init() {
            berthDataService.getBerths().then(function (data) {
                vm.berths = data;
            });
        }
        function save() {
            var berthgroupId = parseInt(vm.berthgroupId) || 0;
            var berthIdsToDel = [];
            var berthIdsToAdd = vm.selectedBerthgroupBerthIds;

            if (vm.berthgroupBerths.length > 0) {

                berthIdsToDel = vm.berthgroupBerths.filter(function (item) {
                    return vm.selectedBerthgroupBerthIds.indexOf(item.id) === -1;
                });
                berthIdsToDel = _.map(berthIdsToDel, function (item) { return item.id });

                berthIdsToAdd = vm.selectedBerthgroupBerthIds.filter(function (itemId) {
                    var orgIds = _.map(vm.berthgroupBerths, function (item) { return item.id });
                    return orgIds.indexOf(itemId) === -1;
                });
            }

            if (berthgroupId > 0 && (berthIdsToDel.length > 0 || berthIdsToAdd.length > 0)) {
                berthgroupDataService.updateBerthgroupBerths(berthgroupId, berthIdsToAdd, berthIdsToDel)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {                   
                }

                function successResult(result) {
                    vm.berthgroupBerths = result;
                }
            }
        }

        function getBerthgroupBerths() {
            var berthgroupId = parseInt(vm.berthgroupId) || 0;
            if (berthgroupId > 0) {
                berthgroupDataService.getBerthgroupBerths(berthgroupId)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
                }

                function successResult(result) {
                    vm.berthgroupBerths = result;
                    vm.selectedBerthgroupBerthIds = _.map(vm.berthgroupBerths, function (item) { return item.id });
                }
            }
        }
    }
}