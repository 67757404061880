﻿module.exports = ['$rootScope', '$uibModalInstance', 'common', 'dataContext', 'modalArguments', splitReservationDateCtrl];

function splitReservationDateCtrl($rootScope, $uibModalInstance, common, context, args) {
    if(!args) {
        args = {
            startsOnDisplay: null,
            endsOnDisplay: null
        };
    }

    var vm = {
        startsOnDisplay: args.startsOnDisplay,
        endsOnDisplay: args.endsOnDisplay,
        splitDateDisplay: moment(args.startsOnDisplay, 'DD-MM-YYYY HH:mm').add(
            moment(args.endsOnDisplay, 'DD-MM-YYYY HH:mm').diff(
                moment(args.startsOnDisplay, 'DD-MM-YYYY HH:mm')
            ) / 2
        ).format('DD-MM-YYYY HH:mm'),
        ok: ok,
        cancel: cancel
    };

    function cancel() {
        $uibModalInstance.close(null);
    }

    function ok() {
        $uibModalInstance.close(vm.splitDateDisplay);
    }

    return vm;
}
