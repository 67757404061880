﻿module.exports = ['constantsService', berthFunctionNameFilter];

function berthFunctionNameFilter(constantsService) {
    return function (berthFunction) {
        if (isNaN(berthFunction))
            berthFunction = parseInt(berthFunction);

        var functionType = _.find(constantsService.berths, function (item) {
            return item.id === berthFunction;
        });

        return functionType ? functionType.name : berthFunction;

    };

}