﻿'use strict';

angular.module('poa.rprp.goal', [])
    .config(['$routeProvider', routeConfig])
    .controller('goalMainController', require('./controllers/goal-main'))
    .controller('nauticalGoalCtrl', require('./controllers/goal-nautical'))
    .controller('commercialGoalCtrl', require('./controllers/goal-commercial'))
    .directive('goalForm', require('./directives/goal-form'))
    .directive('goalOverview', require('./directives/goal-overview'))
    .factory('goalDataService', require('./services/goal-data'))
    .factory('goalModelsService', require('./services/goal-models'))
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/goals/nautical', {
            templateUrl: 'templates/goal/controllers/goal-nautical.html'
        })
        .when('/goals/commercial', {
            templateUrl: 'templates/goal/controllers/goal-commercial.html'
        });
}