﻿'use strict';

module.exports = ['common', 'dataContext', 'tariffModelsService', tariffDataService];

function tariffDataService(common, context, tariffModels) {

    var vm = {
        getTariffs: getTariffs,
        getTariffsByRealisationId: getTariffsByRealisationId,
        getTariffGroups: getTariffGroups,
        getTariffRules: getTariffRules,
        getTariffPendings: getTariffPendings,
        deleteTariff: deleteTariff,
        deleteTariffGroup: deleteTariffGroup,
        saveTariff: saveTariff,
        saveTariffGroup: saveTariffGroup,
        canDeleteTariffGroup: canDeleteTariffGroup,
        approveTariff: approveTariff,
        getApproveTariff:getApproveTariff
    };

    return vm;

    function getTariffs(invoiceType, date) {
        var deferred = common.$q.defer();

        var args = [];
        if (invoiceType)
            args.push('invoiceType=' + invoiceType);
        if (date)
            args.push('date=' + date);

        var url = "/tariff";
        if (args.length > 0)
            url += '?' + args.join('&');

        var req = context.get(url)
            .then(function (data) {
                var result = _.map(data, tariffModels.tariffModel);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getTariffsByRealisationId(realisationId, invoiceType) {
        var deferred = common.$q.defer();
        
        var url = "/tariff/realisation/" + realisationId;
        if (invoiceType)
            url += '?invoiceType=' + invoiceType;

        var req = context.get(url)
            .then(function (data) {
                var result = _.map(data, tariffModels.tariffModel);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getTariffGroups() {
        var deferred = common.$q.defer();
        var url = "/tariff/group";
        var req = context.get(url)
            .then(function (data) {
                var result = _.map(data, tariffModels.tariffGroupModel);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getTariffRules() {
        var deferred = common.$q.defer();
        var url = "/tariff/rules";
        var req = context.get(url)
            .then(function (data) {
                var result = data;
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    
    function getTariffPendings(){
        var deferred = common.$q.defer();
        var url = '/tariff/pending';
        var req = context.get(url)
            .then(function (data) {
                var result = _.map(data, tariffModels.tariffPendingModel);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveTariff(tariff) {
        var deferred = common.$q.defer();
        var url = '/tariff';
        if (tariff.id) url = url + '/' + tariff.id;

        var req = context.post(url, tariff)
            .then(function (data) {
                var result = new tariffModels.tariffModel(data);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function approveTariff(tariff) {
        var deferred = common.$q.defer();
        var url = '/tariff/approve';
        if (tariff.id) url = url + '/' + tariff.id;

        var req = context.post(url, tariff)
            .then(function (data) {
                var result = new tariffModels.tariffModel(data);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    
    function getApproveTariff(tariff) {
        var deferred = common.$q.defer();
        var url = '/tariff/approve';
        if (tariff.id) url = url + '/' + tariff.id;

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveTariffGroup(tariffGroup) {
        var deferred = common.$q.defer();
        var url = '/tariff/group';
        if (tariffGroup.id) url = url + '/' + tariffGroup.id;

        var req = context.post(url, tariffGroup)
            .then(function (data) {
                var result = new tariffModels.tariffGroupModel(data);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteTariff(tariff) {
        var deferred = common.$q.defer();
        var url = '/tariff/' + tariff.id;
        var req = context.del(url)
            .then(function (data) {
                var result = new tariffModels.tariffModel(data);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function canDeleteTariffGroup(tariffGroupId) {
        var deferred = common.$q.defer();
        var url = '/tariff/group/allowed/' + tariffGroupId;
        var req = context.del(url)
            .then(function (data) {
                var result = data;
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteTariffGroup(tariffGroup) {
        var deferred = common.$q.defer();
        var url = '/tariff/group/' + tariffGroup.id;
        var req = context.del(url)
            .then(function (data) {
                var result = new tariffModels.tariffGroupModel(data);
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}