﻿﻿module.exports = ['common', 'constantsService', 'sailingReportDataService', 'sailingReportModals', 'toastr', 'widgetModals', touristTaxReviewController];

function touristTaxReviewController(common, constantsService, sailingReportData, sailingReportModals, toastr, widgetModals) {

    var vm = {
        attached: attached,
        createInvoice: createInvoice,
        display: {
            isFiltered: isFiltered,
            shipType: 1,
            textFilter: null
        },
        items: [],
        resetConfirmation: resetConfirmation,
        selectedItem: null,
        setPenalty: setPenalty,
        status: {
            isLoading: false
        }
    };

    function attached() {
        reload(true);
    }

    function createInvoice(sailingReport) {
        vm.selectedItem = sailingReport;

        widgetModals.areYouSure('You want to create an invoice for this sailing report?')
            .result
            .then(function (result) {
                if (result === true) {
                    sailingReportData.invoice(vm.selectedItem.id, vm.selectedItem, vm.selectedItem.hasPenalty)
                        .then(function (invoiceResult) {
                            vm.selectedItem = null;
                            reload(true);
                        },
                        function (error) {
                            vm.selectedItem = null;
                            toastr.error(error);
                        });
                } else {
                    vm.selectedItem = null;
                }
            }, function () {
                vm.selectedItem = null;
            });
    }

    function isFiltered(item) {
        if (vm.display.shipType && vm.display.shipType > 0 && item.shipTypeId !== vm.display.shipType)
            return false;

        if (!vm.display.textFilter || vm.display.textFilter === '' || vm.display.length <= 2)
            return true;

        if (item.shipName.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
            return true;

        return false;
    }

    function loadReports() {
        vm.status.isLoading = true;

        sailingReportData.getIssueReports(0, 500)
            .then(function (result) {
                vm.items = _.map(result, function (obj) {
                    obj.displayDate = obj.realisedATD !== null ? obj.realisedATD : (obj.plannedETD !== null ? obj.plannedETD : obj.reservationETD);
                    obj.displayType = obj.realisedATD !== null ? "A" : (obj.plannedETD !== null ? "P" : "R");
                    return obj;
                });
                vm.status.isLoading = false;
            }, function (error) {
                vm.status.isLoading = false;
            });
    }

    function reload(clearAll) {

        if (clearAll && clearAll === true) {
            vm.items = [];
        }
        loadReports();
    }

    function setPenalty(sailingReport) {
        vm.selectedItem = sailingReport;

        widgetModals.remarksConfirmStatus({
            title: "Send to legal",
            required: false,
            invoiceTypes: _.where(constantsService.invoiceTypes, function (i) { return i.systemCode.indexOf("TOURIST_TAX_") !== -1; })
            })
            .result
            .then(function(result) {
                    var remarks = null;
                    if (result.remarks !== undefined && result.remarks !== null && result.remarks.length > 0) {
                        remarks = result.remarks[0];
                    }
                    sailingReportData.addPenalty(vm.selectedItem.id, remarks)
                        .then(function(result) {
                                vm.selectedItem = null;
                                reload(true);
                            },
                            function(error) {
                                vm.selectedItem = null;
                                toastr.error(error);
                            });
                },
                function() {
                    vm.selectedItem = null;
                });
    }

    function resetConfirmation(sailingReport) {
        vm.selectedItem = sailingReport;
        widgetModals.areYouSure('You want to reset the confirmation of this item?').result
            .then(function (result) {
                if (result === true) {
                    sailingReportData.resetConfirmation(sailingReport.id)
                        .then(function (result) {
                            vm.selectedItem = null;
                            reload(true);
                        },
                            function (error) {
                                vm.selectedItem = null;
                                toastr.error(error);
                            });
                }
            },
            function () {
                vm.selectedItem = null;
            });
    }

    return vm;

}