﻿module.exports = ['coreDataService', 'berthgroupDataService', 'widgetModals', berthgroupFormDirective];

function berthgroupFormDirective(coreDataService, berthgroupDataService, widgetModals) {
    var directive = {
        bindToController: true,
        controller: berthgroupFormController,
        controllerAs: 'ctrlBGF',
        restrict: 'E',
        scope: {
            berthgroupData: '=',
            onBerthgroupSaved: '&',
            onBerthgroupDeleted: '&'
        },
        link: berthgroupFormLink,
        templateUrl: 'templates/berthgroup/directives/berthgroup-form.html'
    };

    return directive;

    function berthgroupFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () {
            return ctrl.berthgroupData;
        }, function (newValue, oldValue) {
            if (!newValue) return;

            ctrl.gerneralInfoActive = true;
            ctrl.isNewBerthgroup = newValue.id <= 0;
        }, true);
    }

    function berthgroupFormController() {
        var vm = {
            gerneralInfoActive: true,
            deleteBerthgroup: deleteBerthgroup,
            saveBerthgroup: saveBerthgroup,
            isNewBerthgroup: true
        };

        return vm;

        function saveBerthgroup() {
            var isNewBerthgroup = !vm.berthgroupData.id;

            berthgroupDataService.saveBerthgroup(vm.berthgroupData)
            .then(successResult)
            .catch(errorResult);
            function errorResult(response) {
            }

            function successResult(result) {
                vm.onBerthgroupSaved({ savedBerthgroup: result, isNew: isNewBerthgroup });
            }
        }

        function deleteBerthgroup() {
            widgetModals.areYouSure('You want to delete this berth-group?').result
                .then(function (result) {
                    if (result === true) {
                        berthgroupDataService.deleteBerthgroup(vm.berthgroupData)
                        .then(successResult)
                        .catch(errorResult);
                    }
                });
            function errorResult(response) {
            }

            function successResult(result) {
                vm.onBerthgroupDeleted({ deletedBerthgroup: result });
            }
        }
    }

}
