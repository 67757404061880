﻿angular.module('poa.rprp.dashboard', [])
    .config(['$routeProvider', routeConfig])
    .controller('dashboardController', require('./controllers/dashboard'))
    .controller('dashboardControllerNew', require('./controllers/dashboard-new'))
    .factory('dashboardStatusDataService', require('./services/status-data'));

function routeConfig($routeProvider) {
    $routeProvider
        .when('/dashboard', {
            templateUrl: 'templates/dashboard/controllers/dashboard-new.html'
        });
};