﻿module.exports = ['keyControlsDataService', keyControlsInvoiceController];

function keyControlsInvoiceController(keyControlsDataService) {

    var vm = {
        attached: attached,
        isLoading: false,
        items: [],
        filter: {
            dateFrom: null,
            dateTo: null,
        },
        load: load
    };

    function attached() {
        vm.filter.dateFrom = moment().add(-1, 'months');
        vm.filter.dateTo = moment();

        load();
    }

    function load() {
        vm.isLoading = true;

        keyControlsDataService.getInvoices(vm.filter.dateFrom.format('YYYY-MM-DD'), vm.filter.dateTo.format('YYYY-MM-DD'))
            .then(function (result) {
                vm.items.result;
                vm.isLoading = false;
            });
    }

    return vm;
}
