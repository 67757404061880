﻿module.exports = ['tariffDataService', 'tariffModelsService', 'widgetModals', tariffGroupOverviewDirective];

function tariffGroupOverviewDirective(tariffDataService, tariffModels, widgetModals) {
    var directive = {
        bindToController: true,
        controller: tariffGroupOverviewController,
        controllerAs: 'ctrlTariffGroupOverview',
        restrict: 'E',
        scope: {
            activeTariffGroup: '=',
            tariffGroupsData: '='
        },
        templateUrl: 'templates/tariff/directives/tariff-group/tariff-group-overview.html'
    };

    return directive;

    function tariffGroupOverviewController() {
        var vm = {
            setActiveTariffGroup: setActiveTariffGroup,
            selectedLink: selectedLink,
            currentlySelected: false
        }

        return vm;

        function setActiveTariffGroup(tariffGroup) {
            //if new clicked!!!

            if (!tariffGroup) {
                tariffGroup = new tariffModels.tariffGroupModel();
            }

            tariffDataService.canDeleteTariffGroup(tariffGroup.id).then(function (data) {
                tariffGroup.canDelete = data;
            });

            vm.activeTariffGroup = tariffGroup;
        }

        function selectedLink(id) {
            if (vm.activeTariffGroup) {
                if (parseInt(id) === parseInt(vm.activeTariffGroup.id)) {
                    vm.currentlySelected = true;
                    return vm.currentlySelected;
                }
            }
        }
    }
}
