﻿module.exports = ['reservationDataService', 'messageDataService', messagesBoxDirective];

function messagesBoxDirective(reservationData, messageData) {
    var directive = {
        bindToController: true,
        controller: messagesBoxController,
        controllerAs: 'ctrlMessagesBox',
        restrict: 'E',
        scope: {
            messages: '=',
            reservationId: '=',
            customerId: '=',
            isCustomerMessage: '='
        },
        templateUrl: 'templates/customer/directives/message/messages-box.html'
    };

    return directive;

    function messagesBoxController() {
        var vm = {
            messages: this.messages,
            reservationId: this.reservationId,
            customerId: this.customerId,
            getItem: getItem
        };

        return vm;

        function getItem() {

            if (vm.isCustomerMessage) {
                messageData.getCustomerMessagesForCustomer(vm.customerId)
                    .then(function (results) {
                        vm.messages = results;
                    });
            } else {
                reservationData.getMessagesForCustomer(vm.reservationId, vm.customerId)
                    .then(function (results) {
                        vm.messages = results;
                    });
            }
        }
    }

}
