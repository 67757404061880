﻿module.exports = ['$rootScope', '$uibModalInstance', 'common', 'dataContext', 'modalArguments', freeTextConfirmCtrl];

function freeTextConfirmCtrl($rootScope, $uibModalInstance, common, context, args) {

    if (!args)
        args = {};

    var vm = {
        cancel: cancel,
        args: args,
        ok: ok,
        title: 'Please enter a value',
        textValue: null,
        valid: false,
        validate: validate
    };

    function validate() {

        if (vm.textValue.length === 0 || !vm.textValue.trim()) {
            vm.valid = false;
        } else {
            if (!vm.args.validation)
                vm.valid = true;
            else
                eval("vm.valid = vm.args.validation(vm.textValue);");
        }
    }
    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function ok() {
        args.value = vm.textValue;
        $uibModalInstance.close(args);
    }

    return vm;

}