﻿'use strict';

module.exports = ['$location', 'messageDataService', 'reservationDataService', '$routeParams', messageController];

function messageController($location, messageDataService, reservationData, $routeParams) {
    var vm = {
        attached: attached,
        getInbox: getInbox,
        getItem: getItem,
        goToPlanning: goToPlanning,
        messages: [],
        formatStatus: formatStatus,
        isLoading: false,
        messageSent: messageSent,
        selectedConversationId: null,
        planningPagePath: null,
        selectedLink: selectedLink,
        onSelect: onSelect,
        inbox: {
            data: [],
            canLoadMore: true,
            loadMore: loadMore,
            messageFilter: null,
            pageIndex: 0,
            pageSize: 20
        }
    };

    return vm;

    function attached() {
        getInbox();
    }

    function loadMore() {
        if (!vm.inbox.canLoadMore)
            return;

        vm.inbox.pageIndex += 1;
        getInbox();
    }

    function formatStatus(status) {
        switch (status) {
            case 0:
                return 'New';
            case 1:
                return 'sent by customer';
            case 2:
                return 'sent by port authority';
            case 3:
                return 'sent by system';
            case 4:
                return 'done';
            case 255:
                return 'archived';
        }

        return status;
    }

    function getInbox() {
        vm.isLoading = true;
        messageDataService.getMessageInbox(vm.inbox.pageSize, vm.inbox.pageIndex, vm.inbox.messageFilter)
            .then(function (results) {
                results = _.map(results, function (result) {
                    result.reservation = null;

                    if (result.objects && result.objects.length > 0) {
                        const reservation = _.find(result.objects, function (object) {
                            return object.objectType === 'reservation';
                        });
                        if (reservation) {
                            result.reservation = reservation.objectValue;
                        }
                    }

                    return result;
                });

                // TODO: refresh if the message should not be visible anymore
                if (vm.inbox.pageIndex === 0) {
                    vm.inbox.data = results;
                } else {
                    vm.inbox.data = vm.inbox.data.concat(results);
                }
                //countMessages(vm.inbox);
                vm.isLoading = false;
                vm.inbox.canLoadMore = results.length !== 0;
            }, function () {
                vm.isLoading = false;
            });
    }

    function getItem() {
        if (!vm.selectedConversationId) {
            vm.selectedConversationId = isNaN($routeParams.conversationId) ? null : parseInt($routeParams.conversationId);
        }
        vm.messages = [];

        if (vm.selectedConversationId !== null) {
            messageDataService.getConversationMessages(vm.selectedConversationId)
                .then(function (result) {
                    vm.messages = result.messages;

                    if (result.objects && result.objects.length > 0) {
                        const reservation = _.find(result.objects, function (object) {
                            return object.objectType === 'reservation';
                        });
                        if (reservation) {
                            result.reservation = reservation.objectValue;
                            vm.planningPagePath = '/planning/' + result.reservation.id;
                        }
                    }
                });
        }
    }

    function messageSent(result) {
        var conversation = _.find(vm.inbox.data, function (m) { return m.id === result.conversationId; });

        //if (conversation && conversation.status !== result.status) {
            vm.selectedConversationId = null;
            vm.planningPagePath = null;
            getInbox();
        //} else {
        //    getItem();
        //}
    }

    function selectedLink(item) {
        if (vm.selectedConversationId !== null) {
            return item.id === vm.selectedConversationId;
        }

        return false;
    }

    function onSelect(item) {
        vm.selectedConversationId = item.id;
        vm.planningPagePath = '/planning/' + item.reservation.id;
        $location.path('/messages/' + item.id, false);
        getItem();
    }

    function goToPlanning() {
        if (vm.planningPagePath !== null)
            $location.path(vm.planningPagePath);
    }

    //function navigateToReservation(id) {
    //    $location.path('/planning/', false);
    //}
};