﻿'use strict';
module.exports = ['common', '$location', '$routeParams', 'constantsService', 'planningDataService', 'berthgroupDataService', 'arrivalsDataService', 'arrivalsModelService', reviewRealisationController];

function reviewRealisationController(common, $location, $routeParams, constantsService, planningDataService, berthgroupDataService, arrivalsDataService, arrivalsModelService) {

    var vm = {
        getToReview: getToReview,
        attached: attached,
        getStatuses: getStatuses,
        newArrival: null,
        showAddNew: false,
        arrivals: {
            all: [],
            newAdded: null
        },
        page: 'toreview',
        realisationStatuses: [],
        berthGroups: [],
        berths: [],
        getBerthGroups: getBerthGroups,
        status: {
            completed: false,
            errors: [],
            loading:false,
            saving: false,
            setAddNew: setAddNew,
            showAddNew: false
        },
        searchquery: null,
        getBySearch: getBySearch,
        updateSearch: updateSearch,
        deepCopy: deepCopy,
        checkPermission: checkPermission,
        permission: false,
        datePickerOptions: {
            altInputFormats: [
                'd-M-yy',
                'd-M-yyyy',
                'd-M-yy H:mm',
                'd-M-yyyy H:mm',
                'd-M-yy HH:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yy',
                'dd-MM-yyyy',
                'dd-MM-yy H:mm',
                'dd-MM-yyyy H:mm',
                'dd-MM-yy HH:mm',
                'dd-MM-yyyy HH:mm'
            ]
        },
    };
    return vm;


    function deepCopy(obj) {
        var b = JSON.parse(JSON.stringify(obj));
        return b;
    }
    function checkPermission() {
        let result = common.identity.hasPermission('invoice_admin');
        vm.permission = result;
    }
    function attached() {
        vm.getBerthGroups();
        vm.checkPermission();

    }
    function getBerthGroups() {
        constantsService.preloaded.then(function () {
            vm.berthGroups = constantsService.berthGroups;
            if (vm.berthGroups.length > 0) {
                vm.getStatuses();
                vm.getToReview();
            } else {
            }
        });
    }
    function getStatuses() {
        arrivalsDataService.getStatuses()
            .then(function (result) {
                vm.realisationStatuses = _.filter(result, function (item) {
                    return item.show === true;
                });
            });
    }
    function getToReview() {
        vm.status.loading = true;

        arrivalsDataService.getToReview()
            .then(function (result) {
                let allArrivals = result;
                vm.arrivals.init = result;
                allArrivals.forEach(function (arrival) {
                    let upcomming = {};
                    let newItemDetails = {};
                    let planningItems = _.sortBy(arrival.planningItems, function (p) { return p.eta; });
                    let realisationItems = _.sortBy(arrival.realisationItems, function (r) { return r.atd; });
                    if (arrival.realisationItems === null || arrival.realisationItems === []) {
                        upcomming = deepCopy(planningItems[0]);
                        upcomming.step = 0;
                    } else {
                        for (let i = 0; i <= arrival.realisationItems.length; i++) {
                            if (arrival.realisationItems[i]) {
                                if (arrival.realisationItems[i].atd === null) {
                                    if (arrival.realisationItems.length >= planningItems.length) {
                                        newItemDetails = arrival.realisationItems[i];
                                        newItemDetails.etd = moment(arrival.realisationItems[i].ata).add(1, 'days');
                                    } else {
                                        if (planningItems[i]) {
                                            newItemDetails = planningItems[i];
                                        }
                                    }
                                    newItemDetails.step = 1;
                                    newItemDetails.id = arrival.realisationItems[i].id;
                                    break;
                                } else {
                                    if (arrival.realisationItems.length >= planningItems.length) {
                                        newItemDetails = realisationItems[realisationItems.length - 1];
                                        newItemDetails.step = 2;
                                        newItemDetails.eta = realisationItems[realisationItems.length - 1].atd;

                                    } else {
                                        if (planningItems[i + 1]) {
                                            newItemDetails = planningItems[i + 1];
                                            newItemDetails.step = 0;
                                        }
                                    }
                                }
                            }
                        }
                        upcomming = deepCopy(newItemDetails);
                    }
                    upcomming.isChanged = false;
                    upcomming.isSaving = false;
                    upcomming.arrivalId = arrival.id;
                    arrival.newStatus = arrival.status;
                    arrival.showRemarks = false;
                    if (arrival.status === 2) {
                        arrival.hasDeparted = true;
                    } else {
                        arrival.hasDeparted = false;
                    }
                    arrival.upcommingRealisation = upcomming;
                });
                vm.arrivals.all = allArrivals;
                vm.status.loading = false;
            });
    }
    function updateSearch() {
        if (vm.searchquery !== null && vm.searchquery !== undefined
            && vm.searchquery.trim() === '')
            return vm.getBySearch(null);
    }
    function getBySearch(string) {
        if (string !== null) {
            let searchResult = vm.arrivals.init.reduce(function (array, item) {
                let ship = item.shipName.toLowerCase();
                let newString = string.toLowerCase();
                if (ship.indexOf(newString) !== -1) {
                    array.push(item);
                }
                return array;
            }, []);
            vm.arrivals.all = searchResult;
        }
    }


    function setAddNew() {
        vm.editingArrival = null;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = true;
    }

}
