﻿module.exports = ['customerDataService', 'customerAddressPreferencesDataService', 'customerAddressPreferencesModelsService', 'fleetDataService', 'fleetModelService', 'common', 'toastr', 'widgetModals', fleetDetailsDirective];

function fleetDetailsDirective(customerDataService, customerAddressPreferencesDataService, customerAddressPreferencesModels, fleetData, fleetModels, common, toastr, widgetModals) {
    var directive = {
        bindToController: true,
        controller: fleetDetailsController,
        controllerAs: 'ctrlFleetDetails',
        restrict: 'E',
        scope: {
            vessel: '=',
            status: '=',
            vessels: '=',
            customer: '=',
            nav: '=',
            financial: "="

        },
        link: function (scope) {
            scope.$watch('ctrlFleetDetails.vessel', function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    scope.ctrlFleetDetails.setEdit(newValue);
                }
            });
        },
        templateUrl: 'templates/customer/directives/fleet/fleet-details.html'
    };

    return directive;

    function fleetDetailsController() {
        var vm = {
            vessel: this.vessel,
            vessels: this.vessels,
            status: this.status,
            setAddNew: setAddNew,
            setEdit: setEdit,
            getDescription: getDescription,
            customer: this.customer,
            saveShip: saveShip,
            deleteShip: deleteShip,
            addShip: addShip,
            newVessel: new fleetModels.vesselModel(),
            sendFinancial: sendFinancial,
            invoiceAddresses:[],
            invoiceAddress:null
        };

        return vm;
        function addShip() {
            vm.status.errors = [];

            var validation = fleetModels.validateVessel(vm.newVessel);
            if (validation !== true) {
                vm.status.errors = validation;
                return;
            }

            _.each(vm.newVessel.shipProperties, function (property) {
                property.startsOn = moment(property.startsOn).format("YYYY-MM-DDTHH:mm:ss");

                if (property.endsOn !== undefined && property.endsOn !== null)
                    property.endsOn = moment(property.endsOn).format("YYYY-MM-DDTHH:mm:ss");
            });
            vm.newVessel.customerId = vm.customer.id;

            fleetData.addShip(vm.newVessel.customerId, vm.newVessel)
                .then(function (result) {
                    vm.newVessel = new fleetModels.vesselModel();
                    vm.status.showAddNew = false;
                    vm.vessels.push(result);

                    if (vm.invoiceAddress) {
                        var newAddressPreference = customerAddressPreferencesModels.addressPreferencesModels(vm.invoiceAddress);

                        newAddressPreference.customerId = result.customerId;
                        newAddressPreference.objectType = 1;
                        newAddressPreference.objectId = result.id;
                        customerAddressPreferencesDataService.addAddressPreference(newAddressPreference);
                    }
                });
        }

        function deleteShip(ship) {
            widgetModals.areYouSure('Are you sure that you want to delete this vessel from your fleet?').result
                .then(function (result) {
                    if (result === true) {
                        fleetData.deleteShip(vm.vessel.customerId, vm.vessel)
                            .then(
                                function (response) {
                                    var iVessel = _.findIndex(vm.vessels, function (item) { return item.id === vm.vessel.id; });
                                    vm.vessels.splice(iVessel, 1);
                                    vm.vessel = null;
                                },
                                function (response, status, headers, config) {
                                    toastr.clear();
                                    toastr.error('Can\'t delete vessel with reservation.');
                                }
                            );
                    }
                });
        }

        function saveShip() {
            vm.status.errors = [];

            var validation = fleetModels.validateVessel(vm.vessel);
            if (validation !== true) {
                vm.status.errors = validation;
                return;
            }

            vm.vessel.shipPortAuthority = null;
            _.each(vm.vessel.shipProperties, function (property) {
                property.startsOn = moment(property.startsOn).format("YYYY-MM-DDTHH:mm:ss");

                if (property.endsOn !== undefined && property.endsOn !== null)
                    property.endsOn = moment(property.endsOn).format("YYYY-MM-DDTHH:mm:ss");
            });

            vm.status.saving = true;
            fleetData.saveShip(vm.vessel.customerId, vm.vessel)
                .then(function () {
                    customerAddressPreferencesDataService.getBasedOnShip(vm.vessel.id).then(function (result) {
                        if (!vm.invoiceAddress && result) {
                            customerAddressPreferencesDataService.deleteAddressPreference(result)
                                .then(successResult, errorResult);
                        }
                        else if (vm.invoiceAddress) { // save only when user selected an address
                            var newAddressPreference = customerAddressPreferencesModels.addressPreferencesModels(vm.invoiceAddress);
                            if (result !== null)
                                newAddressPreference.id = result.id;

                            newAddressPreference.customerId = vm.vessel.customerId;
                            newAddressPreference.objectType = 1;
                            newAddressPreference.objectId = vm.vessel.id;
                            if (result === null) {
                                customerAddressPreferencesDataService.addAddressPreference(newAddressPreference)
                                    .then(successResult, errorResult);
                            } else {
                                customerAddressPreferencesDataService.updateAddressPreference(newAddressPreference)
                                    .then(successResult, errorResult);
                            }
                        } else {
                            successResult();
                        }
                    }, errorResult);
                }, errorResult);

            function errorResult(response) {
                vm.status.errors = common.errorToText(response);
                vm.status.saving = false;
            }

            function successResult(result) {
                vm.status.completed = true;
                vm.status.saving = false;
            }
        }

        function sendFinancial() {
            customerDataService.sendFinancialData(vm.financial)
                .then(function (result) {
                    toastr.success('The details have been sent by email');
                });
        }

        function setAddNew() {
            vm.status.errors = [];
            vm.status.completed = false;
            vm.status.showAddNew = true;
            vm.vessel = null;
            vm.invoiceAddress = null;
            
            customerAddressPreferencesDataService.getAddressPreferences(vm.customer.id).then(function (result) {
                vm.invoiceAddresses = _.filter(result,function(x){ return x.addressType === 1 && !isRequested(x.addressValue)});
            });
        }

        function setEdit(ship) {

            customerAddressPreferencesDataService.getAddressPreferences(ship.customerId).then(function (result) {
                vm.invoiceAddresses = _.filter(result, function (x) { return x.addressType === 1 && !isRequested(x.addressValue); });

                customerAddressPreferencesDataService.getBasedOnShip(ship.id).then(function (result) {
                    vm.invoiceAddress = result;
                    vm.status.errors = [];
                    vm.status.completed = false;
                    vm.status.showAddNew = false;
                    vm.vessel = ship;
                }, requestError);
            }, requestError);
            
            function requestError(error) {
                vm.status.errors = error;
                vm.status.completed = false;
                vm.status.showAddNew = false;
                vm.vessel = ship;
            }
        }

        function getDescription(vessel) {
            var result = [];
            if (vessel === null || vessel === 'undefined') return null;

            if (vessel.length && vessel.length > 0 && vessel.width && vessel.width > 0) {
                result.push(vessel.length + 'm x ' + vessel.width + 'm');
            } else if (vessel.length && vessel.length > 0) {
                result.push('length ' + vessel.length + 'm');

            } else if (vessel.width && vessel.width > 0) {
                result.push('width ' + vessel.width + 'm');
            }

            if (vessel.paxCapacity !== null && typeof (vessel.paxCapacity) === 'number')
                result.push('# PAX ' + vessel.paxCapacity);

            if (vessel.remarks !== null && vessel.remarks !== 'undefined' && vessel.remarks.trim() !== '') {
                result.push(vessel.remarks.trim());
            }

            return result.join(', ');
        }

        function isRequested(source){
            var stringSource = ""+source;
            return stringSource.indexOf('TEMPF')!==-1;
        }

    }

}
