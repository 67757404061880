﻿module.exports = ['$routeParams', 'arrivalsDataService', 'common', 'invoiceDataService', 'invoiceModelsService', 'planningDataService', 'widgetModals', arrivalsReviewGroupedController];

function arrivalsReviewGroupedController($routeParams, arrivalsDataService, common, invoiceDataService, invoiceModelsService, planningDataService, widgetModals) {

    var vm = {
        arrivals: {
            count: null,
            data: [],
            selectedItems: []
        },
        attached: attached,
        changeCheckboxes: changeCheckboxes,
        checkAll: false,
        formatItemDates: formatItemDates,
        noInvoice: noInvoice,
        sendBack: sendBack,
        sendInvoice: sendInvoice,
        sendVoiceWithoutBHG: sendVoiceWithoutBHG,
        status: {
            isLoading: false,
            indexToProcess: -1
        }
    };

    function attached() {
        loadArrivals();
    }

    function changeCheckboxes() {
        for (var i = 0; i < vm.arrivals.data.length; i++) {
            vm.arrivals.data[i].isChecked = vm.checkAll;
        }
    }

    function clear() {
        vm.arrivals.selectedItems = [];
        vm.status.isLoading = false;
    }

    function formatItemDates(items) {
        if (!items || items.length === 0)
            return null;

        if (items[0].eta) {
            return {
                eta: items[0].eta,
                etd: items[items.length - 1].etd
            };
        }
        else if (items[0].ata) {
            return {
                eta: items[0].ata,
                etd: items[items.length - 1].atd
            };
        }

        return null;
    }

    function loadArrivals() {
        var deferred = common.$q.defer();

        vm.status.isLoading = true;

        arrivalsDataService.getToReviewForShip($routeParams.shipId)
            .then(function (result) {
                vm.arrivals.count = result.count;
                vm.arrivals.data = _.map(result.items, function (item) {
                    item.isChecked = false;
                    return item;
                });
                vm.status.isLoading = false;

                deferred.resolve();
            }, function (error) {
                vm.status.isLoading = false;
                deferred.reject();
            });

        return deferred.promise;
    }

    function noInvoice() {
        var shipTypeId = null;
        if (vm.arrivals.data && vm.arrivals.data.length > 0)
            shipTypeId = vm.arrivals.data[0].shipTypeId;

        widgetModals.remarksConfirmStatus({shipType: shipTypeId}).result
            .then(function (result) {
                if (result.remarks) {
                    vm.status.indexToProcess = vm.arrivals.data.length - 1;
                    nextNoInvoice(result.remarks[0]);
                }
            });
    }

    function nextNoInvoice(remark) {
        var i = vm.status.indexToProcess;
        if (i === -1) {
            loadArrivals();
            return;
        }

        if (vm.arrivals.data[i].isChecked) {
            arrivalsDataService.setStatus(vm.arrivals.data[i].id, {
                remarks: remark,
                status: 6
            })
                .then(function (invoiceResult) {
                    arrivalsDataService.approveArrival(vm.arrivals.data[i])
                        .then(function (approval) {
                            vm.status.indexToProcess--;
                            nextNoInvoice(remark);
                        });
                });
        } else {
            vm.status.indexToProcess--;
            nextNoInvoice(remark);
        }
    }

    function sendBack() {
        var shipTypeId = null;
        if (vm.arrivals.data && vm.arrivals.data.length > 0)
            shipTypeId = vm.arrivals.data[0].shipTypeId;

        widgetModals.remarksConfirmStatus({shipType: shipTypeId}).result
            .then(function (result) {
                if (result.remarks) {
                    vm.status.indexToProcess = vm.arrivals.data.length - 1;
                    nextSendBack(result.remarks[0]);
                }
            });
    }

    function nextSendBack(remark) {
        var i = vm.status.indexToProcess;
        if (i === -1) {
            loadArrivals();
            return;
        }

        if (vm.arrivals.data[i].isChecked) {
            arrivalsDataService.setStatus(vm.arrivals.data[i].id, {
                remarks: 'CM: ' + remark,
                status: 7
            })
                .then(function (arrivalResult) {
                    vm.status.indexToProcess--;
                    nextSendBack(remark);
                });
        } else {
            vm.status.indexToProcess--;
            nextSendBack(remark);
        }
    }

    function sendInvoice() {
        vm.status.indexToProcess = vm.arrivals.data.length - 1;
        nextSendInvoice();
    }

    function nextSendInvoice() {
        var i = vm.status.indexToProcess;
        if (i === -1) {
            loadArrivals();
            return;
        }

        if (vm.arrivals.data[i].isChecked) {
            invoiceDataService.getPreview(vm.arrivals.data[i].id)
                .then(function (invoice) {
                    invoice = invoice.invoice;
                    invoice.customerId = vm.arrivals.data[i].customerId;
                    arrivalsDataService.approveArrivalDefinitively(vm.arrivals.data[i], invoice)
                        .then(function (approvedArrival) {
                            vm.status.indexToProcess--;
                            nextSendInvoice();
                        });

                }, function (error) {
                    vm.status.indexToProcess--;
                    nextSendInvoice();
                });
        } else {
            vm.status.indexToProcess--;
            nextSendInvoice();
        }
    }

    function sendVoiceWithoutBHG() {
        vm.status.indexToProcess = vm.arrivals.data.length - 1;
        nextSendInvoiceWithoutBHG();
    }

    function nextSendInvoiceWithoutBHG() {
        var i = vm.status.indexToProcess;
        if (i === -1) {
            loadArrivals();
            return;
        }

        if (vm.arrivals.data[i].isChecked) {
            invoiceDataService.getPreview(vm.arrivals.data[i].id)
                .then(function (invoice) {
                    invoice = invoice.invoice;
                    invoice.customerId = vm.arrivals.data[i].customerId;

                    invoice.invoiceItems = _.filter(invoice.invoiceItems, function (item) { return item.externalItemCode.indexOf('BHG') === -1; });

                    arrivalsDataService.approveArrivalDefinitively(vm.arrivals.data[i], invoice)
                        .then(function (approvedArrival) {
                            vm.status.indexToProcess--;
                            nextSendInvoiceWithoutBHG();
                        });

                }, function (error) {
                    vm.status.indexToProcess--;
                    nextSendInvoiceWithoutBHG();
                });
        } else {
            vm.status.indexToProcess--;
            nextSendInvoiceWithoutBHG();
        }
    }

    return vm;
}
