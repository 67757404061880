﻿'use strict';

module.exports = ['reportDataService', numberOfReservationsPerYearDirective];

function numberOfReservationsPerYearDirective(reportData) {
    var directive = {
        bindToController: true,
        controller: numberOfReservationsPerYearController,
        controllerAs: 'ctrlNORPY',
        restrict: 'E',
        scope: {
        },
        templateUrl: 'templates/report/directives/number-of-reservations-per-year.html'
    };

    return directive;

    function numberOfReservationsPerYearController() {
        var vm = {
            labels: [],
            data: [],
            options: null
        }

        init();

        return vm;

        function init() {
            loadNumberOfReservationPerYear();
        }

        function loadNumberOfReservationPerYear() {
            reportData.getNumberOfReservationsPerYear()
            .then(successResult)
            .catch(errorResult);
            function errorResult(response) {
            }

            function successResult(result) {
                vm.labels = _.map(result, function (item) { return item.label });
                vm.data = _.map(result, function (item) { return item.dataPoint });
            }
        }
    }
}