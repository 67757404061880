﻿angular.module('poa.rprp', [
    // Angular modules
    'ngCookies',
    'ngRoute',
    'ngSanitize',
    // 3rd party angular modules
    'luegg.directives',
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'ui.bootstrap',
    'ui.select',
    'toastr',
    'chart.js',
    'ui.tinymce',
    // PortOfAmsterdam modules
    'poa.rprp.core',
    'poa.rprp.account',
    'poa.rprp.arrivals',
    'poa.rprp.audit',
    'poa.rprp.berth',
    'poa.rprp.berthgroup',
    'poa.rprp.businessrule',
    'poa.rprp.customer',
    'poa.rprp.dashboard',
    'poa.rprp.externalitemcode',
    'poa.rprp.facility',
    'poa.rprp.fleet',
    'poa.rprp.goal',
    'poa.rprp.invoices',
    'poa.rprp.invoiceremarks',
    'poa.rprp.messages',
    'poa.rprp.planning',
    'poa.rprp.region',
    'poa.rprp.sailingReport',
    'poa.rprp.search',
    'poa.rprp.tariff',
    'poa.rprp.users',
    'poa.rprp.widgets',
    'poa.rprp.report',
    'poa.rprp.security'
]);

// require all internal modules
require('./config');
require('./core/_module');
require('./account/_module');
require('./berth/_module');
require('./berthgroup/_module');
require('./businessrule/_module');
require('./customer/_module');
require('./dashboard/_module');
require('./external-item-code/_module');
require('./facility/_module');
require('./fleet/_module');
require('./invoices/_module');
require('./invoice-remarks/_module');
require('./messages/_module');
require('./planning/_module');
require('./region/_module');
require('./search/_module');
require('./users/_module');
require('./widgets/_module');
require('./goal/_module');
require('./arrivals/_module');
require('./audit/_module');
require('./tariff/_module');
require('./report/_module');
require('./sailing-report/_module');
require('./security/_module');
