﻿'use strict';
module.exports = ['coreDataService', 'berthgroupDataService', berthgroupCommercialGoalFormDirective];

function berthgroupCommercialGoalFormDirective(coreDataService, berthgroupDataService) {
    var directive = {
        controller: berthgroupCommercialGoalFormController,
        controllerAs: 'ctrlBGCGF',
        restrict: 'E',
        scope: {
            berthgroupId: '@'
        },
        bindToController: true,
        link: berthgroupCommercialGoalFormLink,
        templateUrl: 'templates/berthgroup/directives/berthgroup-commercialgoal-form.html'
    };
    return directive;

    function berthgroupCommercialGoalFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () { return ctrl.berthgroupId; },
            function (newValue, oldValue) {
                if (!newValue) return;

                ctrl.getBerthgroupCommercialgoals();
            });
    }

    function berthgroupCommercialGoalFormController() {
        var vm = {
            commercialgoals: [],
            berthgroupCommercialgoals: [],
            selectedBerthgroupCommercialgoalIds: [],
            getBerthgroupCommercialgoals: getBerthgroupCommercialgoals,
            save: save
        };

        init();

        return vm;

        function init() {
            coreDataService.getCommercialgoals().then(function (data) {
                vm.commercialgoals = data;
            });
        }

        function save() {
            var berthgroupId = parseInt(vm.berthgroupId) || 0;
            var commercialgoalIdsToAdd = vm.selectedBerthgroupCommercialgoalIds;
            var commercialgoalIdsToDel = [];

            if (vm.berthgroupCommercialgoals.length > 0) {

                commercialgoalIdsToDel = vm.berthgroupCommercialgoals.filter(function (item) {
                    return vm.selectedBerthgroupCommercialgoalIds.indexOf(item.id) === -1;
                });
                commercialgoalIdsToDel = _.map(commercialgoalIdsToDel, function (item) { return item.id });

                commercialgoalIdsToAdd = vm.selectedBerthgroupCommercialgoalIds.filter(function (itemId) {
                    var orgIds = _.map(vm.berthgroupCommercialgoals, function (item) { return item.id });
                    return orgIds.indexOf(itemId) === -1;
                });
            }

            if (berthgroupId > 0 && (commercialgoalIdsToDel.length > 0 || commercialgoalIdsToAdd.length > 0)) {
                berthgroupDataService.updateBerthgroupCommercialgoals(berthgroupId, commercialgoalIdsToAdd, commercialgoalIdsToDel)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {               
                }

                function successResult(result) {
                    vm.berthgroupCommercialgoals = result;
                }
            }
        }

        function getBerthgroupCommercialgoals() {
            var berthgroupId = parseInt(vm.berthgroupId) || 0;
            if (berthgroupId > 0) {
                berthgroupDataService.getBerthgroupCommercialgoals(berthgroupId)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
                }

                function successResult(result) {
                    vm.berthgroupCommercialgoals = result;
                    vm.selectedBerthgroupCommercialgoalIds = _.map(vm.berthgroupCommercialgoals, function (item) { return item.id });
                }
            }
        }
    }
}
