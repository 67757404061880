﻿module.exports = [invoiceRemarksModelsService];
function invoiceRemarksModelsService() {

    var service = {
        invoiceRemarksModel: invoiceRemarksModel
    };

    return service;


    function invoiceRemarksModel(invoiceRemarksData) {
        var invoiceRemarksModel = {
            id: null,
            text: null,
            systemCode: null,
            invoiceType: null,
            active: null
        };

        if (invoiceRemarksData === undefined || invoiceRemarksData === null)
            return invoiceRemarksModel;

        invoiceRemarksModel.id = invoiceRemarksData.id;
        invoiceRemarksModel.text = invoiceRemarksData.text;
        invoiceRemarksModel.systemCode = invoiceRemarksData.systemCode;
        invoiceRemarksModel.invoiceType = invoiceRemarksData.invoiceType;
        invoiceRemarksModel.active = invoiceRemarksData.active;

        return invoiceRemarksModel;
    }

}