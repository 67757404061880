﻿module.exports = ['$uibModal', widgetModalsService];

function widgetModalsService($uibModal) {

    var service = {
        areYouSure: areYouSure, 
        freeTextConfirm: freeTextConfirm,
        remarksConfirmStatus: remarksConfirmStatus,
        processFlowPicker: processFlowPicker,
        splitReservationDate: splitReservationDate
    };

    return service;

    function freeTextConfirm(args) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/free-text-confirmation.html',
            controllerAs: 'ctrlFreeTextConfirmation',
            controller: 'freeTextConfirmCtrl',
            resolve: {
                modalArguments: function () {
                    return args;
                }
            },
            backdrop: 'static'
        });
    }

    function remarksConfirmStatus(args) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/remarks-confirmation.html',
            controllerAs: 'ctrlRemarksConfirmation',
            controller: 'remarksConfirmStatusCtrl',
            resolve: {
                modalArguments: function () {
                    return args;
                }
            },
            backdrop: 'static'
        });
    }

    function areYouSure(thisMessage) {
        modalAreYouSureController.$inject = ['$uibModalInstance'];

        function modalAreYouSureController($uibModalInstance) {
            var vm = {
                cancel: cancel,
                message: thisMessage,
                ok: ok
            };

            return vm;

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }

            function ok() {
                $uibModalInstance.close(true);
            }
        }

        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/are-you-sure.html',
            controllerAs: 'vm',
            controller: modalAreYouSureController
        });

    }
    
    function processFlowPicker(args) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/process-flow-picker.html',
            controllerAs: 'ctrlProcessFlowPicker',
            controller: 'processFlowPickerCtrl',
            resolve: {
                modalArguments: function () {
                    return args;
                }
            },
            backdrop: 'static'
        });
    }

    function splitReservationDate(args) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/split-reservation-date.html',
            controllerAs: 'ctrlSplitReservationDate',
            controller: 'splitReservationDateCtrl',
            resolve: {
                modalArguments: function () {
                    return args;
                }
            },
            backdrop: 'static'
        });
    }

}