﻿'use strict';
module.exports = [sailingReportItemTableDirective];

function sailingReportItemTableDirective() {
    var directive = {
        controller: sailingReportItemTableController,
        controllerAs: 'ctrlReportTable',
        restrict: 'E',
        scope: {
            report: '=',
            showName: "="
        },
        link: function (scope) {
        },
        bindToController: true,
        templateUrl: 'templates/sailing-report/directives/sailing-report-item-table.html'
    };

    function sailingReportItemTableController() {
        var vm = {
        };
        return vm;
    }

    return directive;
}