﻿'use strict';

module.exports = ['common', 'dataContext', customerFinancialDataService];

function customerFinancialDataService(common, context) {
    var service = {
        getFinancialData: getFinancialData,
        sendFinancialData: sendFinancialData,
        getFinancialDataById: getFinancialDataById
    };

    return service;
    
    function sendFinancialData(data, debtorNumber, shipId) {
        var deferred = common.$q.defer();
        
        var url = '/debtor';
        if (debtorNumber && debtorNumber.length > 0)
            url += '/' + debtorNumber;

        if (shipId && shipId > 0)
            url += '?shipid=' + shipId;

        var request = context.post(url, data)
            .then(function (response) {
                deferred.resolve(response);
            }, function (error) {
                deferred.reject(error);
            });
        return deferred.promise;
    }

    function getFinancialData(debtorNumber) {
        var deferred = common.$q.defer();

        var url = '/debtor/' + debtorNumber;

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    
    function getFinancialDataById(id) {
        var deferred = common.$q.defer();

        var url = '/addresspreference/debtor';
        if (id && id > 0)
            url += '?id=' + id;
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}