﻿module.exports = ['coreDataService', regionFormDirective];

function regionFormDirective() {
    var directive = {
        bindToController: true,
        controller: regionFormController,
        controllerAs: 'ctrlRegionForm',
        restrict: 'E',
        scope: {
            regionData: '='
        },
        templateUrl: 'templates/region/directives/region-form.html'
    };

    return directive;

    function regionFormController(coreDataService) {
        var vm = {
            portauthorities: []
        };

        init();

        return vm;

        function init() {
            coreDataService.getPortauthorities()
                .then(function (data) {
                    vm.regionData.portAuthorities = data;
                });
        }
    };

};
