﻿module.exports = [reservationBoxDirective];

function reservationBoxDirective() {
    var directive = {
        bindToController: true,
        controller: reservationBoxController,
        controllerAs: 'ctrlReservationBox',
        restrict: 'E',
        scope: {
            reservation: '='
        },
        templateUrl: 'templates/customer/directives/reservation/reservation-box.html'
    };

    return directive;

    function reservationBoxController() {
        var vm = {
            reservation: this.reservation,
            planning: {
                berths: null,
                data: null
            },
            getFacilitiesDescription: getFacilitiesDescription
        };

        return vm;

        function getFacilitiesDescription(facilities) {
            _.reduce(facilities, function (curItem, nextItem) { return curItem + ', ' + nextItem; }, '');
        }
    }

}
