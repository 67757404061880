﻿module.exports = ['$routeParams', 'common', 'auditDataService', 'auditModelsService', 'dataContext', auditController];

function auditController($routeParams, common, auditData, auditModels, context) {

    var vm = {
        excelExport: excelExport,
        hasMore: true,
        getLogs: getLogs,
        init: init,
        loading: false,
        loadingExcel: false,
        loadMore: loadMore,
        logs: [],
        rows: [],
        page: 0,
        filter: {
            endDate: null,
            sortAscending: false,
            sortField: 'createdOn',
            sort: sort,
            startDate: null,
            textFilter: null,
            textFilterType: null
        }
    };

    function excelExport() {
        vm.loadingExcel = true;

        const pageSize = (vm.page * 50) + 50;
        const endDate = vm.filter.endDate.format('YYYY-MM-DD');
        const startDate = vm.filter.startDate.format('YYYY-MM-DD');

        var uri = '/audit/security?startDate=' + startDate + '&endDate=' + endDate + '&page=0&size=' + pageSize + '&output=csv&csv_include_header=1&csv_date_format=yyyy-MM-dd+HH:mm&csv_fields=ActionDescription,ObjectDescription,CreatedOn,CreatedByUserName,User,ObjectType,Area,Action&csv_custom_headers=Description,ObjectDescription,CreatedOn,CreatedBy,Affected user,ObjectType,Area,Action';
        context.get(uri)
            .then(function (result) {

                result = result.replace(",",";");
                common.exportToCSV(result,'security-audit-export-' + moment().format('YYYYMMDDHHmmss'));

                vm.loadingExcel = false;
            }, function () {
                vm.loadingExcel = false;
            });
    }

    function getLogs(forceReload) {
        vm.loading = true;

        if (forceReload && forceReload === true) {
            vm.page = 0;
        }

        auditData.getSecurityLog(vm.page, vm.filter.startDate.format('YYYY-MM-DD'), vm.filter.endDate.format('YYYY-MM-DD'), vm.filter.textFilter, vm.filter.textFilterType ? vm.filter.textFilterType.value : null)
            .then(function (result) {
                result = _.map(result, function (item) {
                    return auditModels.logModel(item);
                });
                result = _.flatten(result);

                if (result.length === 0) {
                    vm.hasMore = false;
                }

                if (forceReload && forceReload === true) {
                    vm.rows = result;
                } else {
                    vm.rows = vm.rows.concat(result);
                }
            })
            .finally(function () {
                vm.loading = false;
            });
    }

    function init() {
        vm.filter.endDate = moment();
        vm.filter.startDate = moment().add(-6, 'days');
        getLogs();
    }

    function loadMore() {
        vm.page++;
        getLogs(false);
    }

    function sort(sortField) {
        if (vm.filter.sortField === sortField) {
            vm.filter.sortAscending = !vm.filter.sortAscending;
        } else {
            vm.filter.sortField = sortField;
            vm.filter.sortAscending = true;
        }
    }

    return vm;
}