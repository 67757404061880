﻿module.exports = ['common', 'dataContext', 'customerModelsService', customerAddressPreferencesDataService];

function customerAddressPreferencesDataService(common, context, customerModels) {
    var vm = {
        getAddressPreferences: getAddressPreferences,
        addAddressPreference: addAddressPreference,
        updateAddressPreference: updateAddressPreference,
        getBasedOnShip: getBasedOnShip,
        deleteAddressPreference: deleteAddressPreference
    };

    return vm;

    function getAddressPreferences(id) {
        var deferred = common.$q.defer();

        context.get('/addresspreference/'+ id)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function getBasedOnShip(id) {
        var deferred = common.$q.defer();

        context.get('/addresspreference/ship/'+id)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function addAddressPreference(addresspreference) {
        var deferred = common.$q.defer();

        context.post('/addresspreference',addresspreference)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function updateAddressPreference(addresspreference) {
        var deferred = common.$q.defer();

        context.put('/addresspreference/' + addresspreference.id, addresspreference)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }
    
    function deleteAddressPreference(addresspreference) {
        var deferred = common.$q.defer();

        context.del('/addresspreference/' + addresspreference.id)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }
    
}
