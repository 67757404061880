﻿module.exports = [invoiceStatusLabelDirective];

function invoiceStatusLabelDirective() {
    var directive = {
        bindToController: true,
        controller: invoiceStatusLabelController,
        controllerAs: 'ctrlInvoiceStatusLabel',
        restrict: 'E',
        scope: {
            invoiceData: '='
        },
        templateUrl: 'templates/invoices/directives/invoice-status-label.html'
    };

    return directive;

    function invoiceStatusLabelController() {

        var vm = {
        };

        return vm;
    }
}