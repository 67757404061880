﻿module.exports = ['$routeParams', 'berthModelsService', berthOverviewDirective];

function berthOverviewDirective($routeParams, berthModels) {
    var directive = {
        bindToController: true,
        controller: berthOverviewController,
        controllerAs: 'ctrlBerths',
        restrict: 'E',
        scope: {
            activeBerth: '=',
            berthsData: '='

        },
        templateUrl: 'templates/berth/directives/berth-overview.html'
    };

    return directive;

    function berthOverviewController() {
        var vm = {
            activeOnly: true,
            filteredBerths: filteredBerths,
            setActiveBerth: setActiveBerth,
            selectedLink: selectedLink,
            currentlySelected: false
        };

        return vm;

        function filteredBerths() {
            if (vm.activeOnly) {
                return _.filter(vm.berthsData, function (b) { return b.isActive; });
            } else {
                return vm.berthsData;
            }
        }

        function setActiveBerth(berth) {
            //if new clicked!!!
            if (!berth) {
                berth = new berthModels.berthModel();
                berth.isActive = true;
            }
            else {
                // transform the date properties if needed
                if (berth.availableFrom && !berth.availabilityStartsOn) {
                    berth.availabilityStartsOn = moment(berth.availableFrom).toDate();
                }
                if (berth.availableUntil && !berth.availabilityEndsOn) {
                    berth.availabilityEndsOn = moment(berth.availableUntil).toDate();
                }
            }

            vm.activeBerth = berth;
        }
        function selectedLink(id) {
            if (vm.activeBerth) {
                if (parseInt(id) === parseInt(vm.activeBerth.id)) {
                    vm.currentlySelected = true;
                    return vm.currentlySelected;
                }
            }
        }
    }

}