﻿module.exports = ['constantsService', sailingReportPenaltyStatusFilter];

function sailingReportPenaltyStatusFilter(constantsService) {
    return function (status) {
        if (isNaN(status))
            status = parseInt(status);

        var statusName = _.find(constantsService.sailingReportPenaltyStatus, function (item) {
            return item.id === status;
        });

        return statusName ? statusName.name : status;

    };

}