﻿module.exports = [
    '$anchorScroll',
    '$filter',
    '$routeParams',
    'common',
    'toastr',
    'berthgroupDataService',
    'businessruleDataService',
    'planningCalendarService',
    'planningDataService',
    'planningModals',
    'reservationDataService',
    'widgetModals',
    'reportDataService', planningRivercruiseInboxController];

function planningRivercruiseInboxController($anchorScroll, $filter, $routeParams, common, toastr, berthGroupData, businessRuleData, planningCalendar, planningData, planningModals, reservationData, widgetModals, reportDataService) {

    var vm = {
        addNewPlanningRow: addNewPlanningRow,
        attached: attached,
        cancelSelection: cancelSelection,
        communicatedStatus: [],
        confirmAll: confirmAll,
        dateTimeNow: moment().format(),
        disableBtn: false,
        display: {
            changeMinimumDate: null,
            isAdmin: false,
            isFiltered: isFiltered,
            planningMenuItem: 'messages',
            showReservations: false,
            textFilter: null,
            textFilterType: 'reservation',
            unrespondedMessages: 0
        },
        getRowsForBerth: getRowsForBerth,
        initialReservation: null,
        loading: {
            planned: false,
            unplanned: false
        },
        markPlanningItemAsDeleted: markPlanningItemAsDeleted,
        messages: [],
        newMessage: {
            isIntern: false,
            status: '1',
            text: null
        },
        planAll: planAll,
        planning: {
            berths: [],
            berthDetails: [],
            calendar: planningCalendar,
            endDate: null,
            exceptions: [],
            lastOverviewEndDate: null,
            lastOverviewStartDate: null,
            sanityChecks: [],
            startDate: null
        },
        planningErrors: [],
        queue: [],
        reload: reload,
        sailingReports: [],
        saveMessage: saveMessage,
        saveAndConfirmSelection: saveAndConfirmSelection,
        saveSelection: saveSelection,
        selectedPlanning: null,
        selectedReservation: null,
        selectionIsConfirmed: selectionIsConfirmed,
        setSelection: setSelection,
        showShipDimensions: showShipDimensions,
        reservations: {
            canLoadMore: true,
            data: [],
            filterText: null,
            loadMore: loadMoreUnplanned,
            pageIndex: 0,
            pageSize: 20,
            status: null,
            updateSearch: updateSearch
        },
        updateSelection: updateSelection,
        suggestionsUnplanned: []
    };

    function addNewPlanningRow() {
        var newRow = {
            assignedToBerthId: null,
            eta: null,
            etd: null
        };

        if (vm.selectedPlanning.planningItems.length > 0) {
            var previousRow = vm.selectedPlanning.planningItems[vm.selectedPlanning.planningItems.length - 1];
            newRow.assignedToBerthId = previousRow.assignedToBerthId;
            newRow.reservationId = previousRow.reservationId;
            if (previousRow.etd !== null)
                newRow.eta = moment(previousRow.etd, 'DD-MM-YYYY HH:mm').add(-1, 'days').format('DD-MM-YYYY HH:mm');
        }

        vm.selectedPlanning.planningItems.push(newRow);
    }

    function attached() {
        if (common.identity.hasPermissionForBusinessUnit('planning_admin', 2)) {
            vm.display.isAdmin = true;
        }

        vm.display.showReservations = common.$location.$$path.indexOf('/inbox') > -1;

        if ($routeParams.reservationId) {
            vm.initialReservation = parseInt($routeParams.reservationId);
            setSelection({ reservationId: vm.initialReservation });
        }

        vm.planning.startDate = moment();
        vm.planning.lastOverviewStartDate = vm.planning.startDate;
        vm.planning.endDate = moment().add(6, 'days');
        vm.planning.lastOverviewEndDate = vm.planning.endDate;
        vm.display.changeMinimumDate = vm.planning.startDate.format('DD-MM-YYYY');

        reload();

        berthGroupData.getBerthgroupsDetails([2, 3])
            .then(function (result) {
                result.push({ id: -1, name: 'NO BERTH', berths: [{ id: -1, name: 'NO BERTH' }] });

                vm.planning.berths = result;
                calculateBerths();
            });

    }

    function calculateBerths() {
        var berths = [];
        for (var a = 0; a < vm.planning.berths.length; a++) {
            for (var b = 0; b < vm.planning.berths[a].berths.length; b++) {
                berths.push(vm.planning.berths[a].berths[b]);
            }
        }

        vm.planning.berthDetails = berths;
        calculateObstructions();
    }

    function calculateDays() {
        planningCalendar.calculateDays({
            endDate: vm.planning.endDate,
            startDate: vm.planning.startDate
        });
    }

    function calculateObstructions(obstructions) {
        planningCalendar.calculateBerths(vm.planning.berthDetails, obstructions);
    }

    function cancelSelection(needEnterReason) {

        if (needEnterReason === true) {
            widgetModals.freeTextConfirm({
                title: "Reason",
                description: "Warning! You are about to cancel the reservation for this customer. Please enter a reason",
                required: true
            })
                .result
                .then(function (result) {
                    if (result.value !== undefined && result.value !== null && result.value.length > 0) {
                        var reservationId = vm.selectedReservation ? vm.selectedReservation.id : planning.planningItems[0].reservationId;
                        planningData.cancelReservation(reservationId, result.value)
                            .then(function (cancellationResult) {
                                vm.setSelection(null);
                                loadPlannedReservations();
                                loadUnplannedReservations();
                            });
                    }
                }, function () { });
        } else {
            widgetModals.areYouSure('Warning! You are about to cancel the reservation for this customer')
                .result
                .then(function (result) {
                    if (result === true) {
                        var reservationId = vm.selectedReservation ? vm.selectedReservation.id : planning.planningItems[0].reservationId;
                        planningData.cancelReservation(reservationId)
                            .then(function (cancellationResult) {
                                vm.setSelection(null);
                                loadPlannedReservations();
                                loadUnplannedReservations();
                            });
                    }
                }, function () { });
        }
    }

    function checkInitialSelection() {
        if (vm.initialReservation === null || vm.loading.planned === true || vm.loading.unplanned === true)
            return;

        var foundItem = _.find(planningCalendar.data.planned, function (item) {
            return item.reservationId === $routeParams.reservationId;
        });

        if (foundItem === undefined)
            foundItem = _.find(planningCalendar.data.unplanned, function (item) {
                return item.reservationId === $routeParams.reservationId;
            });

        if (foundItem !== undefined) {
            setSelection(foundItem);
        }

        vm.initialReservation = null;
    }

    function confirmAll() {
        var relevantItems = planningCalendar.getItemsInRange();

        var dlgResult = planningModals.confirmPlanning({
            endDate: vm.planning.endDate,
            items: relevantItems,
            startDate: vm.planning.startDate
        }).result.then(function (result) {
            loadPlannedReservations();
        });
    }

    function convertReservationToPlanning(reservation, convertDates) {
        var planning = {
            planningItems: [],
            reservationId: reservation.id,
            ship: reservation.ship
        };

        // First try to find an existing unplanned version of this reservation. 
        // If this exists it means there has been a suggestion for it.
        var _existing = _.filter(planningCalendar.data.unplanned, function (unplanned) {
            return unplanned.reservationId === reservation.id;
        });
        if (!_existing || _existing.length === 0) {
            const foundSuggestion = _.find(vm.suggestionsUnplanned, function (unplanned) {
                return unplanned.reservation.id === reservation.id;
            });
            if (foundSuggestion && foundSuggestion.planningItems) {
                _existing = foundSuggestion.planningItems;
            }
        }

        if (_existing && _existing.length > 0) {
            planning.planningItems = _.map(_existing, function (item) {
                var _copyItem = common.copyObject(item);

                if (convertDates && !moment.isMoment(_copyItem.eta))
                    _copyItem.etaDisplay = moment(_copyItem.eta).format('DD-MM-YYYY HH:mm');
                if (convertDates && !moment.isMoment(_copyItem.etd))
                    _copyItem.etdDisplay = moment(_copyItem.etd).format('DD-MM-YYYY HH:mm');

                return _copyItem;
            });
        }
        else if (reservation.reservationItems && reservation.reservationItems.length > 0) {
            for (var a = 0; a < reservation.reservationItems.length; a++) {
                planning.planningItems.push({
                    assignedToBerthId: null,
                    etaDisplay: convertDates && !moment.isMoment(reservation.reservationItems[a].startsOn) ? moment(reservation.reservationItems[a].startsOn).format('DD-MM-YYYY HH:mm') : reservation.reservationItems[a].startsOn,
                    etdDisplay: convertDates && !moment.isMoment(reservation.reservationItems[a].endsOn) ? moment(reservation.reservationItems[a].endsOn).format('DD-MM-YYYY HH:mm') : reservation.reservationItems[a].endsOn
                });
            }

            if (planning.planningItems && planning.planningItems.length > 0) {
                // Always set the first ETA to the reservation ETA
                planning.planningItems[0].etaDisplay = moment(reservation.eta).format('DD-MM-YYYY HH:mm');
                // Always set the last ETD to the reservation ETD
                planning.planningItems[planning.planningItems.length - 1].etdDisplay = moment(reservation.etd).format('DD-MM-YYYY HH:mm');
            }
        }
        else {
            planning.planningItems.push({
                assignedToBerthId: null,
                etaDisplay: convertDates ? moment(reservation.eta).format('DD-MM-YYYY HH:mm') : reservation.eta,
                etdDisplay: convertDates ? moment(reservation.etd).format('DD-MM-YYYY HH:mm') : reservation.etd
            });
        }
        return planning;
    }

    function doSanityCheck(items, startDate, endDate) {
        planningData.getSanityCheck(items, startDate, endDate, [2, 3])
            .then(function (result) {
                planningCalendar.setSanityChecks(result);
            });
    }

    function ensureUnplanned(reservation) {
        var dfr = common.$q.defer();

        //var _existing = _.filter(planningCalendar.data.unplanned, function (unplanned) {
        //    return unplanned.reservationId === reservation.id;
        //});

        //if (_existing && _existing.length > 0)
        //    dfr.resolve(reservation);
        //else {
        planningData.getSuggestion(reservation.id)
            .then(function (result) {
                if (result && result.length > 0) {
                    var existing = _.filter(planningCalendar.data.unplanned,
                        function (unplanned) {
                            return unplanned.reservationId === reservation.id;
                        });

                    // timing issue, cause of reload function, sometime the planningCalendar.data.unplanned is filled.
                    // so only add this planning item when the planningCalendar.data.unplanned is NOT filled; skip add this selected reservation into the array when the planningCalendar.data.unplanned is filled; otherwise the planningitem will show twice
                    if (!existing || existing.length === 0) {
                        planningCalendar.calculateItems([result[0].planning], 'unplanned', true);
                    }
                }

                dfr.resolve(reservation);
            }, dfr.reject);
        //}

        return dfr.promise;
    }

    function getMessages(reservationId) {
        vm.display.unrespondedMessages = 0;

        reservationData.getMessages(reservationId)
            .then(function (result) {
                vm.messages = result;

                for (var i = vm.messages.length - 1; i >= 0; i--) {
                    if (vm.messages[i].createdBy.portAuthorityId !== null) {
                        if (vm.messages[i].isIntern)
                            continue;
                        else
                            break;
                    }

                    vm.display.unrespondedMessages++;

                }
            });
    }

    function getSailingReports(reservationId) {
        reportDataService.getSailingReports(reservationId)
            .then(function (result) {
                vm.sailingReports = result;
            });
    }

    function getRowsForBerth(berth) {
        var items = $filter('calendarItems')(vm.planning.calendar.data.planned, berth);
        items = items.concat($filter('calendarItems')(vm.planning.calendar.data.unplanned, berth));

        if (!items || items.length === 0)
            return 1;

        var result = _.max(items, function (item) { return item.display.row; });

        return result ? result.display.row + 1 : 1;
    }

    function getStatus(reservationId) {
        planningData.getStatus(reservationId)
            .then(function (result) {
                vm.communicatedStatus = result;
            });
    }

    function isFiltered(fieldToFilter, valueToFilter) {
        // Hide berths that are not active at the moment
        if (fieldToFilter === 'berth') {
            if((valueToFilter.availableFrom && moment(valueToFilter.availableFrom).isBefore(vm.planning.startDate))&&
                (valueToFilter.availableUntil && moment(valueToFilter.availableUntil).isBefore(vm.planning.endDate))){
                    return false;
            }
        } else if (fieldToFilter === 'berthgroup') {
            if (!valueToFilter.berths || valueToFilter.berths.length === 0) {
                return false;
            }
                
            if (_.filter(valueToFilter.berths, function (berth) { return isFiltered('berth', berth); }).length === 0) {
                return false;
            }
        }

        if (!fieldToFilter || fieldToFilter === '' || !valueToFilter || !vm.display.textFilter || vm.display.textFilter === '')
            return true;

        if (vm.display.textFilterType === 'berth') {
            if (fieldToFilter === 'berth') {
                if (valueToFilter.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1) {
                    return true;
                }
            }
            else if (fieldToFilter === 'berthgroup') {
                if (valueToFilter.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
                    return true;
                return _.some(valueToFilter.berths, function (berth) { return isFiltered('berth', berth); });
            }
            else if (fieldToFilter === 'planned')
                return true;
            return false;
        }
        else if (vm.display.textFilterType === 'reservation') {
            if (fieldToFilter === 'berth') {
                return _.some(vm.planning.calendar.data.planned, function (planned) {
                    return planned.assignedToBerthId === valueToFilter.id &&
                        (
                            (planned.reservationReference && planned.reservationReference.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1) ||
                            (planned.ship && planned.ship.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
                        );
                });
            }
            else if (fieldToFilter === 'berthgroup') {
                return _.some(valueToFilter.berths, function (berth) { return isFiltered('berth', berth); });
            }
            else if (fieldToFilter === 'planned') {
                if (valueToFilter.ship.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
                    return true;
                if (valueToFilter.reservationReference && valueToFilter.reservationReference.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1) {
                    return true;
                }

                return false;
            }
        }

        return true;
    }

    function loadBerthObstructions() {
        businessRuleData.getByType(3, vm.planning.startDate.format('YYYY-MM-DD'), vm.planning.endDate.format('YYYY-MM-DD'))
            .then(function (result) {
                calculateObstructions(result);
            });
    }

    function loadMoreUnplanned() {
        if (!vm.reservations.canLoadMore)
            return;

        vm.reservations.pageIndex += 1;
        loadUnplannedReservations();
    }

    function loadPlannedReservations() {
        vm.loading.planned = true;

        planningData.getPlanned(vm.planning.startDate.format('YYYY-MM-DD'), vm.planning.endDate.format('YYYY-MM-DD'), [2, 3])
            .then(function (result) {
                planningCalendar.calculateItems(result, 'planned');

                doSanityCheck(result, vm.planning.startDate.format('YYYY-MM-DD'), vm.planning.endDate.format('YYYY-MM-DD'));
                vm.loading.planned = false;

                if (vm.initialReservation)
                    checkInitialSelection();
            }, function () {
                vm.loading.planned = false;

                if (vm.initialReservation)
                    checkInitialSelection();
            });
    }

    function loadSuggestions(reservations) {
        if (!reservations || reservations.length === 0)
            return;

        var plannings = [];

        planningData.getSuggestions(vm.planning.startDate.format('YYYY-MM-DD'), vm.planning.endDate.format('YYYY-MM-DD'), [2, 3])
            .then(function (result) {
                var suggestions = _.map(result, function (item) {
                    var planning = item.planning;
                    var res = _.find(reservations, function (reservation) {
                        return reservation.id === planning.reservation.id;
                    });
                    if (res) {
                        planning.customer = res.customer;
                        planning.ship = res.ship;
                    }

                    return planning;
                });
                vm.suggestionsUnplanned = suggestions;
                planningCalendar.calculateItems(suggestions, 'unplanned');
            });
    }

    function loadUnplannedReservations() {
        vm.loading.unplanned = true;

        reservationData.getUnplanned(vm.reservations.pageIndex, vm.reservations.pageSize, [2, 3], vm.reservations.status, vm.reservations.filterText)
            .then(function (result) {
                if (vm.reservations.pageIndex === 0)
                    vm.reservations.data = result;
                else {
                    vm.reservations.data = vm.reservations.data.concat(_.sortBy(result, 'eta'));
                }

                loadSuggestions(result);
                vm.loading.unplanned = false;

                if (vm.initialReservation)
                    checkInitialSelection();

                vm.reservations.canLoadMore = result.length === vm.reservations.pageSize;
            }, function () {
                vm.loading.unplanned = false;

                if (vm.initialReservation)
                    checkInitialSelection();
            });
    }

    function markPlanningItemAsDeleted(itemId) {
        var itemIndex = _.findIndex(vm.selectedPlanning.planningItems, function (i) {
            return i.id === itemId;
        });

        if (itemIndex === -1)
            return;

        var item = vm.selectedPlanning.planningItems[itemIndex];
        if (item.id) {
            item.isDeleted = true; // item already saved, so mark als delete
        } else {
            vm.selectedPlanning.planningItems.splice(itemIndex, 1); // item is new, so remove the list
        }
    }

    function planAll() {
        var relevantItems = _.filter(vm.reservations.data, function (item) {
            return moment(item.eta).isBefore(vm.planning.calendar.display.maximumDate) && moment(item.etd).isAfter(vm.planning.calendar.display.minimumDate);
        });

        var dlgResult = planningModals.makePlanning({
            endDate: vm.planning.endDate,
            items: relevantItems,
            startDate: vm.planning.startDate
        }).result.then(function (result) {
            loadUnplannedReservations();
        });
    }

    function reload(clearAll) {
        if (clearAll && clearAll === true) {
            planningCalendar.clearItems();
            vm.reservations.data = [];
            vm.disableBtn = false;
        }

        common.$timeout(function () {
            calculateDays();
            loadBerthObstructions();
            loadPlannedReservations();
            loadUnplannedReservations();
        }, 200);
    }

    function saveMessage() {
        if (!vm.newMessage.text || vm.newMessage.text === '')
            return;

        var reservationId = null;
        if (vm.selectedReservation && vm.selectedReservation.id)
            reservationId = vm.selectedReservation.id;
        else if (vm.selectedPlanning && vm.selectedPlanning.planningItems && vm.selectedPlanning.planningItems.length > 0)
            reservationId = vm.selectedPlanning.planningItems[0].reservationId;

        reservationData.addMessage(reservationId, vm.newMessage.text, vm.newMessage.isIntern, null, vm.newMessage.status)
            .then(function (result) {
                vm.newMessage.isIntern = false;
                vm.newMessage.status = '1';
                vm.newMessage.text = null;

                getMessages(reservationId);
            });

    }

    function saveNextPlanningItem(confirm) {
        if (vm.queue.length === 0) {
            setSelection(null);
            loadPlannedReservations();
            loadUnplannedReservations();
            toastr.success("Changes Saved");
            reload();
            return;
        }

        var nextItem = vm.queue.splice(0, 1)[0];

        if (nextItem.isDeleted === true) {
            planningData.deletePlanningItem(nextItem.id)
                .then(function () { saveNextPlanningItem(confirm); });
        } else if (nextItem.id) {
            if (confirm === true) {
                planningData.setStatus(nextItem.reservationId, { status: 3 })
                    .then(function () { });
            }
            planningData.updatePlanningItem(nextItem)
                .then(function () { saveNextPlanningItem(confirm); });
        } else {
            if (confirm === true) {
                planningData.setStatus(nextItem.reservationId, { status: 3 })
                    .then(function () { });
            }
            planningData.addPlanningItem(nextItem)
                .then(function () { saveNextPlanningItem(confirm); });
        }

    }

    function saveSelection(confirm) {
        if (!vm.selectedPlanning.planningItems || vm.selectedPlanning.planningItems.length === 0)
            return;

        vm.selectedPlanning.planningItems = _.map(vm.selectedPlanning.planningItems, function (planningItem) {
            planningItem.eta = moment(planningItem.etaDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            if (planningItem.eta === 'Invalid date:00')
                planningItem.eta = null;
            planningItem.etd = moment(planningItem.etdDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            if (planningItem.etd === 'Invalid date:00')
                planningItem.etd = null;
            return planningItem;
        });

        // Validate the items
        var errors = [];

        _.each(vm.selectedPlanning.planningItems, function (item, index) {
            if (item.isDeleted === true) return; // don't validate when the item needs to delete

            if (!item.eta)
                errors.push('No ETA specified for item ' + (index + 1));
            if (!item.etd)
                errors.push('No ETD specified for item ' + (index + 1));
            if (item.eta > item.etd)
                errors.push('ETA cannot be after ETD for item ' + (index + 1));
            if (item.etd < item.eta)
                errors.push('ETD cannot be before ETA for item ' + (index + 1));
            if (!item.assignedToBerthId || item.assignedToBerthId === -1)
                errors.push('No berth specified for item ' + (index + 1));
        });
        if (errors.length > 0) {
            vm.planningErrors = errors;
            return;
        }

        vm.planningErrors = []; // reset erros
        if (vm.selectedPlanning.planningItems[0].id === null || vm.selectedPlanning.planningItems[0].id <= 0) {
            planningData.addPlanning(vm.selectedPlanning)
                .then(function (result) {
                    if (confirm === true) {
                        planningData.setStatus(result.reservation.id, { status: 3 })
                            .then(function () { });
                    }

                    setSelection(null);
                    toastr.success("Changes saved");
                    reload();
                });
        } else {
            vm.queue = common.copyObject(vm.selectedPlanning.planningItems);
            vm.disableBtn = true;
            saveNextPlanningItem(confirm);
        }

    }

    function saveAndConfirmSelection() {
        saveSelection(true);
    }

    function selectionIsConfirmed() {
        if (!vm.communicatedStatus || vm.communicatedStatus.length === 0)
            return false;

        return [1, 2, 3].indexOf(vm.communicatedStatus[vm.communicatedStatus.length - 1].status) > -1;
    }

    function setSelection(selection) {
        vm.display.planningMenuItem = 'planning';
        vm.selectedPlanning = null;
        vm.selectedReservation = null;
        vm.communicatedStatus = [];
        vm.dateTimeNow = moment().format();

        if (!selection) {
            //vm.planning.startDate = vm.planning.lastOverviewStartDate;
            //vm.planning.endDate = vm.planning.lastOverviewEndDate;
            reload(true);
            return;
            //planningCalendar.recalculate(vm.planning.lastOverviewStartDate, vm.planning.lastOverviewEndDate);
        }
        else if (selection.changedPlanningId) {
            // If the plannig is already loaded, just treat it as a normal planning selection
            if (_.find(planningCalendar.data.planned, function (item) {
                return item.reservationId === selection.changedPlanningId;
            }) !== undefined) {
                setSelection({
                    planningId: selection.changedPlanningId
                });
                return;
            }
            // Otherwise load it from the API
            planningData.getPlannedById(selection.changedPlanningId)
                .then(function (result) {
                    if (result && result.planningItems && result.planningItems.length > 0) {
                        planningCalendar.calculateItems([result], 'planned', true);
                        setSelection({ planningId: selection.changedPlanningId });
                    }
                    else {
                        setSelection({ reservationId: selection.changedPlanningId });
                    }
                });

            // Set the date range to the reservation times
            let origEta = moment(selection.reservation.eta);
            let origEtd = moment(selection.reservation.etd);

            vm.planning.startDate = origEta.add(-1, 'days');
            vm.planning.endDate = origEtd.add(1, 'days');
            calculateDays();
            return;
        }
        else if (selection.reservationId) {
            var _res = common.copyObject(_.find(vm.reservations.data, function (r) { return r.id === selection.reservationId; }));

            if (_res) {
                vm.selectedReservation = _res;
                // ensure the suggestion is there
                ensureUnplanned(_res)
                    .then(function (result) {
                        vm.selectedPlanning = convertReservationToPlanning(_res, true);

                        let origEta = moment(_res.eta);
                        let origEtd = moment(_res.etd);

                        vm.planning.startDate = origEta.add(-1, 'days');
                        vm.planning.endDate = origEtd.add(1, 'days');
                        vm.display.showReservations = true;
                        reload(true);
                    });
            } else {
                reservationData.getDetails(selection.reservationId)
                    .then(function (reservation) {
                        if (reservation.plannedEta === null) {
                            ensureUnplanned(reservation)
                                .then(function (result) {
                                    vm.selectedReservation = reservation;
                                    vm.selectedPlanning = convertReservationToPlanning(reservation, true);


                                    let origEta = moment(reservation.eta);
                                    let origEtd = moment(reservation.etd);

                                    vm.planning.startDate = origEta.add(-1, 'days');
                                    vm.planning.endDate = origEtd.add(1, 'days');
                                    vm.display.showReservations = true;
                                    reload(true);
                                });
                        } else {
                            setSelection({ changedPlanningId: selection.reservationId, reservation: reservation });
                            vm.display.showReservations = true;
                        }
                    }, function () { });
            }


            //reload(true);

            //planningCalendar.recalculate(origEta.add(-1, 'days'), origEtd.add(1, 'days'));
            getMessages(selection.reservationId);
            getSailingReports(selection.reservationId);
        }
        else if (selection.planningId) {
            var _plan = common.copyObject(
                _.map(
                    _.filter(planningCalendar.data.planned, function (r) {
                        return r.reservationId === selection.planningId;
                    }), function (m) {
                        m.etaDisplay = (moment.isMoment(m.eta) ? m.eta : moment(m.eta)).format('DD-MM-YYYY HH:mm');
                        m.etdDisplay = (moment.isMoment(m.etd) ? m.etd : moment(m.etd)).format('DD-MM-YYYY HH:mm');
                        return m;
                    }));

            if (_plan && _plan.length > 0) {
                vm.selectedPlanning = {
                    eta: _plan[0].eta,
                    etd: _plan[_plan.length - 1].etd,
                    id: selection.planningId,
                    planningItems: _plan,
                    reservationId: selection.planningId
                };
            }

            reservationData.getDetails(selection.planningId)
                .then(function (reservation) {
                    // Add the planned eta and etd if they are different from those in the reservation
                    if (vm.selectedPlanning) // && vm.selectedPlanning.eta && vm.selectedPlanning.eta !== reservation.eta)
                        reservation.etaOriginal = vm.selectedPlanning.eta;
                    if (vm.selectedPlanning) // && vm.selectedPlanning.etd && vm.selectedPlanning.etd !== reservation.etd)
                        reservation.etdOriginal = vm.selectedPlanning.etd;

                    vm.selectedReservation = reservation;
                }, function () { });

            reload(true);

            getMessages(selection.planningId);
            getStatus(selection.planningId);
            getSailingReports(selection.planningId);
        }
        $anchorScroll();
    }

    function showShipDimensions(ship) {
        if (ship !== undefined && ship !== null) {
            var values = [];
            if (ship.length !== undefined && ship.length !== null) {
                values.push(ship.length + " (L)");
            }

            if (ship.width !== undefined && ship.width !== null) {
                values.push(ship.width + " (W)");
            }

            if (ship.height !== undefined && ship.height !== null) {
                values.push(ship.height + " (H)");
            }

            if (values.length > 0) {
                return values.join(" x ");
            }
        }

        return "";
    }

    function updateSearch(needTimeout) {
        // use time out, give the user some time to finish enter the filter
        vm.reservations.pageIndex = 0;
        vm.reservations.pageSize = 10;

        var timer = needTimeout === true ? 2000 : 0;
        common.$timeout(function () {
            loadUnplannedReservations();
        }, timer);
    }

    function updateSelection() {
        var planning = common.copyObject(vm.selectedPlanning);

        var reservationId = vm.selectedReservation ? vm.selectedReservation.id : planning.planningItems[0].reservationId;

        for (var i = 0; i < planning.planningItems.length; i++) {
            planning.planningItems[i].eta = moment(planning.planningItems[i].etaDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            planning.planningItems[i].etd = moment(planning.planningItems[i].etdDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
        }

        planningCalendar.updateReservation(reservationId, planning);

        if (vm.selectedReservation) {
            var origEta = moment(vm.selectedReservation.eta);
            var origEtd = moment(vm.selectedReservation.etd);
            planningCalendar.recalculate(origEta.add(-1, 'days'), origEtd.add(1, 'days'));
        }
    }

    return vm;

}