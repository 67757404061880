﻿module.exports = ['communicatedStatusService', reservationCommunicatedStatusDirective];

function reservationCommunicatedStatusDirective(communicatedStatusService) {

    var directive = {
        bindToController: true,
        controller: reservationCommunicatedStatusController,
        controllerAs: 'ctrlReservationCommunicatedStatus',
        restrict: 'E',
        scope: {
            communicatedStatus: '='
        },
        templateUrl: 'templates/planning/directives/reservation-communicated-status.html'
    };

    return directive;

    function reservationCommunicatedStatusController() {
        var ctrlReservationCommunicatedStatus = this;

        ctrlReservationCommunicatedStatus.hasStatus = function (status, lastItemOnly) {
            return communicatedStatusService.hasCommunicatedStatus(ctrlReservationCommunicatedStatus.communicatedStatus, status, lastItemOnly);
        }
    }
};