﻿module.exports = ['constantsService', 'userDataService', userFormDirective];

function userFormDirective(constants, userData) {
    var directive = {
        bindToController: true,
        controller: userFormController,
        controllerAs: 'ctrlUserForm',
        restrict: 'E',
        scope: {
            enableUsernameChange: '=',
            showPassword: '=',
            showRole: '=',
            userData: '='
        },
        link: function (scope) {
            let roleId = scope.ctrlUserForm.userData.roleId;
            let roles = scope.ctrlUserForm.userData.roles && scope.ctrlUserForm.userData.roles.length > 0 ? scope.ctrlUserForm.userData.roles[0].roles : null;
            if (roles) {
                scope.ctrlUserForm.userRoles = roles;
                roles.forEach(function (r) {
                    scope.ctrlUserForm.selectedRoles[r.id] = true;
                });
            } else {
                if (roleId) {
                    scope.ctrlUserForm.selectedRoles[roleId] = true;
                    scope.ctrlUserForm.userRoles = [{ id: roleId }];
                }

            }
        },
        templateUrl: 'templates/users/directives/user-form.html'
    };

    return directive;

    function userFormController() {
        var ctrlUserForm = this;
        ctrlUserForm.constants = constants;
        ctrlUserForm.roles = [];
        
        userData.getRoles()
            .then(function (data) {
                ctrlUserForm.roles = data;
            });
        ctrlUserForm.selectedRoles = {};
        ctrlUserForm.checkBoxChange = function () {
            ctrlUserForm.userRoles = Object.keys(ctrlUserForm.selectedRoles).map(function (key) {
                if (ctrlUserForm.selectedRoles[key]) {
                    return { id: parseInt(key) };
                } else {
                    delete ctrlUserForm.selectedRoles[key];
                }
            });
            if (ctrlUserForm.userRoles && ctrlUserForm.userRoles.length > 0) {
                ctrlUserForm.userRoles = _.filter(ctrlUserForm.userRoles, function (r) {
                    if (r) {
                        return r !== undefined;
                    }
                });
            }
            ctrlUserForm.userData.userRoles = ctrlUserForm.userRoles;
        };

    }

};
