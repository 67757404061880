﻿module.exports = ['$location', '$routeParams', 'arrivalsDataService', 'common', 'constantsService', 'invoiceDataService', 'invoiceModelsService', 'planningDataService', 'widgetModals', 'customerDataService', arrivalsReviewController];

function arrivalsReviewController($location, $routeParams, arrivalsDataService, common, constants, invoiceDataService, invoiceModelsService, planningDataService, widgetModals, customerData) {

    var vm = {
        arrivals: {},
        arrivalInvoices: [],
        attached: attached,
        constants: constants,
        defaultConfig: {
            count: null,
            data: [],
            selectedItem: null,
            filterProperty: "shipName",
            filterText: null,
            canLoadMore: false,
            paging: 0,
            loadItems: 20 // nr of items to show
        },
        fullDiscount: fullDiscount,
        hasFlag: hasFlag,
        isSaving: false,
        noInvoice: noInvoice,
        planning: null,
        planningInvoices: [],
        sendBack: sendBack,
        sendInvoice: sendInvoice,
        setSelection: setSelection,
        status: {
            isLoading: false,
            modifyArrivalInvoice: false,
            modifyPlanningInvoice: false
        },
        switchFlow: switchFlow,
        loadArrivals: loadArrivals,
        loadMore: loadMore,
        updateSearch: updateSearch,
        processFlows: [],
        selectedFlow: null,
        customers: [],
        originalCustomerId: null,
        updateArrival: updateArrival,
        editCustomer: false
    };

    function attached() {
        // show only the process flow that contains invoice types with property isDefault; which means this invoicetype need to show in the review page.
        var flowsWithInvoiceTypes = _.map(constants.processFlows, function (flow) {
            flow.invoiceTypes = _.filter(flow.invoiceTypes, function (invoiceType) { return invoiceType.isDefault === true; });
            return flow;
        });

        // clone the flow list
        vm.processFlows = _.filter(flowsWithInvoiceTypes, function (f) { return f.invoiceTypes && f.invoiceTypes.length > 0; });

        if ($routeParams.flowName) {
            vm.selectedFlow = _.find(vm.processFlows, function (flow) { return flow.systemCode.toLowerCase() === $routeParams.flowName.toLowerCase(); });
        }

        // cannot define the flow from url, so select the first one
        if (!vm.selectedFlow) {
            vm.selectedFlow = _.first(vm.processFlows);
        }

        if (!vm.selectedFlow)
            $location.path('/'); // current user has no permission to see any process flows, redirect to home page
        
        vm.arrivals = _.clone(vm.defaultConfig);
        loadArrivals();
    }

    function clear() {
        vm.arrivals.selectedItem = null;
        vm.arrivalInvoices = [];
        vm.planning = null;
        vm.planningInvoices = [];
        vm.status.isLoading = false;
        vm.status.modifyArrivalInvoice = false;
        vm.status.modifyPlanningInvoice = false;
    }

    function fullDiscount(view) {
        var invoices = view === 'planning' ? vm.planningInvoices : vm.arrivalInvoices;

        var args = { action: 'full-discount' };
        if (vm.selectedFlow.invoiceTypes.length > 0) {
            args.invoiceTypes = vm.selectedFlow.invoiceTypes;
        } else {
            if (vm.arrivals.selectedItem && vm.arrivals.selectedItem.shipTypeId) {
                args.shipType = vm.arrivals.selectedItem.shipTypeId;

            } else if (vm.planning && vm.planning.ship) {
                args.shipType = vm.planning.ship.shipTypeId;
            } // else cannot define ship type id
        }
               
        widgetModals.remarksConfirmStatus(args).result
            .then(function (result) {
                if (result.remarks) {
                    vm.isSaving = true;
                    var nrSavedInvoices = 0;
                    var nrToSave = 0;

                    for (var idx = 0; idx < invoices.length; idx++) {
                        var invoice = invoices[idx].invoice;
                        if (invoice) {
                            if (invoice.remarks && invoice.remarks !== '') {
                                invoice.remarks += result.remarks[0];
                            } else {
                                invoice.remarks = result.remarks[0];
                            }
                            invoice.customerId = vm.arrivals.selectedItem.customerId;

                            var discountLines = [];
                            for (var i = 0; i < invoice.invoiceItems.length; i++) {
                                var discountItem = _.clone(invoice.invoiceItems[i]);
                                if (vm.selectedFlow.invoiceTypes && vm.selectedFlow.invoiceTypes[idx].configuration && vm.selectedFlow.invoiceTypes[idx].configuration.disc_ex_code) {
                                    discountItem.externalItemCode = vm.selectedFlow.invoiceTypes[idx].configuration.disc_ex_code; // apply this item code only for rivercruise e.g. 'LIG_KORTING'
                                }
                                discountItem.totalAmount = discountItem.totalAmount * -1;
                                discountItem.unitaryTariff = discountItem.unitaryTariff * -1;
                                discountLines.push(discountItem);
                            }

                            invoice.invoiceItems = invoice.invoiceItems.concat(discountLines);

                            nrToSave++;
                            arrivalsDataService.approveArrivalDefinitively(vm.arrivals.selectedItem, invoice)
                                .then(function (approvedArrival) {

                                })
                                .finally(function () {
                                    nrSavedInvoices++;

                                    if (nrToSave === nrSavedInvoices) {
                                        clear();
                                        loadArrivals();
                                        vm.isSaving = false;
                                    }
                                });
                        }
                    }
                }
            });
    }

    function hasFlag(value, flag) {
        return value & flag;
    }

    function loadArrivals() {
        var deferred = common.$q.defer();

        vm.status.isLoading = true;

        arrivalsDataService.getToReviewByFlow(vm.selectedFlow.id, vm.arrivals.paging, vm.arrivals.loadItems)
            .then(function (result) {
                vm.arrivals.count = result.count;
                vm.arrivals.allItems = result.items;
                applyFiltering();
                vm.status.isLoading = false;

                deferred.resolve();
            }, function (error) {
                vm.status.isLoading = false;
                deferred.reject();
            });

        return deferred.promise;
    }

    function loadPlanningSelection() {
        if (vm.arrivals.selectedItem.reservationId)
            planningDataService.getPlannedById(vm.arrivals.selectedItem.reservationId)
                .then(function (result) {

                    let statuses = result.communicatedStatus;
                    statuses = _.map(statuses, function (item) {
                        switch (item.status) {
                            case 1:
                                item.name = 'Region Confirmed';
                                break;
                            case 2:
                                item.name = 'Berth Group Confirmed';
                                break;
                            case 3:
                                item.name = 'Berth Confirmed';
                                break;
                            case 4:
                                item.name = 'Cancellation Requested by Client';
                                break;
                            case 5:
                                item.name = 'Cancellation Approved by Planner';
                                break;
                            case 6:
                                item.name = 'Changes Requested';
                        }
                        return item;
                    });

                    vm.planning = result;
                    vm.planning.communicatedStatus = statuses;
                    if (vm.arrivals.selectedItem) {
                        for (var i = 0; i < vm.selectedFlow.invoiceTypes.length; i++) {
                            getInvoicePreviewForPlanning(i);
                        }
                    }
                });

        if (vm.arrivals.selectedItem && vm.arrivals.selectedItem.realisationItems.length > 0) {
            for (var i = 0; i < vm.selectedFlow.invoiceTypes.length; i++) {
                getInvoicePreviewForRealisation(i);
            }
        }
    }

    function getInvoicePreviewForPlanning(index) {
        var invoiceType = vm.selectedFlow.invoiceTypes[index];
        if (invoiceType) {
            invoiceDataService.getPreviewForPlanning(vm.arrivals.selectedItem.reservationId, invoiceType.id)
                .then(function (invoice) {
                    vm.planningInvoices[index] = invoice;
                }, function (error) {
                    vm.planningInvoices[index] = {
                        invoice: new invoiceModelsService.invoiceModel(),
                        remarks: null
                    };
                });
        }
    }

    function getInvoicePreviewForRealisation(index) {
        var invoiceType = vm.selectedFlow.invoiceTypes[index];
        if (invoiceType) {
            invoiceDataService.getPreview(vm.arrivals.selectedItem.id, invoiceType.id)
                .then(function (invoice) {
                    vm.arrivalInvoices[index] = invoice;
                }, function (error) {
                    vm.arrivalInvoices[index] = {
                        invoice: new invoiceModelsService.invoiceModel(),
                        remarks: null
                    };
                });
        }
    }

    function loadMore() {
        vm.arrivals.paging += vm.arrivals.loadItems;
        vm.arrivals.canLoadMore = vm.arrivals.items.length > vm.arrivals.paging;
    }

    function noInvoice() {

        var args = { action: 'no-invoice' };
        if (vm.selectedFlow.invoiceTypes.length > 0) {
            args.invoiceTypes = vm.selectedFlow.invoiceTypes;
        } else {
            if (vm.arrivals.selectedItem && vm.arrivals.selectedItem.shipTypeId) {
                args.shipType = vm.arrivals.selectedItem.shipTypeId;

            } else if (vm.planning && vm.planning.ship) {
                args.shipType = vm.planning.ship.shipTypeId;
            } // else cannot define ship type id
        }

        widgetModals.remarksConfirmStatus(args).result
            .then(function (result) {
                if (result.remarks) {
                    vm.isSaving = true;
                    arrivalsDataService.setStatus(vm.arrivals.selectedItem.id, {
                        remarks: result.remarks[0],
                        status: 6
                    })
                        .then(function (invoiceResult) {
                            clear();
                            loadArrivals();
                        })
                        .finally(function() { vm.isSaving = false; });
                }
            });
    }

    function sendBack() {

        var args = {};
        if (vm.selectedFlow.invoiceTypes.length > 0) {
            args.invoiceTypes = vm.selectedFlow.invoiceTypes;
        } else {
            if (vm.arrivals.selectedItem && vm.arrivals.selectedItem.shipTypeId) {
                args.shipType = vm.arrivals.selectedItem.shipTypeId;

            } else if (vm.planning && vm.planning.ship) {
                args.shipType = vm.planning.ship.shipTypeId;
            } // else cannot define ship type id
        }

        widgetModals.remarksConfirmStatus(args).result
            .then(function (result) {
                if (result.remarks) {
                    vm.isSaving = true;

                    arrivalsDataService.setStatus(vm.arrivals.selectedItem.id, {
                        remarks: 'CM: ' + result.remarks[0],
                        status: 7
                    })
                        .then(function (arrivalResult) {
                            clear();
                            loadArrivals();
                        })
                        .finally(function() { vm.isSaving = false; });
                }
            });
    }

    function sendInvoice(view) {

        var isModify = false;
        var list;
        if (view === 'planning') {
            list = vm.planningInvoices;
            isModify = vm.status.modifyPlanningInvoice;
        } else {
            list = vm.arrivalInvoices;
            isModify = vm.status.modifyArrivalInvoice;
        }

        for (var i = 0; i < list.length; i++) {
            list[i].invoice.customerId = vm.arrivals.selectedItem.customerId;
        }

        // since the invoice line has remarks, don't need set the remarks on the invoice
        sendInvoiceWithNoRemark(list);
    }

    function sendInvoiceWithRemark(list) {

        var shipId = null;
        if (list && list.length > 0) {
            shipId = list[0].invoice.specificShip.shipTypeId;
        } else if (vm.arrivalInvoices && vm.arrivalInvoices.length > 0) {
            shipId = vm.arrivalInvoices.invoice.specificShip.shipTypeId;
        } else if (vm.planningInvoices && vm.planningInvoices.length > 0) {
            shipId = vm.planningInvoices.invoice.specificShip.shipTypeId;
        }

        widgetModals.remarksConfirmStatus({ action: '', shipType: shipId}).result
            .then(function (result) {
                if (result.remarks) {
                    for (var i = 0; i < list.length; i++) {
                        var invoice = list[i].invoice;
                        if (invoice.remarks && invoice.remarks !== '') {
                            invoice.remarks += result.remarks[0];
                        } else {
                            invoice.remarks = result.remarks[0];
                        }
                    }
                }
                sendInvoiceWithNoRemark(list);
            });
    }

    function sendInvoiceWithNoRemark(list) {
        vm.isSaving = true;
        var nrSavedInvoices = 0;
        var nrToSave = 0;

        for (var i = 0; i < list.length; i++) {
            if (list[i].invoice) {
                nrToSave++;
                arrivalsDataService.approveArrivalDefinitively(vm.arrivals.selectedItem, list[i].invoice)
                    .then(function (approvedArrival) {
                    })
                    .finally(function () {
                        nrSavedInvoices++;

                        if (nrSavedInvoices === nrToSave) {
                            clear();
                            loadArrivals();
                            vm.isSaving = false;
                        }
                    });
            }
        }
    }

    function setSelection(id) {
        clear();

        if (!id) {
            vm.arrivals.selectedItem = null;
            return;
        }

        vm.arrivals.selectedItem = _.find(vm.arrivals.items, function (item) {
            return item.id === id;
        });

        if (vm.arrivals.selectedItem) {
            vm.originalCustomerId = vm.arrivals.selectedItem.customerId;
        }

        if (vm.arrivals.selectedItem.remarks && !Array.isArray(vm.arrivals.selectedItem.remarks))
            vm.arrivals.selectedItem.remarks = vm.arrivals.selectedItem.remarks.split('\r\n');

        loadPlanningSelection();
        loadCustomers();
    }

    function switchFlow() {
        vm.arrivals = _.clone(vm.defaultConfig);
        clear();
        loadArrivals();
    }

    function updateSearch(needTimeout) {
        var timer = needTimeout === true ? 2000 : 0;
        common.$timeout(function () {
            applyFiltering();
        }, timer);
    }

    function applyFiltering() {
        if (!vm.arrivals.filterText || vm.arrivals.filterText.trim() === "") {
            vm.arrivals.items = vm.arrivals.allItems;
        } else {
            var searchValue = vm.arrivals.filterText.toLowerCase();
            vm.arrivals.items = _.filter(vm.arrivals.allItems, function (item) {
                return item[vm.arrivals.filterProperty] !== null && item[vm.arrivals.filterProperty] !== undefined && item[vm.arrivals.filterProperty].toLowerCase().indexOf(searchValue) !== -1;
            });
        }

        vm.arrivals.paging = vm.arrivals.loadItems; // reset the paging after search
        vm.arrivals.canLoadMore = vm.arrivals.items.length > vm.arrivals.paging;
    }

    function loadCustomers() {
        if(vm.customers.length == 0) {
            customerData.getCustomers().then(result => {
                const filteredResult = _.sortBy(_.filter(result, x => !x.customerPortAuthority || x.customerPortAuthority.active), 'name');
                vm.customers = filteredResult;
            });
        }
    }

    function updateArrival(){
        arrivalsDataService.updateArrival(vm.arrivals.selectedItem).then(function(result){
            if(result && result.customerId != null){
                vm.originalCustomerId = result.customerId;
                vm.arrivals.selectedItem.customerName = vm.customers.find(x => x.id == result.customerId).name;
    
                vm.editCustomer = false;
            }
        });
    }

    return vm;
}
