﻿module.exports = [permissionModels];

function permissionModels() {

    var service = {
        permissionModel: permissionModel,
        validatePermission: validatePermission
    };

    return service

    function permissionModel(permissionData) {
        var permissionModel = {
            id: null,
            internalName: null,
            isPublic: null,
            description: null,
            name: null,
            permissions: []
        };

        if (permissionData === undefined || permissionData === null)
            return permissionData;

        permissionModel.id = permissionData.id;
        permissionModel.internalName = permissionData.internalName;
        permissionModel.isPublic = permissionData.isPublic;
        permissionModel.description = permissionData.description;
        permissionModel.name = permissionData.name;
        permissionModel.permissions = permissionData.permissions;

        return permissionModel;
    };

    function validatePermission(permission) {
        var errors = [];

        if (permission.name === null || permission.name === '')
            errors.push('Please specify a display name');

        if (permission.internalName === null || permission.internalName === '')
            errors.push('Please specify an internal name');

        return errors.length > 0 ? errors : true;
    };

};
