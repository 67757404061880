﻿module.exports = [facilityModels];

function facilityModels() {

    var service = {
        facilityModel: facilityModel
    };

    return service;

    function facilityModel(facilityData) {
        var facilityModel = {
            id: null,
            name: null
        };

        if (facilityData === undefined || facilityData === null)
            return facilityModel;

        facilityModel.id = facilityData.id;
        facilityModel.name = facilityData.name;

        return facilityModel;
    }

}