﻿'use strict';

module.exports = ['common', 'dataContext', dashboardStatusDataService];

function dashboardStatusDataService(common, context) {

    var vm = {
        getFleetIssues: getFleetIssues,
        getReservationStatus: getReservationStatus
    };

    return vm;

    function getFleetIssues() {
        var deferred = common.$q.defer();
        var url = "/fleet/issues/count";
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getReservationStatus() {
        var deferred = common.$q.defer();
        var url = "/status/reservation";
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}