﻿angular.module('poa.rprp.security', [])
    // Controllers
    .config(['$routeProvider', routeConfig])
    .controller('roleOverviewController', require('./controllers/role-overview'))
    // Directives
    .directive('roleForm', require('./directives/role-form'))
    // Modals
    .factory('permissionModals', require('./modals/modals'))
    // Services
    .factory('permissionDataService', require('./services/permission-data'))
    .factory('permissionModelService', require('./services/permission-models'))
    .factory('roleDataService', require('./services/role-data'))
    .factory('roleModelService', require('./services/role-models'))
    ;

function routeConfig($routeProvider) {
    $routeProvider
    .when('/roles', {
        templateUrl: 'templates/security/controllers/role-overview.html'
    });
};
