﻿module.exports = ['common', 'dataContext', 'coreDataModels', coreDataService];

function coreDataService(common, context, coreDataModels) {

    var vm = {
        getShiptypes: getShiptypes,
        getFacilities: getFacilities,
        getBerthgroups: getBerthgroups,
        getBerths: getBerths,
        getCustomers: getCustomers,
        getNauticalgoals: getNauticalgoals,
        getCommercialgoals: getCommercialgoals,
        getBusinessrules: getBusinessrules,
        getPortauthorities: getPortauthorities,
        getRegions: getRegions
    };

    return vm;

    function getShiptypes() {
        var deferred = common.$q.defer();
        var req = context.get('/shiptype')
            .then(function (data) {
                var result = _.map(data, coreDataModels.shiptypeModel);
                deferred.resolve(result);
            });

        return deferred.promise;
    }

    function getBerthgroups() {
        var deferred = common.$q.defer();
        var req = context.get('/berthgroup')
            .then(function (data) {
                var result = _.map(data, coreDataModels.berthgroupModel);
                deferred.resolve(result);
            });

        return deferred.promise;
    }

    function getBerths() {
        var deferred = common.$q.defer();
        var req = context.get('/berth')
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.BerthModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCustomers() {
        var deferred = common.$q.defer();
        var req = context.get('/customer')
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.CustomerModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getFacilities() {
        var deferred = common.$q.defer();
        var req = context.get('/facility')
            .then(function (data) {
                var result = _.map(data, coreDataModels.facilityModel);
                deferred.resolve(result);
            });

        return deferred.promise;
    }

    function getNauticalgoals() {
        var deferred = common.$q.defer();
        var req = context.get('/goal/nautical')
            .then(function (data) {
                var result = _.map(data, coreDataModels.nauticalgoalModel);
                deferred.resolve(result);
            });

        return deferred.promise;

    }

    function getCommercialgoals() {
        var deferred = common.$q.defer();
        var req = context.get('/goal/commercial')
            .then(function (data) {
                var result = _.map(data, coreDataModels.commercialgoalModel);
                deferred.resolve(result);
            });

        return deferred.promise;
    }

    function getPortauthorities() {
        var deferred = common.$q.defer();
        var req = context.get('/port-authority')
            .then(function (data) {
                var result = _.map(data, coreDataModels.portauthorityModel);
                deferred.resolve(result);
            });

        return deferred.promise;
    }

    function getBusinessrules() {
        var deferred = common.$q.defer();
        var req = context.get('/businessrule')
            .then(function (data) {
                var result = _.map(data, coreDataModels.businessruleModel);
                deferred.resolve(result);
            });

        return deferred.promise;
    }

    function getRegions() {
        var deferred = common.$q.defer();
        var req = context.get('/region')
            .then(function (data) {
                var result = _.map(data, coreDataModels.regionModel);
                deferred.resolve(result);
            });

        return deferred.promise;
    }
}