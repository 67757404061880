﻿module.exports = ['common', 'dataContext', keyControlsDataService];

function keyControlsDataService(common, context, reportModels) {

    var service = {
        getInvoices: getInvoices
    };

    function getInvoices(dateFrom, dateTo) {
        var deferred = common.$q.defer();
        var url = '/keycontrol/invoices?dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        var req = context.get(url)
            .then(function (data) {
                var result = data;
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    return service;

}