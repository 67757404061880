﻿module.exports = ['constantsService', hasPermissionDirective];

function hasPermissionDirective(constants) {
    return {
        link: function (scope, element, attrs) {
            // ensure the constants are loaded before we check the business unit
            constants.preloaded.then(function () {
                var show = false;
                if (attrs && attrs.hasBusinessUnit) {
                    var units = attrs.hasBusinessUnit.split(',');
                    if (units.length > 0) {
                        show = _.some(units, function (unit) { return constants.hasBusinessUnit(unit); });
                    }
                }

                element.removeClass('ng-hide');
                if (!show)
                    element.addClass('ng-hide');
            });
        }
    };
}
