﻿module.exports = [goalModelsService];

function goalModelsService() {

    var service = {
        goalModel: goalModel
    };

    return service;

    function goalModel(goalData) {
        var goalModel = {
            id: null,
            name: null,
            businessUnit: null,
            goalType: null,
            systemCode: null
        };

        if (goalData === undefined || goalData === null)
            return goalModel;

        goalModel.id = goalData.id;
        goalModel.name = goalData.name;
        goalModel.goalType = goalData.goalType;
        goalModel.businessUnit = goalData.businessUnit;
        goalModel.systemCode = goalData.systemCode;

        return goalModel;
    }

}