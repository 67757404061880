﻿module.exports = [invoiceStateService];

function invoiceStateService() {
    var service = {
        filter: {
            invoiceType: null,
            endDate: null,
            pageIndex: 0,
            pageSize: 999,
            startDate: null
        }
    };

    return service;
}