﻿angular.module('poa.rprp.messages', [])
    .config(['$routeProvider', routeConfig])
    // Controllers
    .controller('messageController', require('./controllers/messages'))
    .controller('systemMessageController', require('./controllers/system-messages'))
    .controller('templateController', require('./controllers/templates'))
    // Directives
    .directive('messageList', require('./directives/message-list'))
    .directive('sendMessage', require('./directives/send-message'))
    .directive('showBerth', require('./directives/show-berth'))
    .directive('systemMessageOverview', require('./directives/system-messages/system-message-overview'))
    .directive('systemMessageForm', require('./directives/system-messages/system-message-form'))
    .directive('templateOverview', require('./directives/templates/template-overview'))
    .directive('templateForm', require('./directives/templates/template-form'))
    // Services
    .factory('messageDataService', require('./services/message-data'))
    .factory('systemMessageDataService', require('./services/system-message-data'))
    .factory('templateDataService', require('./services/template-data'));

function routeConfig($routeProvider) {
    $routeProvider
        .when('/messages', {
            templateUrl: 'templates/messages/controllers/messages.html'
        })
        .when('/messages/:conversationId', {
            templateUrl: 'templates/messages/controllers/messages.html'
        })
        .when('/messages/reservation/:reservationId', {
            templateUrl: 'templates/messages/controllers/messages.html'
            })
        .when('/messages/customer/:customerId', {
            templateUrl: 'templates/messages/controllers/messages.html'
        })
        .when('/system-messages',
            { templateUrl: 'templates/messages/controllers/system-messages.html' })
        .when('/templates',
            { templateUrl: 'templates/messages/controllers/templates.html' });
};
