﻿module.exports = ['$routeParams', 'common', 'dataContext', 'reportDataService', 'auditDataService', reportTariffController];

function reportTariffController($routeParams, common, context, reportData, auditData) {

    var vm = {
        loading: true,
        getReport: getReport,
        tariffs: [],
        filteredTariffs: [],
        filter: {
            sortAscending: true,
            sortField: 'eta',
            sort: sort
        },
        showHistory: false,
        loadingHistory: false,
        selectedYear: null,
        selectableYears: [],
        onClickHistoryButton: onClickHistoryButton,
        filterByYear: filterByYear
    };

    function onClickHistoryButton() {
        vm.showHistory = !vm.showHistory;

        if (vm.showHistory) {
            vm.loadingHistory = true;
            auditData.getTariffLogs()
                .then(function (result) {
                    if (result) {
                        var histories = _.groupBy(result, 'objectId');
                        for (var key in histories) {

                            vm.filteredTariffs.forEach(function (tg) {
                                var types = tg.rows;
                                for (var i = 0; i < types.length; i++) {
                                    var tariffs = types[i].rows;
                                    for (var j = 0; j < tariffs.length; j++) {
                                        if (tariffs[j].id == key) {
                                            tariffs[j].history = histories[key];
                                            break;
                                        }
                                    }
                                }
                            });
                        }
                    }

                    vm.loadingHistory = false;
                }, function (error) {
                    vm.loadingHistory = false;
                });
        }
    }

    function filterByYear() {
        let filteredTariffs = vm.tariffs;
        if (vm.selectedYear) {
            const startOfYear = moment(vm.selectedYear, 'YYYY').startOf('year');
            const endOfYear = moment(vm.selectedYear, 'YYYY').endOf('year');

            filteredTariffs = filteredTariffs.filter(function (x) {
                const startsOnMoment = moment(x.startsOn);
                const endsOnMoment = moment(x.endsOn);
                if (startsOnMoment.isBefore(endOfYear) && (x.endsOn && endsOnMoment.isAfter(startOfYear) || !x.endsOn)) {
                    return x;
                }
            });
        }

        vm.filteredTariffs = _.chain(filteredTariffs)
            .groupBy('tariffGroupId')
            .map(function (value, key) { return ({ tariffGroupId: key, rows: value }) })
            .map(function (fg) { fg.rows = _.groupBy(fg.rows, 'tariffGroupName'); return fg; })
            .map(function (fg) { fg.rows = _.map(fg.rows, function (value, key) { return ({ tariffGroupName: key, rows: value }) }); return fg; })
            .value();
    }

    function getReport() {
        reportData.getReportTariffs()
            .then(function (result) {
                vm.tariffs = result;
                vm.loading = false;
                vm.selectableYears = _.chain(result)
                    .map(function(x) {
                        const dates = [moment().format('YYYY'), moment().add(1, 'year').format('YYYY')]
                        const startsOn = moment(x.startsOn).format('YYYY');
                        const endsOn = moment(x.endsOn).add(-1).format('YYYY');
                        if (/^\d{4}$/g.exec(startsOn)) {
                            dates.push(startsOn);
                        }

                        if (/^\d{4}$/g.exec(endsOn)) {
                            dates.push(endsOn);
                        }

                        return dates;
                    })
                    .flatten()
                    .compact()
                    .uniq()
                    .sortBy()
                    .value();
                vm.selectedYear = moment().format('YYYY');
                vm.filterByYear();
            });
    }

    function sort(sortField) {
        if (vm.filter.sortField === sortField) {
            vm.filter.sortAscending = !vm.filter.sortAscending;
        } else {
            vm.filter.sortField = sortField;
            vm.filter.sortAscending = true;
        }
    }

    return vm;
}