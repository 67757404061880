﻿module.exports = ['$uibModal', resetPasswordModalService];

function resetPasswordModalService($uibModal) {

    var service = {
        resetPassword: resetPassword
    };

    return service;

    function resetPassword(args) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/customer/modals/reset-password-modal.html',
            controllerAs: 'vm',
            controller: 'modalResetPasswordController',
            resolve: {
                modalArguments: function () {
                    return args;
                }
            },
            size: 'lg'
        });
    };
};