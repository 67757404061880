﻿module.exports = ['$uibModal', planningModalsService];

function planningModalsService($uibModal) {

    var service = {
        confirmPlanning: confirmPlanning,
        makePlanning: makePlanning
    };

    return service;

    function confirmPlanning(args) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/planning/modals/confirm-planning-modal.html',
            controllerAs: 'vm',
            controller: 'modalConfirmPlanningController',
            resolve: {
                modalArguments: function () {
                    return args;
                }
            },
            size: 'lg'
        });

    };

    function makePlanning(args) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/planning/modals/make-planning-modal.html',
            controllerAs: 'vm',
            controller: 'modalMakePlanningController',
            resolve: {
                modalArguments: function () {
                    return args;
                }
            },
            size: 'lg'
        });

    };

};