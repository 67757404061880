﻿'use strict';

module.exports = ['reportDataService', tiledDashboardDirective];

function tiledDashboardDirective(reportData) {
    var directive = {
        bindToController: true,
        controller: tiledDashboardController,
        controllerAs: 'ctrlTD',
        restrict: 'E',
        scope: {
            itemsData: '=',
            dashboardTitle: '=',
            onItemSelect: '&'
        },
        templateUrl: 'templates/report/directives/tiled-dashboard.html'
    };

    return directive;

    function tiledDashboardController() {
        var vm = {
            onSelect: onSelect,
            selectedItem: null
        };

        return vm;


        function onSelect(name){
            var item  = _.filter(vm.itemsData,function(x){ return x.name === name })[0];
            vm.selectedItem = item;
            vm.onItemSelect({ item: item });
        }
    }

}