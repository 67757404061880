﻿module.exports = [planningStatusLabelDirective];

function planningStatusLabelDirective() {
    var directive = {
        bindToController: true,
        controller: planningStatusLabelController,
        controllerAs: 'ctrlPlanningStatusLabel',
        restrict: 'E',
        scope: {
            planningData: '='
        },
        templateUrl: 'templates/planning/directives/planning-status-label.html'
    };

    return directive;

    function planningStatusLabelController() {

        var vm = {
        };

        return vm;
    }
}