﻿module.exports = ['customerFinancialDataService', 'toastr', financialInformationDirective];

function financialInformationDirective(customerFinancialDataService, toastr) {
    var directive = {
        bindToController: true,
        controller: financialInformationController,
        controllerAs: 'ctrlFinancialInformation',
        link: function (scope) {
//            console.log('link called');
//            scope.ctrlFinancialInformation.loadInformation();
            scope.$watch('ctrlFinancialInformation.debtorNumber', scope.ctrlFinancialInformation.loadInformation);
            scope.$watch('ctrlFinancialInformation.shipId', scope.ctrlFinancialInformation.loadInformation);
        },
        restrict: 'E',
        scope: {
            debtorNumber: '=',
            shipId: '=',
            isReadOnly: '='
        },
        templateUrl: 'templates/customer/directives/financial/financial-information.html'
    };

    return directive;

    function financialInformationController() {
        var ctrlFinancialInformation = this;
        ctrlFinancialInformation.financialData = null;
        ctrlFinancialInformation.financialDataEdit = null;
        ctrlFinancialInformation.isEditing = false;
        ctrlFinancialInformation.isLoading = false;

        ctrlFinancialInformation.loadInformation = function () {

            if (ctrlFinancialInformation.debtorNumber && isNaN(ctrlFinancialInformation.debtorNumber) && ctrlFinancialInformation.debtorNumber.indexOf('TEMPF') !== -1) {
                var splitarray = ctrlFinancialInformation.debtorNumber.split(':');
                customerFinancialDataService.getFinancialDataById(splitarray[1])
                    .then(function (response) {
                        ctrlFinancialInformation.financialData = response;
                        
                        ctrlFinancialInformation.isLoading = false;
                    }, function (error) {
                        ctrlFinancialInformation.isLoading = false;
                    });
            } 
            else if (ctrlFinancialInformation.debtorNumber !== undefined && ctrlFinancialInformation.debtorNumber !== null && ctrlFinancialInformation.debtorNumber !== "" && ctrlFinancialInformation.debtorNumber > 0) {
                ctrlFinancialInformation.isLoading = true;
                customerFinancialDataService.getFinancialData(ctrlFinancialInformation.debtorNumber)
                    .then(function (response) {
                        ctrlFinancialInformation.financialData = response;
                        ctrlFinancialInformation.isLoading = false;
                    }, function (error) {
                        ctrlFinancialInformation.isLoading = false;
                    });
            }
        };

        ctrlFinancialInformation.saveFinancialInfo = function () {
            customerFinancialDataService.sendFinancialData(ctrlFinancialInformation.financialDataEdit, ctrlFinancialInformation.debtorNumber, ctrlFinancialInformation.shipId)
                .then(function (result) {
                    toastr.success('The information has been sent to our financial department. It can take a while before the changes take effect.', '', { timeOut: 7000 });
                    ctrlFinancialInformation.setEdit(false);
                });
        };

        ctrlFinancialInformation.setEdit = function (enable) {
            if (enable === true || enable === undefined) {
                if (ctrlFinancialInformation.financialData === null)
                    ctrlFinancialInformation.financialDataEdit = {
                        address: null,
                        city: null,
                        cocNumber: null,
                        companyName: null,
                        country: null,
                        debtorNumber: null,
                        hasAutomaticCollection: null,
                        hasGreenCertificate: null,
                        iban: null,
                        invoiceEmail: null,
                        phoneNumber: null,
                        postalCode: null,
                        vatNumber: null
                    };
                else
                    ctrlFinancialInformation.financialDataEdit = ctrlFinancialInformation.financialData;

                ctrlFinancialInformation.isEditing = true;
            }
            else {
                ctrlFinancialInformation.financialDataEdit = null;
                ctrlFinancialInformation.isEditing = false;
            }
        };
    };

};
