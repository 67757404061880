﻿module.exports = ['common', 'dataContext', externalItemCodeDataService];

function externalItemCodeDataService(common, context) {

    var service = {
        add: addItemCode,
        delete: deleteItemCode,
        getAll: getAll,
        getById: getById,
        getByInvoiceTypes: getByInvoiceTypes,
        update: update
    };

    function addItemCode(itemCode) {
        var deferred = common.$q.defer();      

        var req = context.post('/externalitemcode', itemCode)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteItemCode(itemCode) {
        var deferred = common.$q.defer();

        context.del('/externalitemcode/' + itemCode.id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getAll(includeInActive) {
        var deferred = common.$q.defer();

        var url = includeInActive === true ? '/externalitemcode?includeInActive=true' : '/externalitemcode';
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getById(itemCodeId) {
        var deferred = common.$q.defer();

        var req = context.get('/externalitemcode/' + itemCodeId)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getByInvoiceTypes(invoiceTypes) {
        var deferred = common.$q.defer();

        var url = '/externalitemcode';
        if (invoiceTypes && invoiceTypes.length > 0)
            url += '?invoiceTypes=' + invoiceTypes.join(',');

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function update(itemCode) {
        var deferred = common.$q.defer();

        context.put('/externalitemcode/' + itemCode.id, itemCode)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    return service;

}