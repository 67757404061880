﻿module.exports = ['constantsService', berthGroupFunctionNameFilter];

function berthGroupFunctionNameFilter(constantsService) {
    return function (berthGroupFunction) {
        if (isNaN(berthGroupFunction)) {
            berthGroupFunction = parseInt(berthGroupFunction);
        }

        var functionType = _.find(constantsService.berthGroups, function (item) {
            return item.id === berthGroupFunction;
        });

        return functionType ? functionType.name : berthGroupFunction;

    };

}