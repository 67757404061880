﻿module.exports = ['$routeParams', 'common', 'regionDataService', 'regionModelsService', 'widgetModals', regionOverviewController];

function regionOverviewController($routeParams, common, regionData, regionModels, widgetModals) {

    var vm = {
        addRegion: addRegion,
        attached: attached,
        common: common,
        deleteRegion: deleteRegion,
        editingRegion: null,
        newRegion: new regionModels.regionModel(),
        saveRegion: saveRegion,
        status: {
            completed: false,
            errors: [],
            saving: false,
            setAddNew: setAddNew,
            setEdit: setEdit,
            showAddNew: false
        },
        regions: [],
        selectedLink: selectedLink,
        currentlySelected: false
    };
    function selectedLink(id) {
        if (vm.editingRegion) {
            if (parseInt(id) === parseInt(vm.editingRegion.id)) {
                vm.currentlySelected = true;
                return vm.currentlySelected;
            }
        }
    }
    function addRegion() {
        vm.status.saving = true;
        regionData.addRegion(vm.newRegion)
            .then(function (result) {
                vm.regions.push(result);
                vm.status.saving = false;
                vm.status.showAddNew = false;
            });
    }

    function attached() {
        regionData.getRegions()
            .then(function (result) {
                vm.regions = result;
            });

        var regionId = $routeParams.regionId;
        if (regionId !== undefined && regionId !== null && regionId !== '' && regionId > 0) {
            regionData.getRegion(regionId)
            .then(function (result) {
                if (result !== undefined && result !== null) {
                    vm.status.setEdit(result);
                }
            });
        }
    }

    function deleteErrorResult(response) {
        vm.status.errors = common.errorToText(response);
        vm.status.saving = false;
    }

    function deleteSuccessResult(response) {
        var iUser = _.findIndex(vm.regions, function (item) { return item.id === vm.editingRegion.id; });
        vm.regions.splice(iUser, 1);
        vm.editingRegion = null;
        vm.status.saving = false;
    }

    function deleteRegion(user) {
        vm.status.errors = [];

        widgetModals.areYouSure('You want to delete this region?').result
            .then(function (result) {
                if (result === true) {
                    vm.status.saving = true;
                    regionData.deleteRegion(vm.editingRegion)
                    .then(deleteSuccessResult, deleteErrorResult);
                }
            });
    }

    function saveRegion() {
        vm.status.errors = [];

        vm.status.saving = true;
        regionData.saveRegion(vm.editingRegion)
            .then(successResult, errorResult);

        function errorResult(response) {
            vm.status.errors = common.errorToText(response);
            vm.status.saving = false;
        }

        function successResult(result) {
            vm.editingRegion = null;
            vm.status.completed = true;
            vm.status.saving = false;
        }
    }

    function setAddNew() {
        vm.editingRegion = null;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = true;
    }

    function setEdit(region) {
        vm.editingRegion = region;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = false;
    }

    return vm;
}
