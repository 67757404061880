﻿module.exports = ['templateDataService', templateForm];

function templateForm(templateDataService) {
    var directive = {
        bindToController: true,
        controller: templateFormController,
        controllerAs: 'ctrlTemplateForm',
        restrict: 'E',
        scope: {
            templateData: '='
        },
        templateUrl: 'templates/messages/directives/templates/template-form.html'
    };

    return directive;

    function templateFormController() {
        var vm = {
            saveTemplate: saveTemplate,
            tinymceOptions: {
                plugins: 'link lists',
                height: 500,
                menubar: false
            }
        };

        return vm;

        function saveTemplate() {
            templateDataService.saveTemplate(vm.templateData)
                    .then(successResult)
                    .catch();

            function successResult(result) {
                vm.templateData = result;
            }
        }
    }
}
