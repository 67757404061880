﻿module.exports = ['constantsService', 'userDataService', userFormDirective];

function userFormDirective(constants, userData) {
    var directive = {
        bindToController: true,
        controller: userFormController,
        controllerAs: 'ctrlUserForm',
        link: function (scope) {
            scope.$watch('ctrlUserForm.userData', inputChanged);
        },
        restrict: 'E',
        scope: {
            showPassword: '=',
            showRole: '=',
            userData: '='
        },
        templateUrl: 'templates/users/directives/admin-user-form.html'
    };

    return directive;

    function inputChanged(userData) {
        // Check/append the 'ALL' type
        var existingZero = _.findIndex(userData.roles, function (role) {
            return role.businessUnit === 0;
        });
        if (existingZero > -1) {
            userData.roles[existingZero].businessUnitName = 'All types';
        } else {
            userData.roles.push({
                businessUnit: 0,
                businessUnitName: 'All types',
                roles: []
            });
        }

        // Check/append all business units
        for (var i = 0; i < constants.businessUnits.length; i++) {
            var existing = _.findIndex(userData.roles, function (userRole) { return userRole.businessUnit === constants.businessUnits[i].id; });
            if (existing > -1) {
                userData.roles[existing].businessUnitName = constants.businessUnits[i].name;
            } else {
                userData.roles.push({
                    businessUnit: constants.businessUnits[i].id,
                    businessUnitName: constants.businessUnits[i].name,
                    roles: []
                });
            }
        }
    }

    function userFormController() {
        var ctrlUserForm = this;
        ctrlUserForm.constants = constants;
        ctrlUserForm.roles = [];

        userData.getAdminRoles()
            .then(function (data) {
                ctrlUserForm.roles = data;
            });
    };

};
