﻿module.exports = ['common', 'dataContext', sailingReportDataService];

function sailingReportDataService(common, context) {

    var service = {
        addPenalty: addPenalty,
        getIssueReports: getIssueReports,
        getIssueReportCounts: getIssueReportCounts,
        getInvoicedReports: getInvoicedReports,
        getInvoicedReportsBySearch: getInvoicedReportsBySearch,
        getLegalTodoCount: getLegalTodoCount,
        getLegalTodoOverview: getLegalTodoOverview,
        invoice: invoice,
        resetConfirmation: resetConfirmation,
        review: review,
        setPenalty: setPenalty,
        updateBaseDataAndSendToLegal: updateBaseDataAndSendToLegal,
        getById: getById
    };

    function addPenalty(sailingReportId, remarks) {
        var deferred = common.$q.defer();
        var url = '/sailingreport/' + sailingReportId + '/penalty';
        var req = context.post(url, { motivation: remarks })
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getIssueReports(pageIndex, pageSize) {
        var deferred = common.$q.defer();

        //deferred.resolve(generateDummy());
        var args = [
            'pindex=' + pageIndex,
            'psize=' + pageSize
        ];

        var url = "/sailingreport/issues?" + args.join('&');
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getIssueReportCounts() {
        var deferred = common.$q.defer();

        var url = "/sailingreport/issues/count";
        context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    } 

    function getInvoicedReports(dateFrom, dateUntil, pageIndex, pageSize) {
        var deferred = common.$q.defer();

        if (!dateFrom || !dateUntil)
            deferred.reject('Please supply valid dates');

        dateFrom = (moment.isMoment(dateFrom) ? dateFrom : moment(dateFrom)).format('YYYY-MM-DD');
        dateUntil = (moment.isMoment(dateUntil) ? dateUntil : moment(dateUntil)).format('YYYY-MM-DD');

        var args = [
            'pindex=' + pageIndex,
            'psize=' + pageSize,
            'dateFrom=' + dateFrom,
            'dateUntil=' + dateUntil
        ];

        var url = "/sailingreport/invoiced?" + args.join('&');
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getInvoicedReportsBySearch(query, pageIndex, pageSize) {
        var deferred = common.$q.defer();

        var args = [
            'pindex=' + pageIndex,
            'psize=' + pageSize
        ];

        args.push('q=' + query);
        var url = "/sailingreport/invoiced/search?" + args.join('&');
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getLegalTodoCount() {
        var deferred = common.$q.defer();
        var url = "/sailingreport/legal/review/count";
        context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getLegalTodoOverview() {
        var deferred = common.$q.defer();
        var url = "/sailingreport/legal/review";
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function invoice(sailingReportId, sailingReport, includePenalty) {
        var deferred = common.$q.defer();

        var args = [];
        if (includePenalty !== undefined && includePenalty !== null) {
            args.push('includePenalty=' + includePenalty);
        }

        var url = "/sailingreport/" + sailingReportId + '/invoice?' + args.join('&');
        var req = context.post(url, sailingReport)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function review(sailingReportId, remarks) {
        var deferred = common.$q.defer();
        var url = '/sailingreport/' + sailingReportId + '/review';
        var req = context.post(url, { internalRemarks: remarks })
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function resetConfirmation(sailingReportId) {
        var deferred = common.$q.defer();
        var url = '/sailingreport/' + sailingReportId + '/reset-confirmation';
        var req = context.post(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function setPenalty(sailingReportId, penalty) {
        var deferred = common.$q.defer();
        var url = '/sailingreport/' + sailingReportId + '/legal';
        var req = context.put(url, penalty)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function generateDummy() {

        var result = [];

        for (var i = 0; i < 50; i++) {
            var item = {
                id: i,
                reservationId: i + 213,
                paxDisembarking: randomPax(),
                paxEmbarking: randomPax(),
                paxTransit: randomPax(),
                lastEditOn: randomDate(1,50),
                status: randomIntFromInterval(1, 3),
                reservation: {
                    eta: randomDate(5,25),
                    id: i + 213,
                    ship: {
                        name: 'My ship ' + i,
                        paxCapacity: randomPax()
                    }
                }
            };

            if (item.status > 1)
                item.checkedOn = Math.random() > .7 ? randomDate(0, 3) : null;

            result.push(item);
        }

        return result;
    }

    function randomDate(min, max) {
        var daysAgo = randomIntFromInterval(min, max);
        return moment().add(daysAgo * -1, 'days').toJSON();
    }

    function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function randomPax() {
        var result = 0;

        if (Math.random() > .7)
            result = randomIntFromInterval(1, 200);

        return result;
    }

    function updateBaseDataAndSendToLegal(sailingReportId, sailingReport) {
        var deferred = common.$q.defer();
        var url = "/sailingreport/" + sailingReportId + '/objection';
        var req = context.post(url, sailingReport)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getById(id, includeItems) {
        var deferred = common.$q.defer();
        var url = "/sailingreport/" + id + "?includeItems=" + includeItems;
        context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
    
        return deferred.promise;
    };

    return service;
}