﻿module.exports = ['common', 'dataContext', 'facilityModelsService', facilityDataService];

function facilityDataService(common, context, facilityModels) {

    var service = {
        getFacilitys: getFacilitys,
        getFacility: getFacility,
        addFacility: addFacility,
        deleteFacility: deleteFacility,
        saveFacility: saveFacility
    };

    return service;

    function getFacilitys() {
        var deferred = common.$q.defer();
        var req = context.get('/facility')
            .then(function (data) {
                deferred.resolve(_.map(data, facilityModels.FacilityModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getFacility(id) {
        var deferred = common.$q.defer();
        var req = context.get('/facility/' + id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function addFacility(facility) {
        var deferred = common.$q.defer();

        var req = context.post('/facility/', facility)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveFacility(facility) {
        var deferred = common.$q.defer();
        var req = context.put('/facility/' + facility.id, facility)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteFacility(facility) {
        var deferred = common.$q.defer();
        var req = context.del('/facility/' + facility.id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

}