﻿module.exports = ['common', 'dataContext', userAccountService];

function userAccountService(common, context) {
    var service = {
        getUserInfo: getUserInfo,
        loginUser: loginUser,
        registerUser: registerUser,
        saveCustomerInfo: saveCustomerInfo,
        saveUserInfo: saveUserInfo
    };

    return service;

    function loginUser(userData, rememberMe) {
        var tokenUrl = '/account/login';
        if (!userData.grant_type) {
            userData.grant_type = 'password';
        }
        userData.applicationKey = common.config.applicationKey;
        userData.applicationSecret = common.config.applicationSecret;

        var deferred = common.$q.defer();
        context.post(tokenUrl, userData)
            .then(function (data) {
                common.identity.set(userData.username, data, rememberMe);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
        return deferred.promise;
    };

    function getUserInfo() {
        var deferred = common.$q.defer();
        var req = context.get('/account/userinfo')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function registerUser(userData) {
        var accountUrl = '/account/register';
        return context.post(accountUrl, userData);
    };

    function saveCustomerInfo(customerInfo) {
        var deferred = common.$q.defer();
        var req = context.put('/account/customerinfo', customerInfo)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    };

    function saveUserInfo(userInfo) {
        var deferred = common.$q.defer();
        var req = context.put('/account/userinfo', userInfo)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    };

};