﻿'use strict';
angular.module('poa.rprp.report', [])
    .config(['$routeProvider', routeConfig])
    .controller('keyControlsInvoiceController', require('./controllers/report-keycontrols-invoices'))
    .controller('reportFlowsController', require('./controllers/report-flows'))
    .controller('reportInvoiceIssuesRemarksController', require('./controllers/report-invoice-issues-remarks'))
    .controller('reportMainController', require('./controllers/report-main'))
    .controller('reportRevenueController', require('./controllers/report-open-revenue'))
    .controller('reportPlanningController', require('./controllers/report-planning'))
    .controller('reportTariffController', require('./controllers/report-tariff'))
    .controller('reportReservationsController', require('./controllers/report-reservations'))
    .controller('reportResponseTimeController', require('./controllers/report-responsetime'))
    .controller('reportRolesController', require('./controllers/report-roles'))
    .controller('reportSailingReportRemarksController', require('./controllers/report-sailingreport-remarks'))
    .controller('reportTurnoverController', require('./controllers/report-turnover'))
    .controller('reportUserRolesController', require('./controllers/report-user-roles'))
    .controller('invoicedDiscountPerReasonController', require('./controllers/report-invoiced-discount-per-reason'))
    .controller('realisationsPerBerthController', require('./controllers/report-realisations-per-berth'))
    .controller('reportArrivalAndDepartureTimeController', require('./controllers/report-arrival-and-departure-time'))
    .directive('numberOfReservationsPerYear', require('./directives/number-of-reservations-per-year'))
    .directive('numberOfReservationsPerCustomerPerYear', require('./directives/number-of-reservations-per-customer-per-year'))
    .directive('numberOfBerthOccupationPerYear', require('./directives/number-of-berth-occupation-per-year'))
    .directive('fleetLengthPerCustomer', require('./directives/fleet-length-per-customer'))
    .directive('fleetLengthPerFleet', require('./directives/fleet-length-per-fleet'))
    .directive('tiledDashboard', require('./directives/tiled-dashboard'))
    .factory('keyControlsDataService', require('./services/keycontrols-data'))
    .factory('reportDataService', require('./services/report-data'))
    .factory('reportModelsService', require('./services/report-models'));

function routeConfig($routeProvider) {
    $routeProvider
        .when('/reports/key-controls/invoices', {
            templateUrl: 'templates/report/controllers/report-keycontrols-invoices.html'
        })
        .when('/reports', {
            templateUrl: 'templates/report/controllers/report-main.html'
        })
        .when('/reports/flows', {
            templateUrl: 'templates/report/controllers/report-flows.html'
        })
        .when('/reports/open-revenue', {
            templateUrl: 'templates/report/controllers/report-open-revenue.html'
        })
        .when('/reports/planning', {
            templateUrl: 'templates/report/controllers/report-planning.html'
        })
        .when('/reports/tariff', {
            templateUrl: 'templates/report/controllers/report-tariff.html'
        })
        .when('/reports/reservations', {
            templateUrl: 'templates/report/controllers/report-reservations.html'
        })
        .when('/reports/responsetime', {
            templateUrl: 'templates/report/controllers/report-responsetime.html'
        })
        .when('/reports/roles', {
            templateUrl: 'templates/report/controllers/report-roles.html'
        })
        .when('/reports/turnover', {
            templateUrl: 'templates/report/controllers/report-turnover.html'
        })
        .when('/reports/user-roles', {
            templateUrl: 'templates/report/controllers/report-user-roles.html'
        })
        .when('/reports/invoiced-discount', {
            templateUrl: 'templates/report/controllers/report-invoiced-discount-per-reason.html'
        })
        .when('/reports/realisations-per-berth', {
            templateUrl: 'templates/report/controllers/report-realisations-per-berth.html'
        })
        .when('/reports/report-arrival-and-departure-time', {
            templateUrl: 'templates/report/controllers/report-arrival-and-departure-time.html'
        })
        .when('/reports/report-sailingreport-remarks', {
            templateUrl: 'templates/report/controllers/report-sailingreport-remarks.html'
        })
        .when('/reports/report-invoice-issues-remarks', {
            templateUrl: 'templates/report/controllers/report-invoice-issues-remarks.html'
        });
}