﻿module.exports = ['common', 'dataContext', 'coreDataModels', berthgroupDataService];

function berthgroupDataService(common, context, coreDataModels) {

    var service = {
        addBerthgroup: addBerthgroup,
        deleteBerthgroup: deleteBerthgroup,
        getBerthgroups: getBerthgroups,
        getBerthgroupsDetails: getBerthgroupsDetails,
        getBerthgroup: getBerthgroup,
        getBerthgroupsForAdmin: getBerthgroupsForAdmin,
        getBerthgroupBerths: getBerthgroupBerths,
        getBerthgroupShiptypes: getBerthgroupShiptypes,
        getBerthgroupNauticalgoals: getBerthgroupNauticalgoals,
        getBerthgroupCommercialgoals: getBerthgroupCommercialgoals,
        saveBerthgroup: saveBerthgroup,
        updateBerthgroupShiptypes: updateBerthgroupShiptypes,
        updateBerthgroupBerths: updateBerthgroupBerths,
        updateBerthgroupNauticalgoals: updateBerthgroupNauticalgoals,
        updateBerthgroupCommercialgoals: updateBerthgroupCommercialgoals
    };

    return service;
    function updateBerthgroupBerths(bertgrouphId, berthIdsToAdd, berthIdsToDel) {
        var url = '/berthgroup/' + bertgrouphId + '/berth';
        var args = {
            referenceId: bertgrouphId,
            relatedObjectsToAdd: berthIdsToAdd,
            relatedObjectsToDel: berthIdsToDel
        };

        var deferred = common.$q.defer();
        var req = context.post(url, args)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.berthgroupModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updateBerthgroupNauticalgoals(berthgroupId, nauticalgoalIdsToAdd, nauticalgoalIdsToDel) {
        var url = '/berthgroup/' + berthgroupId + '/goal/nautical';
        var args = {
            referenceId: berthgroupId,
            relatedObjectsToAdd: nauticalgoalIdsToAdd,
            relatedObjectsToDel: nauticalgoalIdsToDel
        };
        var deferred = common.$q.defer();
        var req = context.post(url, args)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.nauticalgoalModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updateBerthgroupCommercialgoals(berthgroupId, commercialgoalIdsToAdd, commercialgoalIdsToDel) {
        var url = '/berthgroup/' + berthgroupId + '/goal/commercial';
        var args = {
            referenceId: berthgroupId,
            relatedObjectsToAdd: commercialgoalIdsToAdd,
            relatedObjectsToDel: commercialgoalIdsToDel
        };
        var deferred = common.$q.defer();
        var req = context.post(url, args)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.commercialgoalModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updateBerthgroupShiptypes(berthgroupId, shiptypeIdsToAdd, shiptypeIdsToDel) {
        var url = '/berthgroup/' + berthgroupId + '/shiptype';
        var args = {
            referenceId: berthgroupId,
            relatedObjectsToAdd: shiptypeIdsToAdd,
            relatedObjectsToDel: shiptypeIdsToDel
        };

        var deferred = common.$q.defer();
        var req = context.post(url, args)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.shiptypeModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthgroupBerths(berthgroupId) {
        var url = '/berthgroup/' + berthgroupId + '/berth';
        var deferred = common.$q.defer();
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.commercialgoalModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthgroupCommercialgoals(berthgroupId) {
        var url = '/berthgroup/' + berthgroupId + '/goal/commercial';
        var deferred = common.$q.defer();
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.commercialgoalModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthgroupNauticalgoals(berthgroupId) {
        var url = '/berthgroup/' + berthgroupId + '/goal/nautical';
        var deferred = common.$q.defer();
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.nauticalgoalModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }


    function getBerthgroupShiptypes(berthgroupId) {
        var url = '/berthgroup/' + berthgroupId + '/shiptype';
        var deferred = common.$q.defer();
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.shiptypeModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }


    function getBerthgroups() {
        var deferred = common.$q.defer();
        var req = context.get('/berthgroup')
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.BerthgroupModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthgroupsForAdmin() {
        var deferred = common.$q.defer();
        var req = context.get('/berthgroup/admin')
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.BerthgroupModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthgroupsDetails(businessUnits,flows) {
        var deferred = common.$q.defer();
        var url ='';
        
        if(businessUnits)
            url = '/berthgroup/details/businessunits/' + businessUnits.join(',');
        if(flows)
            url = '/berthgroup/details/flows/' + flows.join(',');

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(_.map(data, coreDataModels.BerthgroupModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthgroup(id) {
        var deferred = common.$q.defer();
        var req = context.get('/berthgroup/' + id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function addBerthgroup(berthgroup) {
        var deferred = common.$q.defer();

        var req = context.post('/berthgroup/', berthgroup)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveBerthgroup(berthgroup) {
        var url = '/berthgroup';
        if (berthgroup.id) url = url + '/' + berthgroup.id;

        var deferred = common.$q.defer();
        var req = context.post(url, berthgroup)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteBerthgroup(berthgroup) {
        var deferred = common.$q.defer();
        var req = context.del('/berthgroup/' + berthgroup.id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

}