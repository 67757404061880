﻿module.exports = ['common', arrivalsReceiptDirective];

function arrivalsReceiptDirective(common) {

    var directive = {
        bindToController: true,
        controller: arrivalsReceiptController,
        controllerAs: 'ctrlArrivalsReceipt',
        link: function (ctrl) {
        },
        restrict: 'E',
        scope: {
            arrivalData: '='
        },
        templateUrl: 'templates/arrivals/directives/arrivals-receipt.html'
    };
    return directive;

    function arrivalsReceiptController() {
        var ctrlArrivalsReceipt = this;
        ctrlArrivalsReceipt.datePickerOptions = {
            altInputFormats: [
                'd-M-yyyy',
                'd-M-yyyy H:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yyyy',
                'dd-MM-yyyy H:mm',
                'dd-MM-yyyy HH:mm',
                'yyyy-MM-ddTHH:mm:ss'
            ]
        };

        ctrlArrivalsReceipt.getFacilitiesDescription = function (facilities) {
            return _.map(facilities, function (item) {
                return item.facility.name;
            }).join(', ');
        }
    }

};