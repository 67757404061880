﻿module.exports = ['common', 'dataContext', 'regionModelsService', regionDataService];

function regionDataService(common, context, regionModels) {

    var service = {
        getRegions: getRegions,
        getRegion: getRegion,
        addRegion: addRegion,
        deleteRegion: deleteRegion,
        saveRegion: saveRegion
    };

    return service;

    function getRegions() {
        var deferred = common.$q.defer();
        var req = context.get('/region')
            .then(function (data) {
                deferred.resolve(_.map(data, regionModels.RegionModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getRegion(id) {
        var deferred = common.$q.defer();
        var req = context.get('/region/' + id)
            .then(function (data) {
                deferred.resolve(_.map(data, regionModels.RegionModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function addRegion(region) {
        var deferred = common.$q.defer();

        var req = context.post('/region/', region)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveRegion(region) {
        var deferred = common.$q.defer();
        var req = context.put('/region/' + region.id, region)
            .then(function (data) {
                deferred.resolve(_.map(data, regionModels.RegionModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteRegion(region) {
        var deferred = common.$q.defer();
        var req = context.del('/region/' + region.id)
            .then(function (data) {
                deferred.resolve(_.map(data, regionModels.RegionModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

}