﻿angular.module('poa.rprp')
    .config(['$locationProvider', locationConfigurator])
    .config(['$routeProvider', routeConfig])
    .config(['$translateProvider', translationConfigurator])
    .config(toastConf)
    .value('config', {
        apiHostEasyDock: 'http://localhost:6107',
        apiHostCruiseDock: 'https://testadmin.cruisedock.nl/api',
        //apiHost: 'https://easydockadmin.kvsadev.nl/api',
        //apiHost: '/api',
        applicationKey: 'a',
        applicationSecret: 'b',
        version: 0.1
    })
    .run(['$location', '$rootScope', 'identity', authenticateRoutes])
    .run(['$location', '$rootScope', '$route', preventReload]);

function locationConfigurator($locationProvider) {
    //$locationProvider.html5Mode(true);
}

function routeConfig($routeProvider) {
    $routeProvider
        .otherwise({
            redirectTo: '/dashboard'
        });
}

function translationConfigurator($translateProvider) {
    var translation_en = require('./translations/en/_index');
    var translation_nl = require('./translations/nl/_index');

    $translateProvider
        .translations('en', translation_en)
        .translations('nl', translation_nl)
        .preferredLanguage('en')
        .fallbackLanguage('en');
}

function authenticateRoutes($location, $rootScope, identity) {
    $rootScope.$on("$locationChangeStart", function (event, next, current) {
        if (identity.isAuthenticated)
            return;

        var unauthenticatedRoutes = [
            '/account/login'
        ];

        var locationToCheck = $location.path();

        if (unauthenticatedRoutes.indexOf(locationToCheck) > -1)
            return;

        $location.path('/account/login');
        event.preventDefault();
    });

}

function preventReload($location, $rootScope, $route) {
    // Prevent the $location.path from reloading if specified
    var original = $location.path;
    $location.path = function (path, reload) {
        if (reload === false) {
            var lastRoute = $route.current;
            var un = $rootScope.$on('$locationChangeSuccess', function () {
                $route.current = lastRoute;
                un();
            });
        }
        return original.apply($location, [path]);
    };
}

function toastConf(toastrConfig) {
    angular.extend(toastrConfig, {
        "closeButton": true,
        "debug": false,
        "positionClass": "toast-bottom-right",
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "2000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    });
}
