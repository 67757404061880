﻿module.exports = [
    '$anchorScroll',
    '$filter',
    '$routeParams',
    '$window',
    'common',
    'toastr',
    'berthgroupDataService',
    'businessruleDataService',
    'planningCalendarService',
    'planningDataService',
    'planningModals',
    'reservationDataService',
    'widgetModals',
    'communicatedStatusService',
    'constantsService', planningPublicBerthsInboxController];

function planningPublicBerthsInboxController($anchorScroll, $filter, $routeParams, $window, common, toastr, berthGroupData, businessRuleData, planningCalendar, planningData, planningModals, reservationData, widgetModals, communicatedStatusService, constantsService) {

    var vm = {
        addNewBerthBlocked: addNewBerthBlocked,
        attached: attached,
        calculateConsecutiveReservations: calculateConsecutiveReservations,
        cancelSelection: cancelSelection,
        communicatedStatus: [],
        confirmAll: confirmAll,
        dateTimeNow: moment().format(),
        disableBtn: false,
        display: {
            changeMinimumDate: null,
            isAdmin: false,
            isFiltered: isFiltered,
            planningMenuItem: 'messages',
            showReservations: false,
            textFilter: null,
            textFilterType: 'reservation',
            unrespondedMessages: 0
        },
        getRowsForBerth: getRowsForBerth,
        initialReservation: null,
        loading: {
            planned: false,
            unplanned: false
        },
        markPlanningItemAsDeleted: markPlanningItemAsDeleted,
        markBlockadeAsDeleted: markBlockadeAsDeleted,
        messages: [],
        newMessage: {
            isIntern: false,
            status: '1',
            text: null
        },
        openBerthDetailsPage: openBerthDetailsPage,
        planAll: planAll,
        planning: {
            berths: [],
            berthDetails: [],
            calendar: planningCalendar,
            endDate: null,
            exceptions: [],
            lastOverviewEndDate: null,
            lastOverviewStartDate: null,
            sanityChecks: [],
            startDate: null
        },
        planningErrors: [],
        queue: [],
        queueBlockades: [],
        reload: reload,
        saveMessage: saveMessage,
        saveSelection: saveSelection,
        saveStatus: saveStatus,
        selectedPlanning: null,
        selectedReservation: null,
        sendISPSNotification: sendISPSNotification,
        setSelection: setSelection,
        showShipDimensions: showShipDimensions,
        showWarnings: false,
        reservations: {
            canLoadMore: true,
            data: [],
            filterText: null,
            loadMore: loadMoreUnplanned,
            pageIndex: 0,
            pageSize: 20,
            status: null,
            updateSearch: updateSearch
        },
        updateSelection: updateSelection,
        suggestionsUnplanned: []
    };

    function addNewBerthBlocked() {
        var newRow = {
            assignedToBerthId: null,
            availableFrom: null,
            availableUntil: null
        };

        if (vm.selectedPlanning.planningItems.length > 0) {
            var previousRow = vm.selectedPlanning.planningItems[vm.selectedPlanning.planningItems.length - 1];
            newRow.assignedToBerthId = -1;
            newRow.reservationId = previousRow.reservationId;
            if (previousRow.eta !== null) {
                newRow.availableFrom = moment(previousRow.eta).format('YYYY-MM-DDTHH:mm:ss');
                newRow.etaDisplay = moment(previousRow.eta).format('DD-MM-YYYY HH:mm');
            }

            if (previousRow.etd !== null) {
                newRow.availableUntil = moment(previousRow.etd).format('YYYY-MM-DDTHH:mm:ss');
                newRow.etdDisplay = moment(previousRow.etd).format('DD-MM-YYYY HH:mm');
            }
        }

        vm.selectedPlanning.blockades.push(newRow);
    }

    function attached() {
        if (common.identity.hasPermissionForBusinessUnit('planning_admin', 1)) {
            vm.display.isAdmin = true;
        }

        vm.display.showReservations = common.$location.$$path.indexOf('/inbox') > -1;

        vm.planning.startDate = moment();
        vm.planning.lastOverviewStartDate = vm.planning.startDate;
        vm.planning.endDate = moment().add(6, 'days');
        vm.planning.lastOverviewEndDate = vm.planning.endDate;
        vm.display.changeMinimumDate = vm.planning.startDate.format('DD-MM-YYYY');

        if ($routeParams.reservationId) {
            vm.initialReservation = parseInt($routeParams.reservationId);
            setSelection({ reservationId: vm.initialReservation });
        }

        reload();

        berthGroupData.getBerthgroupsDetails([1])
            .then(function (result) {
                vm.planning.berths = result;
                calculateBerths();
            });
    }

    function calculateBerths() {
        var berths = [];
        for (var a = 0; a < vm.planning.berths.length; a++) {
            for (var b = 0; b < vm.planning.berths[a].berths.length; b++) {
                berths.push(vm.planning.berths[a].berths[b]);
            }
        }

        vm.planning.berthDetails = berths;
        calculateObstructions();
    }

    function calculateDays() {
        planningCalendar.calculateDays({
            endDate: vm.planning.endDate,
            startDate: vm.planning.startDate
        });
    }

    function calculateObstructions(obstructions) {
        planningCalendar.calculateBerths(vm.planning.berthDetails, obstructions);
    }

    function calculateConsecutiveReservations(includeSelectedPlanning) {
        var totalHours = 0;

        if (vm.selectedPlanning && vm.selectedPlanning.consecutiveReservations && vm.selectedPlanning.consecutiveReservations.length > 0) {
            for (var i = 0; i < vm.selectedPlanning.consecutiveReservations.length; i++) {
                var currentReservation = vm.selectedPlanning.consecutiveReservations[i];
                totalHours = totalHours + moment(currentReservation.etd).diff(moment(currentReservation.eta), 'hours');
            }

            if (includeSelectedPlanning === true && vm.selectedPlanning.planningItems && vm.selectedPlanning.planningItems.length > 0) {
                var items = _.sortBy(vm.selectedPlanning.planningItems, 'eta');
                var firstItem = _.first(items);
                var lastItem = _.last(items);

                var etaDisplay = moment(firstItem.etaDisplay, 'DD-MM-YYYY HH:mm');
                var etdDisplay = moment(lastItem.etdDisplay, 'DD-MM-YYYY HH:mm');

                var eta = etaDisplay.isValid() ? etaDisplay : moment(_.first(items).eta);
                var etd = etdDisplay.isValid() ? etdDisplay : moment(_.last(items).etd);

                totalHours = totalHours + etd.diff(eta, 'hours');
            }
        }

        return totalHours;
    }

    function cancelSelection(needEnterReason) {

        if (needEnterReason === true) {
            widgetModals.freeTextConfirm({
                title: "Reason",
                description: "Warning! You are about to cancel the reservation for this customer. Please enter a reason",
                required: true
            })
                .result
                .then(function (result) {
                    if (result.value !== undefined && result.value !== null && result.value.length > 0) {
                        var reservationId = vm.selectedReservation ? vm.selectedReservation.id : planning.planningItems[0].reservationId;
                        planningData.cancelReservation(reservationId, result.value)
                            .then(function (cancellationResult) {
                                vm.queueBlockades = _.map(_.filter(vm.selectedPlanning.blockades, function (b) { return b.id > -1; }), function (blockade) {
                                    blockade.isDeleted = true;
                                    return blockade;
                                });
                                saveNextBlockade(); // remove the linked blockades
                            });
                    }
                }, function () { });
        } else {
            widgetModals.areYouSure('Warning! You are about to cancel the reservation for this customer')
                .result
                .then(function (result) {
                    if (result === true) {
                        var reservationId = vm.selectedReservation ? vm.selectedReservation.id : planning.planningItems[0].reservationId;
                        planningData.cancelReservation(reservationId)
                            .then(function (cancellationResult) {
                                vm.queueBlockades = _.map(_.filter(vm.selectedPlanning.blockades, function (b) { return b.id > -1; }), function (blockade) {
                                    blockade.isDeleted = true;
                                    return blockade;
                                });
                                saveNextBlockade(); // remove the linked blockades
                            });
                    }
                }, function () { });
        }
    }

    function checkInitialSelection() {
        if (vm.initialReservation === null || vm.loading.planned == true || vm.loading.unplanned == true)
            return;

        var foundItem = _.find(planningCalendar.data.planned, function (item) {
            return item.reservationId == $routeParams.reservationId;
        });

        if (foundItem === undefined)
            foundItem = _.find(planningCalendar.data.unplanned, function (item) {
                return item.reservationId == $routeParams.reservationId;
            });

        if (foundItem !== undefined) {
            setSelection(foundItem);
        }

        vm.initialReservation = null;
    }

    function confirmAll() {
        var relevantItems = planningCalendar.getItemsInRange();

        var dlgResult = planningModals.confirmPlanning({
            endDate: vm.planning.endDate,
            items: relevantItems,
            startDate: vm.planning.startDate
        }).result.then(function (result) {
            loadPlannedReservations();
        });
    }

    function convertReservationToPlanning(reservation, convertDates) {
        var planning = {
            planningItems: [],
            reservationId: reservation.id,
            ship: reservation.ship
        };

        // First try to find an existing unplanned version of this reservation. 
        // If this exists it means there has been a suggestion for it.
        var _existing = _.filter(planningCalendar.data.unplanned, function (unplanned) {
            return unplanned.reservationId == reservation.id;
        });
        if (!_existing || _existing.length === 0) {
            _existing = _.find(vm.suggestionsUnplanned, function (unplanned) {
                return unplanned.reservation.id == reservation.id;
            }).planningItems;
        }

        if (_existing && _existing.length > 0) {
            planning.planningItems = _.map(_existing, function (item) {
                var _copyItem = common.copyObject(item);

                if (convertDates && !moment.isMoment(_copyItem.eta))
                    _copyItem.etaDisplay = moment(_copyItem.eta).format('DD-MM-YYYY HH:mm');
                if (convertDates && !moment.isMoment(_copyItem.etd))
                    _copyItem.etdDisplay = moment(_copyItem.etd).format('DD-MM-YYYY HH:mm');

                return _copyItem;
            });
        }
        else if (reservation.reservationItems && reservation.reservationItems.length > 0) {
            for (var a = 0; a < reservation.reservationItems.length; a++) {
                planning.planningItems.push({
                    assignedToBerthId: null,
                    etaDisplay: convertDates && !moment.isMoment(reservation.reservationItems[a].startsOn) ? moment(reservation.reservationItems[a].startsOn).format('DD-MM-YYYY HH:mm') : reservation.reservationItems[a].startsOn,
                    etdDisplay: convertDates && !moment.isMoment(reservation.reservationItems[a].endsOn) ? moment(reservation.reservationItems[a].endsOn).format('DD-MM-YYYY HH:mm') : reservation.reservationItems[a].endsOn
                });
            }

            if (planning.planningItems && planning.planningItems.length > 0) {
                // Always set the first ETA to the reservation ETA
                planning.planningItems[0].etaDisplay = moment(reservation.eta).format('DD-MM-YYYY HH:mm');
                // Always set the last ETD to the reservation ETD
                planning.planningItems[planning.planningItems.length - 1].etdDisplay = moment(reservation.etd).format('DD-MM-YYYY HH:mm');
            }
        }
        else {
            planning.planningItems.push({
                assignedToBerthId: null,
                etaDisplay: convertDates ? moment(reservation.eta).format('DD-MM-YYYY HH:mm') : reservation.eta,
                etdDisplay: convertDates ? moment(reservation.etd).format('DD-MM-YYYY HH:mm') : reservation.etd
            });
        }
        return planning;
    }

    function doSanityCheck(items, startDate, endDate) {
        vm.showWarnings = false;
        planningData.getSanityCheck(items, startDate, endDate, [1])
            .then(function (result) {
                planningCalendar.setSanityChecks(result);
            });
    }

    function ensureUnplanned(reservation) {
        var dfr = common.$q.defer();

        planningData.getSuggestion(reservation.id)
            .then(function (result) {
                if (result && result.length > 0) {
                    var existing = _.filter(planningCalendar.data.unplanned,
                        function (unplanned) {
                            return unplanned.reservationId === reservation.id;
                        });

                    // timing issue, cause of reload function, sometime the planningCalendar.data.unplanned is filled.
                    // so only add this planning item when the planningCalendar.data.unplanned is NOT filled; skip add this selected reservation into the array when the planningCalendar.data.unplanned is filled; otherwise the planningitem will show twice
                    if (!existing || existing.length === 0) {
                        planningCalendar.calculateItems([result[0].planning], 'unplanned', true);
                    }

                    if (result[0].planning !== undefined &&
                        result[0].planning !== null &&
                        result[0].planning.reservation !== undefined &&
                        result[0].planning.reservation !== null) {
                        reservation.consecutiveReservations = result[0].planning.reservation.consecutiveReservations;
                    }
                }

                dfr.resolve(reservation);
            }, dfr.reject);

        return dfr.promise;
    }

    function getMessages(reservationId) {
        vm.display.unrespondedMessages = 0;

        reservationData.getMessages(reservationId)
            .then(function (result) {
                vm.messages = result;

                for (var i = vm.messages.length - 1; i >= 0; i--) {
                    if (vm.messages[i].createdBy.portAuthorityId != null) {
                        if (vm.messages[i].isIntern)
                            continue;
                        else
                            break;
                    }

                    vm.display.unrespondedMessages++;

                }
            });
    }

    function getRowsForBerth(berth) {
        var items = $filter('calendarItems')(vm.planning.calendar.data.planned, berth);
        items = items.concat($filter('calendarItems')(vm.planning.calendar.data.unplanned, berth));

        if (!items || items.length == 0)
            return 1;

        var result = _.max(items, function (item) { return item.display.row + item.display.extraRows; });

        return result ? result.display.row + result.display.extraRows + 1 : 1;
    }

    function getStatus(reservationId) {
        planningData.getStatus(reservationId)
            .then(function (result) {
                vm.communicatedStatus = result;
            });
    }

    function isFiltered(fieldToFilter, valueToFilter) {
        // Hide berths that are not active at the moment
        if (fieldToFilter === 'berth') {
            if((valueToFilter.availableFrom && moment(valueToFilter.availableFrom).isBefore(vm.planning.startDate))
            && (valueToFilter.availableUntil && moment(valueToFilter.availableUntil).isAfter(vm.planning.endDate))) {
                return false;
            }
        } else if (fieldToFilter === 'berthgroup') {
            if (!valueToFilter.berths || valueToFilter.berths.length === 0) {
                return false;
            }

            if (_.filter(valueToFilter.berths, function (berth) { return isFiltered('berth', berth); }).length === 0) {
                return false;
            }
        }

        if (!fieldToFilter || fieldToFilter === '' || !valueToFilter || !vm.display.textFilter || vm.display.textFilter === '')
            return true;

        if (vm.display.textFilterType === 'berth') {
            if (fieldToFilter === 'berth') {
                if (valueToFilter.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
                    return true;
            }
            else if (fieldToFilter === 'berthgroup') {
                if (_.some(valueToFilter.berths, function (berth) { return isFiltered('berth', berth); }))
                    return true;
                if (valueToFilter.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
                    return true;
            }
            else if (fieldToFilter === 'planned')
                return true;
            return false;
        }
        else if (vm.display.textFilterType === 'reservation') {
            if (fieldToFilter === 'berth') {
                return _.some(vm.planning.calendar.data.planned, function (planned) {
                    return planned.assignedToBerthId === valueToFilter.id &&
                        (
                            (planned.reservationReference && planned.reservationReference.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1) ||
                            (planned.ship && planned.ship.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
                        );
                });
            }
            else if (fieldToFilter === 'berthgroup')
                return _.some(valueToFilter.berths, function (berth) { return isFiltered('berth', berth); });
            else if (fieldToFilter === 'planned') {
                if (valueToFilter.ship.name.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1)
                    return true;
                if (valueToFilter.reservationReference && valueToFilter.reservationReference.toLowerCase().indexOf(vm.display.textFilter.toLowerCase()) > -1) {
                    return true;
                }

                return false;
            }
        }

        return true;
    }

    function loadBerthObstructions() {
        businessRuleData.getByType(3, vm.planning.startDate.format('YYYY-MM-DD'), vm.planning.endDate.format('YYYY-MM-DD'))
            .then(function (result) {
                calculateObstructions(result);
                if (vm.selectedPlanning !== null) {
                    vm.selectedPlanning.blockades = _.filter(planningCalendar.data.berthObstructions, function (obstruction) {
                        return obstruction.reservationId !== undefined && obstruction.reservationId !== null && obstruction.reservationId === (vm.selectedPlanning.id ? vm.selectedPlanning.id : vm.selectedReservation.id);
                    });
                }
            });
    }

    function loadMoreUnplanned() {
        if (!vm.reservations.canLoadMore)
            return;

        vm.reservations.pageIndex += 1;
        loadUnplannedReservations();
    }

    function loadPlannedReservations() {
        vm.loading.planned = true;

        planningData.getPlanned(vm.planning.startDate.format('YYYY-MM-DD'), vm.planning.endDate.format('YYYY-MM-DD'), [1])
            .then(function (result) {
                planningCalendar.calculateItems(result, 'planned');

                doSanityCheck(result, vm.planning.startDate.format('YYYY-MM-DD'), vm.planning.endDate.format('YYYY-MM-DD'));
                vm.loading.planned = false;

                if (vm.initialReservation)
                    checkInitialSelection();
            }, function () {
                vm.loading.planned = false;

                if (vm.initialReservation)
                    checkInitialSelection();
            });
    }

    function loadSuggestions(reservations) {
        if (!reservations || reservations.length == 0)
            return;

        planningData.getSuggestions(vm.planning.startDate.format('YYYY-MM-DD'), vm.planning.endDate.format('YYYY-MM-DD'), [1])
            .then(function (result) {
                var suggestions = _.map(result, function (item) {
                    var planning = item.planning;
                    var res = _.find(reservations, function (reservation) {
                        return reservation.id == planning.reservation.id;
                    });
                    if (res) {
                        planning.customer = res.customer;
                        planning.ship = res.ship;
                    }

                    return planning;
                });
                vm.suggestionsUnplanned = suggestions;
                planningCalendar.calculateItems(suggestions, 'unplanned');
            });
    };

    function loadUnplannedReservations() {
        vm.loading.unplanned = true;

        reservationData.getUnplanned(vm.reservations.pageIndex, vm.reservations.pageSize, [1], vm.reservations.status, vm.reservations.filterText)
            .then(function (result) {
                if (vm.reservations.pageIndex === 0)
                    vm.reservations.data = result;
                else {
                    vm.reservations.data = vm.reservations.data.concat(_.sortBy(result, 'eta'));
                }
                loadSuggestions(result);
                vm.loading.unplanned = false;

                if (vm.initialReservation)
                    checkInitialSelection();

                vm.reservations.canLoadMore = result.length === vm.reservations.pageSize;
            }, function () {
                vm.loading.unplanned = false;

                if (vm.initialReservation)
                    checkInitialSelection();
            });
    }

    function markPlanningItemAsDeleted(itemId) {
        var itemIndex = _.findIndex(vm.selectedPlanning.planningItems, function (i) {
            return i.id === itemId;
        });

        if (itemIndex === -1)
            return;

        var item = vm.selectedPlanning.planningItems[itemIndex];
        if (item.id) {
            item.isDeleted = true; // item already saved, so mark als delete
        } else {
            vm.selectedPlanning.planningItems.splice(itemIndex, 1); // item is new, so remove the list
        }
    }

    function markBlockadeAsDeleted(itemId) {
        var itemIndex = _.findIndex(vm.selectedPlanning.blockades, function (i) {
            return i.id === itemId;
        });

        if (itemIndex === -1)
            return;

        var item = vm.selectedPlanning.blockades[itemIndex];
        if (item.id) {
            item.isDeleted = true; // item already saved, so mark als delete
        } else {
            vm.selectedPlanning.blockades.splice(itemIndex, 1); // item is new, so remove the list
        }
    }

    function openBerthDetailsPage() {
        $window.open('https://www.portofamsterdam.com/nl/scheepvaart/zeevaart/details-ligplaats', '_blank');
    }

    function planAll() {
        var relevantItems = _.filter(vm.reservations.data, function (item) {
            return moment(item.eta).isBefore(vm.planning.calendar.display.maximumDate) && moment(item.etd).isAfter(vm.planning.calendar.display.minimumDate);
        });

        var dlgResult = planningModals.makePlanning({
            endDate: vm.planning.endDate,
            items: relevantItems,
            startDate: vm.planning.startDate
        }).result.then(function (result) {
            loadUnplannedReservations();
        });
    }

    function reload(clearAll) {
        if (clearAll && clearAll == true) {
            planningCalendar.clearItems();
            vm.reservations.data = [];
            vm.disableBtn = false;
        }

        common.$timeout(function () {
            calculateDays();
            loadBerthObstructions();
            loadPlannedReservations();
            loadUnplannedReservations();
        }, 200);
    }

    function saveMessage() {
        if (!vm.newMessage.text || vm.newMessage.text === '')
            return;

        var reservationId = null;
        if (vm.selectedReservation && vm.selectedReservation.id)
            reservationId = vm.selectedReservation.id;
        else if (vm.selectedPlanning && vm.selectedPlanning.planningItems && vm.selectedPlanning.planningItems.length > 0)
            reservationId = vm.selectedPlanning.planningItems[0].reservationId;

        reservationData.addMessage(reservationId, vm.newMessage.text, vm.newMessage.isIntern, null, vm.newMessage.status)
            .then(function (result) {
                vm.newMessage.isIntern = false;
                vm.newMessage.status = '1';
                vm.newMessage.text = null;

                getMessages(reservationId);
            });

    }

    function saveNextPlanningItem() {
        if (vm.queue.length === 0) {
            saveNextBlockade();
            return;
        }

        var nextItem = vm.queue.splice(0, 1)[0];

        if (nextItem.isDeleted === true) {
            planningData.deletePlanningItem(nextItem.id)
                .then(saveNextPlanningItem);
        } else if (nextItem.id) {
            planningData.updatePlanningItem(nextItem)
                .then(saveNextPlanningItem);
        } else {

            planningData.addPlanningItem(nextItem)
                .then(saveNextPlanningItem);
        }

    }

    function saveNextBlockade() {
        if (vm.queueBlockades.length === 0) {
            setSelection(null);
            toastr.success("Changes Saved");
            reload();
            return;
        }

        var nextItem = vm.queueBlockades.splice(0, 1)[0];

        if (nextItem.isDeleted === true) {
            if (nextItem.id) {
                businessRuleData.del(nextItem)
                    .then(saveNextBlockade);
            } else {
                saveNextBlockade();
            }
        } else if (nextItem.id) {
            businessRuleData.update(nextItem)
                .then(saveNextBlockade);
        } else {
            businessRuleData.add(nextItem)
                .then(saveNextBlockade);
        }

    }

    function saveSelection() {
        if (!vm.selectedPlanning.planningItems || vm.selectedPlanning.planningItems.length == 0)
            return;

        vm.selectedPlanning.planningItems = _.map(vm.selectedPlanning.planningItems, function (planningItem) {
            planningItem.eta = moment(planningItem.etaDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            if (planningItem.eta === 'Invalid date:00')
                planningItem.eta = null;
            planningItem.etd = moment(planningItem.etdDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            if (planningItem.etd === 'Invalid date:00')
                planningItem.etd = null;
            return planningItem;
        });

        vm.selectedPlanning.blockades = _.map(vm.selectedPlanning.blockades, function (blockade) {
            blockade.availableFrom = moment(blockade.etaDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            if (blockade.availableFrom === 'Invalid date:00')
                blockade.availableFrom = null;
            blockade.availableUntil = moment(blockade.etdDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            if (blockade.availableUntil === 'Invalid date:00')
                blockade.availableUntil = null;
            return blockade;
        });

        // Validate the items
        var errors = [];

        _.each(vm.selectedPlanning.planningItems, function (item, index) {
            if (item.isDeleted === true) return; // don't validate when the item needs to delete

            if (!item.eta)
                errors.push('No ETA specified for item ' + (index + 1));
            if (!item.etd)
                errors.push('No ETD specified for item ' + (index + 1));
            if (item.eta > item.etd)
                errors.push('ETA cannot be after ETD for item ' + (index + 1));
            if (item.etd < item.eta)
                errors.push('ETD cannot be before ETA for item ' + (index + 1));
            if (!item.assignedToBerthId || item.assignedToBerthId === -1)
                errors.push('No berth specified for item ' + (index + 1));
        });

        _.each(vm.selectedPlanning.blockades, function (blockade, index) {
            if (blockade.isDeleted === true) return; // don't validate when the item needs to delete

            if (!blockade.availableFrom)
                errors.push('No ETA specified for blockade ' + (index + 1));
            if (!blockade.availableUntil)
                errors.push('No ETD specified for blockade ' + (index + 1));
            if (!blockade.assignedToBerthId || blockade.assignedToBerthId === -1)
                errors.push('No berth specified for blockade ' + (index + 1));
        });
        if (errors.length > 0) {
            vm.planningErrors = errors;
            return;
        }

        vm.planningErrors = [];
        vm.queueBlockades = _.map(vm.selectedPlanning.blockades, function (blockade) {
            var obj = common.copyObject(blockade);
            obj.berthId = blockade.assignedToBerthId;
            obj.businessRuleId = 3; // Berth blocked
            obj.startsOn = blockade.availableFrom;
            obj.endsOn = blockade.availableUntil;
            obj.remarks = vm.selectedReservation.id + ':' + vm.selectedReservation.ship.name;
            return obj;
        });
        if (vm.selectedPlanning.planningItems[0].id == null || vm.selectedPlanning.planningItems[0].id <= 0) {
            planningData.addPlanning(vm.selectedPlanning)
                .then(function () {
                    saveNextBlockade();
                });
        } else {
            vm.queue = common.copyObject(vm.selectedPlanning.planningItems);
            vm.disableBtn = true;
            saveNextPlanningItem();
        }
    }

    function saveStatus() {

        var status = 3;
        var statuses = _.filter(vm.communicatedStatus, function (item) { return item.status !== 9; }); // filter the send ISPS status
        if (vm.selectedPlanning && vm.selectedPlanning.reservationId && !communicatedStatusService.hasCommunicatedStatus(statuses, status, true)) {
            planningData.setStatus(vm.selectedPlanning.reservationId, { status: status }) // set status to berth confirm when the last status is not berth confirm
                .then(function () {
                    vm.saveSelection();
                });
        } else {
            vm.saveSelection();
        }
    }

    function sendISPSNotification() {
        planningData.sendISPSNotification(vm.selectedPlanning.reservationId)
            .then(function (result) {
                vm.communicatedStatus = result;
                toastr.success('The ISPS notification has been sent');
            });
    }

    function setSelection(selection) {
        vm.display.planningMenuItem = 'planning';
        vm.selectedPlanning = null;
        vm.selectedReservation = null;
        vm.communicatedStatus = [];
        vm.dateTimeNow = moment().format();

        if (!selection) {
            reload(true);
            return;
        }
        else if (selection.changedPlanningId) {
            // If the plannig is already loaded, just treat it as a normal planning selection
            if (_.find(planningCalendar.data.planned, function (item) {
                return item.reservationId === selection.changedPlanningId;
            }) !== undefined) {
                setSelection({
                    planningId: selection.changedPlanningId
                });
                return;
            }
            // Otherwise load it from the API
            planningData.getPlannedById(selection.changedPlanningId)
                .then(function (result) {
                    if (result && result.planningItems && result.planningItems.length > 0) {
                        planningCalendar.calculateItems([result], 'planned', true);
                        setSelection({ planningId: selection.changedPlanningId });
                    }
                    else {
                        setSelection({ reservationId: selection.changedPlanningId });
                    }
                });

            // Set the date range to the reservation times
            var origEta = moment(selection.reservation.eta);
            var origEtd = moment(selection.reservation.etd);

            vm.planning.startDate = origEta.add(-1, 'days');
            vm.planning.endDate = origEtd.add(1, 'days');
            calculateDays();
            return;
        }
        else if (selection.reservationId) {
            var _res = common.copyObject(_.find(vm.reservations.data, function (r) { return r.id == selection.reservationId }));

            if (_res) {
                vm.selectedReservation = _res;

                // ensure the suggestion is there
                ensureUnplanned(_res)
                    .then(function (result) {
                        vm.selectedPlanning = convertReservationToPlanning(_res, true);
                        vm.selectedPlanning.consecutiveReservations = _res.consecutiveReservations;

                        var origEta = moment(_res.eta);
                        var origEtd = moment(_res.etd);

                        vm.planning.startDate = origEta.add(-1, 'days');
                        vm.planning.endDate = origEtd.add(1, 'days');
                        vm.display.showReservations = true;
                        reload(true);
                    });
            } else {
                reservationData.getDetails(selection.reservationId)
                    .then(function (reservation) {
                        if (reservation.plannedEta === null) {
                            ensureUnplanned(reservation)
                                .then(function (result) {
                                    vm.selectedReservation = reservation;
                                    vm.selectedPlanning = convertReservationToPlanning(reservation, true);
                                    vm.selectedPlanning.consecutiveReservations = reservation.consecutiveReservations;

                                    var origEta = moment(reservation.eta);
                                    var origEtd = moment(reservation.etd);

                                    vm.planning.startDate = origEta.add(-1, 'days');
                                    vm.planning.endDate = origEtd.add(1, 'days');
                                    vm.display.showReservations = true;
                                    reload(true);
                                });
                        } else {
                            setSelection({ changedPlanningId: selection.reservationId, reservation: reservation });
                            vm.display.showReservations = true;
                        }
                    }, function () { });
            }


            //reload(true);

            //planningCalendar.recalculate(origEta.add(-1, 'days'), origEtd.add(1, 'days'));
            getMessages(selection.reservationId);
        }
        else if (selection.planningId) {
            var _plan = common.copyObject(
                _.map(
                    _.filter(planningCalendar.data.planned, function (r) {
                        return r.reservationId == selection.planningId;
                    }), function (m) {
                        m.etaDisplay = (moment.isMoment(m.eta) ? m.eta : moment(m.eta)).format('DD-MM-YYYY HH:mm');
                        m.etdDisplay = (moment.isMoment(m.etd) ? m.etd : moment(m.etd)).format('DD-MM-YYYY HH:mm');
                        return m;
                    }));

            if (_plan && _plan.length > 0) {
                vm.selectedPlanning = {
                    eta: _plan[0].eta,
                    etd: _plan[_plan.length - 1].etd,
                    id: selection.planningId,
                    planningItems: _plan,
                    reservationId: selection.planningId
                };
            }

            reservationData.getDetails(selection.planningId)
                .then(function (reservation) {
                    // Add the planned eta and etd if they are different from those in the reservation
                    // The check is made in the directive caculating difference by hours. 
                    if (vm.selectedPlanning) // && vm.selectedPlanning.eta && vm.selectedPlanning.eta !== reservation.eta)
                    {
                        reservation.etaOriginal = vm.selectedPlanning.eta;
                        //if (vm.selectedPlanning) // && vm.selectedPlanning.etd && vm.selectedPlanning.etd !== reservation.etd)
                        reservation.etdOriginal = vm.selectedPlanning.etd;
                        vm.selectedPlanning.consecutiveReservations = reservation.consecutiveReservations;
                    }

                    vm.selectedReservation = reservation;

                }, function () { });

            reload(true);

            getMessages(selection.planningId);
            getStatus(selection.planningId);
        }
        if (vm.selectedReservation && vm.selectedReservation.reservationItems) {
            vm.selectedReservation.reservationItems.forEach(function (item) {
                if (!item.berth && item.berthId) {
                    item.berth = _.find(constantsService.berths, function (berth) {
                        return berth.id === item.berthId;
                    });
                }
            });
        }
        $anchorScroll();
    };

    function showShipDimensions(ship) {
        if (ship !== undefined && ship !== null) {
            var values = [];
            if (ship.length !== undefined && ship.length !== null) {
                values.push(ship.length + " (L)");
            }

            if (ship.width !== undefined && ship.width !== null) {
                values.push(ship.width + " (W)");
            }

            if (ship.height !== undefined && ship.height !== null) {
                values.push(ship.height + " (H)");
            }

            if (values.length > 0) {
                return values.join(" x ");
            }
        }

        return "";
    }

    function updateSearch(needTimeout) {
        // use time out, give the user some time to finish enter the filter
        vm.reservations.pageIndex = 0;
        vm.reservations.pageSize = 10;

        var timer = needTimeout === true ? 2000 : 0;
        common.$timeout(function () {
            loadUnplannedReservations();
        }, timer);
    }

    function updateSelection() {
        var planning = common.copyObject(vm.selectedPlanning);

        var reservationId = vm.selectedReservation ? vm.selectedReservation.id : planning.planningItems[0].reservationId;

        for (var i = 0; i < planning.planningItems.length; i++) {
            planning.planningItems[i].eta = moment(planning.planningItems[i].etaDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
            planning.planningItems[i].etd = moment(planning.planningItems[i].etdDisplay, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm') + ':00';
        }

        planningCalendar.updateReservation(reservationId, planning);

        if (vm.selectedReservation) {
            var origEta = moment(vm.selectedReservation.eta);
            var origEtd = moment(vm.selectedReservation.etd);
            planningCalendar.recalculate(origEta.add(-1, 'days'), origEtd.add(1, 'days'));
        }
    }

    return vm;

};