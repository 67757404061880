﻿'use strict';

angular.module('poa.rprp.sailingReport', [])
    .config(['$routeProvider', routeConfig])
    .controller('sailingReportIssuesController', require('./controllers/sailing-report-issues'))
    .controller('sailingReportOverviewApproveController', require('./controllers/sailing-report-approve'))
    .directive('sailingReportItemTable', require('./directives/sailing-report-item-table'))
    .factory('sailingReportDataService', require('./services/sailing-report-data'))
    .factory('sailingReportModals', require('./modals/modals'))
    .filter('sailingReportStatus', require('./filters/sailing-report-status'))
    .filter('sailingReportPenaltyStatus', require('./filters/sailing-report-penalty-status'))
    ;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/sailing-report/issues', {
            templateUrl: 'templates/sailing-report/controllers/sailing-report-issues.html'
        })
        .when('/sailing-report/overview/approve', {
            templateUrl: 'templates/sailing-report/controllers/sailing-report-approve.html'
        })
        ;
}
