﻿module.exports = [reservationCommunicatedStatusLabelDirective];

function reservationCommunicatedStatusLabelDirective() {
    var directive = {
        bindToController: true,
        controller: reservationCommunicatedStatusLabelController,
        controllerAs: 'ctrlReservationCommunicatedStatusLabel',
        restrict: 'E',
        scope: {
            communicatedStatus: '='
        },
        templateUrl: 'templates/planning/directives/reservation-communicated-status-label.html'
    };

    return directive;

    function reservationCommunicatedStatusLabelController() {

        var vm = {
        };

        return vm;
    }
}