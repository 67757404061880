﻿module.exports = ['constantsService', 'fleetDataService', '$uibModal', fleetIssuesController];

function fleetIssuesController(constantsService, fleetDataService, $uibModal) {

    var vm = {
        attached: attached,
        isLoading: false,
        items: [],
        fileArray: [],
        loadItems: loadItems,
        redirectToCustomer: redirectToCustomer,
        selectedShipType: null,
        importShipTypes: [],
        importExternalShips: importExternalShips
    };

    function attached() {
        loadItems();
        loadShipTypes();
    }

    function loadItems() {
        vm.isLoading = true;

        fleetDataService.getIssues()
            .then(function (result) {
                vm.items = result;
                vm.isLoading = false;
            });

    }

    function loadShipTypes() {
        _.each(constantsService.shipTypes, function (shipType) {
            if (shipType.id === 4 || shipType.id === 5) {
                vm.importShipTypes.push(shipType);
            }
        });

        vm.selectedShipType = vm.importShipTypes[0];
    }

    function importExternalShips() {
        var file = vm.fileArray[0];

        if (!file) {
            return;
        } 

        modalImportSuccessController.$inject = ['$uibModalInstance'];

        function modalImportSuccessController($uibModalInstance) {
            var vm = {
                ok: ok
            };

            return vm;

            function ok() {
                $uibModalInstance.close(true);
            }
        }

        fleetDataService.importExternalShips(vm.selectedShipType.name, file)
            .then(function (result) {
                if (result) {
                    $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: 'templates/fleet/modals/import-ships-success.html',
                        controllerAs: 'vm',
                        controller: modalImportSuccessController,
                        bindToController: true
                    });
                }
            });
    }

    function redirectToCustomer(customerId, shipId) {
        common.$location.path('/customers/' + customerId + '/ship/' + shipId);
    }

    return vm;
}
