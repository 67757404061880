﻿module.exports = [facilityFormDirective];

function facilityFormDirective() {
    var directive = {
        bindToController: true,
        controller: facilityFormController,
        controllerAs: 'ctrlFacilityForm',
        restrict: 'E',
        scope: {
            facilityData: '='
        },
        templateUrl: 'templates/facility/directives/facility-form.html'
    };

    return directive;

    function facilityFormController() {
        var ctrlUserForm = this;
    };

};
