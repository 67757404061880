﻿module.exports = [thSortableHeaderDirective];

function thSortableHeaderDirective() {
    var directive = {
        restrict: 'A',
        scope: {
            displayText: '@',
            sortDescending: '=',
            sortField: '=',
            sortValue: '@'
        },
        templateUrl: 'templates/widgets/directives/th-sortable-header.html'
    };

    return directive;
};