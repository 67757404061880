﻿module.exports = [roleModels];

function roleModels() {

    var service = {
        roleModel: roleModel,
        validateRole: validateRole
    };

    return service

    function roleModel(roleData) {
        var roleModel = {
            id: null,
            internalName: null,
            isPublic: null,
            description: null,
            name: null,
            permissions: []
        };

        if (roleData === undefined || roleData === null)
            return roleData;

        roleModel.id = roleData.id;
        roleModel.internalName = roleData.internalName;
        roleModel.isPublic = roleData.isPublic;
        roleModel.description = roleData.description;
        roleModel.name = roleData.name;
        roleModel.permissions = roleData.permissions;

        return roleModel;
    };

    function validateRole(role) {
        var errors = [];

        if (role.name === undefined || role.name === null || role.name === '')
            errors.push('Please specify a display name');

        if (role.internalName === undefined || role.internalName === null || role.internalName === '')
            errors.push('Please specify an internal name');

        return errors.length > 0 ? errors : true;
    };

};
