﻿'use strict';

module.exports = ['goalModelsService', goalOverviewDirective];

function goalOverviewDirective(goalModels) {
    var directive = {
        bindToController: true,
        controller: goalOverviewController,
        controllerAs: 'ctrlGoalOverview',
        restrict: 'E',
        scope: {
            activeGoal: '=',
            goalsData: '=',
            goalType: '@'
        },
        templateUrl: 'templates/goal/directives/goal-overview.html'
    };

    return directive;

    function goalOverviewController() {
        var vm = {
            setActiveGoal: setActiveGoal,
            selectedLink: selectedLink,
            currentlySelected: false
        };

        return vm;

        function setActiveGoal(goal) {
            //if new clicked!!!
            if (!goal) {
                goal = new goalModels.goalModel();
                goal.goalType = vm.goalType.toLowerCase();
            }

            vm.activeGoal = goal;
        }
        function selectedLink(id) {
            if (vm.activeGoal) {
                if (parseInt(id) === parseInt(vm.activeGoal.id)) {
                    vm.currentlySelected = true;
                    return vm.currentlySelected;
                }
            }
        }
    }

}