﻿module.exports = ['common', 'dataContext', shipPropertyService];

function shipPropertyService(common, context) {

    var service = {
        addProperty: addProperty,
        deleteProperty: deleteProperty,
        updateProperty: updateProperty
    };

    return service;

    function addProperty(property) {
        //ship Id included in property model
        var deferred = common.$q.defer();
        var req = context.post('/ship/' + property.shipId + '/property', property)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function deleteProperty(property) {
        var deferred = common.$q.defer();
        var req = context.del('/ship/' + property.shipId + '/property/' + property.id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function updateProperty(property) {
        var deferred = common.$q.defer();
        var req = context.put('/ship/' + property.shipId + '/property/' + property.id, property)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}