﻿module.exports = ['identity', 'userAccountService', profileController];

function profileController(identity, userAccountService) {
    var vm = {
        saveCustomerInfo: saveCustomerInfo,
        saveProfile: saveProfile,
        userAccount: identity.userInfo
    };

    function init() {
        /*
        userAccountService.getUserInfo()
            .then(function (result) {
                vm.userAccount = result;
            });
        */
    };

    function saveCustomerInfo() {
        userAccountService.saveCustomerInfo(vm.userAccount.customer)
            .then(function () {

            });
    };

    function saveProfile() {
        userAccountService.saveUserInfo(vm.userAccount)
            .then(function () {

            });
    };

    init();

    return vm;

};
