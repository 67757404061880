﻿module.exports = ['$uibModalInstance', 'planningDataService', 'modalArguments', 'constantsService', confirmPlanningModalController];

function confirmPlanningModalController($uibModalInstance, planningData, args, constants) {
    var vm = {
        args: args,
        cancel: cancel,
        isSaving: false,
        showOnlyBerthOption: false,
        ok: ok,
        selectedItems: [],
        selectedStatus: '1',
        setCheckboxes: setCheckboxes,
        toggleItem: toggleItem
    };

    if (vm.args.items) {
        vm.args.items = _.filter(vm.args.items, function (item) { return item.communicatedStatus !== undefined && item.communicatedStatus.status != 3;});
        vm.showOnlyBerthOption = _.every(_.keys(_.groupBy(vm.args.items, function (reservation) { return reservation.ship ? reservation.ship.shipTypeId : 0; })), function (key) { return constants.isBusinessUnitShipType(1, key); });
        if (vm.showOnlyBerthOption === true) {
            vm.selectedStatus = '3'; // default select berth
        }
    }
        

    setCheckboxes();

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function setCheckboxes() {
        vm.selectedItems = _.filter(_.map(vm.args.items, function (item) {
            if (!item.communicatedStatus || item.communicatedStatus.length == 0 || item.communicatedStatus.status < vm.selectedStatus)
                return item.reservationId;
            return null;
        }), function (filteredItem) {
            return filteredItem != null;
        });
    }

    function toggleItem(itemId) {
        if (!itemId)
            return;

        if (vm.selectedItems.indexOf(itemId) > -1)
            vm.selectedItems.splice(vm.selectedItems.indexOf(itemId), 1);
        else
            vm.selectedItems.push(itemId);
    }

    function ok() {
        vm.isSaving = true;
        saveNextItem();
    }

    function saveNextItem() {
        if (!vm.selectedItems || vm.selectedItems.length == 0) {
            vm.isSaving = false;
            $uibModalInstance.close(true);
            return;
        }

        var nextItem = vm.selectedItems.splice(0, 1);
        planningData.communicateStatus(nextItem, parseInt(vm.selectedStatus), null)
            .then(saveNextItem, saveNextItem);
    }

    return vm;
};