﻿module.exports = ['constantsService', 'coreDataService', 'customerDataService', customerFormDirective];

function customerFormDirective(constants, coreDataService, customerData) {
    var directive = {
        bindToController: true,
        controller: customerFormController,
        controllerAs: 'ctrlCustomerForm',
        restrict: 'E',
        scope: {
            customerData: '='
        },
        templateUrl: 'templates/customer/directives/customer-form.html'
    };

    return directive;

    function customerFormController() {
        var vm = {
            shipTypes: constants.shipTypes,
            portauthorities: []
        };

        init();

        return vm;

        function init() {
            portAuthorities = coreDataService.getPortauthorities()
                .then(function (data) {
                    vm.portauthorities = data;
                });
        }
    }

}
