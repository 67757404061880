﻿module.exports = [berthModels];

function berthModels() {

    var service = {
        berthModel: berthModel
    };

    return service;

    function berthModel(berthData) {
        var result = {
            id: null,
            name: null,
            isDivisible: null,
            portAuthorityId: null,
            capacity: null,
            length: null,
            availabilityStartsOn: null,
            availabilityEndsOn: null,
            berthCode: null
        };

        if (berthData === undefined || berthData === null)
            return result;

        result.id = berthData.id;
        result.name = berthData.name;
        result.isDivisible = berthData.isDivisible;
        result.portAuthorityId = berthData.portAuthorityId;
        result.capacity = berthData.capacity;
        result.length = berthData.length;
        result.availabilityStartsOn = berthData.availabilityStartsOn;
        result.availabilityEndsOn = berthData.availabilityEndsOn;
        result.berthCode = berthData.berthCode;

        return result;
    }

}