module.exports = [planningCalendarDirective];

function planningCalendarDirective() {
	return {
		bindToController: true,
		controller: () => {},
		controllerAs: 'ctrlPlanningCalendar',
		restrict: 'E',
		scope: {
			apiSource: '<',
			data: '<',
			days: '<',
			berthGroups: '<',
			selectedPlanning: '<',
			selectedReservation: '<',
			display: '<',
			getRowsForBerth: '<',
			selectPlanning: '<',
			selectReservation: '<'
		},
		templateUrl: 'templates/planning/directives/planning-calendar.html'
	}
}
