﻿module.exports = ['common',  '$filter', 'reportDataService', reportResponseTimeController];

function reportResponseTimeController(common, $filter,  reportData) {

    var vm = {
        attached: attached,
        common: common,
        downloadReport: downloadReport,
        filter: {
            endDate: null,
            reservationYear: null,
            startDate: null
        },
        years: [],
        responseTimes: [],
        loading: true,
        reload: reload
    };

    function attached() {
        vm.filter.startDate = moment().add(-1, 'months');
        vm.filter.endDate = moment();
        vm.filter.reservationYear = moment().year().toString();

        for (var i = 2017; i < moment().year() + 2; i++) {
            vm.years.push(i);
        }

        reload(true);
    }

    function downloadReport() {
        var csvOutput = 'Reservation reference;Customer;Ship;ShipType;Request user;Request type;Request date;Response date;Response time in hours;Reservation ETA;Response user\r\n';

        for (var j = 0; j < vm.responseTimes.length; j++) {
            var data = vm.responseTimes[j];
            csvOutput += data.reservationReference + ';';
            csvOutput += data.customerName + ';';
            csvOutput += data.shipName + ';';
            csvOutput += $filter('shipTypeDisplay')(data.shipTypeId)+ ';';
            csvOutput += data.requestingUser + ';';
            csvOutput += data.requestType + ';';
            csvOutput += moment(data.requestDate).format('DD-MM-YYYY HH:mm') + ';';
            csvOutput += moment(data.responseDate).format('DD-MM-YYYY HH:mm') + ';';
            csvOutput += data.responseTime + ';';
            csvOutput += moment(data.reservationETA).format('DD-MM-YYYY HH:mm') + ';';
            csvOutput += data.responseUser + ';';
            csvOutput += '\r\n';
        }
        common.exportToCSV(csvOutput, 'report-responsetime-' + moment().format('YYYYMMDDHHmm'));
    }

    function reload(clearAll) {
        if (clearAll && clearAll === true)
            vm.responseTimes = [];

        loadReport();
    }

    function loadReport() {
        vm.loading = true;
        reportData.getReportResponseTime(vm.filter.startDate.format('YYYY-MM-DD'), vm.filter.endDate.format('YYYY-MM-DD'), vm.filter.reservationYear)
            .then(function (result) {
                for (var i = 0; i < result.length; i++) {
                    if (result[i].responseTime) {
                        var totalHours = Math.floor(result[i].responseTime / 60);
                        var remainingMinutes = result[i].responseTime - (totalHours * 60);
                        result[i].responseTime = totalHours + ':' + common.formatting.padLeft(remainingMinutes.toString(), '0', 2);
                    }
                }

                vm.responseTimes = result;
                vm.loading = false;
            });
    }

    return vm;
}