﻿module.exports = ['$uibModal', sailingReportModalsService];

function sailingReportModalsService($uibModal) {

    var service = {
        approveReports: approveReports
    };

    return service;

    function approveReports(reportItems) {
        approveReportsController.$inject = ['$uibModalInstance', 'invoiceDataService'];

        function approveReportsController($uibModalInstance, invoiceDataService) {
            var vm = {
                attached: attached,
                cancel: cancel,
                errors: [],
                items: reportItems,
                loadingInvoices: false,
                ok: ok,
                problemItems: []
            };

            function attached() {
                vm.problemItems = _.filter(vm.items, function (item) {
                    return (item.paxEmbarking === null || item.paxEmbarking === 0) && (item.paxDisembarking === null || item.paxDisembarking === 0) && (item.paxTransit === null || item.paxTransit === 0);
                });
                if (vm.problemItems.length > 0) {
                    vm.loadingInvoices = true;

                    generateNextPreview();
                }
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }

            function generateNextPreview() {
                var nextProblem = _.find(vm.problemItems, function (item) { return !item.invoicePreview; });
                if (nextProblem) {
                    invoiceDataService.getPreviewForSailingReport(nextProblem.id)
                        .then(function (invoice) {
                            if (invoice.invoice) {
                                invoice.invoice.totalAmount = _.reduce(invoice.invoice.invoiceItems, function (memo, item) {
                                    return Math.round((memo + item.totalAmount) * 100) / 100;
                                }, 0);
                            }

                            _.find(vm.problemItems, function (item) { return item.id === nextProblem.id; }).invoicePreview = invoice;
                            generateNextPreview();
                        });
                }
                else
                    vm.loadingInvoices = false;
            }

            function ok() {
                $uibModalInstance.close(vm.items);
            }

            return vm;
        };

        var modalResult = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/sailing-report/modals/approve-reports.html',
            controllerAs: 'vm',
            controller: approveReportsController
        });

        return modalResult;

    };

};