﻿module.exports = ['$routeParams', 'common', 'dataContext', 'userDataService', 'roleDataService', reportUserRolesController];

function reportUserRolesController($routeParams, common, context, usersData, rolesData) {

    var vm = {
        loading: true,
        getReport: getReport,
        users: [],
        roles: [],
        isInRelationship: isInRelationship,
        downloadReport: downloadReport
    };

    function getReport() {

        usersData.getUsers()
            .then(function(result) {
                vm.users = result;

                return rolesData.getAll();
            })
            .then(function(result) {
                vm.roles = _.sortBy(result, 'name');
                vm.loading = false;
            });
    }

    function isInRelationship(user, index) {
        var role = vm.roles[index];
        var roles = _.uniq(_.map(user.roles, 'roles').reduce(function (a, b) {
            return Array.from(a).concat(Array.from(b));
        }, []), 'id');

        var found = roles.find(function (item) {
            return item.id === role.id;
        });

        if (found === undefined) {
            return false;
        }

        return true;
    }

    function downloadReport() {
        var csvOutput = 'User name;';

        for (var i = 0; i < vm.roles.length; i++) {
            csvOutput += vm.roles[i].name + ';';
        }

        csvOutput += '\r\n';

        for (var j = 0; j < vm.users.length; j++) {
            var user = vm.users[j];
            csvOutput += user.name + ';';

            for (var k = 0; k < vm.roles.length; k++) {
                if (vm.isInRelationship(user, k)) {
                    csvOutput += 'x;';
                } else {
                    csvOutput += ';';
                }
            }
            csvOutput += '\r\n';
        }
        common.exportToCSV(csvOutput,'report-user-roles-' + moment().format('YYYYMMDDHHmm'));
    }

    return vm;
}