﻿module.exports = ['$routeParams', 'common', 'facilityDataService', 'facilityModelsService', 'widgetModals', facilityOverviewController];

function facilityOverviewController($routeParams, common, facilityData, facilityModels, widgetModals) {

    var vm = {
        addFacility: addFacility,
        attached: attached,
        common: common,
        deleteFacility: deleteFacility,
        editingFacility: null,
        newFacility: new facilityModels.facilityModel(),
        saveFacility: saveFacility,
        status: {
            completed: false,
            errors: [],
            saving: false,
            setAddNew: setAddNew,
            setEdit: setEdit,
            showAddNew: false
        },
        facilitys: [],
        selectedLink: selectedLink,
        currentlySelected: false
    };
    function selectedLink(id) {
        if (vm.editingFacility) {
            if (parseInt(id) === parseInt(vm.editingFacility.id)) {
                vm.currentlySelected = true;
                return vm.currentlySelected;
            }
        }
    }
    function addFacility() {
        vm.status.saving = true;
        facilityData.addFacility(vm.newFacility)
            .then(function (result) {
                vm.facilitys.push(result);
                vm.status.saving = false;
                vm.status.showAddNew = false;
            });
    }

    function attached() {
        facilityData.getFacilitys()
            .then(function (result) {
                vm.facilitys = result;
            });

        var facilityId = $routeParams.facilityId;
        if (facilityId !== undefined && facilityId !== null && facilityId !== '' && facilityId > 0) {
            facilityData.getFacility(facilityId)
            .then(function (result) {
                if (result !== undefined && result !== null) {
                    vm.status.setEdit(result);
                }
            });
        }
    }

    function deleteErrorResult(response) {
        vm.status.errors = common.errorToText(response);
        vm.status.saving = false;
    }

    function deleteSuccessResult(response) {
        var iUser = _.findIndex(vm.facilitys, function (item) { return item.id === vm.editingFacility.id; });
        vm.facilitys.splice(iUser, 1);
        vm.editingFacility = null;
        vm.status.saving = false;
    }

    function deleteFacility(user) {
        vm.status.errors = [];

        widgetModals.areYouSure('You want to delete this facility?').result
            .then(function (result) {
                if (result === true) {
                    vm.status.saving = true;
                    facilityData.deleteFacility(vm.editingFacility)
                    .then(deleteSuccessResult, deleteErrorResult);
                }
            });
    }

    function saveFacility() {
        vm.status.errors = [];

        vm.status.saving = true;
        facilityData.saveFacility(vm.editingFacility)
            .then(successResult, errorResult);

        function errorResult(response) {
            vm.status.errors = common.errorToText(response);
            vm.status.saving = false;
        }

        function successResult(result) {
            vm.editingFacility = null;
            vm.status.completed = true;
            vm.status.saving = false;
        }
    }

    function setAddNew() {
        vm.editingFacility = null;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = true;
    }

    function setEdit(facility) {
        vm.editingFacility = facility;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = false;
    }

    return vm;
}
