﻿'use strict';
module.exports = ['common', 'dataContext', constantsService];

function constantsService(common, context) {
    var qPreload = common.$q.defer();
    var constants = {
        elementsToLoad: 0,
        init: init,
        preloaded: qPreload.promise,
        shipTypes: [], // this will be filled after loaded business units
        shipPropertyTypes: [],
        berths: [],
        businessrules: [],
        businessUnits: [],
        berthGroups: [],
        hasBusinessUnit: hasBusinessUnit,
        isBusinessUnitShipType: isBusinessUnitShipType,
        getFlowsForPermissions:getFlowsForPermissions,
        processFlows: [],
        regions: [],
        sailingReportStatus: [
            { id: 1, name: 'Open' },
            { id: 2, name: 'Invoiced' },
            { id: 3, name: '1st reminder sent' },
            { id: 4, name: '2nd reminder sent' }
        ],
        sailingReportPenaltyStatus: [
            { id: 1, name: 'Pending (changed report)' },
            { id: 2, name: 'Pending (no declaration)' },
            { id: 3, name: 'Confirmed' },
            { id: 4, name: 'Approved' }
        ],
        connectorTypes:[
            { id: -1, name: ''},
            { id: 0, name: 'Other'},
            { id: 1, name: '1 large plug (total of 250 Amp)'},
            { id: 2, name: '2 medium plugs (total of 250 Amp)'},
            { id: 3, name: '5 small plugs (total of 400 Amp, Powerlock)'},
        ],
        connectorLocations:[
            { id: null, name: ''},
            { id: 0, name: 'Front End'},
            { id: 1, name: 'Rear End'}
        ],
        shipTypeConfigs: [
            null,
            { req: ["eni", "pax"] }, // 1 = river cruise
            { maxAtdMin: 120, resItemAtdMin: 120, disableBeforeAtd: true, req: ["eni"] }, // 2 = party boat
            { req: ["imo", "pax"] }, // 3 = sea cruise
            { req: ["imo"] }, // 4 = seagoing
            { req: ["eni"] }, // 5 = inland
            {}, // 6 = navy
            {}, // 7 = port authority
            {}, // 8 = pilot
            {}, // 9 = police
            { req: ["imo"] }, // 10 = super jacht
            {
                maxAtdMin: 15, resItemAtdMin: 15,
                prop: {
                    "length": 23.60,
                    "width": 4.40
                }
            } // 11 = canal boat
        ],
        invoiceTypeCalculationSources: {
            Reservation: 1,
            Planning: 2,
            Realisation: 4
        },
        invoiceTypes: [], // this will be filled after loaded process flows
        invoiceRemarks:[],
        getInvoiceTypesBasedOnShipType: getInvoiceTypesBasedOnShipType,
        getInvoiceTypesBasedOnBusinessUnits:getInvoiceTypesBasedOnBusinessUnits
    };

    function hasBusinessUnit(businessUnit) {
        if (constants.businessUnits.length === 0)
            return false;

        if (typeof (businessUnit) !== "number") {
            businessUnit = parseInt(businessUnit);
        }

        return _.some(constants.businessUnits, function (unit) { return unit.id === businessUnit; });
    }

    function isBusinessUnitShipType(businessUnit, shipType) {

        var businessUnitId = businessUnit;
        if (typeof (businessUnit) !== "number") {
            businessUnitId = parseInt(businessUnit);
        }

        var shipTypeId = shipType;
        if (typeof (shipType) !== "number") {
            shipTypeId = parseInt(shipType);
        }

        var unit = _.find(constants.businessUnits, function (u) { return u.id === businessUnitId; });
        if (unit !== undefined) {
            return _.some(unit.shipTypes, function (type) { return type.id === shipTypeId; });
        }
        return false;
    }

    function getFlowsForPermissions(permissions){
        var units = common.identity.getBusinessUnitsForPermissions(permissions);
        return _.filter(constants.processFlows,function(flow){
            return _.contains(units, flow.businessUnitId);
        });
    }

    function getInvoiceTypesBasedOnShipType(shipTypeId) {
        var shipTypes = _.filter(constants.shipTypes, function (shipType) { return shipType.id === shipTypeId; });
        var unitIds = _.uniq(_.flatten(_.pluck(shipTypes, 'businessUnitIds')));

        return getInvoiceTypesBasedOnBusinessUnits(unitIds);
    } 

    function getInvoiceTypesBasedOnBusinessUnits(businessUnits){
        var flows = _.filter(constants.processFlows, function (flow) { return businessUnits.indexOf(flow.businessUnitId) !== -1; });

        var foundInvoiceTypes = [];
        for (var i = 0; i < flows.length; i++) {
            foundInvoiceTypes = _.union(foundInvoiceTypes, flows[i].invoiceTypes);
        }
        _.uniq(foundInvoiceTypes, false, function (item) { return item.id; });

        return foundInvoiceTypes;
    }

    function init() {
        // reset the promise
        qPreload = common.$q.defer();
        constants.preloaded = qPreload.promise;
        constants.elementsToLoad = 0;

        // load BusinessUnits
        constants.elementsToLoad++;
        loadBusinessUnits()
            .then(function (businessUnits) {
                constants.businessUnits = businessUnits;
                if (businessUnits && businessUnits.length > 0) {
                    for (var i = 0; i < businessUnits.length; i++) {
                        constants.shipTypes = _.union(constants.shipTypes, businessUnits[i].shipTypes);
                    }
                    _.uniq(constants.shipTypes, false, function (item) { return item.id; });
                }
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });

        // load Berths
        constants.elementsToLoad++;
        loadBerths()
            .then(function (result) {
                constants.berths = result;
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });

        // load BusinessRules
        constants.elementsToLoad++;
        loadBusinessRules()
            .then(function (result) {
                constants.businessrules = result;
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });

        // load BerthGroups
        constants.elementsToLoad++;
        loadBerthGroups()
            .then(function (result) {
                constants.berthGroups = result;
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });

        // load Regions
        constants.elementsToLoad++;
        loadRegions()
            .then(function (result) {
                constants.regions = result;
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });

        // load InvoiceRemarks
        constants.elementsToLoad++;
        loadInvoiceRemarks()
            .then(function (result) {
                constants.invoiceRemarks = result;
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });

        // load ProcessFlows
        constants.elementsToLoad++;
        loadProcessFlows()
            .then(function (result) {
                constants.processFlows = result;
                if (result && result.length > 0) {
                    for (var i = 0; i < result.length; i++) {
                        constants.invoiceTypes = _.union(constants.invoiceTypes, result[i].invoiceTypes);
                    }
                }
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });

        // load ship property types
        constants.elementsToLoad++;
        loadShipPropertyTypes()
            .then(function (shipPropertyTypes) {
                constants.shipPropertyTypes = shipPropertyTypes;
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });
    }

    function preloadStepDone() {
        constants.elementsToLoad--;
        if (constants.elementsToLoad === 0) {
            qPreload.resolve();
        }
    }

    function loadProcessFlows() {
        var deferred = common.$q.defer();
        var req = context.get('/processflow/user')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    
    function loadBerths() {
        var deferred = common.$q.defer();
        var req = context.get('/berth')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
        return deferred.promise;
    }
    function loadBusinessRules() {
        var deferred = common.$q.defer();
        var req = context.get('/businessrule')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
        return deferred.promise;
    }
    function loadBerthGroups() {
        var deferred = common.$q.defer();
        var req = context.get('/berthgroup/details')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function loadRegions() {
        var deferred = common.$q.defer();
        var req = context.get('/region')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function loadInvoiceRemarks() {
        var deferred = common.$q.defer();
        var req = context.get('/invoiceRemark/active')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function loadBusinessUnits() {
        var deferred = common.$q.defer();
        var req = context.get('/businessunit/user')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function loadShipPropertyTypes() {
        var deferred = common.$q.defer();
        var req = context.get('/ship/propertytype')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    init();

    return constants;
}
