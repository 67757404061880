﻿module.exports = ['constantsService', fleetModels];

function fleetModels(constantsService) {

    var service = {
        validateVessel: validateVessel,
        vesselModel: vesselModel,
        shipPropertyModel: shipPropertyModel
    };

    return service;

    function validateVessel(vessel) {
        var errors = [];
        if (vessel === null || vessel === undefined) {
            errors.push('Please specify a vessel');
            return errors;
        }

        if (vessel.name === null || vessel.name === undefined || vessel.name === '')
            errors.push('Please specify a vessel name');
        else if (vessel.name.length >= 50)
            errors.push('Vessel name cannot contain more than 50 characters');

        // max draught
        if (vessel.maxDraught !== undefined && vessel.maxDraught !== null && vessel.maxDraught !== "") {
            if (isNaN(vessel.maxDraught))
                errors.push('Vessel Maximum Draught must be a number');
            else if (vessel.maxDraught <= 0)
                errors.push('Please specify a valid vessel maximum draught');
        }

        // max pax
        if (vessel.paxCapacity !== undefined && vessel.paxCapacity !== null && vessel.paxCapacity !== "") {
            if (isNaN(vessel.paxCapacity))
                errors.push('Vessel PAX capacity must be a number');
            else if (vessel.paxCapacity <= 0)
                errors.push('Please specify a valid vessel PAX capacity');
        }

        // height
        if (vessel.height !== undefined && vessel.height !== null && vessel.height !== "") {
            if (isNaN(vessel.height))
                errors.push('Vessel height must be a number');
            else if (vessel.height <= 0)
                errors.push('Please specify a valid vessel height');
        }

        var config = null;
        if (vessel.shipTypeId === null || vessel.shipTypeId === undefined || vessel.shipTypeId === '')
            errors.push('Please specify a vessel type');
        else {
            config = constantsService.shipTypeConfigs[vessel.shipTypeId];
            shipPropertyTypes = _.filter(constantsService.shipPropertyTypes, function (propType) {
                return _.some(propType.shipTypes, function (shipType) { return shipType.id === vessel.shipTypeId; });
            });
        }



        // ship properties: length, width, greenaward etc
        _.each(shipPropertyTypes, function (propertyType) {

            var properties = null;
            if (vessel.shipProperties !== undefined && vessel.shipProperties !== null && vessel.shipProperties.length > 0) {
                properties = _.filter(vessel.shipProperties, function (property) {
                    return property.property === propertyType.id;
                });
            }

            if (properties === undefined || properties === null || properties.length === 0) {
                errors.push('Please specify a vessel ' + propertyType.name);
            } else {
                _.each(properties, function (property) {
                    if (property.startsOn === undefined || property.startsOn === null) {
                        errors.push('Please specify a (valid) ' + propertyType.name + ' starts on.');
                    }

                    if (propertyType.systemCode === 'length' || propertyType.systemCode === 'width') {
                        if (isNaN(property.value))
                            errors.push('Vessel ' + propertyType.name + ' must be a number');
                        else if (property.value <= 0)
                            errors.push('Please specify a valid vessel ' + propertyType.name);
                    } else {
                        if (property.value === undefined || property.value === null || property.value.trim().length === 0) {
                            errors.push('Please specify a (valid) vessel ' + propertyType.name);
                        }

                        if (property.endsOn === undefined || property.endsOn === null) {
                            errors.push('Please specify a (valid) ' + propertyType.name + ' ends on.');
                        } else {
                            if (moment(property.startsOn) > moment(property.endsOn)) {
                                errors.push('Vessel ' + propertyType.name + ' starts on cannot later than ends on.');
                            }
                        }
                    }
                });
            }
        });

        if (config) {
            if (Array.isArray(config.req)) {

                // PAX
                if (config.req.indexOf("pax") > -1) {
                    if (vessel.paxCapacity === undefined || vessel.paxCapacity === null || vessel.paxCapacity === '')
                        errors.push('Please specify a vessel PAX capacity'); // e.g. required for rivercruise and seacruise
                }

                // IMO
                if (config.req.indexOf("imo") > -1) {
                    if (vessel.imo === null || vessel.imo === undefined || vessel.imo === '' || isNaN(vessel.imo))
                        errors.push('Please specify a vessel IMO, IMO number must be a number');
                    else if (vessel.imo.length !== 7)
                        errors.push('IMO number must contain 7 digits');
                }

                // ENI
                if (config.req.indexOf("eni") > -1) {
                    if (vessel.eni === null || vessel.eni === undefined || vessel.eni === '' || isNaN(vessel.eni))
                        errors.push('Please specify a vessel ENI, ENI number must be a number');
                    else if (vessel.eni.length !== 8)
                        errors.push('ENI number must contain 8 digits');
                }
            }
        }
        
        return errors.length > 0 ? errors : true;
    }

    function vesselModel(shipData) {
        //var shipModel = {
        //    id: null,
        //    contstructionType: null,
        //    customerId: null,
        //    name: null,
        //    shipTypeId: 1,
        //    height: null,
        //    length: null,
        //    shipPortAuthority: {
        //        debtorNumber: null
        //    }
        //};

        //if (shipData === undefined || shipData === null)
        //    return shipModel;

        //shipModel.id = shipData.id;
        //shipModel.contstructionType = customerData.contstructionType;
        //shipModel.customerId = customerData.customerId;
        //shipModel.name = customerData.name;
        //shipModel.shipTypeId = customerData.shipTypeId;
        //shipModel.height = customerData.height;
        //shipModel.length = customerData.length;
        //shipModel.shipPortAuthority.debtorNumber = customerData.shipPortAuthority.debtorNumber;

        //return shipModel;

        return {
            id: null,
            contstructionType: null,
            customerId: null,
            name: null,
            shipTypeId: 1,
            height: null,
            length: null,
            width: null,
            shipPortAuthority: {
                debtorNumber: null
            }
        };
    }

    function shipPropertyModel(source) {
        if (!source) {
            var today = new Date().toISOString().split('T')[0];

            return {
                id: null,
                shipId: null,
                property: null,
                value: null,
                startsOn: moment.utc(today, 'YYYY-MM-DD'),
                endsOn: null,
                createdByUserId: null,
                createdOn: null,
                mode: 'inline', // only for UI; readonly, inline, popup
                isSingleValue: null, // only for UI
                name: null // only for UI
            };
        }

        return {
            id: source.id,
            shipId: source.shipId,
            property: source.property,
            value: source.value,
            startsOn: source.startsOn,
            endsOn: source.endsOn,
            createdByUserId: source.createdByUserId,
            createdOn: source.createdOn,
            mode: source.mode, // only for UI
            isSingleValue: source.isSingleValue, // only for UI
            name: source.name // only for UI
        };
    }
};