﻿'use strict';
module.exports = [arrivalsDateDisplayFilter];

function arrivalsDateDisplayFilter() {
    return function (item, obj) {
        var arrivalDate = moment();
        var departureDate = moment();

        if (!item)
            return null;
        if (obj === 'planning') {
            if (!item.eta || !item.etd)
                return null;
            arrivalDate = moment(item.eta);
            departureDate = moment(item.etd);
        } else if (obj === 'realisation') {
            if (!item.ata || !item.atd)
                return null;
            arrivalDate = moment(item.ata);
            departureDate = moment(item.atd);
        } else if (obj === 'review') {
            if (item.realisationItems && item.realisationItems.length > 0) {
                arrivalDate = moment(item.realisationItems[0].ata);
                departureDate = moment(item.realisationItems[item.realisationItems.length - 1].atd);
            }
            else if (item.planningItems && item.planningItems.length > 0) {
                arrivalDate = moment(item.planningItems[0].eta);
                departureDate = moment(item.planningItems[item.planningItems.length - 1].etd);
            }
        }

        if (arrivalDate.year() === departureDate.year()) {
            if (arrivalDate.month() === departureDate.month()) {
                if (arrivalDate.dayOfYear() === departureDate.dayOfYear()) {
                    return departureDate.format('D MMM YYYY') + ' ' + arrivalDate.format('HH:mm') + ' - ' + departureDate.format('HH:mm');
                }
            }
            return arrivalDate.format('D MMM HH:mm') + ' - ' + departureDate.format('D MMM HH:mm');
        }
        return arrivalDate.format('D MMM YYYY HH:mm') + ' - ' + departureDate.format('DD MMM YYYY HH:mm');
    };
};