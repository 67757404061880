﻿'use strict';

angular.module('poa.rprp.tariff', [])
    .config(['$routeProvider', routeConfig])
    .controller('tariffController', require('./controllers/tariff'))
    .controller('tariffGroupController', require('./controllers/tariff-group'))
    .factory('tariffDataService', require('./services/tariff-data'))
    .factory('tariffModelsService', require('./services/tariff-models'))
    .directive('tariffForm', require('./directives/tariff/tariff-form'))
    .directive('tariffOverview', require('./directives/tariff/tariff-overview'))
    .directive('tariffGroupForm', require('./directives/tariff-group/tariff-group-form'))
    .directive('tariffGroupOverview', require('./directives/tariff-group/tariff-group-overview'))
    ;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/tariff', {
            templateUrl: 'templates/tariff/controllers/tariff.html'
        })
        .when('/tariffgroup', {
            templateUrl: 'templates/tariff/controllers/tariff-group.html'
        });
}