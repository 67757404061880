﻿module.exports = ['$location', 'common', 'arrivalsDataService', 'identity', 'reservationDataService', 'messageDataService', 'customerDataService', dashboardController];

function dashboardController($location, common, arrivalsDataService, identityService, reservationData, messageDataService, customerDataService) {

    var vm = {
        attached: attached,
        reservations: {
            publicBerthsCount: null,
            publicBerthsList: [],
            rivercruiseCount: null,
            rivercruiseList: []
        },
        getLatestMessages: getLatestMessages,
        messageItems: [],
        getLatestCustomers: getLatestCustomers,
        customersList: [],
        reviewList: [],
        onMessageSelect: onMessageSelect
    };

    return vm;

    function attached() {
        checkPermissions();

        countUnplannedReservations();
        loadUnplannedReservations();
        getLatestCustomers();
        getArrivalsReviewList();
    };

    function checkPermissions() {
        // Check if the user can see any of the dashboard boxes
        var hasAnyPermissionForDashboard = identityService.hasPermission('customer_admin,invoice_admin,message,message_admin,planning_admin');

        // If not then we'll redirect to any applicable next page
        if (!hasAnyPermissionForDashboard) {
            if (identityService.hasPermission('planning')) {
                common.$location.path('/planning');
            }
            else if (identityService.hasPermission('realisation,realisation_admin,realisation_create')) {
                common.$location.path('/arrivals');
            }
        }
    };

    function getLatestCustomers(){
        customerDataService.getCustomers()
            .then(function (results) {
                vm.customersList = results.reverse().slice(0, 5);

            });
    }
    function countUnplannedReservations() {
        reservationData.countUnplanned([1])
            .then(function (result) {
                vm.reservations.publicBerthsCount = result;
            });
        reservationData.countUnplanned([2, 3])
            .then(function (result) {
                vm.reservations.rivercruiseCount = result;
            });
    };

    function loadUnplannedReservations() {
        reservationData.getUnplanned(null, null, [1])
            .then(function (result) {
                vm.reservations.publicBerthsList = _.sortBy(result.splice(0, 5), 'eta');
            });
        reservationData.getUnplanned(null, null, [2,3])
            .then(function (result) {
                vm.reservations.rivercruiseList = _.sortBy(result.splice(0, 5), 'eta');
            });
    };

    function getLatestMessages() {
        messageDataService.getLatestMessages()
            .then(function (results) {
                vm.messageItems = results;
            });
    }

    function getArrivalsReviewList() {
        arrivalsDataService.getToReview(0, 5)
            .then(function (results) {
                vm.reviewList = results.items;
            });
    }

    function onMessageSelect(item) {
        // when the reservation is set, always use the reservation. if not fallback on Customer
        if (item.message !== null && item.message.conversationId !== null) {
            $location.path('/messages/' + item.message.conversationId);
        } else if (item.reservationId !== null) {
            $location.path('/messages/reservation/' + item.reservationId);
        } else if (item.message !== null) {
            $location.path('/messages/customer/' + item.message.customerId);
        }
    }
};