﻿'use strict';

angular.module('poa.rprp.invoices', [])
    .config(['$routeProvider', routeConfig])
    .controller('addCreditInvoiceController', require('./controllers/add-credit-invoice'))
    .controller('editInvoiceController', require('./controllers/edit-invoice'))
    .controller('invoicesController', require('./controllers/invoices'))
    .directive('invoiceCreator', require('./directives/invoice-creator'))
    .directive('invoiceTable', require('./directives/invoice-table'))
    .directive('invoiceStatusLabel', require('./directives/invoice-status-label'))
    .factory('invoiceDataService', require('./services/invoice-data'))
    .factory('invoiceModelsService', require('./services/invoice-models'))
    .factory('invoiceStateService', require('./services/invoice-state'))
    .filter('invoiceTextDisplay', require('./filters/invoice-text-display'))
    .filter('invoiceStatusDisplay', require('./filters/invoice-status-display'))
    .filter('removeUnusedDecimals', require('./filters/remove-unused-decimals'))
    ;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/invoices', {
            templateUrl: 'templates/invoices/controllers/invoices.html'
        })
        .when('/invoices/:invoiceId', {
            templateUrl: 'templates/invoices/controllers/edit-invoice.html'
        })
        .when('/invoices/:invoiceId/credit', {
            templateUrl: 'templates/invoices/controllers/add-credit-invoice.html'
        });
}
