﻿'use strict';
module.exports = ['toastr', 'coreDataService', 'berthDataService', berthNauticalGoalFormDirective];

function berthNauticalGoalFormDirective(toastr, coreDataService, berthDataService) {
    var directive = {
        bindToController: true,
        controller: berthNauticalGoalFormController,
        controllerAs: 'ctrlNGF',
        restrict: 'E',
        scope: {
            berthId: '@'
        },
        link: berthNauticalGoalFormLink,
        templateUrl: 'templates/berth/directives/berth-nauticalgoal-form.html'
    };
    return directive;

    function berthNauticalGoalFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () { return ctrl.berthId; },
            function (newValue, oldValue) {
                if (!newValue) return;

                ctrl.getBerthNauticalgoals();
            });
    }

    function berthNauticalGoalFormController() {
        var vm = {

            nauticalgoals: [],
            berthNauticalgoals: [],
            selectedBerthNauticalgoalIds: [],
            getBerthNauticalgoals: getBerthNauticalgoals,
            save: save
        };

        init();

        return vm;

        function init() {
            coreDataService.getNauticalgoals().then(function (data) {
                vm.nauticalgoals = data;
            });
        }

        function save() {
            var berthId = parseInt(vm.berthId) || 0;
            var nauticalgoalIdsToAdd = vm.selectedBerthNauticalgoalIds;
            var nauticalgoalIdsToDel = [];

            if (vm.berthNauticalgoals.length > 0) {

                nauticalgoalIdsToDel = vm.berthNauticalgoals.filter(function (item) {
                    return vm.selectedBerthNauticalgoalIds.indexOf(item.id) === -1;
                });
                nauticalgoalIdsToDel = _.map(nauticalgoalIdsToDel, function (item) { return item.id });

                nauticalgoalIdsToAdd = vm.selectedBerthNauticalgoalIds.filter(function (itemId) {
                    var orgIds = _.map(vm.berthNauticalgoals, function (item) { return item.id });
                    return orgIds.indexOf(itemId) === -1;
                });
            }

            if (berthId > 0 && (nauticalgoalIdsToDel.length > 0 || nauticalgoalIdsToAdd.length > 0)) {
                berthDataService.updateBerthNauticalgoals(berthId, nauticalgoalIdsToAdd, nauticalgoalIdsToDel)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
               
                }

                function successResult(result) {
                    vm.berthNauticalgoals = result;
                }
            }
            toastr.success("Changes Saved");
        }

        function getBerthNauticalgoals() {
            var berthId = parseInt(vm.berthId) || 0;
            if (berthId > 0) {
                berthDataService.getBerthNauticalgoals(berthId)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
                }

                function successResult(result) {
                    vm.berthNauticalgoals = result;
                    vm.selectedBerthNauticalgoalIds = _.map(vm.berthNauticalgoals, function (item) { return item.id });
                }
            }
        }
    }
}