﻿module.exports = ['$location', 'common', 'messageDataService', navbarDirective];

function navbarDirective($location, common, messageData) {
    var directive = {
        bindToController: true,
        controller: navbarController,
        controllerAs: 'ctrlNavbar',
        restrict: 'E',
        templateUrl: 'templates/widgets/directives/navbar.html'
    };
    
    return directive;

    function navbarController() {
        var vm = this;

        vm.attached = function () {
            loadMessageCount();
        };
        vm.identity = common.identity;
        vm.messageCount = 0;
        vm.reloadMessageCountHandle = null;
        vm.supportedLanguages = common.language.supportedLanguages;
        vm.title = ' Easydock beheer applicatie';
        vm.setLanguage = function (lang) {
            common.language.set(lang);
        };
        vm.setTitle = function (e) {
            let textLink = angular.element(e.target);
            vm.title = textLink.text();
        };
        vm.isActive = function (viewLocation) {
            if (viewLocation === '/arrivals' && $location.path() === '/arrivals/review/rivercruise') {
                return true;
            }
            if (viewLocation === '/arrivals' && $location.path() === '/arrivals/review/public-berths') {
                return true;
            }
            if (viewLocation === '/nautical' && $location.path() === '/berth') {
                return true;
            }
            if (viewLocation === '/nautical' && $location.path() === '/berthgroup') {
                return true;
            }
            if (viewLocation === '/nautical' && $location.path() === '/region') {
                return true;
            }
            if (viewLocation === '/nautical' && $location.path() === '/facility') {
                return true;
            }
            if (viewLocation === '/nautical' && $location.path() === '/goals/nautical') {
                return true;
            }
            if (viewLocation === '/nautical' && $location.path() === '/goals/commercial') {
                return true;
            }
            if (viewLocation === '/security' && $location.path() === '/users') {
                return true;
            }
            if (viewLocation === '/security' && $location.path() === '/audit') {
                return true;
            }
            if (viewLocation === '/security' && $location.path() === '/roles') {
                return true;
            }
            if (viewLocation === '/financial' && $location.path() === '/invoices') {
                return true;
            }
            if (viewLocation === '/financial' && $location.path() === '/tariff') {
                return true;
            }
            if (viewLocation === '/financial' && $location.path() === '/tariffgroup') {
                return true;
            }
            if (viewLocation === '/financial' && $location.path() === '/invoiceremarks') {
                return true;
            }
            if (viewLocation === '/reports' && $location.path() === '/reports/flows') {
                return true;
            }
            if (viewLocation === '/reports' && $location.path() === '/reports/planning') {
                return true;
            }
            if (viewLocation === '/reports' && $location.path() === '/fleet/issues') {
                return true;
            }
            if (viewLocation === '/reports' && $location.path() === '/reports/tariff') {
                return true;
            }
            if (viewLocation === '/reports' && $location.path() === '/reports/reservations') {
                return true;
            }
            if (viewLocation === '/reports' && $location.path() === '/reports/roles') {
                return true;
            }
            if (viewLocation === '/reports' && $location.path() === '/reports/turnover') {
                return true;
            }
            if (viewLocation === '/reports' && $location.path() === '/reports/user-roles') {
                return true;
            }
            if (viewLocation === $location.path()) {
                return true;
            }
        };

        function loadMessageCount() {
            messageData.getPendingMessageCount()
                .then(function (result) {
                    if (result && result.count) {
                        vm.messageCount = result.count;

                        vm.reloadMessageCountHandle = common.$timeout(loadMessageCount, 30000);
                    }
                }, function (error) { });
        }
    }

}

