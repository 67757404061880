﻿module.exports = [communicatedStatusService];

function communicatedStatusService() {

    var service = {
        hasCommunicatedStatus: hasCommunicatedStatus
    };

    function hasCommunicatedStatus(communicatedStatusArray, statusToCheck, lastItemOnly) {
        if (!communicatedStatusArray || communicatedStatusArray.length === 0)
            return false;

        if (lastItemOnly && lastItemOnly === true)
            return _.last(communicatedStatusArray).status === statusToCheck;

        return _.some(communicatedStatusArray, function (item) {
            return item.status >= statusToCheck;
        });
    }

    return service;

}