﻿module.exports = ['common', 'constantsService', 'fleetModelService', 'fleetModals', shipFormDirective];

function shipFormDirective(common, constants, fleetModels, fleetModals) {
    var directive = {
        bindToController: true,
        controller: shipFormController,
        controllerAs: 'ctrlShipForm',
        restrict: 'E',
        scope: {
            shipData: '=',
            invoiceAddresses: '=',
            invoiceAddress:'=',
            simpleMode: '='
        },
        link: function (scope) {
            scope.$watchCollection(function (scope) {
                return scope.ctrlShipForm.shipData;
            },
                function (newValue, oldValue) {
                    if (newValue && oldValue && oldValue.id !== newValue.id) {
                        scope.ctrlShipForm.setShipTypeConfig();
                    }
                });
        },
        templateUrl: 'templates/fleet/directives/ship-form.html'
    };

    return directive;

    function shipFormController() {
        var ctrlShipForm = this;
        ctrlShipForm.constants = constants;
        ctrlShipForm.datePickerOptions = {
            altInputFormats: [
                'd-M-yyyy',
                'd-M-yyyy H:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yyyy',
                'dd-MM-yyyy H:mm',
                'dd-MM-yyyy HH:mm'
            ]
        };
        ctrlShipForm.connectorType = -1;
        ctrlShipForm.shipTypeConfig = null;
        ctrlShipForm.shipPropertyTypes = [];

        ctrlShipForm.init = function () {

            if (ctrlShipForm.constants.shipPropertyTypes && ctrlShipForm.constants.shipPropertyTypes.length > 0) {
                ctrlShipForm.shipPropertyTypes = _.indexBy(ctrlShipForm.constants.shipPropertyTypes, 'id');
                ctrlShipForm.setShipTypeConfig();
            } else {
                ctrlShipForm.constants.preloaded.then(function () {
                    ctrlShipForm.shipPropertyTypes = _.indexBy(ctrlShipForm.constants.shipPropertyTypes, 'id');
                    ctrlShipForm.setShipTypeConfig();
                });
            }

            if(ctrlShipForm.shipData.connectorQuantity === null && ctrlShipForm.shipData.connectorAmpere === null)
                ctrlShipForm.connectorType = -1;
            else if(ctrlShipForm.shipData.connectorQuantity === 1 && ctrlShipForm.shipData.connectorAmpere === 250)
                ctrlShipForm.connectorType = 1;
            else if(ctrlShipForm.shipData.connectorQuantity === 2 && ctrlShipForm.shipData.connectorAmpere === 125)
                ctrlShipForm.connectorType = 2;
            else if(ctrlShipForm.shipData.connectorQuantity === 5 && ctrlShipForm.shipData.connectorAmpere === 80)
                ctrlShipForm.connectorType = 3;
            else
                ctrlShipForm.connectorType = 0;
        };

        ctrlShipForm.onSelectConnectorType = function () {
            switch(ctrlShipForm.connectorType){
                case 0: ctrlShipForm.shipData.connectorQuantity = 0;
                ctrlShipForm.shipData.connectorAmpere = 0;
                break;
                case 1: ctrlShipForm.shipData.connectorQuantity = 1;
                ctrlShipForm.shipData.connectorAmpere = 250;
                break;
                case 2:ctrlShipForm.shipData.connectorQuantity = 2;
                ctrlShipForm.shipData.connectorAmpere = 125;
                break;
                case 3:ctrlShipForm.shipData.connectorQuantity = 5;
                ctrlShipForm.shipData.connectorAmpere = 80;
                break;
                default: ctrlShipForm.shipData.connectorQuantity = null;
                ctrlShipForm.shipData.connectorAmpere = null;
                ctrlShipForm.shipData.connectorLocation = null;
                break;
            }
        };


        ctrlShipForm.setShipTypeConfig = function () {
            if (ctrlShipForm.shipData && ctrlShipForm.shipData.shipTypeId) {
                ctrlShipForm.shipTypeConfig = ctrlShipForm.constants.shipTypeConfigs[ctrlShipForm.shipData.shipTypeId];

                // handle ship property types, add new ship properties when it needs
                var properties = [];
                _.each(ctrlShipForm.shipPropertyTypes, function (propType) { // loop through the property types

                    // check if this property type contains this shiptype, otherwise hide this property
                    var hasProperty = _.some(propType.shipTypes, function (shipType) { return shipType.id === ctrlShipForm.shipData.shipTypeId; });
                    if (hasProperty === false) return;

                    var hasProp = false;
                    if (ctrlShipForm.shipData.shipProperties) {
                        existingProp = _.each(ctrlShipForm.shipData.shipProperties, function (shipProp) { // loop through the existing properties
                            if (shipProp.property === propType.id) {
                                hasProp = true;
                                properties.push(shipProp);
                            }
                        });
                    }

                    if (hasProp === false) {
                        var prop = fleetModels.shipPropertyModel();
                        prop.property = propType.id;
                        prop.isSingleValue = !propType.isDateDependent;
                        prop.name = propType.name;
                        prop.isFirst = true;

                        if (propType.defaultValues) {
                            prop.value = propType.defaultValues[0];
                        }

                        properties.push(prop);
                    }
                });
                ctrlShipForm.shipData.shipProperties = _.sortBy(_.sortBy(properties, 'startsOn').reverse(), 'property');

                if (ctrlShipForm.shipData.id && ctrlShipForm.shipData.id > 0) {
                    var previousPropTypeId = null;
                    _.each(ctrlShipForm.shipData.shipProperties, function (existing) {

                        if (previousPropTypeId !== existing.property) {
                            existing.isFirst = true;
                            previousPropTypeId = existing.property;
                        } else {
                            existing.isFirst = false;
                        }

                        var foundPropType = ctrlShipForm.shipPropertyTypes[existing.property];
                        if (common.identity.hasPermission('ship_admin')) {
                            var allowInline = existing.id === null && _.filter(ctrlShipForm.shipData.shipProperties, function (p) { return p.property === existing.property; }).length === 1;
                            if (allowInline || existing.value === null || existing.value === undefined || existing.value.trim().length === 0) {
                                existing.mode = 'inline';
                            } else if (foundPropType.allowChangeByAdmin) {
                                existing.mode = 'popup';
                            } else {
                                existing.mode = 'readonly';
                            }
                        } else {
                            existing.mode = 'readonly';
                        }
                        existing.isSingleValue = existing.value === null || existing.value === undefined || existing.value.trim().length === 0 || !foundPropType.isDateDependent;
                        existing.name = foundPropType.name;
                    });
                }
            }

            if (ctrlShipForm.shipTypeConfig && ctrlShipForm.shipTypeConfig.prop) {
                _.mapObject(ctrlShipForm.shipTypeConfig.prop, function (value, key) {
                    ctrlShipForm.shipData[key] = value;
                });
            }
        }


        ctrlShipForm.editShipProperties = function (shipPropertyTypeId) {
            fleetModals.shipProperties({ shipProperties: ctrlShipForm.shipData.shipProperties, shipPropertyType: ctrlShipForm.shipPropertyTypes[shipPropertyTypeId] })
                .result
                .then(function (response) {
                    if (response !== null) {
                        ctrlShipForm.shipData.shipProperties = response;
                        ctrlShipForm.setShipTypeConfig();
                    }
                });
        }
    }    
}
