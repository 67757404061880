﻿angular.module('poa.rprp.audit', [])
    .config(['$routeProvider', routeConfig])
    // Controllers
    .controller('auditController', require('./controllers/audit'))
    .controller('auditSecurityController', require('./controllers/audit-security'))
    .factory('auditDataService', require('./services/audit-data'))
    .factory('auditModelsService', require('./services/audit-models'))
    ;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/audit', {
            templateUrl: 'templates/audit/controllers/audit.html'
        })
        .when('/audit/security', {
            templateUrl: 'templates/audit/controllers/audit-security.html'
        });

}