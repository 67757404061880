﻿module.exports = ['common', reservationPlanningService];

function reservationPlanningService() {

    var service = {
        calculateDays: calculateDays,
        calculateItem: calculateItem,
        data: {
            berthObstructions: [],
            planned: [],
            unplanned: []
        },
        days: [],
        display: {
            dayPercentage: 0,
            maximumDate: null,
            minimumDate: null
        }
    };

    return service;

    function calculateDays(source) {

        if (!source)
            return;

        if (source.reservation.eta && source.reservation.etd) {
            service.display.minimumDate = (moment.isMoment(source.eta) ? source.reservation.eta : moment(source.reservation.eta)).add(-1, 'days');
            service.display.maximumDate = (moment.isMoment(source.etd) ? source.reservation.etd : moment(source.reservation.etd)).add(1, 'days');
        }
        else if (source.planningItems) {
            var minimumDate, maximumDate = null;
            for (var i = 0; i < source.planningItems.length; i++) {
                if (!minimumDate || source.planningItems[i].eta < minimumDate)
                    minimumDate = source.planningItems[i].eta;
                if (!maximumDate || source.planningItems[i].etd > maximumDate)
                    maximumDate = source.planningItems[i].etd;
            }

            service.display.minimumDate = moment(minimumDate);
            service.display.maximumDate = moment(maximumDate);
        }
        else
            return;

        service.display.maximumDate = moment(service.display.maximumDate.format('YYYY-MM-DD'));
        service.display.minimumDate = moment(service.display.minimumDate.format('YYYY-MM-DD'));

        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var totalDays = Math.ceil(service.display.maximumDate.diff(service.display.minimumDate, 'days'));
        var minDate = moment(service.display.minimumDate.format('YYYY-MM-DD'));

        service.days = [];
        for (var i = 0; i < totalDays + 1; i++) {
            service.days.push(minDate.format('ddd DD'));
            minDate.add(1, 'days');
        }

        service.display.dayPercentage = (100 / (service.days.length));
    }

    function calculateItem(source) {
        if (!source.planningItems)
            return [];

        var items = [];

        for (var i = 0; i < source.planningItems.length; i++) {
            var item = source.planningItems[i];
            if (!item.id && source.reservation.id)
                item.id = source.reservation.id;
            item.display = {
                first: i == 0,
                last: source.planningItems.length == (i + 1),
                row: 0
            };
            item.customer = source.reservation.customer;
            item.ship = source.reservation.ship;
            item = calculateCalendarBlock(item);

            if (item != null)
                items.push(item);
        }
        return items;
    }

    function calculateCalendarBlock(item) {
        var eta = moment.isMoment(item.eta) ? item.eta : moment(item.eta);
        var etd = moment.isMoment(item.etd) ? item.etd : moment(item.etd);

        var _MS_PER_DAY = 1000 * 60 * 60 * 24;

        item.display.startPosition = (service.display.dayPercentage * (eta - service.display.minimumDate) / _MS_PER_DAY);

        item.display.width = (service.display.dayPercentage * (etd - service.display.minimumDate) / _MS_PER_DAY) - item.display.startPosition;
        if (item.display.startPosition + item.display.width > 100) {
            item.display.width = 100 - item.display.startPosition;
        }

        if (item.display.startPosition + item.display.width < 0)
            item.display.width = 0;
        else if (item.display.startPosition < 0) {
            item.display.width += item.display.startPosition;
            item.display.startPosition = 0;
        }
        else if (item.display.startPosition >= 98)
            item.display.width = 0;
        return item;
    }

};