﻿module.exports = ['common', hasAllPermissionsDirective];

function hasAllPermissionsDirective(common) {
    return {
        link: function (scope, element, attrs) {
            var show = false;
            if (attrs && attrs.hasAllPermissions)
                show = common.identity.hasAllPermissions(attrs.hasAllPermissions);
            
            element.removeClass('ng-hide');
            if (!show)
                element.addClass('ng-hide');
        }
    };
}
