﻿module.exports = ['common', 'dataContext', invoiceDataService];

function invoiceDataService(common, context) {

    var service = {
        create: create,
        createCredit: createCredit,
        createFromRealisation: createFromRealisation,
        createFullCredit: createFullCredit,
        generateXml: generateXml,
        generateXmlOnServer: generateXmlOnServer,
        get: getInvoices,
        getCredit: getCredit,
        getInvoice: getInvoice,
        getInvoicesByTextSearch: getInvoicesByTextSearch,
        getPreview: getPreview,
        getPreviewForPlanning: getPreviewForPlanning,
        getPreviewForReservation: getPreviewForReservation,
        getPreviewForSailingReport: getPreviewForSailingReport,
        searchQuery: null,
        filterStart: null,
        filterEnd: null,
        update: update,
        updateDebtor: updateDebtor
    };

    function create(invoice, invoiceType) {
        var deferred = common.$q.defer();      

        var req = context.post('/invoice/invoicetype/' + invoiceType, invoice)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function createCredit(invoiceId, invoice) {
        var deferred = common.$q.defer();
        
        var req = context.post('/invoice/' + invoiceId + '/credit', invoice)
            .then(function(data) {
                    deferred.resolve(data);
                },
                function(error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function createFromRealisation() {
        var deferred = common.$q.defer();

        var req = context.get('/invoice/fromrealisation')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function createFullCredit(invoiceId, invoice) {
        var deferred = common.$q.defer();
        var req = context.post('/invoice/' + invoiceId + '/createfullcredit', invoice)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function generateXml(ids) {
        var deferred = common.$q.defer();

        var req = context.post('/invoice/exact', ids)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function generateXmlOnServer() {
        var deferred = common.$q.defer();

        var req = context.get('/invoice/exact/server')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCredit(invoiceId) {
        var deferred = common.$q.defer();

        var req = context.get('/invoice/' + invoiceId + '/credit')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getPreview(realisationId, invoiceType) {
        var deferred = common.$q.defer();

        var args = [];
        if (invoiceType)
            args.push('invoiceTypeId=' + invoiceType);

        var req = context.get('/invoice/preview/' + realisationId + '?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, deferred.reject);

        return deferred.promise;
    }

    function getPreviewForPlanning(reservationId, invoiceTypeId) {
        var deferred = common.$q.defer();

        var args = [];
        if (invoiceTypeId) args.push('invoiceTypeId=' + invoiceTypeId);

        var req = context.get('/invoice/preview/' + reservationId + '/planning?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, deferred.reject);

        return deferred.promise;
    }

    function getPreviewForReservation(reservationId, invoiceTypeId) {
        var deferred = common.$q.defer();

        var args = [];
        if (invoiceTypeId) args.push('invoiceTypeId=' + invoiceTypeId);

        var req = context.get('/invoice/preview/' + reservationId + '/reservation?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, deferred.reject);

        return deferred.promise;
    }

    function getPreviewForSailingReport(reservationId) {
        var deferred = common.$q.defer();

        var req = context.get('/invoice/preview/' + reservationId + '/sailingreport')
            .then(function (data) {
                deferred.resolve(data);
            }, deferred.reject);

        return deferred.promise;
    }

    function getInvoice(invoiceId) {
        var deferred = common.$q.defer();

        var req = context.get('/invoice/' + invoiceId)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getInvoices(dateFrom, dateUntil, pageSize, pageIndex, invoiceType) {
        var deferred = common.$q.defer();

        if (!dateFrom || !dateUntil)
            deferred.reject('Please supply valid dates');

        dateFrom = (moment.isMoment(dateFrom) ? dateFrom : moment(dateFrom)).format('YYYY-MM-DD');
        dateUntil = (moment.isMoment(dateUntil) ? dateUntil : moment(dateUntil)).format('YYYY-MM-DD');
        
        var args = [];
        args.push('dateFrom=' + dateFrom);
        args.push('dateUntil=' + dateUntil);
        args.push('psize=' + pageSize);
        args.push('pindex=' + pageIndex);
        if (invoiceType) args.push('invoiceType=' + invoiceType);

        var req = context.get('/invoice?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getInvoicesByTextSearch(query, invoiceType) {
        var deferred = common.$q.defer();

        var args = [];
        args.push('q=' + query);
        if (invoiceType) args.push('invoiceType=' + invoiceType);

        var req = context.get('/invoice/search?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function update(invoice) {
        var deferred = common.$q.defer();

        context.put('/invoice/' + invoice.id, invoice)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function updateDebtor(invoice) {
        var deferred = common.$q.defer();

        context.put('/invoice/' + invoice.id + '/debtor', invoice)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    return service;

}