﻿module.exports = ['common', 'reservationPlanningService', 'reservationDataService', 'berthDataService' ,showBerthDirective];

function showBerthDirective(common, reservationPlanning, reservationData, berthDataService) {

    var directive = {
        bindToController: true,
        controller: showBerthController,
        controllerAs: 'ctrlShowBerth',
        link: function (scope, b, c, d) {
            scope.ctrlShowBerth.getReservationPlanning(scope.ctrlShowBerth.reservationId);
        },
        restrict: 'E',
        scope: {
            reservationId: '='
        },
        templateUrl: 'templates/messages/directives/show-berth.html'
    };
    return directive;
    
    function showBerthController() {
        var ctrlShowBerth = this;
            ctrlShowBerth.reservationPlanning = reservationPlanning;
            ctrlShowBerth.loadingLeft = 2;
            ctrlShowBerth.ship = {};
            ctrlShowBerth.planning = {
                berths: null,
                data: null
            };
            ctrlShowBerth.calculatePlanning = function () {
                reservationPlanning.calculateDays(ctrlShowBerth.planning.data);
                ctrlShowBerth.planning.data = reservationPlanning.calculateItem(ctrlShowBerth.planning.data);
                ctrlShowBerth.loadingLeft--;
             };
            ctrlShowBerth.berthFilter = function (berthId) {
                 return function (planningItem) {
                     return planningItem.id === berthId;
                }
            };
            ctrlShowBerth.getReservationPlanning = function (id) {
                reservationData.getReservationPlanning(id)
                    .then(function (planningResult) {
                        ctrlShowBerth.planning.data = planningResult;
                        if (planningResult && planningResult.planningItems && planningResult.planningItems.length > 0) {
                            ctrlShowBerth.ship = planningResult.ship;
                            ctrlShowBerth.dates = { eta: planningResult.reservation.eta, etd: planningResult.reservation.etd };
                            ctrlShowBerth.planning.berths = _.uniq(_.map(planningResult.planningItems, function (planningItem) {
                                berthDataService.getBerth(planningItem.assignedToBerthId).then(function (berthGroup) {
                                    planningItem.berthGroup = berthGroup;
                                });
                                return planningItem;
                            }), false, function (uniqueItem) {
                                return uniqueItem.id;
                            });
                            ctrlShowBerth.calculatePlanning();
                        }
                        else
                            ctrlShowBerth.loadingLeft--;
                    }, function () {
                        ctrlShowBerth.loadingLeft--;
                    });
        };

    };
};