﻿module.exports = ['$routeParams', 'common', 'reportDataService', 'dataContext', reportSailingReportRemarksController];

function reportSailingReportRemarksController($routeParams, common, reportData, context) {

    var vm = {
        getData: getData,
        init: init,
        loading: false,
        items: [],
        filter: {
            endDate: null,
            startDate: null
        }
    };

    function getData() {
        vm.loading = true;

        reportData.getSailingReportRemarks(vm.filter.startDate.format('YYYY-MM-DD'), vm.filter.endDate.format('YYYY-MM-DD'), vm.filter.textFilter, vm.filter.textFilterType ? vm.filter.textFilterType.value : null)
            .then(function (result) {
                vm.items = result;
            })
            .finally(function () {
                vm.loading = false;
            });
    }

    function init() {
        vm.filter.endDate = moment();
        vm.filter.startDate = moment().add(-1, 'year');
        //vm.filter.startDate = moment().add(-3, 'month');
        getData();
    }

    return vm;
}