﻿module.exports = [customerModels];

function customerModels() {

    var service = {
        customerModel: customerModel
    };

    return service;

    function customerModel(customerData) {
        var customerModel = {
            id: null,
            name: null,
            emailAddress: null,
            phoneNumber: null,
            defaultShipTypeId: null,
            priority: null,
            customerPortAuthority: {
                debtorNumber: null,
                active: null
            },
            remarks: null,
            btw: null,
            kvk: null
        };

        if (customerData === undefined || customerData === null)
            return customerModel;

        customerModel.id = customerData.id;
        customerModel.name = customerData.name;
        customerModel.emailAddress = customerData.emailAddress;
        customerModel.phoneNumber = customerData.phoneNumber;
        customerModel.defaultShipTypeId = customerData.defaultShipTypeId;
        customerModel.priority = customerData.priority;
        customerModel.customerPortAuthority = customerData.customerPortAuthority;
        customerModel.remarks = customerData.remarks;
        customerModel.btw = customerData.btw;
        customerModel.kvk = customerData.kvk;

        return customerModel;
    }

}