﻿module.exports = ['permissionDataService', 'permissionModals', roleFormDirective];

function roleFormDirective(permissionData, permissionModals) {
    var directive = {
        bindToController: true,
        controller: roleFormController,
        controllerAs: 'ctrlRoleForm',
        restrict: 'E',
        scope: {
            roleData: '='
        },
        templateUrl: 'templates/security/directives/role-form.html'
    };

    return directive;

    function roleFormController() {
        var ctrlRoleForm = this;
        ctrlRoleForm.addPermission = addPermission;
        ctrlRoleForm.permissions = [];

        permissionData.get()
            .then(function (data) {
                ctrlRoleForm.permissions = data;
            });

        function addPermission() {
            permissionModals.addPermission()
                .result.then(function (result) {
                    result.isPublic = ctrlRoleForm.roleData.isPublic;
                    permissionData.add(result)
                        .then(function (newPermission) {
                            ctrlRoleForm.permissions.push(newPermission);
                        });
                });

        }
    };

};
