﻿module.exports = ['fleetDataService', 'invoiceModelsService', 'tariffDataService', 'externalItemCodeDataService', 'widgetModals', invoiceCreatorDirective];

function invoiceCreatorDirective(fleetDataService, invoiceModels, tariffDataService, externalItemCodeDataService, widgetModals) {

    var directive = {
        controller: invoiceCreatorController,
        controllerAs: 'ctrlInvoiceCreator',
        link: function (scope) {
            if (scope.ctrlInvoiceCreator && scope.ctrlInvoiceCreator.invoice) {
                if (scope.ctrlInvoiceCreator.invoice.realisationId) {
                    // Get tariffs by Realisation Id
                    tariffDataService.getTariffsByRealisationId(scope.ctrlInvoiceCreator.invoice.realisationId, scope.ctrlInvoiceCreator.invoiceType.id)
                        .then(function (tariffs) {
                            scope.ctrlInvoiceCreator.tariffs = tariffs;
                        });
                } else {
                    // no realisationId present, get tariff by ship type and date
                    tariffDataService.getTariffs(scope.ctrlInvoiceCreator.invoiceType.id, scope.ctrlInvoiceCreator.invoice.createdOn)
                        .then(function (tariffs) {
                            scope.ctrlInvoiceCreator.tariffs = tariffs;
                        });
                }

                externalItemCodeDataService.getByInvoiceTypes([scope.ctrlInvoiceCreator.invoiceType.id])
                    .then(function (externalItemCodes) {
                        scope.ctrlInvoiceCreator.externalItemCodes = externalItemCodes;
                    });
            }
        },
        restrict: 'E',
        scope: {
            allowEdit: '=',
            allowEditOriginal: '=',
            discount: '=',
            invoice: '=',
            invoiceAction: '=',
            showDiscount: '=',
            showDescriptionNewLine: '=',
            showRemarks: '=',
            invoiceType: '='
        },
        bindToController: true,
        templateUrl: 'templates/invoices/directives/invoice-creator.html'
    };

    function invoiceCreatorController() {

        var vm = {
            addNewLine: addNewLine,
            attached: attached,
            calculateTotal: calculateTotal,
            discount: {
                amount: null,
                description: null,
                type: 'perc'
            },
            externalItemCodes: [],
            newLine: null,
            onChangeTariff: onChangeTariff,
            removeLine: removeLine,
            showNewLine: showNewLine,
            showRemarksDialog: showRemarksDialog,
            tariffs: [],
            tariffRules: [],
            totalAmount: totalAmount,
            totalDiscount: totalDiscount,
            newLineErrors: []
        };

        function addNewLine() {

            vm.newLineErrors = [];

            if (!vm.invoice.invoiceItems)
                vm.invoice.invoiceItems = [];

            if (vm.newLine.tariffId === null)
                vm.newLineErrors.push("Please select a tariff type");

            if (vm.newLine.description === null || vm.newLine.description === '')
                vm.newLineErrors.push("Please specify the description");

            if (vm.newLine.unitaryTariff === null || vm.newLine.unitaryTariff === '' || isNaN(vm.newLine.unitaryTariff))
                vm.newLineErrors.push("Tariff must be a number");

            if (vm.newLine.quantity === null || vm.newLine.quantity === '' || isNaN(vm.newLine.quantity))
                vm.newLineErrors.push("Quantity must be a number");

            vm.newLine.totalAmount = Math.round(vm.newLine.unitaryTariff * vm.newLine.quantity * 100) / 100;
            if (isNaN(vm.newLine.totalAmount))
                vm.newLineErrors.push("Cannot calculate the total amount");
            else if ((Math.round((vm.newLine.totalAmount) * 100) / 100) + (Math.round((vm.totalAmount()) * 100) / 100) < 0)
                vm.newLineErrors.push("Discount amount cannot larger than total amount");
            else if (vm.newLine.totalAmount < (Math.round((vm.newLine.totalAmount) * 100) / 100))
                vm.newLine.totalAmount = (Math.round((vm.newLine.totalAmount) * 100) / 100); // fix rounding problem e.g. -3.65 * 178.5 = -651.525 but the original invoice item has 651.52

            if (vm.showRemarks === true && (vm.newLine.remarks === undefined || vm.newLine.remarks === null || vm.newLine.remarks.trim() === ''))
                vm.newLineErrors.push("Please select/enter a (discount) remarks");

            if (vm.newLineErrors.length > 0) {
                return;
            }
            
            vm.newLine.id = -1;
            vm.invoice.invoiceItems.push(vm.newLine);
            vm.newLine = null;
        }

        function attached() {
            tariffDataService.getTariffRules()
                .then(function (data) {
                    vm.tariffRules = data;
                });
        }

        function calculateTotal(invoiceItem) {
            if (invoiceItem) {
                if (invoiceItem.id > 0 && invoiceItem.totalAmount)
                    return invoiceItem.totalAmount; // (bug 46855) rounding issue(3.65 * 284.1) must 1036.96 NOT 1036.37

                 // (bug 46855) rounding issue(3.65 * 284.1) original total amout is 1036.96 NOT 1036.37
                if (vm.invoice && vm.invoice.invoiceItems && vm.invoice.invoiceItems.length > 0) {
                    var foundOriginalItem = _.find(vm.invoice.invoiceItems, function (invItem) {
                        return invItem.unitaryTariff === Number.parseFloat(invoiceItem.unitaryTariff) && invItem.quantity === Number.parseFloat(invoiceItem.quantity);
                    });

                    if (foundOriginalItem)
                        return foundOriginalItem.totalAmount;
                }

                // javascript bug: need manually to convert 2 decimals. e.g. => 3307.17 + (-1102.39)
                return Math.round((invoiceItem.unitaryTariff * invoiceItem.quantity) * 100) / 100;
            }

            return 0;
        }

        function onChangeTariff() {
            var newTariff = _.find(vm.tariffs, function (tariff) { return tariff.id === vm.newLine.tariffId; });
            if (newTariff !== undefined && newTariff !== null) {
                vm.newLine.externalItemCode = newTariff.externalItemCode;
                vm.newLine.unitaryTariff = newTariff.unitaryTariff;
                vm.newLine.costCentre = newTariff.costCentre;
            } else {
                vm.newLine.externalItemCode = null;
                vm.newLine.unitaryTariff = 0;
                vm.newLine.costCentre = null;
            }
        }

        function showNewLine(originalLine) {
            vm.newLine = new invoiceModels.invoiceItemModelCredit(originalLine);
            //vm.invoice.invoiceItems.push(new invoiceModels.invoiceItemModel());
        }

        function showRemarksDialog(invoiceItem) {
            widgetModals.remarksConfirmStatus({ title: "Invoice remarks", action: vm.invoiceAction, invoiceTypes: [vm.invoiceType] })
                .result
                .then(function (response) {
                    var remarks = null;
                    if (response.remarks) {

                        if (response.remarks instanceof Array) {
                            if (response.remarks.length > 0) {
                                remarks = response.remarks[0];
                            }
                        } else {
                            remarks = response.remarks;
                        }
                    }
                    invoiceItem.remarks = remarks;

                }, function () { });
        }

        function removeLine(index) {
            vm.invoice.invoiceItems.splice(index, 1);
        }

        function totalAmount() {
            if (!vm.invoice) return 0;

            var sum = _.reduce(vm.invoice.invoiceItems, function (memo, item) {
                // item.unitaryTariff * item.quantity;
                // javascript bug: need manually to convert 2 decimals. e.g. => 3307.17 + (-1102.39)
                return Math.round((memo + item.totalAmount) * 100) / 100;
            }, 0);

            return sum + totalDiscount();
        }

        function totalDiscount() {
            if (!vm.discount || !vm.discount.amount || vm.discount.amount === '')
                return 0;

            var sum = _.reduce(vm.invoice.invoiceItems, function (memo, item) {
                return Math.round((memo + item.unitaryTariff * item.quantity) * 100) / 100;
            }, 0);

            if (vm.discount.type === 'perc')
                return (sum * (parseFloat(vm.discount.amount) / 100)) * -1;

            return (Math.round((vm.discount.amount) * 100) / 100) * -1;
        }

        return vm;
    }

    return directive;
}
