﻿module.exports = ['$q', 'constantsService', 'identity', preloaderCtrl];

function preloaderCtrl($q, constantsService, identityService) {
    var deferred = $q.defer();
    var vm = {
        done: deferred.promise,
        identity: identityService,
        isDone: false,
        steps: 3
    };

    constantsService.preloaded
        .then(function () { preLoadStepDone('constants'); }, function () { preLoadStepDone('constants-error'); });
    identityService.preloaded
        .then(function () { preLoadStepDone('identity'); }, function () { preLoadStepDone('identity-error'); });
    identityService.reloadUserInfo()
        .then(function () { preLoadStepDone('userinfo'); }, function () { preLoadStepDone('userinfo-error'); });

    function preLoadStepDone(source) {
        vm.steps--;
        if (vm.steps === 0) {
            vm.isDone = true;
            deferred.resolve(true);
        }
    }

    return vm;
}
