﻿module.exports = ['common', '$route', 'arrivalsDataService',  'fleetDataService', newArrivalDirective];

function newArrivalDirective(common, $route, arrivalsDataService, fleetDataService) {

    var directive = {
        bindToController: true,
        controller: newArrivalController,
        controllerAs: 'ctrlNewArrival',
        restrict: 'E',
        //link: function (scope) {
        //    console.log(scope);

        //},
        scope: {
            status: "="
        },
        templateUrl: 'templates/arrivals/directives/new-arrival.html'
    };

    return directive;
    function newArrivalController() {
        var ctrlNewArrival = this;

        var vm = {
            attached: attached,
            getVessels: getVessels,
            selectedVessel: null,
            selectVessel: selectVessel,
            selectedId: null,
            vessels: {
                init: null,
                all: null
            },
            updateSearch: updateSearch,
            getBySearch: getBySearch,
            searchquery: null,
            numPerPage: 10,
            currentPage: 1,
            addArrival: addArrival
        };
        return vm;
        function attached() {
            vm.getVessels();
        }
        function selectVessel(ship) {
            vm.selectedVessel = ship;
            vm.selectedId = ship.id;
        }
        function getVessels() {
            fleetDataService.getAllVessels()
                .then(function (result) {
                    vm.vessels.init = result;
                });
        }
        function updateSearch() {
            if (vm.searchquery !== null && vm.searchquery !== undefined
                && vm.searchquery.trim() === '')
                return vm.getBySearch(null);
        }
        function getBySearch(string) {
            if (string !== null) {
                let searchResult = vm.vessels.init.reduce(function (array, item) {
                    let ship = item.name.toLowerCase();
                    let newString = string.toLowerCase();
                    if (ship.indexOf(newString) !== -1) {
                        array.push(item);
                    }
                    return array;
                }, []);
                vm.vessels.all = searchResult;
            }
        }
        function addArrival(ship) {
            let arrival = {
                customerId: ship.customerId,
                shipId: ship.id,
                status: 1,
                length: ship.length,
                remarks: "Realisation created without reservation and without planning"
            };
            arrivalsDataService.addArrival(arrival)
                .then(function (result) {
                    vm.status = false;
                    $route.reload();
                });
        }

    }
}
