﻿module.exports = ['common', calendarBlockDirective];

function calendarBlockDirective(common) {

    var directive = {
        bindToController: true,
        controller: calendarBlockController,
        controllerAs: 'ctrlCalendarBlock',
        link: function (scope) {
            if (!scope || !scope.ctrlCalendarBlock)
                return;

            if (!scope.ctrlCalendarBlock.titleText || scope.ctrlCalendarBlock.titleText === '') {
                scope.ctrlCalendarBlock.titleText =
                    scope.ctrlCalendarBlock.calendarItem.ship.name + '\n' +
                    'ETA: ' + moment(scope.ctrlCalendarBlock.calendarItem.eta).format('DD-MM-YYYY HH:mm') + '\n' +
                    'ETD: ' + moment(scope.ctrlCalendarBlock.calendarItem.etd).format('DD-MM-YYYY HH:mm') + '\n' +
                    (scope.ctrlCalendarBlock.calendarItem.ship.width ?
                        'LxB: ' + scope.ctrlCalendarBlock.calendarItem.ship.length + 'm x ' + scope.ctrlCalendarBlock.calendarItem.ship.width + 'm' :
                        'LOA: ' + scope.ctrlCalendarBlock.calendarItem.ship.length + 'm');
                if (scope.ctrlCalendarBlock.calendarItem && scope.ctrlCalendarBlock.calendarItem.reservationReference) {
                    scope.ctrlCalendarBlock.titleText += '\nREF: ' + scope.ctrlCalendarBlock.calendarItem.reservationReference;
                }

            }
        },
        restrict: 'E',
        scope: {
            calendarItem: '=',
            displayText: '@',
            itemClasses: '@',
            titleText: '@'
        },
        templateUrl: 'templates/planning/directives/calendar-block.html'
    };

    return directive;

    function calendarBlockController() {
        var ctrlCalendarBlock = this;
    }

}