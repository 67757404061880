﻿module.exports = [reportModelsService];

function reportModelsService() {

    var service = {
        reportModel: reportModel,
        reportPlanningModel: reportPlanningModel,
        reportTariffModel: reportTariffModel,
        reportTariffGroupModel: reportTariffGroupModel
    };

    return service;

    function reportModel(reportData) {
        var result = {
            label: null,
            dataPoint: null,
        };

        if (reportData === undefined || reportData === null)
            return result;

        result.label = reportData.label;
        result.dataPoint = reportData.dataPoint;

        return result;
    }

    function reportPlanningModel(reportData) {
        var planningModel = {
            berth: null,
            berthId: null,
            shipName: null,
            shipType: null,
            customer: null,
            eta: null,
            etd: null,
            communicatedStatus: null,
            reservationNumber: null
        };

        if (reportData === undefined || reportData === null)
            return planningModel;

        planningModel.berth = reportData.berth;
        planningModel.berthId = reportData.berthId;
        planningModel.shipName = reportData.shipName;
        planningModel.shipType = reportData.shipType;
        planningModel.customer = reportData.customer;
        planningModel.eta = moment(reportData.eta).toDate();
        planningModel.etd = moment(reportData.etd).toDate();
        planningModel.communicatedStatus = getDescription(reportData.communicatedStatus);
        planningModel.reservationNumber = reportData.reservationNumber;

        return planningModel;
    }

    function reportTariffModel(tariffData) {
        var tariffModel = {
            id: null,
            tariffGroupId: null,
            tariffGroupName: null,
            tariffGroup: null,
            description: null,
            systemCode: null,
            startsOn: null,
            endsOn: null,
            unitaryTariff: null,
            externalItemCode: null,
            costCentre: null,
            rangeStartsFrom: null,
            approvedOn: null,
            approvedBy: null,
            approvedByUserId: null,
            businessLogic: null
        };

        if (tariffData === undefined || tariffData === null)
            return tariffModel;

        tariffModel.id = tariffData.id;
        tariffModel.tariffGroupId = tariffData.tariffGroupId;
        tariffModel.tariffGroupName = tariffData.tariffGroup.description;
        tariffModel.tariffGroup = reportTariffGroupModel(tariffData.tariffGroup);
        tariffModel.description = tariffData.description;
        tariffModel.systemCode = tariffData.systemCode;
        tariffModel.startsOn = moment(tariffData.startsOn).toDate();
        tariffModel.endsOn = moment(tariffData.endsOn).toDate();
        tariffModel.unitaryTariff = tariffData.unitaryTariff;
        tariffModel.externalItemCode = tariffData.externalItemCode;
        tariffModel.costCentre = tariffData.costCentre;
        tariffModel.rangeStartsFrom = tariffData.rangeStartsFrom;
        tariffModel.approvedOn = tariffData.approvedOn ? moment(tariffData.approvedOn).toDate() : null;
        tariffModel.approvedBy = tariffData.approvedBy;
        tariffModel.approvedByUserId = tariffData.approvedByUserId;
        tariffModel.businessLogic = tariffData.businessLogic;

        return tariffModel;
    }

    function reportTariffGroupModel(tariffGroupData) {
        var tariffGroupModel = {
            id: null,
            description: null,
            shipTypeId: null
        };

        if (tariffGroupData === undefined || tariffGroupData === null)
            return tariffGroupModel;

        tariffGroupModel.id = tariffGroupData.id;
        tariffGroupModel.description = tariffGroupData.description;
        tariffGroupModel.shipTypeId = tariffGroupData.shipTypeId;

        return tariffGroupModel;
    }

    function getDescription(communicatedStatus) {
        switch (communicatedStatus) {
        case 1:
            return "Region";
        case 2:
            return "Berth Group";
        case 3:
            return "Berth";
        default:
            return null;
        }
    }
}