﻿angular.module('poa.rprp.planning', [])
    .config(['$routeProvider', routeConfig])
    // Controllers
    .controller('modalConfirmPlanningController', require('./modals/confirm-planning-modal'))
    .controller('modalMakePlanningController', require('./modals/make-planning-modal'))
    .controller('planningController', require('./planning'))
    .controller('planningPublicBerthsInboxController', require('./controllers/planning-public-berths-inbox'))
    .controller('planningRivercruiseInboxController', require('./controllers/planning-rivercruise-inbox'))
    .controller('planningGenericInboxController', require('./controllers/planning-generic-inbox'))
    // Directives
    .directive('calendarBlock', require('./directives/calendar-block'))
    .directive('communicatedStatus', require('./directives/communicated-status'))
    .directive('planningCalendar', require('./directives/planning-calendar'))
    .directive('planningReceipt', require('./directives/planning-receipt'))
    .directive('planningStatusLabel', require('./directives/planning-status-label'))
    .directive('reservationReceipt', require('./directives/reservation-receipt'))
    .directive('reservationCommunicatedStatus', require('./directives/reservation-communicated-status'))
    .directive('reservationStatusLabel', require('./directives/reservation-status-label'))
    .directive('reservationCommunicatedStatusLabel', require('./directives/reservation-communicated-status-label'))
    // Filters
    .factory('activityDateDisplayFilter', require('./filters/activity-date-display'))
    .factory('reservationDateDisplayFilter', require('./filters/reservation-date-display'))
    .factory('shiptypeDisplayFilter', require('./filters/shiptype-display'))
    .factory('calendarItemsFilter', require('./filters/calendar-items-filter'))
    // Services
    .factory('planningModals', require('./modals/modals'))
    .factory('planningCalendarService', require('./services/planning-calendar'))
    .factory('planningDataService', require('./services/planning-data'))
    .factory('reservationDataService', require('./services/reservation-data'))
    .factory('reservationPlanningService', require('./services/reservation-planning'))
    .factory('communicatedStatusService', require('./services/communicated-status-models'))
    ;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/planning/test', {
            templateUrl: 'templates/planning/controllers/planning-test.html'
        })
        .when('/planning', {
            templateUrl: 'templates/planning/controllers/planning-generic-inbox.html'
        })
        .when('/planning/:reservationId', {
            templateUrl: 'templates/planning/controllers/planning-generic-inbox.html'
        });
};
