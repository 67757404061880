﻿module.exports = ['constantsService', 'coreDataService', 'customerDataService', newCustomerDirective];

function newCustomerDirective(constants, coreDataService, customerData) {
    var directive = {
        bindToController: true,
        controller: newCustomerController,
        controllerAs: 'ctrlNewCustomer',
        restrict: 'E',
        scope: {
            customerData: '='
        },
        templateUrl: 'templates/customer/directives/new-customer.html'
    };

    return directive;

    function newCustomerController() {
        var vm = {
            shipTypes: constants.shipTypes,
            portauthorities: []
        };

        init();

        return vm;

        function init() {
            portAuthorities = coreDataService.getPortauthorities()
                .then(function (data) {
                    vm.portauthorities = data;
                });
        }
    }

}
