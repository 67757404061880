﻿module.exports = ['$uibModalInstance', 'modalArguments', processFlowPickerCtrl];

function processFlowPickerCtrl($uibModalInstance, args) {

    if (!args)
        args = {};

    var vm = {
        args: args,
        cancel: cancel,
        ok: ok
    };

    function ok() {        
        if (args.availableFlows && args.availableFlows.length > 0) {
            $uibModalInstance.close(args.availableFlows);
        } else {
            $uibModalInstance.close(null);
        }
    }

    function cancel() {
        $uibModalInstance.close(null);
    }

    return vm;

}