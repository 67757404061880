﻿module.exports = ['$uibModalInstance', 'planningDataService', 'modalArguments', confirmPlanningModalController];

function confirmPlanningModalController($uibModalInstance, planningData, args) {
    var vm = {
        args: args,
        cancel: cancel,
        isSaving: false,
        ok: ok,
        selectedItems: [],
        toggleItem: toggleItem
    };

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function toggleItem(itemId) {
        if (!itemId)
            return;

        if (vm.selectedItems.indexOf(itemId) > -1)
            vm.selectedItems.splice(vm.selectedItems.indexOf(itemId), 1);
        else
            vm.selectedItems.push(itemId);
    }

    function ok() {
        vm.isSaving = true;
        saveNextItem();
    }

    function saveNextItem() {
        if (!vm.selectedItems || vm.selectedItems.length == 0) {
            vm.isSaving = false;
            $uibModalInstance.close(true);
            return;
        }

        var nextItem = vm.selectedItems.splice(0, 1);
        planningData.communicateStatus(nextItem, parseInt(vm.selectedStatus), null)
            .then(saveNextItem, saveNextItem);
    }

    return vm;
};