﻿'use strict';

module.exports = ['systemMessageDataService', systemMessageController];

function systemMessageController(systemMessageDataService) {
    var vm = {
        systemMessages: [],
        loadSystemMessages: loadSystemMessages,
        activeSystemMessage: null,
        onSystemMessageSaved: onSystemMessageSaved,
        onSystemMessageDeleted: onSystemMessageDeleted
    };

    init();

    return vm;

    function init() {
        loadSystemMessages();
    }

    function onSystemMessageSaved(savedSystemMessage, isNew) {
        let newSystemMessages = _.clone(vm.systemMessages);
    
        if (!isNew) {
            newSystemMessages = _.filter(newSystemMessages, x => x.id != savedSystemMessage.id);
        }
    
        newSystemMessages.push(savedSystemMessage);
    
        vm.systemMessages = newSystemMessages.sort((a, b) => {
            if (a.isActive !== b.isActive) {
                return a.isActive ? -1 : 1;
            }
        
            if (a.type !== b.type) {
                return b.type - a.type;
            }
        
            return b.id - a.id;
        });
        vm.activeSystemMessage = null;
    }

    function onSystemMessageDeleted(deletedSystemMessage) {
        var idx = _.findIndex(vm.systemMessages, function (item) { return item.id === deletedSystemMessage.id; });

        if (idx >= 0) {
            vm.systemMessages.splice(idx, 1);
        }

        vm.activeSystemMessage = null;
    }

    function loadSystemMessages() {
        systemMessageDataService.getSystemMessages()
            .then(function (result) {
                vm.systemMessages = result;
            });
    }
}
