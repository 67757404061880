﻿module.exports = ['constantsService', sailingReportStatusFilter];

function sailingReportStatusFilter(constantsService) {
    return function (status) {
        if (isNaN(status))
            status = parseInt(status);

        var statusName = _.find(constantsService.sailingReportStatus, function (item) {
            return item.id === status;
        });

        return statusName ? statusName.name : status;

    };

}