﻿'use strict';
module.exports = ['coreDataModels', 'constantsService', berthgroupGeneralFormDirective];

function berthgroupGeneralFormDirective(coreDataModels, constantsService) {
    var directive = {
        controller: berthgroupGeneralFormController,
        controllerAs: 'ctrlGBGF',
        restrict: 'E',
        scope: {
            berthgroupData: '='
        },
        bindToController: true,
        templateUrl: 'templates/berthgroup/directives/berthgroup-general-form.html'
    };

    return directive;

    function berthgroupGeneralFormController() {
        var vm = {
            functionTypes: coreDataModels.berthFunctions,
            regions: constantsService.regions
        };
        return vm;
    }

}
