﻿'use strict';
module.exports = ['coreDataService', 'berthgroupDataService', berthgroupNauticalGoalFormDirective];

function berthgroupNauticalGoalFormDirective(coreDataService, berthgroupDataService) {
    var directive = {
        bindToController: true,
        controller: berthgroupNauticalGoalFormController,
        controllerAs: 'ctrlBGNGF',
        restrict: 'E',
        scope: {
            berthgroupId: '@'
        },
        link: berthgroupNauticalGoalFormLink,
        templateUrl: 'templates/berthgroup/directives/berthgroup-nauticalgoal-form.html'
    };
    return directive;

    function berthgroupNauticalGoalFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () { return ctrl.berthgroupId; },
            function (newValue, oldValue) {
                if (!newValue) return;

                ctrl.getBerthgroupNauticalgoals();
            });
    }

    function berthgroupNauticalGoalFormController() {
        var vm = {

            nauticalgoals: [],
            berthgroupNauticalgoals: [],
            selectedBerthgroupNauticalgoalIds: [],
            getBerthgroupNauticalgoals: getBerthgroupNauticalgoals,
            save: save
        };

        init();

        return vm;

        function init() {
            coreDataService.getNauticalgoals().then(function (data) {
                vm.nauticalgoals = data;
            });
        }

        function save() {
            var berthgroupId = parseInt(vm.berthgroupId) || 0;
            var nauticalgoalIdsToAdd = vm.selectedBerthgroupNauticalgoalIds;
            var nauticalgoalIdsToDel = [];

            if (vm.berthgroupNauticalgoals.length > 0) {

                nauticalgoalIdsToDel = vm.berthgroupNauticalgoals.filter(function (item) {
                    return vm.selectedBerthgroupNauticalgoalIds.indexOf(item.id) === -1;
                });
                nauticalgoalIdsToDel = _.map(nauticalgoalIdsToDel, function (item) { return item.id });

                nauticalgoalIdsToAdd = vm.selectedBerthgroupNauticalgoalIds.filter(function (itemId) {
                    var orgIds = _.map(vm.berthgroupNauticalgoals, function (item) { return item.id });
                    return orgIds.indexOf(itemId) === -1;
                });
            }

            if (berthgroupId > 0 && (nauticalgoalIdsToDel.length > 0 || nauticalgoalIdsToAdd.length > 0)) {
                berthgroupDataService.updateBerthgroupNauticalgoals(berthgroupId, nauticalgoalIdsToAdd, nauticalgoalIdsToDel)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {                 
                }

                function successResult(result) {
                    vm.berthgroupNauticalgoals = result;
                }
            }
        }

        function getBerthgroupNauticalgoals() {
            var berthgroupId = parseInt(vm.berthgroupId) || 0;
            if (berthgroupId > 0) {
                berthgroupDataService.getBerthgroupNauticalgoals(berthgroupId)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
                }

                function successResult(result) {
                    vm.berthgroupNauticalgoals = result;
                    vm.selectedBerthgroupNauticalgoalIds = _.map(vm.berthgroupNauticalgoals, function (item) { return item.id });
                }
            }
        }
    }
}