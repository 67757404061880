﻿module.exports = ['common', 'dataContext', 'userModelsService', userDataService];

function userDataService(common, context, userModels) {

    var service = {
        activeDisabledUser: activeDisabledUser,
        addUser: addUser,
        deactivateUser: deactivateUser,
        deleteUser: deleteUser,
        getAdminRoles: getAdminRoles,
        getRoles: getRoles,
        getUsers: getUsers,
        getUser : getUser,
        saveUser: saveUser,
        resetPassword: resetPassword,
        getUsersForCustomer: getUsersForCustomer,
        closeTabs: true
    };
    return service;

    function addUser(user) {
        var deferred = common.$q.defer();
        user.email = user.emailAddress;
        if (user.userRoles && user.userRoles.length > 0) {
            user.roles = [{ businessUnit: 0, roles: user.userRoles }];
        }
        var req = context.post('/user/', user)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deactivateUser(user) {
        var deferred = common.$q.defer();
        var req = context.del('/user/' + user.id + '/deactivate')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteUser(user) {
        var deferred = common.$q.defer();
        var req = context.del('/user/' + user.id, user)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function activeDisabledUser(user) {
        var deferred = common.$q.defer();
        var req = context.put('/user/active/' + user.id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getAdminRoles() {
        var deferred = common.$q.defer();
        var req = context.get('/role/admin')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getRoles() {
        var deferred = common.$q.defer();
        var req = context.get('/role')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getUser(id) {
        var deferred = common.$q.defer();
        var req = context.get('/user/' + id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getUsers() {
        var deferred = common.$q.defer();
        var req = context.get('/user')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getUsersForCustomer(customerId) {
        var deferred = common.$q.defer();

        var req = context.get('/user/getforcustomer/' + customerId)
            .then(function (data) {
                deferred.resolve(_.map(data, userModels.userModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveUser(user) {
        if (user.userRoles && user.userRoles.length > 0) {
            user.roles = [{ businessUnit: 0, roles: user.userRoles }];
        }
        var deferred = common.$q.defer();
        var req = context.put('/user/' + user.id, user)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function resetPassword(userEmail, newPassword, sendConfirmation) {
        var deferred = common.$q.defer();

        var args = {
            newPassword: newPassword,
            sendConfirmation: sendConfirmation,
            userEmail: userEmail
        };

        var req = context.post('/user/resetpassword', args)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
};
