﻿module.exports = ['$routeParams', 'common', 'auditDataService', 'auditModelsService', auditController];

function auditController($routeParams, common, auditData, auditModels) {

    var vm = {
        hasMore: true,
        filterTypes: [],
        getLogs: getLogs,
        init: init,
        loading: false,
        loadMore: loadMore,
        logs: [],
        rows: [],
        page: 0,
        filter: {
            endDate: null,
            sortAscending: false,
            sortField: 'createdOn',
            sort: sort,
            startDate: null,
            textFilter: null,
            textFilterType: null
        }
    };

    function getFilterTypes() {
        auditData.getObjectTypes()
            .then(function (result) {
                vm.filterTypes = _.map(result, function (item) {
                    return {
                        name: common.formatting.camelCaseToWords(item),
                        value: item
                    };
                });
                vm.filterTypes.unshift({ name: '[no filter]', value: null });
                vm.filter.textFilterType = vm.filterTypes[0];
            });
    }

    function getLogs(forceReload) {
        vm.loading = true;

        if (forceReload && forceReload === true) {
            vm.page = 0;
        }

        auditData.getLogs(vm.page, vm.filter.startDate.format('YYYY-MM-DD'), vm.filter.endDate.format('YYYY-MM-DD'), vm.filter.textFilter, vm.filter.textFilterType ? vm.filter.textFilterType.value : null)
            .then(function (result) {
                result = _.map(result, function (item) {
                    return auditModels.logModel(item);
                });
                result = _.flatten(result);

                if (result.length === 0) {
                    vm.hasMore = false;
                }

                if (forceReload && forceReload === true) {
                    vm.rows = result;
                } else {
                    vm.rows = vm.rows.concat(result);
                }
            })
            .finally(function () {
                vm.loading = false;
            });
    }

    function init() {
        vm.filter.endDate = moment();
        vm.filter.startDate = moment().add(-6, 'days');
        getFilterTypes();
        getLogs();
    }

    function loadMore() {
        vm.page++;
        getLogs(false);
    }

    function sort(sortField) {
        if (vm.filter.sortField === sortField) {
            vm.filter.sortAscending = !vm.filter.sortAscending;
        } else {
            vm.filter.sortField = sortField;
            vm.filter.sortAscending = true;
        }
    }

    return vm;
}