﻿module.exports = ['$routeParams', 'common', 'dataContext', 'roleDataService', 'permissionDataService', reportRolesController];

function reportRolesController($routeParams, common, context, rolesData, permissionsData) {

    var vm = {
        loading: true,
        getReport: getReport,
        roles: [],
        permissions: [],
        isInRelationship: isInRelationship,
        downloadReport: downloadReport
    };

    function getReport() {

        rolesData.getAll()
            .then(function (result) {
                vm.roles = result;

                return permissionsData.get();
            })
            .then(function (result) {
                vm.permissions = _.sortBy(result, 'name');
                _.map(vm.permissions,
                    function(permission) {
                        if (permission.internalName === 'planning_admin') {
                            permission.name += ' Administrator';
                        }
                    });
                vm.loading = false;
            });
    }

    function isInRelationship(role, index) {
        var permission = vm.permissions[index];
        var found = role.permissions.find(function(item) {
            return item.id === permission.id;
        });

        if (found === undefined) {
            return false;
        }

        return true;
    }

    function downloadReport() {
        var csvOutput = 'Role name;';

        for (var i = 0; i < vm.permissions.length; i++) {
            csvOutput += vm.permissions[i].name + ';';
        }

        csvOutput += '\r\n';

        for (var j = 0; j < vm.roles.length; j++) {
            var role = vm.roles[j];
            csvOutput += role.name + ';';

            for (var k = 0; k < vm.permissions.length; k++) {
                if (vm.isInRelationship(role, k)) {
                    csvOutput += 'x;';
                } else {
                    csvOutput += ';';
                }
            }
            csvOutput += '\r\n';
        }

        common.exportToCSV(csvOutput,'report-roles-permissions-' + moment().format('YYYYMMDDHHmm'));
    }

    return vm;
}