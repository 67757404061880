﻿'use strict';
module.exports = ['goalDataService', nauticalGoalCtrl];

function nauticalGoalCtrl(goalDataService) {
    var vm = {
        nauticalGoals: [],
        loadGoals: loadGoals,
        activeNauticalGoal: null,
        onNauticalGoalSaved: onNauticalGoalSaved,
        onNauticalGoalDeleted: onNauticalGoalDeleted,
    };

    init();

    return vm;

    function init() {
        loadGoals();
    }

    function onNauticalGoalSaved(savedGoal, isNew) {
        if (isNew) {
            vm.nauticalGoals.push(savedGoal);
        }

        vm.activeNauticalGoal = null;
    }

    function onNauticalGoalDeleted(deletedGoal) {
        var idx = _.findIndex(vm.nauticalGoals, function (item) { return item.id === deletedGoal.id; });
        if (idx >= 0)
            vm.nauticalGoals.splice(idx, 1);

        vm.activeNauticalGoal = null;
    }

    function loadGoals() {

        goalDataService.getNauticalGoals()
            .then(function (result) {
                vm.nauticalGoals = result;
            });

    }
}