﻿'use strict';
module.exports = ['toastr', 'coreDataService', 'berthDataService', berthFacilityFormDirective];

function berthFacilityFormDirective(toastr, coreDataService, berthDataService) {
    var directive = {
        bindToController: true,
        controller: berthFacilityFormController,
        controllerAs: 'ctrtBFF',
        restrict: 'E',
        scope: {
            berthId: '@'
        },
        link: berthFacilityFormLink,
        templateUrl: 'templates/berth/directives/berth-facility-form.html'
    };
    return directive;

    function berthFacilityFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () { return ctrl.berthId; },
            function (newValue, oldValue) {
                if (!newValue) return;

                ctrl.getBerthFacilities();
            });
    }

    function berthFacilityFormController() {
        var vm = {
            facilities: [],
            berthFacilities: [],
            selectedBerthfacilityIds: [],
            getBerthFacilities: getBerthFacilities,
            save: save
        };

        init();

        return vm;

        function init() {
            coreDataService.getFacilities().then(function (data) {
                vm.facilities = data;
            });
        }

        function save() {
            var berthId = parseInt(vm.berthId) || 0;
            var facilityIdsToAdd = vm.selectedBerthfacilityIds;
            var facilityIdsToDel = [];

            if (vm.berthFacilities.length > 0) {

                facilityIdsToDel = vm.berthFacilities.filter(function (item) {
                    return vm.selectedBerthfacilityIds.indexOf(item.id) === -1;
                });
                facilityIdsToDel = _.map(facilityIdsToDel, function (item) { return item.id });

                facilityIdsToAdd = vm.selectedBerthfacilityIds.filter(function (itemId) {
                    var orgIds = _.map(vm.berthFacilities, function (item) { return item.id });
                    return orgIds.indexOf(itemId) === -1;
                });
            }

            if (berthId > 0 && (facilityIdsToDel.length > 0 || facilityIdsToAdd.length > 0)) {
                berthDataService.updateBerthFacilities(berthId, facilityIdsToAdd, facilityIdsToDel)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
                
                }

                function successResult(result) {
                    vm.berthFacilities = result;
                }
            }
            toastr.success("Changes Saved");
        }

        function getBerthFacilities() {
            var berthId = parseInt(vm.berthId) || 0;
            if (berthId > 0) {
                berthDataService.getBerthFacilities(berthId)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
                }

                function successResult(result) {
                    vm.berthFacilities = result;
                    vm.selectedBerthfacilityIds = _.map(vm.berthFacilities, function (item) { return item.id });
                }
            }
        }
    }
}