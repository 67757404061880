﻿module.exports = ['invoiceDataService', 'invoiceModelsService', '$routeParams', 'constantsService', editInvoiceController];

function editInvoiceController(invoiceDataService, invoiceModels, $routeParams, constantsService) {

    var vm = {
        attached: attached,
        closeInvoice: function () { location.href = '/#!/invoices'; },
        errors: [],
        invoice: null,
        isLoading: false,
        save: save
    };

    function attached() {
        if ($routeParams.invoiceId) {
            vm.isLoading = true;

            invoiceDataService.getInvoice($routeParams.invoiceId)
                .then(function (result) {
                    vm.isLoading = false;
                    vm.invoice = result;
                    
                    constantsService.preloaded.then(function () {
                        vm.invoiceType = _.find(constantsService.invoiceTypes, function (invoiceType) { return invoiceType.id === result.invoiceTypeId; });
                    });
                });
        }
    }

    function validateNew() {
        var result = [];

        if (!vm.invoice || !vm.invoice.invoiceItems || vm.invoice.invoiceItems.length === 0)
            result.push('Please add one or more lines to the invoice');

        for (var i = 0; i < vm.invoice.invoiceItems.length; i++) {
            var line = vm.invoice.invoiceItems[i];
            if (!line.tariffId || line.tariffId === '')
                result.push('Line ' + (i + 1) + ': No tariff type selected');
            if (!line.description || line.description === '')
                result.push('Line ' + (i + 1) + ': No description entered');
            if (!line.unitaryTariff || parseFloat(line.unitaryTariff < 0))
                result.push('Line ' + (i + 1) + ': No tariff entered');
            if (!line.quantity || parseFloat(line.quantity < 0))
                result.push('Line ' + (i + 1) + ': No quantity entered');
        }

        return result;
    }

    function save() {
        vm.errors = validateNew();

        if (vm.errors.length > 0) {
            return;
        }

        vm.invoice.invoiceItems = _.map(vm.invoice.invoiceItems, function (invoiceItem) {
            invoiceItem.totalAmount = Math.round((invoiceItem.unitaryTariff * invoiceItem.quantity) * 100) / 100;
            return invoiceItem;
        });

        invoiceDataService.update(vm.invoice)
            .then(function (result) {
                location.href = '/#!/invoices';
            });
    }

    return vm;
}