﻿'use strict';

module.exports = ['reportDataService', numberOfBerthOccupationPerYearDirective];

function numberOfBerthOccupationPerYearDirective(reportData) {
    var directive = {
        bindToController: true,
        controller: numberOfBerthOccupationPerYearController,
        controllerAs: 'ctrlNOBOPY',
        restrict: 'E',
        scope: {
        },
        templateUrl: 'templates/report/directives/number-of-berth-occupation-per-year.html'
    };

    return directive;

    function numberOfBerthOccupationPerYearController() {
        var vm = {
            labels: [],
            data: [],
            options: null
        }

        init();

        return vm;

        function init() {
            loadNumberOfBerthOccupationPerYear();
        }

        function loadNumberOfBerthOccupationPerYear() {
            reportData.getNumberOfBerthOccupationPerYear()
            .then(successResult)
            .catch(errorResult);
            function errorResult(response) {
            }

            function successResult(result) {
                vm.labels = _.map(result, function (item) { return item.label });
                vm.data = _.map(result, function (item) { return item.dataPoint });
                vm.options = { legend: { display: true } };
            }
        }
    }
}