﻿module.exports = ['$rootScope', '$uibModalInstance', 'common', 'constantsService', 'dataContext', 'modalArguments', remarksConfirmStatusCtrl];

function remarksConfirmStatusCtrl($rootScope, $uibModalInstance, common, constantsService, context, args) {

    if (!args)
        args = {};

    var vm = {
        cancel: cancel,
        args: args,
        ok: ok,
        title: 'Confirm',
        items: [],
        remarks: null,
        selectedRemark: null,
        valid: false,
        evaluateSelectedRemark: evaluateSelectedRemark,
        validate: validate
    };
    vm.selectedRemark = vm.items[0];

    function evaluateSelectedRemark() {
        if (vm.selectedRemark === -1) {
            vm.remarks = '';
            vm.valid = false;
        } else {
            vm.remarks = vm.selectedRemark;
            vm.valid = true;
        }
    }

    function validate() {
        vm.valid = !vm.args.required || (vm.remarks.trim() && vm.remarks.trim().length > 0);
    }

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function ok() {
        validate();

        if (vm.valid) {
            args.remarks = [vm.remarks];
            $uibModalInstance.close(args);
        }
    }

    function init(){
        var remarkList = constantsService.invoiceRemarks;
        var systemCode = getSystemCode();
        if(vm.args.shipType)
            vm.args.invoiceTypes = constantsService.getInvoiceTypesBasedOnShipType(vm.args.shipType);
            
        if (systemCode)
            remarkList = remarkList.filter(function (x) { return x.systemCode === systemCode; });

        if(vm.args.invoiceTypes)
        {
            remarkList = remarkList.filter(function (x) 
            {
                return _.some(vm.args.invoiceTypes, function (item) { return x.invoiceTypeId === item.id;});
            });
        }

        if (vm.args.invoiceTypes && vm.args.invoiceTypes.length > 1) {
            vm.items = remarkList.map(function (item) {
                var invoiceType = _.find(vm.args.invoiceTypes, function (invoiceType) { return invoiceType.id === item.invoiceTypeId; });
                if (invoiceType)
                    return invoiceType.name + ' - ' + item["text"];

                return item["systemCode"] + ' - ' + item["text"];
            });
        } else {
            vm.items = remarkList.map(function (item) { return item["text"]; });
        }
        vm.selectedRemark = vm.items[0];
        vm.evaluateSelectedRemark();
    }

    function getSystemCode(){
        switch(vm.args.action){
            case 'no-invoice': return 'NO_INVOICE';
            case 'full-discount': return 'FULL_DISCOUNT';
            default: return 'DEFAULT';
        }
    }
    
    init();

    return vm;

}