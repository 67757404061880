﻿module.exports = ['systemMessageDataService', 'widgetModals', systemMessageFormDirective];

function systemMessageFormDirective(systemMessageDataService, widgetModals) {
    var directive = {
        bindToController: true,
        controller: systemMessageFormController,
        controllerAs: 'ctrlSystemMessageForm',
        restrict: 'E',
        scope: {
            systemMessageData: '=',
            onSystemMessageSaved: '&',
            onSystemMessageDeleted: '&'
        },
        link: systemMessageFormLink,
        templateUrl: 'templates/messages/directives/system-messages/system-message-form.html'
    };

    return directive;

    function systemMessageFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () {
            return ctrl.systemMessageData;
        }, function (newValue, oldValue) {
            if (!newValue) {
                return;
            }
            var systemMessageId = newValue.id || 0;
            ctrl.isNewSystemMessage = systemMessageId <= 0;
        }, true);
    }

    function systemMessageFormController() {
        var vm = {
            deleteSystemMessage: deleteSystemMessage,
            saveSystemMessage: saveSystemMessage,
            isNewSystemMessage: true,
            status: {
                errors: []
            },
            datePickerOptions: {
                altInputFormats: [
                    'd-M-yy',
                    'd-M-yyyy',
                    'd-M-yy H:mm',
                    'd-M-yyyy H:mm',
                    'd-M-yy HH:mm',
                    'd-M-yyyy HH:mm',
                    'dd-MM-yy',
                    'dd-MM-yyyy',
                    'dd-MM-yy H:mm',
                    'dd-MM-yyyy H:mm',
                    'dd-MM-yy HH:mm',
                    'dd-MM-yyyy HH:mm'
                ]
            },
            tinymceOptions: {
                plugins: 'link lists',
                height: 400,
                menubar: false
            }
        }

        return vm;

        function saveSystemMessage() {
            var validation = [];
            if (!vm.systemMessageData.name) {
                validation.push('There is no name');
            }

            if (!vm.systemMessageData.body) {
                validation.push('There is no body');
            }
            
            if (!vm.systemMessageData.type) {
                validation.push('No type selected');
            }

            if (validation.length !== 0) {
                vm.status.errors = validation;
                return;
            }

            vm.systemMessageData.startsOn = vm.systemMessageData.startsOn ? moment(vm.systemMessageData.startsOn).format("YYYY-MM-DDTHH:mm:ss") : null;
            vm.systemMessageData.endsOn = vm.systemMessageData.endsOn ? moment(vm.systemMessageData.endsOn).format("YYYY-MM-DDTHH:mm:ss"): null;
            
            systemMessageDataService.saveSystemMessage(vm.systemMessageData)
                .then(successResult)
                .catch(errorResult);
            function errorResult(response) {
                if (vm.systemMessageData) {
                    vm.systemMessageData.startsOn = moment(response.startsOn).toDate();
                    vm.systemMessageData.endsOn = moment(response.endsOn).toDate();
                }
            }

            function successResult(result) {
                if (vm.systemMessageData) {
                    vm.systemMessageData.startsOn = moment(result.startsOn).toDate();
                    vm.systemMessageData.endsOn = moment(result.endsOn).toDate();
                }

                vm.onSystemMessageSaved({ savedSystemMessage: result, isNew: vm.isNewSystemMessage });
            }
        }

        function deleteSystemMessage() {
            widgetModals.areYouSure('You want to delete this system message?').result
                .then(function (result) {
                    if (result === true) {
                        systemMessageDataService.deleteSystemMessage(vm.systemMessageData)
                            .then(successResult)
                            .catch(errorResult);
                    }
                });
            function errorResult(response) {
            }

            function successResult(result) {
                vm.onSystemMessageDeleted({ deletedSystemMessage: result });
            }
        }
    }

}
