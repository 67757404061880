﻿'use strict';
module.exports = ['toastr' ,'coreDataService', berthGeneralFormDirective];

function berthGeneralFormDirective(toastr ,coreDataService) {
    var directive = {
        controller: berthGeneralFormController,
        controllerAs: 'ctrlGBForm',
        restrict: 'E',
        scope: {
            berthData: '='
        },
        bindToController: true,
        templateUrl: 'templates/berth/directives/berth-general-form.html'
    };

    return directive;

    function berthGeneralFormController() {
        var vm = {
            portauthorities: []
        };


        init();

        return vm;
        function init() {
            coreDataService.getPortauthorities()
                .then(function (data) {
                    vm.portauthorities = data;
                });
        }
    }

}
