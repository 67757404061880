﻿module.exports = ['common', 'dataContext', 'customerModelsService', customerDataService];

function customerDataService(common, context, customerModels) {

    var service = {
        approveCustomer: approveCustomer,
        getCustomers: getCustomers,
        getCustomer: getCustomer,
        getCustomerShipTypes: getCustomerShipTypes,
        addCustomer: addCustomer,
        deleteCustomer: deleteCustomer,
        saveCustomer: saveCustomer,
        saveCustomerShipTypes: saveCustomerShipTypes,
        searchQuery: null
    };

    return service;

    function approveCustomer(customerId) {
        var deferred = common.$q.defer();

        var url = '/customer/' + customerId + '/approve';

        var req = context.post(url, {})
            .then(function (data) {
                deferred.resolve(_.map(data, customerModels.CustomerModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCustomers(searchquery) {
        var deferred = common.$q.defer();

        var url = '/customer';
        if (typeof (searchquery) !== 'undefined' && searchquery !== null && searchquery.trim() !== '')
            url = url + '?q=' + searchquery.trim();

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(_.map(data, customerModels.CustomerModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCustomer(id) {
        var deferred = common.$q.defer();
        var req = context.get('/customer/' + id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCustomerShipTypes(id) {
        var deferred = common.$q.defer();
        var req = context.get('/customer/' + id + '/shiptypes')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function addCustomer(customer) {
        var deferred = common.$q.defer();

        var req = context.post('/customer/', customer)
            .then(function (data) {
                deferred.resolve(data);
                service.sendFinancialData(customer);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveCustomer(customer) {
        var deferred = common.$q.defer();
        var req = context.put('/customer/' + customer.id, customer)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveCustomerShipTypes(customerId, shipTypes) {
        var deferred = common.$q.defer();
        var req = context.put('/customer/' + customerId + '/shiptypes', shipTypes)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteCustomer(customer) {
        var deferred = common.$q.defer();
        var req = context.del('/customer/' + customer.id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

}