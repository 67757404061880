﻿module.exports = ['tariffDataService', 'tariffModelsService', 'widgetModals', tariffFormDirective];

function tariffFormDirective(tariffDataService, tariffModelsService, widgetModals) {
    var directive = {
        bindToController: true,
        controller: tariffFormController,
        controllerAs: 'ctrlTariffForm',
        restrict: 'E',
        scope: {
            tariffData: '=',
            tariffPendingData: '=',
            allowedApprove: '=',
            onTariffSaved: '&',
            onTariffDeleted: '&',
            onTariffApproved: '&'
        },
        link: tariffFormLink,
        templateUrl: 'templates/tariff/directives/tariff/tariff-form.html'
    };

    return directive;

    function tariffFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () {
            return ctrl.tariffData;
        }, function (newValue, oldValue) {
            if (!newValue) {
                return;
            }
            var tariffId = newValue.id || 0;
            ctrl.isNewTariff = tariffId <= 0;
        }, true);
    }

    function tariffFormController() {
        var vm = {
            deleteTariff: deleteTariff,
            saveTariff: saveTariff,
            isNewTariff: true,
            approveTariff: approveTariff,
            duplicateTariff: duplicateTariff,
            tariffGroups: [],
            status: {
                errors: []
            },
            datePickerOptions: {
                altInputFormats: [
                    'd-M-yy',
                    'd-M-yyyy',
                    'd-M-yy H:mm',
                    'd-M-yyyy H:mm',
                    'd-M-yy HH:mm',
                    'd-M-yyyy HH:mm',
                    'dd-MM-yy',
                    'dd-MM-yyyy',
                    'dd-MM-yy H:mm',
                    'dd-MM-yyyy H:mm',
                    'dd-MM-yy HH:mm',
                    'dd-MM-yyyy HH:mm'
                ]
            }
        }

        init();

        return vm;

        function init() {
            tariffDataService.getTariffGroups().then(function (data) {
                vm.tariffGroups = data;
            });
        }

        function saveTariff() {
            var validation = tariffModelsService.validateTariff(vm.tariffData);

            if (validation !== true) {
                vm.status.errors = validation;
                return;
            }

            vm.tariffData.startsOn = moment(vm.tariffData.startsOn).format("YYYY-MM-DDTHH:mm:ss");
            vm.tariffData.endsOn = moment(vm.tariffData.endsOn).format("YYYY-MM-DDTHH:mm:ss");
            
            tariffDataService.saveTariff(vm.tariffData)
                .then(successResult)
                .catch(errorResult);
            function errorResult(response) {
                if (vm.tariffData) {
                    vm.tariffData.startsOn = moment(response.startsOn).toDate();
                    vm.tariffData.endsOn = moment(response.endsOn).toDate();
                }
            }

            function successResult(result) {
                if (vm.tariffData) {
                    vm.tariffData.startsOn = moment(result.startsOn).toDate();
                    vm.tariffData.endsOn = moment(result.endsOn).toDate();
                    vm.tariffData.externalItemCode = result.externalItemCode;
                    vm.tariffData.costCentre = result.costCentre;
                }

                vm.onTariffSaved({ savedTariff: result, isNew: vm.isNewTariff });
            }
        }

        function deleteTariff() {
            widgetModals.areYouSure('You want to delete this tariff?').result
                .then(function (result) {
                    if (result === true) {
                        tariffDataService.deleteTariff(vm.tariffData)
                            .then(successResult)
                            .catch(errorResult);
                    }
                });
            function errorResult(response) {
            }

            function successResult(result) {
                vm.onTariffDeleted({ deletedTariff: result });
            }
        }

        function approveTariff() {
            vm.tariffData.startsOn = moment(vm.tariffData.startsOn).format("YYYY-MM-DDTHH:mm:ss");
            vm.tariffData.endsOn = moment(vm.tariffData.endsOn).format("YYYY-MM-DDTHH:mm:ss");

            tariffDataService.approveTariff(vm.tariffData)
                .then(successResult)
                .catch(errorResult);

            function errorResult(response) {
                if(response.status === 403){
                    vm.status.errors =[];
                    vm.status.errors.push(response.data);
                }
                if (vm.tariffData) {
                    vm.tariffData.startsOn = moment(response.startsOn).toDate();
                    vm.tariffData.endsOn = moment(response.endsOn).toDate();
                }
            }

            function successResult(result) {
                var tariff = tariffModelsService.tariffModel(result);
                vm.tariffData = tariff;
                vm.onTariffApproved({approvedTariff: tariff});
            }
        }

        function duplicateTariff() {
            const duplicateTariff = new tariffModelsService.tariffModel();
            duplicateTariff.description = vm.tariffData.description;
            duplicateTariff.systemCode = vm.tariffData.systemCode;
            duplicateTariff.businessLogic = vm.tariffData.businessLogic;
            duplicateTariff.tariffGroup = vm.tariffData.tariffGroup;
            duplicateTariff.tariffGroupId = vm.tariffData.tariffGroupId;
            duplicateTariff.externalItemCode = vm.tariffData.externalItemCode;
            duplicateTariff.startsOn = vm.tariffData.startsOn;
            duplicateTariff.endsOn = vm.tariffData.endsOn;
            duplicateTariff.unitaryTariff = vm.tariffData.unitaryTariff;
            duplicateTariff.costCentre = vm.tariffData.costCentre;
            duplicateTariff.rangeStartsFrom = vm.tariffData.rangeStartsFrom;
            vm.isNewTariff = true;
            vm.tariffData = duplicateTariff;
        }
    }

}
