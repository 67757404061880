﻿module.exports = ['coreDataModels', berthGroupFunctionTypeFilter];

function berthGroupFunctionTypeFilter(coreDataModels) {
    return function (berthGroupFunction) {
        if (isNaN)
            berthGroupFunction = parseInt(berthGroupFunction);

        var functionType = _.find(coreDataModels.berthFunctions, function (item) {
            return item.id === berthGroupFunction;
        });

        return functionType ? functionType.text : berthGroupFunction;

    };

}