﻿module.exports = [arrivalsStatusLabelDirective];

function arrivalsStatusLabelDirective() {
    var directive = {
        bindToController: true,
        controller: arrivalsStatusLabelController,
        controllerAs: 'ctrlArrivalsStatusLabel',
        restrict: 'E',
        scope: {
            arrivalData: '='
        },
        templateUrl: 'templates/arrivals/directives/arrivals-status-label.html'
    };

    return directive;

    function arrivalsStatusLabelController() {

        var vm = {
        };

        return vm;
    }
}