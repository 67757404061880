﻿module.exports = [coreDataModels];

function coreDataModels() {

    var vm = {
        regionModel: regionModel,
        shiptypeModel: shiptypeModel,
        facilityModel: facilityModel,
        berthgroupModel: berthgroupModel,
        berthModel: berthModel,
        customerModel: customerModel,
        businessruleModel: businessruleModel,
        nauticalgoalModel: nauticalgoalModel,
        portauthorityModel: portauthorityModel,
        commercialgoalModel: commercialgoalModel,
        berthFunctions: [{ id: 1, text: 'Reservation group' }, { id: 2, text: 'Planning group' }]
    };

    return vm;

    function baseModel(baseData) {
        var baseModel = {
            id: null,
            name: null
        };

        if (baseData === undefined || baseData === null)
            return baseModel;

        baseModel.id = baseData.id;
        baseModel.name = baseData.name;

        return baseModel;
    }

    function shiptypeModel(shiptypeData) {
        return baseModel(shiptypeData);
    }

    function facilityModel(facilityData) {
        return baseModel(facilityData);
    }

    function nauticalgoalModel(nauticalgoalData) {
        return baseModel(nauticalgoalData);
    }

    function commercialgoalModel(commercialgoalData) {
        return baseModel(commercialgoalData);
    }

    function portauthorityModel(portauthorityData) {
        return baseModel(portauthorityData);
    }

    function businessruleModel(businessruleData) {
        return baseModel(businessruleData);
    }

    function berthgroupModel(berthgroupData) {
        var result = {
            id: null,
            name: null,
            functionType: null,
            regionId: null,
            sortOrder: null
        };

        if (berthgroupData === undefined || berthgroupData === null)
            return result;

        result.id = berthgroupData.id;
        result.name = berthgroupData.name;
        result.functionType = berthgroupData.functionType;
        result.regionId = berthgroupData.regionId;
        result.sortOrder = berthgroupData.sortOrder;

        return result;
    }

    function berthModel(berthData) {
        var result = {
            id: null,
            name: null,
            portAuthorityId: null
        };

        if (berthData === undefined || berthData === null)
            return result;

        result.id = berthData.id;
        result.name = berthData.name;
        result.portAuthorityId = berthData.portAuthorityId;

        return result;
    }

    function customerModel(customerData) {
        var customerModel = {
            id: null,
            name: null,
            customerPortAuthority: {
                debtorNumber: null,
                active: null
            }
        };

        if (customerData === undefined || customerData === null)
            return customerModel;

        customerModel.id = customerData.id;
        customerModel.name = customerData.name;
        customerModel.customerPortAuthority = customerData.customerPortAuthority;

        return customerModel;
    }

    function regionModel(regionData) {
        return baseModel(regionData);
    }
}