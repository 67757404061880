﻿module.exports = ['common', 'communicatedStatusService', 'planningDataService', 'toastr', communicatedStatusDirective];

function communicatedStatusDirective(common, communicatedStatusService, planningDataService, toastr) {

    var directive = {
        bindToController: true,
        controller: communicatedStatusController,
        controllerAs: 'ctrlCommunicatedStatus',
        restrict: 'E',
        scope: {
            communicatedStatus: '=',
            reservationId: '=',
            isReadOnly: '='
        },
        templateUrl: 'templates/planning/directives/communicated-status.html'
    };

    return directive;

    function communicatedStatusController() {
        var ctrlCommunicatedStatus = this;

        this.common = common;
        this.isConfirmed = false;
        this.isSendingNotification = false;
        this.isReadOnly = false;

        ctrlCommunicatedStatus.setStatus = function (status) {
            planningDataService.setStatus(ctrlCommunicatedStatus.reservationId, { status: status })
                .then(function (result) {
                    ctrlCommunicatedStatus.communicatedStatus = result;
                    ctrlCommunicatedStatus.isConfirmed = true;
                });

        }

        ctrlCommunicatedStatus.hasStatus = function (status, lastItemOnly) {
            return communicatedStatusService.hasCommunicatedStatus(ctrlCommunicatedStatus.communicatedStatus, status, lastItemOnly);
        }

        ctrlCommunicatedStatus.resendConfirmation = function () {
            ctrlCommunicatedStatus.isSendingNotification = true;
            planningDataService.resendConfirmation(ctrlCommunicatedStatus.reservationId)
                .then(function (result) {
                    toastr.success("Notification successfully sent.");
                    ctrlCommunicatedStatus.isSendingNotification = false;
                });

        }
    }

};
