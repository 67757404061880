﻿module.exports = ['$anchorScroll', '$routeParams', 'common', 'roleDataService', 'roleModelService', roleOverviewController];

function roleOverviewController($anchorScroll, $routeParams, common, roleData, roleModels) {

    var vm = {
        addRole: addRole,
        attached: attached,
        editingRole: null,
        newRole: new roleModels.roleModel(),
        roles: [],
        saveRole: saveRole,
        status: {
            completed: false,
            errors: [],
            saving: false,
            setAddNew: setAddNew,
            setEdit: setEdit,
            showAddNew: false
        },
    };

    function addRole(role) {

        var validation = roleModels.validateRole(vm.newRole);
        if (validation !== true) {
            vm.status.errors = validation;
            return;
        }

        vm.status.saving = true;
        roleData.add(vm.newRole)
            .then(function(result) {
                    vm.roles.push(result);
                    vm.newRole = new roleModels.roleModel();
                    vm.status.saving = false;
                    vm.status.showAddNew = false;
                },
                function(response) {
                    vm.status.errors = common.errorToText(response);
                    vm.status.saving = false;
                });
    }

    function attached() {
        loadRoles();

        var roleId = $routeParams.roleId;
        if (roleId !== undefined && roleId !== null && roleId !== '' && roleId > 0) {
            roleData.getRole(roleId)
                .then(function (result) {
                    if (result !== undefined && result !== null) {
                        vm.status.setEdit(result);
                    }
                });
        }
    }

    function loadRoles() {
        roleData.getAll()
            .then(function (result) {
                vm.roles = result;
            });
    }

    function saveRole() {
        if (vm.editingRole === null) return;

        vm.status.errors = [];

        var validation = roleModels.validateRole(vm.editingRole);
        if (validation !== true) {
            vm.status.errors = validation;
            return;
        }

        vm.status.saving = true;
        roleData.update(vm.editingRole)
            .then(successResult, errorResult);

        function errorResult(response) {
            vm.status.errors = common.errorToText(response);
            vm.status.saving = false;
        };

        function successResult(result) {
            vm.editingRole = null;
            vm.status.completed = true;
            vm.status.saving = false;
        };
    };
    function setAddNew() {
        vm.editingRole = null;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = true;
    };

    function setEdit(role) {
        vm.editingRole = role;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = false;
        $anchorScroll();
    };

    return vm;
};
