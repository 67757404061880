﻿module.exports = [
  "$routeParams",
  "common",
  "dataContext",
  "reportDataService",
  reportPlanningController,
];

function reportPlanningController($routeParams, common, context, reportData) {
  var vm = {
    loading: true,
    exportCsv: exportCsv,
    getReport: getReport,
    chageReportFilter: chageReportFilter,
    loadMore: loadMore,
    report: [],
    rows: [],
    hasMore: true,
    page: 0,
    filter: {
      sortAscending: true,
      sortField: "eta",
      sort: sort,
    },
    downloadFilename: "planning_" + moment().format("YYYYMMDDHHmmss") + ".csv",
    dateYearFilterOptions: [
      new Date().getFullYear(),
      new Date().getFullYear() - 1,
      new Date().getFullYear() - 2,
      new Date().getFullYear() - 3,
    ],
    dateYearFilter: new Date().getFullYear(),
  };

  function chageReportFilter() {
    vm.page = 0;
    getReport(vm.page, vm.dateYearFilter);
  }

  function getReport() {
    reportData
      .getReportPlanning(vm.page, vm.dateYearFilter)
      .then(function (result) {
        vm.report = result;

        vm.rows = vm.report;
        vm.loading = false;
      });
  }

  function loadMore() {
    vm.page++;
    reportData
      .getReportPlanning(vm.page, vm.dateYearFilter)
      .then(function (result) {
        if (result.length === 0) {
          vm.hasMore = false;
          vm.loading = false;
          return;
        }
        _.map(result, function (item) {
          vm.rows.push(item);
        });

        vm.loading = false;
      });
  }

  function sort(sortField) {
    if (vm.filter.sortField === sortField) {
      vm.filter.sortAscending = !vm.filter.sortAscending;
    } else {
      vm.filter.sortField = sortField;
      vm.filter.sortAscending = true;
    }
  }

  function exportCsv() {
    var uri =
      "/report/planning?page=0&size=65000&output=csv&csv_include_header=1&csv_date_format=yyyy-MM-dd+HH:mm&dateYearFilter=" + vm.dateYearFilter;

    context.get(uri).then(function (result) {
      common.exportToCSV(
        result,
        "planning-export-" + moment().format("YYYYMMDDHHmmss")
      );
    });
  }

  function search(query) {
    query = query.toLowerCase();

    if (query === null || query === undefined || query.length === 0) {
      vm.rows = vm.report;
    } else {
      vm.rows = _.filter(vm.report, function (item) {
        return (
          item.shipName.toLowerCase().indexOf(query) !== -1 ||
          item.eta.toString().toLowerCase().indexOf(query) !== -1 ||
          item.etd.toString().toLowerCase().indexOf(query) !== -1 ||
          item.berth.toLowerCase().indexOf(query) !== -1 ||
          item.customer.toLowerCase().indexOf(query) !== -1
        );
      });
    }
  }

  return vm;
}
