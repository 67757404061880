﻿module.exports = ['constantsService', shiptypeDisplayFilter];

function shiptypeDisplayFilter(constants) {
    return function (shiptype) {
        if (!shiptype)
            return null;

        var result = _.find(constants.shipTypes, function (item) {
            return item.id === shiptype;
        });

        if (result && result.name)
            return result.name;

        return shiptype;
    };
};
