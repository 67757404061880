﻿'use strict';
module.exports = [invoiceTableDirective];

function invoiceTableDirective() {
    var directive = {
        controller: invoiceTableController,
        controllerAs: 'ctrlInvoiceTable',
        restrict: 'E',
        scope: {
            invoice: '=',
            showArticleCode: '=',
            showRemarks: '=',
            showTotal: '='
        },
        link: function (scope) {
            //console.log(scope);
        },
        bindToController: true,
        templateUrl: 'templates/invoices/directives/invoice-table.html'
    };

    function invoiceTableController() {
        var vm = {
            totalAmount: totalAmount
        };

        function totalAmount() {
            var sum = _.reduce(vm.invoice.invoiceItems, function (memo, item) {
                return Math.round((memo + item.totalAmount) * 100) / 100; //item.unitaryTariff * item.quantity;
            }, 0);

            return sum;
        }
        return vm;
    }

    return directive;
}