﻿'use strict';
module.exports = ['goalDataService', commercialGoalCtrl];

function commercialGoalCtrl(goalDataService) {
    var vm = {
        commercialGoals: [],
        loadGoals: loadGoals,
        activeCommercialGoal: null,
        onCommercialGoalSaved: onCommercialGoalSaved,
        onCommercialGoalDeleted: onCommercialGoalDeleted
    };

    init();

    return vm;

    function init() {
        loadGoals();
    }
    function onCommercialGoalSaved(savedGoal, isNew) {
        if (isNew) {
            vm.commercialGoals.push(savedGoal);
        }

        vm.activeCommercialGoal = null;
    }


    function onCommercialGoalDeleted(deletedGoal) {
        var idx = _.findIndex(vm.commercialGoals, function (item) { return item.id === deletedGoal.id; });
        if (idx >= 0)
            vm.commercialGoals.splice(idx, 1);

        vm.activeCommercialGoal = null;
    }


    function loadGoals() {
        goalDataService.getCommercialGoalsForAdmin()
            .then(function (result) {
                vm.commercialGoals = result;
            });

    }
}