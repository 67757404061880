﻿module.exports = ['orderByFilter','invoiceRemarksDataService', 'invoiceRemarksModelsService', invoiceRemarksOverviewDirective];

function invoiceRemarksOverviewDirective(orderBy, invoiceRemarksDataService, invoiceRemarksModels) {
    var directive = {
        bindToController: true,
        controller: invoiceRemarksOverviewController,
        controllerAs: 'ctrlInvoiceRemarksOverview',
        restrict: 'E',
        scope: {
            invoiceRemarksData: '=',
            activeInvoiceRemark: '=',
            invoiceRemarksSystemCodes: '=',
        },
        templateUrl: 'templates/invoice-remarks/directives/invoice-remarks-overview.html'
    };

    return directive;

    function invoiceRemarksOverviewController() {
        var vm = {
            setActiveInvoiceRemark: setActiveInvoiceRemark,
            onSortBySystemCodeClick: onSortBySystemCodeClick,
            onSortByActiveClick: onSortByActiveClick,
            onSortByInvoiceTypeClick:onSortByInvoiceTypeClick,
            selectedLink: selectedLink,
            sortByActive:false,
            sortByInvoiceType:false,
            sortBySystemCode: false,
            currentlySelected: false
        };
        
        return vm;

        function setActiveInvoiceRemark(invoiceRemarks) {
            //if new clicked!!!
            if (!invoiceRemarks) {
                invoiceRemarks = new invoiceRemarksModels.invoiceRemarksModel();
                invoiceRemarks.invoiceType = 1;
            }
            vm.activeInvoiceRemark = invoiceRemarks;
        }

        function selectedLink(id) {
            if (vm.activeInvoiceRemark) {
                if (parseInt(id) === parseInt(vm.activeInvoiceRemark.id)) {
                    vm.currentlySelected = true;
                    return vm.currentlySelected;
                }
            }
        }

        function onSortBySystemCodeClick(){
            vm.sortBySystemCode = !vm.sortBySystemCode;
        }

        function onSortByActiveClick(){
            vm.sortByActive = !vm.sortByActive;
            vm.sortByInvoiceType = false;

            if(vm.sortByActive){
                vm.invoiceRemarksData = orderBy( vm.invoiceRemarksData,'active',true);
            }else{
                vm.invoiceRemarksData = orderBy( vm.invoiceRemarksData,'text',false);
            }

        }
        
        function onSortByInvoiceTypeClick(){
            vm.sortByInvoiceType = !vm.sortByInvoiceType;
            vm.sortByActive = false;
            if(vm.sortByInvoiceType){
                vm.invoiceRemarksData = orderBy( vm.invoiceRemarksData,'invoiceType',false);
            }else{
                vm.invoiceRemarksData = orderBy( vm.invoiceRemarksData,'text',false);
            }

        }
    }
}