﻿'use strict';

angular.module('poa.rprp.invoiceremarks', [])
    .config(['$routeProvider', routeConfig])
    .controller('invoiceRemarksController', require('./controllers/invoice-Remarks'))
    .factory('invoiceRemarksDataService', require('./services/invoice-remarks-data'))
    .factory('invoiceRemarksModelsService', require('./services/invoice-remarks-models'))
    .directive('invoiceRemarksOverview', require('./directives/invoice-remarks-overview'))
    .directive('invoiceRemarksForm', require('./directives/invoice-remarks-form'))
    ;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/invoiceremarks',
            {
                templateUrl: 'templates/invoice-remarks/controllers/invoice-remarks.html'
            });
}