﻿module.exports = [activityDateDisplayFilter];

function activityDateDisplayFilter() {
    return function (activity) {
        if (!activity)
            return null;
        if (!activity.startsOn)
            return null;

        var startsOn = moment(activity.startsOn);

        if (!activity.endsOn)
            return startsOn.format('D MMM YYYY HH:mm');

        var endsOn = moment(activity.endsOn);

        if (startsOn.year() === endsOn.year()) {
            if (startsOn.month() === endsOn.month()) {
                if (startsOn.dayOfYear() === endsOn.dayOfYear()) {
                    return endsOn.format('D MMM YYYY') + ' | ' + startsOn.format('HH:mm') + ' - ' + endsOn.format('HH:mm');
                }
                return startsOn.format('D') + ' - ' + endsOn.format('D MMM YYYY') + ' | ' + startsOn.format('HH:mm') + ' - ' + endsOn.format('HH:mm');
            }
            return startsOn.format('D MMM') + ' - ' + endsOn.format('D MMM YYYY') + ' | ' + startsOn.format('HH:mm') + ' - ' + endsOn.format('HH:mm');
        }
        return startsOn.format('D MMM YYYY') + ' - ' + endsOn.format('DD MMM YYYY') + ' | ' + startsOn.format('HH:mm') + ' - ' + endsOn.format('HH:mm');
    };
};
