﻿module.exports = ['common','coreDataService','constantsService','invoiceRemarksDataService','reportDataService', invoicedDiscountPerReasonController];

function invoicedDiscountPerReasonController(common,coreDataService,constant, invoiceRemarksData, reportData) {

    var vm = {
        attached: attached,
        isLoading: false,
        filter: {
            dateFrom: null,
            dateTo: null,
            selectedCustomer:null,
            selectedShipType:null,
            selectedRemark:null,
            sortAscending: true,
            sortField: 'eta',
            sort: sort
        },
        dashboardData:[],
        dashboardTitle:'All Remarks',
        onSelectDashBoard: onSelectDashBoard,
        onSelectRemark:onSelectRemark,
        onSelectFilter:onSelectFilter,
        invoiceRemarks:[],
        realisationModels:[],
        revenueModels:[],
        customers:[],
        shipTypes:[],
        getAmount:getAmount,
        exportToCSV:exportToCSV,
        sortOnFromTime:sortOnFromTime,
        sortOnUntilTime:sortOnUntilTime,
        getGrossAmount:getGrossAmount,
        getDiscountAmount:getDiscountAmount,
        getReservationStatus:getReservationStatus
    };
    var selectedDashboarditem = null;
    function attached() {

        vm.filter.dateFrom  = moment().date(1).add(-1, 'days');
        vm.filter.dateTo  = moment();

        load();
    }

    function load() {
        vm.isLoading = true;
        vm.dashboardData.push({name:'Number of realisations',data:null});
        vm.dashboardData.push({name:'Revenue realised',data:null,type:'currency'});
        vm.dashboardData.push({name:'Discount realised',data:null,type:'currency'});

        reportData.getAllRemarks(
            moment(vm.filter.dateFrom).format('YYYY-MM-DD'),
            moment(vm.filter.dateTo).format('YYYY-MM-DD'), 
            vm.filter.selectedShipType).then(function(result){
                vm.invoiceRemarks =_.sortBy(result,function(i){ return i;});
            });

        coreDataService.getCustomers().then(function (data) {
            vm.customers = _.sortBy(data,'name');
        });

        vm.shipTypes = _.sortBy(constant.shipTypes,'name');

        GetData();
    }

    function GetData(remark){
        vm.isLoading = false;
        vm.dashboardData[0].data = null;
        vm.dashboardData[1].data = null;
        vm.dashboardData[2].data = null;
        if(remark){
            remark = remark.substring(remark.indexOf("-") + 2);
        }
        reportData.getInvoiceDiscountStats(
            moment(vm.filter.dateFrom).format('YYYY-MM-DD'),
            moment(vm.filter.dateTo).format('YYYY-MM-DD'), 
            vm.filter.selectedShipType,
            vm.filter.selectedCustomer,
            remark).then(function(result){
            vm.dashboardData[0].data = result.numberOfRealisations;
            vm.dashboardData[1].data = result.totalRevenue;
            vm.dashboardData[2].data = result.totalDiscount;
            vm.isLoading = true;
        });

        if(selectedDashboarditem){
            onSelectDashBoard(selectedDashboarditem);
        }
    }

    function onSelectDashBoard(item){
        var remark =  vm.filter.selectedRemark;
        if(remark){
            remark = remark.substring(remark.indexOf("-") + 2);
        }
        
        vm.revenueModels = [];
        vm.realisationModels = [];

        selectedDashboarditem = item;

        switch(item.name){
            case vm.dashboardData[0].name: 
            reportData.getRealisationsBasedOnRemark(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateTo).format('YYYY-MM-DD'), vm.filter.selectedShipType, vm.filter.selectedCustomer, remark).then(function(result){
                vm.realisationModels = result;
                vm.filter.sort('ata');
            }); break;
            
            case vm.dashboardData[1].name: 
            reportData.getAllInvoiceWithRemarks(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateTo).format('YYYY-MM-DD'), vm.filter.selectedShipType, vm.filter.selectedCustomer, remark).then(function(result){
                vm.revenueModels = result;
                vm.filter.sort('ata');
            }); break;
            
            case vm.dashboardData[2].name: 
            reportData.getAllInvoiceDiscounts(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateTo).format('YYYY-MM-DD'), vm.filter.selectedShipType, vm.filter.selectedCustomer, remark).then(function(result){
                vm.revenueModels = result;
                vm.filter.sort('ata');
            }); break;
        }
    }

    function onSelectFilter(){
        reportData.getAllRemarks(
            moment(vm.filter.dateFrom).format('YYYY-MM-DD'),
            moment(vm.filter.dateTo).format('YYYY-MM-DD'),
            vm.filter.selectedShipType).then(function (result) {
                vm.invoiceRemarks = _.sortBy(result, function (i) { return i; });
                if(!_.any(vm.invoiceRemarks ,function(x){return x == vm.filter.selectedRemark})){
                    vm.filter.selectedRemark = null;
                }
                onSelectRemark();
            });
    }

    function onSelectRemark(){
        if(vm.filter.selectedRemark!==null&&vm.filter.selectedRemark.length>0){
            vm.dashboardTitle = vm.filter.selectedRemark;
            GetData(vm.filter.selectedRemark);
        }
        else{
            vm.dashboardTitle = 'All Remarks';
            GetData();
        }
    }

    function getAmount(invoice){
        var sum = 0;
        for(i=0;i<invoice.invoiceItems.length;i++){
            sum += invoice.invoiceItems[i].totalAmount;
        }
        invoice.totalAmount = sum;
        return Math.round(sum*100)/100;
    }
    
    function getGrossAmount(invoice){
        var sum = 0;
        for(i=0;i<invoice.invoiceItems.length;i++){
            if(invoice.invoiceItems[i].totalAmount>0)
                sum += invoice.invoiceItems[i].totalAmount;
        }
        invoice.grossAmount = sum;
        return Math.round(sum*100)/100;
    }
    
    function getDiscountAmount(invoice){
        var sum = 0;
        for(i=0;i<invoice.invoiceItems.length;i++){
            if(invoice.invoiceItems[i].totalAmount<0)
                sum += invoice.invoiceItems[i].totalAmount;
        }
        invoice.discountAmount = sum;
        return Math.round(sum*100)/100;
    }

    function sort(sortField) {
        if (vm.filter.sortField === sortField) {
            vm.filter.sortAscending = !vm.filter.sortAscending;
        } else {
            vm.filter.sortField = sortField;
            vm.filter.sortAscending = true;
        }
    }

    function sortOnFromTime(model)
    {
        model.forEach(function(a){
            a.fromTime = (a.ata?a.ata:a.eta)
        });
        sort('fromTime');
    }

    function sortOnUntilTime(model)
    {
        model.forEach(function(a){
            a.untilTime = (a.atd?a.atd:a.etd)
        });
        sort('untilTime');
    }

    function getReservationStatus(reservation){
        if(reservation!=null&&reservation.reservationStatus){
            var status;
            if(reservation.reservationStatus.isCancelled){
                status = 'Cancelled'
            }
            if(reservation.reservationStatus.isChangedButNotConfirmed){
                status = 'Changed'
            }
            if(reservation.reservationStatus.isPlanningCompleted){
                status = 'Planned'
            }
            if(reservation.reservationStatus.isRejected){
                status = 'Rejected'
            }
            if(reservation.reservationStatus.isTransferred){
                status = 'Transferred'
            }
            reservation.status = status;
            return status;
        }
    }

    function exportToCSV(){
        var remark = vm.filter.selectedRemark;
        if(remark){
            remark = remark.substring(remark.indexOf("-") + 2);
        }
        exportRealisationToCSV(remark);
        exportRevenueToCSV(remark);
        exportDiscountToCSV(remark);
    }

    function exportRealisationToCSV(remark){
        reportData.getRealisationsBasedOnRemark(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateTo).format('YYYY-MM-DD'), vm.filter.selectedCustomer, remark).then(function(result){
            var csvOutput = 'REMARK;CUSTOMER;SHIP;FROM;UNTIL;\r\n';
            for (var i = 0; i < result.length; i++) {
                var realisationModel = result[i];
                csvOutput +=
                realisationModel.remark + ';' +
                realisationModel.realisation.customer.name + ';' +
                realisationModel.realisation.ship.name + ';' +
                (realisationModel.ata == null? realisationModel.eta:realisationModel.ata) + ';' +
                (realisationModel.atd == null? realisationModel.etd:realisationModel.atd)   + '\r\n';
            }

            common.exportToCSV(csvOutput,'report-invoice-discounts-realisations-'+ moment().format('YYYYMMDD'));
        });
    }

    function exportRevenueToCSV(remark){
        reportData.getAllInvoiceWithRemarks(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateTo).format('YYYY-MM-DD'), vm.filter.selectedCustomer, remark).then(function(result){
            var csvOutput = invoiceCSVOutput(result);

            common.exportToCSV(csvOutput,'report-invoice-discounts-revenue-'+ moment().format('YYYYMMDD'));
        });
    }
    
    function exportDiscountToCSV(remark){
        reportData.getAllInvoiceDiscounts(moment(vm.filter.dateFrom).format('YYYY-MM-DD'), moment(vm.filter.dateTo).format('YYYY-MM-DD'), vm.filter.selectedCustomer, remark).then(function(result){
            var csvOutput = invoiceCSVOutput(result);
            common.exportToCSV(csvOutput,'report-invoice-discounts-discount-'+ moment().format('YYYYMMDD'));
        });
    }

    function invoiceCSVOutput(result){
        var csvOutput = 'ED NUMBER;CUSTOMER;SHIP;FROM;UNTIL;STATUS;GROSS TURNOVER;DISCOUNT;REMARK;NETTO TURNOVER;DESCRIPTION;COST CENTRE;UNITARY TARRIF;QUANTITY;AMOUNT;\r\n';
        for (var i = 0; i < result.length; i++) {
            var model = result[i];
            var reservation = model.reservation;
            var invoice = model.invoice;
            for (var j = 0; j < invoice.invoiceItems.length; j++) {
                var invoiceItem = invoice.invoiceItems[j];
                csvOutput +=
                (reservation==null?'':reservation.reservationReference) + ';' +
                (reservation==null?'':reservation.customer.name) + ';' +
                (reservation==null?'':reservation.ship.name) + ';' +
                (model.ata==null?model.eta:model.ata) + ';' +
                (model.atd==null?model.etd:model.atd) + ';' +
                getReservationStatus(reservation)+ ';' +
                getGrossAmount(invoice)+ ';' +
                getDiscountAmount(invoice)+ ';' +
                model.remark + ';' +
                getAmount(invoice)+ ';' +
                invoiceItem.description + ';' +
                invoiceItem.costCentre + ';' +
                invoiceItem.unitaryTariff + ';' +
                invoiceItem.quantity + ';' +
                invoiceItem.totalAmount + '\r\n';
            }
        }
        return csvOutput;
    }

    return vm;
}
