﻿module.exports = [templateOverviewDirective];

function templateOverviewDirective() {
    var directive = {
        bindToController: true,
        controller: templateOverviewController,
        controllerAs: 'ctrlTemplateOverview',
        restrict: 'E',
        scope: {
            activeTemplate: '=',
            templatesData: '='
        },
        templateUrl: 'templates/messages/directives/templates/template-overview.html'
    };

    return directive;

    function templateOverviewController() {
        var vm = {
            setActiveTemplate: setActiveTemplate,
            selectedLink: selectedLink,
            currentlySelected: false
        };
        
        return vm;

        function setActiveTemplate(template) {
            vm.activeTemplate = _.clone(template);
        }

        function selectedLink(id) {
            if (vm.activeTemplate) {
                if (parseInt(id) === parseInt(vm.activeTemplate.id)) {
                    vm.currentlySelected = true;
                    return vm.currentlySelected;
                }
            }
        }
    }
}
