﻿module.exports = ['common', 'dataContext', 'reportDataService', 'coreDataService', reportTurnoverController];

function reportTurnoverController(common, context, reportData, coreDataService) {

    var vm = {
        loading: true,
        getReport: getReport,
        values: [],
        dayValues: [],
        chartLabels: [],
        dayChartLabels: [],
        generate: generate,
        filterData: {},
        setupData: setupData,
        setupValuesByPeriod: setupValuesByPeriod,
        setupLabelsByPeriod: setupLabelsByPeriod,
        berthGroups: [],
        berths: [],
        customers: [],
        periods: [
            {
                value: 1,
                name: 'Day'
            },
            {
                value: 7,
                name: 'Week'
            },
            {
                value: 30,
                name: 'Month'
            }
        ],
        datePickerOptions: {
            altInputFormats: [
                'd-M-yy',
                'd-M-yyyy',
                'dd-MM-yy',
                'dd-MM-yyyy'
            ]
        },
        chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 50
                }
            },
            elements: {
                point: {
                    radius: 7,
                    hoverRadius: 10,
                    hitRadius: 2,
                    backgroundColor: 'rgba(144,185,202,1)'
                },
                line: {
                    borderColor: 'rgba(144,185,202,1)',
                    backgroundColor: 'rgba(144,185,202,1)',
                    borderWidth: 3
                }
            },
            tooltips: {
                titleFontSize: 14,
                bodyFontSize: 16
            },
            scales: {
                xAxes: [{
                    ticks: {
                        autoSkip: false,
                        maxRotation: 45,
                        minRotation: 45
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
    };

    init();

    return vm;

    function init() {
        vm.filterData.dateFrom = moment().subtract(3, 'months').toDate();
        vm.filterData.dateTo = moment().toDate();
        vm.filterData.period = 7;

        coreDataService.getBerthgroups().then(function (data) {
            vm.berthGroups = data;
        });

        coreDataService.getBerths().then(function (data) {
            vm.berths = data;
        });

        coreDataService.getCustomers().then(function (data) {
            vm.customers = data;
        });
    }

    function getReport(dateFrom, dateTo) {
        vm.dayValues = [];

        reportData.getReportTurnover(dateFrom, dateTo, vm.filterData.berthGroupId, vm.filterData.berthId, vm.filterData.customerId)
            .then(function (result) {
                vm.setupData(result);

                vm.loading = false;
            });
    }

    function generate() {
        if (!vm.filterData || !vm.filterData.dateFrom || !vm.filterData.dateTo) {
            return;
        }

        var dateFrom = vm.filterData.dateFrom;
        var dateTo = vm.filterData.dateTo;

        vm.getReport(moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD'));

        var dateIterator = new Date(dateFrom.getTime());
        dateIterator.setDate(dateIterator.getDate() - 1);

        var labels = [];
        while (dateIterator < dateTo) {
            labels.push(dateIterator);
            dateIterator = new Date(dateIterator.setDate(dateIterator.getDate() + 1));
        }

        vm.dayChartLabels = _.map(labels, function (label) { return moment(label).format('DD-MM-YYYY') });
    }

    function setupData(result) {
        if (result.length === 0) {
            _.map(vm.dayChartLabels, function (chartLabel) {
                vm.dayValues.push(0);
            });
        } else {
            var index = 0;
            for (var iterator = 0; iterator < vm.dayChartLabels.length; iterator++) {
                if (!result[index]) {
                    vm.dayValues.push(0);
                    continue;
                }

                while (vm.dayChartLabels[iterator] !== result[index].label) {
                    vm.dayValues.push(0);
                    break;
                }

                if (vm.dayValues[iterator] === 0)
                    continue;

                vm.dayValues.push(result[index].dataPoint);
                index++;
            }
        }

        vm.setupValuesByPeriod(vm.filterData.period);
    }

    function setupValuesByPeriod(period) {
        var weekValues = [];
        var monthValues = [];

        switch (period) {
            case 7:
                for (var i = 0; i < vm.dayValues.length; i += 7) {
                    var weekValue = 0;
                    for (var j = i; j < i + 7; j++) {
                        if (vm.dayValues[j] !== undefined) {
                            weekValue += vm.dayValues[j];
                        } else {
                            weekValue += 0;
                        }
                    }
                    weekValues.push(weekValue);
                }
                vm.values = weekValues;
                vm.chartLabels = vm.setupLabelsByPeriod(period);
                break;
            case 30:
                for (var i = 0; i < vm.dayValues.length; i += 30) {
                    var monthValue = 0;
                    for (var j = i; j < i + 30; j++) {
                        if (vm.dayValues[j] !== undefined) {
                            monthValue += vm.dayValues[j];
                        } else {
                            monthValue += 0;
                        }
                    }
                    monthValues.push(monthValue);
                }
                vm.values = monthValues;
                vm.chartLabels = vm.setupLabelsByPeriod(period);
                break;
            default:
                vm.values = vm.dayValues;
                vm.chartLabels = vm.setupLabelsByPeriod(period);
                break;
        }
    }

    function setupLabelsByPeriod(period) {
        var weekLabels = [];
        var monthLabels = [];

        switch (period) {
            case 7:
                for (var i = 0; i < vm.dayChartLabels.length; i += 7) {
                    var weekLabel = '';

                    if (vm.dayChartLabels[i] !== undefined) {
                        weekLabel += vm.dayChartLabels[i] + ' - ';
                    } else {
                        return weekLabels;
                    }

                    if (vm.dayChartLabels[i + 6] !== undefined) {
                        weekLabel += vm.dayChartLabels[i + 6];
                    } else {
                        weekLabel += vm.dayChartLabels[vm.dayChartLabels.length - 1];
                    }

                    weekLabels.push(weekLabel);
                }
                return weekLabels;
            case 30:
                for (var i = 0; i < vm.dayChartLabels.length; i += 30) {
                    var monthLabel = '';

                    if (vm.dayChartLabels[i] !== undefined) {
                        monthLabel += vm.dayChartLabels[i] + ' - ';
                    } else {
                        return monthLabels;
                    }

                    if (vm.dayChartLabels[i + 29] !== undefined) {
                        monthLabel += vm.dayChartLabels[i + 29];
                    } else {
                        monthLabel += vm.dayChartLabels[vm.dayChartLabels.length - 1];
                    }

                    monthLabels.push(monthLabel);
                }
                return monthLabels;
            default:
                return vm.dayChartLabels;
        }
    }
}  