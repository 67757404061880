﻿'use strict';
module.exports = ['toastr', 'common', 'constantsService', 'businessruleDataService', berthBusinessrulesDirective];

function berthBusinessrulesDirective(toastr, common, constantsService, businessruleDataService) {
    var directive = {
        controller: berthBusinessrulesCtrl,
        controllerAs: 'berthBusinessrulesCtrl',
        restrict: 'E',
        scope: {
            berthId: '@'
        },
        link: berthBusinessrulesFromLink,
        bindToController: true,
        templateUrl: 'templates/berth/directives/berth-businessrules.html'
    };

    return directive;

    function berthBusinessrulesFromLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () { return ctrl.berthId; },
            function (newValue, oldValue) {
                if (!newValue) return;
                ctrl.getBusinessRulesByBerth(ctrl.berthId);
            });
    }
    function berthBusinessrulesCtrl() {
        var vm = {
            businessrule: {
                businessRuleId: null,
                businessRuleParameters: null,
                endsOn: null,
                id: null,
                startsOn: null,
                remarks: null
            },
            datePickerOptions: {
                altInputFormats: [
                    'd-M-yy',
                    'd-M-yyyy',
                    'd-M-yy H:mm',
                    'd-M-yyyy H:mm',
                    'd-M-yy HH:mm',
                    'd-M-yyyy HH:mm',
                    'dd-MM-yy',
                    'dd-MM-yyyy',
                    'dd-MM-yy H:mm',
                    'dd-MM-yyyy H:mm',
                    'dd-MM-yy HH:mm',
                    'dd-MM-yyyy HH:mm'
                ]
            },
            defaultValues: {}, // {businessRuleId: defaultValues}
            portauthorities: [],
            businessRuleTypes: [],
            businessrules: [],
            getBusinessRulesByBerth: getBusinessRulesByBerth,
            ruleOnEditDate: ruleOnEditDate,
            save: save,
            errors: null,
            deleteRule: deleteRule
        };


        init();

        return vm;
        function init() {
            vm.businessRuleTypes = constantsService.businessrules;
            var greenaward = _.find(constantsService.shipPropertyTypes, function (propType) {
                return propType.systemCode === 'greenaward';
            });

            // id 8 is greenaward
            vm.defaultValues[8] = greenaward.defaultValues;
        }

        function deleteRule(rule) {
            businessruleDataService.del(rule);
            reload();
        }

        function reload() {
            vm.errors = null;
            common.$timeout(function () {
                init();
                getBusinessRulesByBerth(vm.berthId);
                vm.businessrule = {
                    businessRuleId: null,
                    businessRuleParameters: null,
                    endsOn: null,
                    id: null,
                    startsOn: null,
                    remarks: null
                };
            }, 200);
        }
        function getBusinessRulesByBerth(id) {
            let berthId = parseInt(id);
            businessruleDataService.getByBerth(berthId)
                .then(successResultGetAll)
                .catch(function (response) {
                    // show error?
                });
            
            function successResultGetAll(result) {
                vm.businessrules = result;
                vm.businessrules.forEach(function (rule) {
                    rule.startError = false;
                    rule.endError = false;
                    rule.endsOnFormated = undefined;
                    rule.startsOnFormated = undefined;
                    if (rule.businessRuleName === null) {
                        vm.businessRuleTypes.forEach(function (type) {
                            if (rule.businessRuleId === type.id) {
                                rule.businessRuleName = type.name;
                            }
                        });
                    }
                    if (rule.startsOn !== null) {
                        rule.startsOn = moment(rule.startsOn);
                        rule.startsOnFormated = moment(rule.startsOn).format("DD-MM-YYYY HH:mm");
                    }
                    if (rule.endsOn !== null) {
                        rule.endsOn = moment(rule.endsOn);
                        rule.endsOnFormated = moment(rule.endsOn).format("DD-MM-YYYY HH:mm");
                    }
                   
                });
            }

        }

        function ruleOnEditDate(date, typeId, ruleId, str1, str2) {

            let dateFormat = 'DD-MM-YYYY HH:mm';
            let newDate = moment();
            if (str2 === 'array') {
                newDate = moment(date, dateFormat).format('YYYY-MM-DDTHH:mm') + ':00';
                vm.businessrules.forEach(function (rule) {
                    if (rule.id === ruleId) {
                        if (str1 === 'start') {
                            rule.startsOn = newDate;
                        } else {
                            rule.endsOn = newDate;
                        }
                    }
                });
            }
            if (str2 === 'new') {
                //newDate = moment(date, dateFormat);
                if (str1 === 'start') {
                    vm.businessrule.startsOn = date;
                } else {
                    vm.businessrule.endsOn = date;
                }

            }
        }

        function save(data) {
            if (data === 'array') {
                vm.businessrules.forEach(function (rule, i, ar) {

                    if (rule.startsOn)
                        rule.startsOn = moment(rule.startsOn).format('YYYY-MM-DDTHH:mm') + ':00';

                    if (rule.endsOn)
                        rule.endsOn = moment(rule.endsOn).format('YYYY-MM-DDTHH:mm') + ':00';

                    if (!validateDate(rule)) {
                        businessruleDataService.update(rule)
                            .then(function (result) {
                                successResult(result, i === ar.length - 1); // only the last save needs to reload the list
                            })
                            .catch(errorResult);
                    } else {
                        vm.errors.forEach(function (error) {
                            if (error.id === rule.id) {
                                if (error.field === 'startsOn')
                                    rule.startError = true;
                                if (error.field === 'endsOn')
                                    rule.endError = true;
                            }
                        });
                    }
                });
            } else {
                vm.businessrule.berthId = vm.berthId;
                vm.businessrule.id = null;

                if (vm.businessrule.startsOn)
                    vm.businessrule.startsOn = moment(vm.businessrule.startsOn).format('YYYY-MM-DDTHH:mm') + ':00';

                if (vm.businessrule.endsOn)
                    vm.businessrule.endsOn = moment(vm.businessrule.endsOn).format('YYYY-MM-DDTHH:mm') + ':00';

                if (!validateDate(vm.businessrule)) {
                    businessruleDataService.update(vm.businessrule)
                        .then(function (result) {
                            successResult(result, true);
                        })
                        .catch(errorResult);
                }
            }
                       

            function errorResult(response) {
              
            }

            function successResult(result, needToReload) {
                vm.businessrule = result;
                if (needToReload === true) {
                    reload();
                }
                toastr.success("Changes Saved");
            }

            function validateDate(obj) {
                let dateFormat = 'DD-MM-YYYY HH:mm';
                let dateFormat2 = 'YYYY-MM-DDTHH:mm:00';
                let errors = [];
                if (obj.businessRuleId === 3) {
                    if (moment(obj.startsOn, dateFormat, true).isValid() || moment(obj.startsOn, dateFormat2, true).isValid()) {
                        obj.startsOn = moment(obj.startsOn).format('YYYY-MM-DDTHH:mm') + ':00';
                    } else {
                        obj.startsOn = null;
                    }
                    if (moment(obj.endsOn, dateFormat, true).isValid() || moment(obj.endsOn, dateFormat2, true).isValid()) {
                        obj.endsOn = moment(obj.endsOn).format('YYYY-MM-DDTHH:mm') + ':00';
                    } else {
                        obj.endsOn = null;
                    }
                    if (obj.remarks.length < 0 || obj.remarks === ' ') {
                        obj.remarks = null;
                    }
                    if (!obj.startsOn)
                        errors.push({ error: 'Invalid Date format. Should be "DD-MM-YYYY HH:mm"', id: obj.id, field: 'startsOn' });
                    if (!obj.endsOn)
                        errors.push({ error: 'Invalid Date format. Should be "DD-MM-YYYY HH:mm"', id: obj.id, field: 'endsOn' });
                    if (!obj.remarks)
                        errors.push({ error: 'Please fill in the remarks', id: obj.id, field: 'remarks' });
                }
                if (obj.businessRuleId === 2 || obj.businessRuleId === 2) {
                    //obj.startsOn = null;
                    //obj.endsOn = null;
                    if (obj.businessRuleParameters === ' ' || obj.businessRuleParameters.length < 0)
                        obj.businessRuleParameters = null;

                    if (!obj.businessRuleParameters)
                        errors.push({ error: 'Please fill in the parameters', id: obj.id, field: 'parameters' });
                }
                if (errors.length > 0) {
                    vm.errors = errors;
                    return true;
                } else {
                    return false;
                }

            }

        }
    }

}