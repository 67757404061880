﻿'use strict';

module.exports = ['goalDataService', goalMainController];

function goalMainController(goalDataService) {
    var vm = {
        nauticalGoals: [],
        commercialGoals: [],
        loadGoals: loadGoals,
        activeNauticalGoal: null,
        activeCommercialGoal: null,
        onNauticalGoalSaved: onNauticalGoalSaved,
        onNauticalGoalDeleted: onNauticalGoalDeleted,
        onCommercialGoalSaved: onCommercialGoalSaved,
        onCommercialGoalDeleted: onCommercialGoalDeleted
    };

    init();

    return vm;

    function init() {
        loadGoals();
    }
    function onCommercialGoalSaved(savedGoal, isNew) {
        if (isNew) {
            vm.commercialGoals.push(savedGoal);
        }

        vm.activeCommercialGoal = null;
    }

    function onNauticalGoalSaved(savedGoal, isNew) {
        if (isNew) {
            vm.nauticalGoals.push(savedGoal);
        }

        vm.activeNauticalGoal = null;
    }

    function onCommercialGoalDeleted(deletedGoal) {
        var idx = _.findIndex(vm.commercialGoals, function (item) { return item.id === deletedGoal.id; });
        if (idx >= 0)
            vm.commercialGoals.splice(idx, 1);

        vm.activeCommercialGoal = null;
    }

    function onNauticalGoalDeleted(deletedGoal) {
        var idx = _.findIndex(vm.nauticalGoals, function (item) { return item.id === deletedGoal.id; });
        if (idx >= 0)
            vm.nauticalGoals.splice(idx, 1);

        vm.activeNauticalGoal = null;
    }

    function loadGoals() {
        goalDataService.getCommercialGoals()
            .then(function (result) {
                vm.commercialGoals = result;
            });

        goalDataService.getNauticalGoals()
            .then(function (result) {
                vm.nauticalGoals = result;
            });

    }
}