﻿module.exports = [auditModels];

function auditModels() {

    var service = {
        logModel: logModel,
    };

    return service;

    function logModel(auditData) {
        var logs = [];

        var logModel = {
            user: null,
            createdBy: null,
            createdOn: null,
            action: null,
            actionDescription: null,
            objectDescription: null,
            objectId: null,
            objectType: null,
            fieldName: null,
            originalValue: null,
            newValue: null,
            area: null
        };

        if (auditData === undefined || auditData === null)
            return auditData;

        if (auditData.fields === null) {
            logModel.actionDescription = auditData.actionDescription;
            logModel.user = auditData.user ? auditData.user : auditData.createdByUserName;
            logModel.createdBy = auditData.createdByUserName;
            logModel.createdOn = moment(auditData.createdOn).format('D MMM YYYY HH:mm');
            logModel.action = auditData.action;
            logModel.objectType = auditData.objectType;
            logModel.fieldName = auditData.fieldName;
            logModel.objectId = auditData.objectId;
            logModel.objectDescription = auditData.objectDescription;
            logModel.originalValue = auditData.originalValue;
            logModel.newValue = auditData.newValue;
            logModel.area = auditData.area;

            return logModel;
        } else {
            _.map(auditData.fields, function(field) {
                logs.push({
                    actionDescription: auditData.actionDescription,
                    user: auditData.user ? auditData.user : auditData.createdByUserName,
                    createdBy: auditData.createdByUserName,
                    createdOn: moment(auditData.createdOn).format('D MMM YYYY HH:mm'),
                    action: auditData.action,
                    objectDescription: auditData.objectDescription,
                    objectId: auditData.objectId,
                    objectType: auditData.objectType,
                    fieldName: field.fieldName,
                    originalValue: field.originalValue,
                    newValue: field.newValue,
                    area: auditData.area
                });
            });

            return logs;
        }
    };

};
