﻿angular.module('poa.rprp.customer', [])
    .config(['$routeProvider', routeConfig])
    .controller('customerOverviewController', require('./controllers/customer-overview'))
    .controller('modalResetPasswordController', require('./modals/reset-password-modal'))
    .directive('customerForm', require('./directives/customer-form'))
    .directive('newCustomer', require('./directives/new-customer'))
    .directive('customerFinancialInformation', require('./directives/financial/financial-information'))
    .directive('customerInvoiceAddresses', require('./directives/financial/invoice-addresses'))
    // user directives
    //.directive('userBox', require('./directives/user/user-box'))
    //.directive('userList', require('./directives/user/user-list'))
    .directive('userDetails', require('./directives/user/user-details'))
    // fleet directives
    //.directive('fleetList', require('./directives/fleet/fleet-list'))
    //.directive('fleetBox', require('./directives/fleet/fleet-box'))
    .directive('fleetDetails', require('./directives/fleet/fleet-details'))
    // reservation directives
    .directive('reservationList', require('./directives/reservation/reservation-list'))
    .directive('reservationBox', require('./directives/reservation/reservation-box'))
    // message directives
    .directive('messagesList', require('./directives/message/messages-list'))
    .directive('messagesBox', require('./directives/message/messages-box'))
    .factory('customerDataService', require('./services/customer-data'))
    .factory('customerFinancialDataService', require('./services/customer-financial-data'))
    .factory('customerModelsService', require('./services/customer-models'))
    .factory('customerAddressPreferencesDataService', require('./services/customer-address-preferences-data'))
    .factory('customerAddressPreferencesModelsService', require('./services/customer-address-preferences-models'))
    .factory('userModals', require('./modals/modals'))
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/customers', {
            templateUrl: 'templates/customer/controllers/customer-overview.html'
        })
        .when('/customers/:customerId', {
            templateUrl: 'templates/customer/controllers/customer-overview.html'
        })
        .when('/customers/:customerId/ship/:shipId', {
            templateUrl: 'templates/customer/controllers/customer-overview.html'
        })
        .when('/customers/:customerId/user/:userId', {
            templateUrl: 'templates/customer/controllers/customer-overview.html'
        });
};