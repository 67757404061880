﻿'use strict';
module.exports = ['toastr', 'coreDataService', 'coreDataModels', 'berthDataService', berthBerthgroupFormDirective];

function berthBerthgroupFormDirective(toastr, coreDataService, coreDataModels, berthDataService) {
    var directive = {
        controller: berthBerthgroupFormController,
        controllerAs: 'ctrlBBG',
        restrict: 'E',
        scope: {
            berthId: '@'
        },
        bindToController: true,
        link: berthBerthgroupFormLink,
        templateUrl: 'templates/berth/directives/berth-berthgroup-form.html'
    };
    return directive;

    function berthBerthgroupFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () { return ctrl.berthId; },
            function (newValue, oldValue) {
                if (!newValue) return;

                ctrl.getBerthBerthgroups();
            });
    }

    function berthBerthgroupFormController() {
        var vm = {
            berthgroups: [],
            berthBerthgroups: [],
            berthFunctions: coreDataModels.berthFunctions,
            selectedBerthBerthgroupIds: [],
            getBerthBerthgroups: getBerthBerthgroups,
            save: save
        };

        init();

        return vm;

        function init() {
            coreDataService.getBerthgroups().then(function (data) {
                vm.berthgroups = data;
            });
        }

        function save() {
            var berthId = parseInt(vm.berthId) || 0;
            var berthgroupIdsToAdd = vm.selectedBerthBerthgroupIds;
            var berthgroupIdsToDel = [];

            if (vm.berthBerthgroups.length > 0) {

                berthgroupIdsToDel = vm.berthBerthgroups.filter(function (item) {
                    return vm.selectedBerthBerthgroupIds.indexOf(item.id) === -1;
                });
                berthgroupIdsToDel = _.map(berthgroupIdsToDel, function (item) { return item.id });

                berthgroupIdsToAdd = vm.selectedBerthBerthgroupIds.filter(function (itemId) {
                    var orgIds = _.map(vm.berthBerthgroups, function (item) { return item.id });
                    return orgIds.indexOf(itemId) === -1;
                });
            }

            if (berthId > 0 && (berthgroupIdsToDel.length > 0 || berthgroupIdsToAdd.length > 0)) {
                berthDataService.updateBerthBerthgroups(berthId, berthgroupIdsToAdd, berthgroupIdsToDel)
                    .then(successResult)
                    .catch(errorResult);
                function errorResult(response) {
                    
                }

                function successResult(result) {
                    vm.berthBerthgroups = result;
                }
            }
            toastr.success("Changes Saved");
        }

        function getBerthBerthgroups() {
            var berthId = parseInt(vm.berthId) || 0;
            if (berthId > 0) {
                berthDataService.getBerthBerthgroups(berthId)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {
                }

                function successResult(result) {
                    vm.berthBerthgroups = result;
                    vm.selectedBerthBerthgroupIds = _.map(vm.berthBerthgroups, function (item) { return item.id });
                }
            }
        }
    }
}