﻿module.exports = ['common', planningCalendarService];

function planningCalendarService() {

    var service = {
        calculateBerths: calculateBerths,
        calculateDays: calculateDays,
        calculateItems: calculateItems,
        calculateItem: calculateItem,
        recalculateCalendarBlocks: recalculateCalendarBlocks,
        clearItems: clearItems,
        clearUnplannedItems: clearUnplannedItems,
        clearOtherItems: clearOtherItems,
        data: {
            berthObstructions: [],
            planned: [],
            sanityChecks: {
                berthSanityIssues: [],
                planningSanityIssues: []
            },
            unplanned: []
        },
        days: [],
        display: {
            dayPercentage: 0,
            maximumDate: null,
            minimumDate: null
        },
        getItemsInRange: getItemsInRange,
        recalculate: recalculate,
        setSanityChecks: setSanityChecks,
        updateReservation: updateReservation
    };

    function calculateBerths(berthDetails, obstructions, apiSource) {
        if (berthDetails == null || berthDetails.length == 0)
            return;

        service.data.berthObstructions = apiSource && service.data.berthObstructions && service.data.berthObstructions.length
            ? service.data.berthObstructions.filter(x => x.apiSource !== apiSource)
            : [];

        if (obstructions && obstructions.length > 0) {
            // map the obstructions to fit the same structure as berth details, for easy processing
            var mappedObstructions = _.map(obstructions, function (obstruction) {
                var availability = '';
                if (obstruction.startsOn && obstruction.endsOn)
                    availability = 'Niet beschikbaar van ' + moment(obstruction.startsOn).format('DD-MM-YYYY HH:mm') + ' tot ' + moment(obstruction.endsOn).format('DD-MM-YYYY HH:mm');
                else if (obstruction.startsOn)
                    availability = 'Niet beschikbaar vanaf ' + moment(obstruction.startsOn).format('DD-MM-YYYY HH:mm');
                else if (obstruction.endsOn)
                    availability = 'Niet beschikbaar tot ' + moment(obstruction.endsOn).format('DD-MM-YYYY HH:mm');

                if (availability === '') {
                    availability = obstruction.remarks;
                } else {
                    if (obstruction.berthName !== undefined && obstruction.berthName !== null) {
                        availability += " (" + obstruction.berthName + ")";
                    }

                    availability += "\n" + obstruction.remarks;
                }

                return {
                    availability: availability,
                    availableFrom: obstruction.startsOn,
                    availableUntil: obstruction.endsOn,
                    id: obstruction.berthId,
                    remarks: obstruction.remarks,
                    obstructionId: obstruction.id
                };
            });
            berthDetails = berthDetails.concat(mappedObstructions);
        }

        var obstructedBerths = _.filter(berthDetails, function (berth) {
            var availableFrom, availableUntil = null;

            if (berth.availableFrom)
                availableFrom = moment(berth.availableFrom);
            if (berth.availableUntil)
                availableUntil = moment(berth.availableUntil);

            if (availableFrom && availableUntil) {  // availableFrom and availableUntil are both set
                if (availableFrom <= service.display.maximumDate && availableUntil >= service.display.minimumDate)
                    return true;
            } else if (availableUntil) {            // availableFrom is missing, but availableUntil is set
                if (availableUntil <= service.display.maximumDate)
                    return true;
            } else if (availableFrom) {             // availableUntil is missing, but availableFrom is set
                if (availableFrom >= service.display.minimumDate)
                    return true;
            }

            return false;
        });

        service.data.berthObstructions = apiSource && service.data.berthObstructions && service.data.berthObstructions.length
            ? service.data.berthObstructions.filter(x => x.apiSource !== apiSource)
            : [];
            
        _.each(obstructedBerths, function (berth) {
            var availability = '';
            if (berth.availableFrom && berth.availableUntil)
                availability = 'Beschikbaar van ' + moment(berth.availableFrom).format('DD-MM-YYYY') + ' tot ' + moment(berth.availableUntil).format('DD-MM-YYYY');
            else if (berth.availableFrom)
                availability = 'Beschikbaar vanaf ' + moment(berth.availableFrom).format('DD-MM-YYYY');
            else if (berth.availableUntil)
                availability = 'Beschikbaar tot ' + moment(berth.availableUntil).format('DD-MM-YYYY');

            var description = berth.remarks ? berth.remarks : 'niet beschikbaar';
            if (availability === '') {
                availability = description;
            } else {
                if (berth.name !== undefined && berth.name !== null) {
                    availability += " (" + berth.name + ")";
                }

                availability += "\n" + description;
            }

            var result = {
                assignedToBerthId: berth.id,
                availableFrom: berth.availableFrom,
                availableUntil: berth.availableUntil,
                availability: berth.availability ? berth.availability : availability,
                remarks: description
            };

            if (berth.obstructionId) {
                result.id = berth.obstructionId;

                // check the remark is startwith reservation id or not. which means this obstruction is link to a reservation
                if (berth.remarks) {
                    var remarksParts = berth.remarks.split(':');
                    if (remarksParts.length > 1) {
                        var resId = parseInt(remarksParts[0]);
                        if (isNaN(resId) === false) {
                            result.reservationId = resId;
                            result.remarks = berth.remarks.replace(remarksParts[0] + ':', '');
                            result.etaDisplay = moment(berth.availableFrom).format('DD-MM-YYYY HH:mm');
                            result.etdDisplay = moment(berth.availableUntil).format('DD-MM-YYYY HH:mm');
                        }
                    }
                }

                service.data.berthObstructions.push(calculateCalendarBlock(result, 'availableFrom', 'availableUntil', apiSource));
            }
            else if (service.display.minimumDate && service.display.maximumDate) {
                var blockStartMoment = moment(service.display.minimumDate);
                var blockEndMoment = moment(service.display.maximumDate);
                var availableFromMoment = moment(berth.availableFrom);
                var availableUntilMoment = moment(berth.availableUntil);

                result.blockStart = blockStartMoment.startOf('day');
                result.blockEnd = blockEndMoment.endOf('day');
                result.availableFrom = availableFromMoment.startOf('day');
                result.availableUntil = availableUntilMoment;

                if (result.blockStart.isBefore(result.availableFrom)) {
                    service.data.berthObstructions.push(calculateCalendarBlock(_.clone(result), 'blockStart', 'availableFrom', apiSource));

                    if (result.blockEnd.isAfter(result.availableUntil))
                        service.data.berthObstructions.push(calculateCalendarBlock(_.clone(result), 'availableUntil', 'blockEnd', apiSource));

                } else {
                    if (result.blockEnd.isAfter(result.availableUntil))
                        service.data.berthObstructions.push(calculateCalendarBlock(_.clone(result), 'availableUntil', 'blockEnd', apiSource));
                }
            }
        });
    }

    function calculateDays(source) {

        if (!source)
            return;

        if (source.startDate && source.endDate) {
            service.display.minimumDate = moment.isMoment(source.startDate) ? source.startDate : moment(source.startDate);
            service.display.maximumDate = moment.isMoment(source.endDate) ? source.endDate : moment(source.endDate);
        }
        else if (source.items) {
            var minimumDate, maximumDate = null;
            for (var i = 0; i < source.items.length; i++) {
                if (!service.display.minimumDate || source.items[i].eta < service.display.minimumDate)
                    minimumDate = source.items[i].eta;
                if (!service.display.maximumDate || source.items[i].etd < service.display.maximumDate)
                    maximumDate = source.items[i].etd;
            }

            service.display.minimumDate = moment(minimumDate).add(-1, 'days');
            service.display.maximumDate = moment(maximumDate);
        }
        else
            return;

        service.display.maximumDate = moment(service.display.maximumDate.format('YYYY-MM-DD'));
        service.display.minimumDate = moment(service.display.minimumDate.format('YYYY-MM-DD'));

        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var totalDays = Math.ceil(service.display.maximumDate.diff(service.display.minimumDate, 'days'));
        var minDate = moment(service.display.minimumDate.format('YYYY-MM-DD'));

        service.days = [];
        for (var i = 0; i < totalDays + 1; i++) {
            service.days.push(minDate.format('dd DD-MM'));
            minDate.add(1, 'days');
        }

        service.display.dayPercentage = (100 / (service.days.length));
    }

    function calculateItems(source, target, append) {
        if (!service.data[target])
            return;

        if (!append || append === false)
            service.data[target] = [];

        if (!source || source.length == 0)
            return;

        for (var a = 0; a < source.length; a++) {
            // Exclude already planned, but changed items as suggestion blocks
            if (target === 'unplanned' && source[a].reservation && source[a].reservation.reservationStatus && source[a].reservation.reservationStatus.isChangedButNotConfirmed && source[a].reservation.reservationStatus.isChangedButNotConfirmed === true)
                continue;

            var items = calculateItem(source[a]);
            for (var b = 0; b < items.length; b++) {
                service.data[target].push(items[b]);
            }
        }

        calculateRows(service.data[target]);
    };

    function calculateItem(source) {
        if (!source || !source.planningItems)
            return [];

        var items = [];

        for (var i = 0; i < source.planningItems.length; i++) {
            var item = source.planningItems[i];
            if (!item.reservationId && source.reservationId)
                item.reservationId = source.reservationId;
            item.display = {
                first: i == 0,
                last: source.planningItems.length == (i + 1),
                row: -1,
                extraRows: 0
            };
            if (!item.communicatedStatus) {
                // TODO need to fix this; the reservation.communicatedStatus should contain an array and not only one status
                // since other communicatedStatus (cancelled, changed etc...) is stored into the database, the UI needs to updated
                // need to check the impact of this changes; e.g. in reservation-duplicate-current, calendarblock etc...
                if (source.communicatedStatus && source.communicatedStatus.length > 0) {
                    var status = _.filter(_.sortBy(source.communicatedStatus, 'communicatedOn'), function (obj) { return obj.status <= 3; });
                    if (status.length > 0) {
                        item.communicatedStatus = _.last(status);
                    } else {
                        item.communicatedStatus = [];
                    }
                } else {
                    item.communicatedStatus = [];
                }
            }

            if (!item.customer)
                item.customer = source.customer;
            if (!item.ship)
                item.ship = source.ship;
            item = calculateCalendarBlock(item);

            if (source.reservation) {
                item.apiSource = source.reservation.apiSource;

                if (source.reservation.reservationReference) {
                    item.reservationReference = source.reservation.reservationReference;
                }
                if (source.reservation.reservationStatus) {
                    item.reservationStatus = source.reservation.reservationStatus;
                }

                if (source.reservation.reservationSlaves) {
                    var slave = _.first(source.reservation.reservationSlaves);
                    if (slave) {
                        item.display.extraRows = 1;
                        item.slaveShip = slave.ship;
                    }
                }
            }

            if (source.processFlowId)
                item.processFlowId = source.processFlowId;

            if (item != null)
                items.push(item);
        }

        return items;
    }

    function calculateCalendarBlock(item, startTimeField, endTimeField, apiSource) {
        item.apiSource = item.apiSource || apiSource || null;
        
        if (!startTimeField)
            startTimeField = 'eta';
        if (!endTimeField)
            endTimeField = 'etd';

        var eta = moment.isMoment(item[startTimeField]) ? item[startTimeField] : moment(item[startTimeField]);
        var etd = moment.isMoment(item[endTimeField]) ? item[endTimeField] : moment(item[endTimeField]);

        var _MS_PER_DAY = 1000 * 60 * 60 * 24;

        if (item.display == undefined)
            item.display = {};

        item.display.startPosition = service.display.dayPercentage * ((eta - service.display.minimumDate) / _MS_PER_DAY);
        item.display.width = service.display.dayPercentage * ((etd - service.display.minimumDate) / _MS_PER_DAY) - item.display.startPosition;

        if ((item.display.startPosition + item.display.width) > 100)
            item.display.width = 100 - item.display.startPosition;

        if ((item.display.startPosition + item.display.width) < 0) {
            item.display.width = 0;

        } else if (item.display.startPosition < 0) {
            item.display.width += item.display.startPosition;
            item.display.startPosition = 0;

        } else if (item.display.startPosition >= 98) {
            item.display.width = 0;
        }

        return item;
    }

    function calculateRows(items) {
        var rowStatus = {};

        //var allItems = vm.data.planned.concat(vm.data.unplanned);
        var items = service.data.planned.concat(service.data.unplanned);
        items = _.sortBy(items, function (item) { return item.display.startPosition + item.display.width; });

        for (var itemIndex = 0; itemIndex < items.length; itemIndex++) {
            var item = items[itemIndex];

            var berthId = item.assignedToBerthId.toString();

            if (!rowStatus[berthId]) {
                var index = 0;
                item.display.row = index;

                if (item.display.extraRows && item.display.extraRows > 0) {
                    index = index + item.display.extraRows; // calendar item has extra row; e.g.STS. so reserve extra space for next item
                }

                rowStatus[berthId] = []; // define as an array, so we could use the length property of this array
                rowStatus[berthId][index] = item.display.startPosition + item.display.width;
                continue;
            }

            for (var idx = 0; idx < rowStatus[berthId].length; idx++) {
                if (rowStatus[berthId][idx] !== undefined && rowStatus[berthId][idx] <= item.display.startPosition) {
                    item.display.row = idx;
                    rowStatus[berthId][idx] = item.display.startPosition + item.display.width;
                    break;
                }
            }

            if (item.display.row == -1) {
                var newIndex = rowStatus[berthId].length;
                item.display.row = newIndex;

                if (item.display.extraRows && item.display.extraRows > 0) {
                    newIndex = newIndex + item.display.extraRows; // calendar item has extra row; e.g.STS. so reserve extra space for next item
                }
                rowStatus[berthId][newIndex] = item.display.startPosition + item.display.width;
            }
        }
    }

    function clearItems() {
        clearUnplannedItems();
        clearOtherItems();
    }

    function clearUnplannedItems() {
        service.data.unplanned = [];
    }
    function clearOtherItems() {
        service.data.planned = [];
        service.data.sanityChecks.berthSanityIssues = [];
        service.data.sanityChecks.planningSanityIssues = [];
    }

    function getItemsInRange(sourceType) {
        var source = service.data.planned;
        if (sourceType && service.data[sourceType])
            source = service.data[sourceType];

        var result = _.filter(source, function (item) {
            return moment(item.eta).isBefore(service.display.maximumDate) && moment(item.etd).isAfter(service.display.minimumDate);
        });

        return result;
    }

    function recalculate(startDate, endDate) {
        calculateDays({
            endDate: moment(endDate),
            startDate: moment(startDate)
        });

        for (var i = 0; i < service.data.planned.length; i++) {
            calculateCalendarBlock(service.data.planned[i]);
        }

        for (var i = 0; i < service.data.unplanned.length; i++) {
            calculateCalendarBlock(service.data.unplanned[i]);
        }
    }

    function setSanityChecks(sanityChecks) {
        service.data.sanityChecks.berthSanityIssues = [];
        service.data.sanityChecks.planningSanityIssues = [];

        if (!sanityChecks)
            return;

        if (sanityChecks.berthSanityIssues) {
            for (var i = 0; i < sanityChecks.berthSanityIssues.length; i++) {
                calculateCalendarBlock(sanityChecks.berthSanityIssues[i], 'startIssue', 'endIssue', 'easydock');
            }
            service.data.sanityChecks.berthSanityIssues = sanityChecks.berthSanityIssues;
        }

        service.data.sanityChecks.planningSanityIssues = sanityChecks.planningSanityIssues;

        if (service.data.sanityChecks.planningSanityIssues) {
            for (var i = 0; i < service.data.sanityChecks.planningSanityIssues.length; i++) {
                var foundItem = _.find(service.data.planned, function (item) {
                    return item.id === service.data.sanityChecks.planningSanityIssues[i].planningItem.id;
                });

                if (foundItem) {
                    if (foundItem.display.planningSanity) {
                        foundItem.display.planningSanity.push(service.data.sanityChecks.planningSanityIssues[i].issue);
                    } else {
                        foundItem.display.planningSanity = [service.data.sanityChecks.planningSanityIssues[i].issue];
                    }
                }
            }
        }
    }

    function updateReservation(reservationId, planning) {
        var type = 'unplanned';

        service.data.planned = _.filter(service.data.planned, function (item) {
            if (item.reservationId == reservationId)
                type = 'planned';
            return item.reservationId != reservationId;
        });
        service.data.unplanned = _.filter(service.data.unplanned, function (item) {
            if (item.reservationId == reservationId)
                type = 'unplanned';
            return item.reservationId != reservationId;
        });

        var newItems = calculateItem(planning);
        for (var i = 0; i < newItems.length; i++) {
            service.data[type].push(newItems[i]);
        }
    }

    function recalculateCalendarBlocks(items, startTimeField, endTimeField) {
        for (var i = 0; i < items.length; i++) {
            var startTF = startTimeField;
            var endTF = endTimeField;
            if (startTimeField == 'availableFrom' && endTimeField == 'availableUntil') {
                if (items[i]['blockStart']) {
                    startTF = 'blockStart';
                    endTF = 'availableFrom';
                }
                if (items[i]['blockEnd']) {
                    startTF = 'availableUntil';
                    endTF = 'blockEnd'
                }
            }
            calculateCalendarBlock(items[i], startTF, endTF);
        }
    }

    return service;
};
