﻿module.exports = [calendarItemsFilter];

function calendarItemsFilter() {

    return function (items, berth, reservationId, apiSource) {
        if (!items || items.length == 0)
            return [];
        return _.filter(items, function (item) {
            var isMatch = false;
            var fieldToMatch = item.assignedToBerthId;
            if (fieldToMatch == null || fieldToMatch == undefined)
                fieldToMatch = item.berth.id;
            if (fieldToMatch == null || fieldToMatch == undefined)
                return;
            
            if (berth && berth.id)
                isMatch = fieldToMatch == berth.id;
            else
                isMatch = fieldToMatch == null || fieldToMatch == -1;
            if (isMatch && reservationId)
                isMatch = item.reservationId == reservationId

            if (isMatch && apiSource)
                isMatch = item.apiSource !== undefined && item.apiSource == apiSource

            return isMatch;
        });
    };

};