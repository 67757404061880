﻿angular.module('poa.rprp.region', [])
    .config(['$routeProvider', routeConfig])
    .controller('regionOverviewController', require('./controllers/region-overview'))
    .directive('regionForm', require('./directives/region-form'))
    .factory('regionDataService', require('./services/region-data'))
    .factory('regionModelsService', require('./services/region-models'))
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/region', {
            templateUrl: 'templates/region/controllers/region-overview.html'
        })
        .when('/region/:regionId', {
            templateUrl: 'templates/region/controllers/region-overview.html'
        });
}