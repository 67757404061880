﻿module.exports = [invoiceModelsService];

function invoiceModelsService() {

    var service = {
        invoiceItemModel: invoiceItemModel,
        invoiceModel: invoiceModel,
        invoiceItemModelCredit: invoiceItemModelCredit
    };

    function invoiceModel() {
        return {
            customerId: null,
            customerReference: null,
            debtorNumber: null,
            description: null,
            id: null,
            invoiceDate: null,
            realisationId: null,
            specificShipId: null,
            invoiceItems: [],
            remarks: null
        };
    }

    function invoiceItemModel() {
        return {
            costCentre: null,
            description: null,
            discount: 0,
            discountType: 0,
            externalItemCode: null,
            id: null,
            invoiceId: null,
            quantity: 0,
            tariffId: null,
            totalAmount: 0,
            unitaryTariff: 0,
            remarks: null
        };
    }

    function invoiceItemModelCredit(source) {

        var result;
        if (source !== undefined && source !== null) {
            result = {
                costCentre: source.costCentre,
                description: source.description,
                discount: source.discount,
                discountType: source.discountType,
                externalItemCode: source.externalItemCode,
                id: null,
                invoiceId: null,
                quantity: source.quantity,
                tariffId: source.tariffId,
                totalAmount: 0,
                unitaryTariff: source.unitaryTariff * -1,
                remarks: source.remarks
            };

            result.totalAmount = result.quantity * result.unitaryTariff;

        } else {
            result = {
                costCentre: null,
                description: null,
                discount: 0,
                discountType: 0,
                externalItemCode: null,
                id: null,
                invoiceId: null,
                quantity: 0,
                tariffId: null,
                totalAmount: 0,
                unitaryTariff: 0,
                remarks: null
            };
        }

        return result;
    }

    return service;

}