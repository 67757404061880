﻿module.exports = ['tariffDataService', 'tariffModelsService', 'widgetModals', tariffOverviewDirective];

function tariffOverviewDirective(tariffDataService, tariffModels, widgetModals) {
    var directive = {
        bindToController: true,
        controller: tariffOverviewController,
        controllerAs: 'ctrlTariffOverview',
        restrict: 'E',
        scope: {
            activeTariff: '=',
            tariffsData: '=',
            allowedApprove: '=',
            activeTariffPending: '=',
            tariffPendingsData: '='
        },
        templateUrl: 'templates/tariff/directives/tariff/tariff-overview.html'
    };

    return directive;

    function tariffOverviewController() {
        var vm = {
            setActiveTariff: setActiveTariff,
            selectedLink: selectedLink,
            currentlySelected: false,
            groupByTariffGroup: true,
            tariffGroups: [],
            tariffs: [],
            selectedYear: null,
            selectableYears: [],
            filterByYear: filterByYear
        };

        init();

        return vm;

        function init() {
            tariffDataService.getTariffGroups().then(function (data) {
                vm.tariffGroups = data;
            });

            tariffDataService.getTariffs()
            .then(function (result) {
                let tariffs = result;
                tariffDataService.getTariffPendings()
                    .then(function (data) {
                        let tariffPendings = data;
                        let selectableYears = [moment().format('YYYY'), moment().add(1, 'year').format('YYYY')];
                        for(let i =0;i<tariffs.length;i++)
                        {
                            const startsOn = moment(tariffs[i].startsOn).format('YYYY');
                            const endsOn = moment(tariffs[i].endsOn).add(-1).format('YYYY');
                            if (/^\d{4}$/g.exec(startsOn)) {
                                selectableYears.push(startsOn);
                            }

                            if (/^\d{4}$/g.exec(endsOn)) {
                                selectableYears.push(endsOn);
                            }

                            tariffs[i].needsApproval =false;
                            var filterList = _.filter(tariffPendings,function(x){return x.tariffId == tariffs[i].id});
                            if(filterList.length>0){
                                tariffs[i].needsApproval =true;
                            }
                            if(!tariffs[i].approvedOn){
                                tariffs[i].needsApproval = true;
                            }
                        }

                        vm.selectableYears = _.chain(selectableYears).flatten().compact().uniq().sortBy().value();
                        vm.tariffs = _.sortBy(tariffs, 'description');
                        vm.selectedYear = moment().format('YYYY');
                        vm.filterByYear();
                    });
            });
                
        }

        function setActiveTariff(tariff) {
            //if new clicked!!!
            if (!tariff) {
                tariff = new tariffModels.tariffModel();
            }

            vm.activeTariff = _.clone(tariff);
            var activeTariffPendings = _.filter(vm.tariffPendingsData, function(x){return x.tariffId == tariff.id});
            if(activeTariffPendings.length == 0){
                vm.activeTariffPending = null;
            }
            else{
                vm.activeTariffPending = _.last(activeTariffPendings);
                
                vm.activeTariffPending.startsOn = moment(vm.activeTariffPending.startsOn).toDate();
                vm.activeTariffPending.endsOn = moment(vm.activeTariffPending.endsOn).toDate();
            }

            vm.allowedApprove.value = "Not allowed to approve";
            if(tariff.id){
                vm.allowedApprove.isLoading = true;
                tariffDataService.getApproveTariff(vm.activeTariff).then(function (data) {
                    vm.allowedApprove.value = data;
                    vm.allowedApprove.isLoading = false;
                });
            }
        }

        function selectedLink(id) {
            if (vm.activeTariff) {
                if (parseInt(id) === parseInt(vm.activeTariff.id)) {
                    vm.currentlySelected = true;
                    return vm.currentlySelected;
                }
            }
        }

        function filterByYear() {
            let filteredTariffs = vm.tariffs;
            if (vm.selectedYear) {
                const startOfYear = moment(vm.selectedYear, 'YYYY').startOf('year');
                const endOfYear = moment(vm.selectedYear, 'YYYY').endOf('year');
    
                filteredTariffs = filteredTariffs.filter(function (x) {
                    const startsOnMoment = moment(x.startsOn);
                    const endsOnMoment = moment(x.endsOn);
                    // console.log(startsOnMoment.isBefore(endOfYear), endsOnMoment.isAfter(startOfYear));
                    if (startsOnMoment.isBefore(endOfYear) && (x.endsOn && endsOnMoment.isAfter(startOfYear) || !x.endsOn)) {
                        return x;
                    }
                });
            }

            vm.tariffsData = filteredTariffs;
        }
    }
}
