﻿module.exports = [systemMessageOverviewDirective];

function systemMessageOverviewDirective() {
    var directive = {
        bindToController: true,
        controller: systemMessageOverviewController,
        controllerAs: 'ctrlSystemMessageOverview',
        restrict: 'E',
        scope: {
            activeSystemMessage: '=',
            systemMessagesData: '='
        },
        templateUrl: 'templates/messages/directives/system-messages/system-message-overview.html'
    };

    return directive;

    function systemMessageOverviewController() {
        var vm = {
            setActiveSystemMessage: setActiveSystemMessage,
            selectedLink: selectedLink,
            currentlySelected: false
        };

        return vm;

        function setActiveSystemMessage(systemMessage) {
            //if new clicked!!!
            if (!systemMessage) {
                systemMessage = {
                    id: 0,
                    name: null,
                    body: null,
                    type: 1,
                    startsOn: null,
                    endsOn: null,
                    isActive: true
                };
            } else {
                systemMessage = {
                    id: systemMessage.id,
                    name: systemMessage.name,
                    body: systemMessage.body,
                    type: systemMessage.type,
                    startsOn: systemMessage.startsOn ? moment(systemMessage.startsOn).toDate() : null,
                    endsOn: systemMessage.endsOn ? moment(systemMessage.endsOn).toDate() : null,
                    isActive: systemMessage.isActive
                };
            }

            vm.activeSystemMessage = systemMessage;
        }

        function selectedLink(id) {
            if (vm.activeSystemMessage) {
                if (parseInt(id) === parseInt(vm.activeSystemMessage.id)) {
                    vm.currentlySelected = true;
                    return vm.currentlySelected;
                }
            }
        }
    }
}
