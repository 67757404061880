﻿'use strict';

module.exports = ['common', 'dataContext', templateDataService];

function templateDataService(common, context) {
    var vm = {
        getTemplates: getTemplates,
        saveTemplate: saveTemplate,
        deleteTemplate: deleteTemplate
    };

    return vm;

    function getTemplates() {
        var deferred = common.$q.defer();
        var req = context.get('/templates')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    // POST will throw for now because currently you cannot add new templates
    function saveTemplate(template) {
        var deferred = common.$q.defer();
        var url = '/templates';
        var req;
        if (template.id) {
            url = url + '/' + template.id;
            req = context.put(url, template);
        } else {
            req = context.post(url, template);
        }

        req.then(function (data) {
            deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    // DELETE will throw for now because currently you cannot delete templates
    function deleteTemplate(template) {
        var deferred = common.$q.defer();
        var url = '/templates/' + template.id;
        var req = context.del(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}
