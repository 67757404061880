﻿'use strict';
module.exports = ['coreDataService', 'berthgroupDataService', berthgroupShipTypeFormDirective];

function berthgroupShipTypeFormDirective(coreDataService, berthgroupDataService) {
    var directive = {
        bindToController: true,
        controller: berthgroupShipTypeFormController,
        controllerAs: 'ctrlBGSF',
        restrict: 'E',
        scope: {
            berthgroupId: '@'
        },
        link: berthgroupShipTypeFormLink,
        templateUrl: 'templates/berthgroup/directives/berthgroup-shiptype-form.html'
    };
    return directive;

    function berthgroupShipTypeFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () { return ctrl.berthgroupId; },
            function (newValue, oldValue) {
                if (!newValue) return;

                ctrl.getBerthgroupShiptypes();
            });
    }

    function berthgroupShipTypeFormController() {
        var vm = {
            shiptypes: [],
            berthgroupShiptypes: [],
            selectedBerthgroupShiptypeIds: [],
            getBerthgroupShiptypes: getBerthgroupShiptypes,
            save: save
        };

        init();

        return vm;

        function init() {
            coreDataService.getShiptypes().then(function (data) {
                vm.shiptypes = data;
            });
        }

        function save() {
            var berthgroupId = parseInt(vm.berthgroupId) || 0;
            var shiptypeIdsToDel = [];
            var shiptypeIdsToAdd = vm.selectedBerthgroupShiptypeIds;

            if (vm.berthgroupShiptypes.length > 0) {

                shiptypeIdsToDel = vm.berthgroupShiptypes.filter(function (item) {
                    return vm.selectedBerthgroupShiptypeIds.indexOf(item.id) === -1;
                });
                shiptypeIdsToDel = _.map(shiptypeIdsToDel, function (item) { return item.id });

                shiptypeIdsToAdd = vm.selectedBerthgroupShiptypeIds.filter(function (itemId) {
                    var orgIds = _.map(vm.berthgroupShiptypes, function (item) { return item.id });
                    return orgIds.indexOf(itemId) === -1;
                });
            }

            if (berthgroupId > 0 && (shiptypeIdsToDel.length > 0 || shiptypeIdsToAdd.length > 0)) {
                berthgroupDataService.updateBerthgroupShiptypes(berthgroupId, shiptypeIdsToAdd, shiptypeIdsToDel)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {            
                }

                function successResult(result) {
                    vm.berthgroupShiptypes = result;
                }
            }
        }

        function getBerthgroupShiptypes() {
            var berthgroupId = parseInt(vm.berthgroupId) || 0;
            if (berthgroupId > 0) {
                berthgroupDataService.getBerthgroupShiptypes(berthgroupId)
                .then(successResult)
                .catch(errorResult);
                function errorResult(response) {                
                }

                function successResult(result) {
                    vm.berthgroupShiptypes = result;
                    vm.selectedBerthgroupShiptypeIds = _.map(vm.berthgroupShiptypes, function (item) { return item.id });
                }
            }
        }
    }
}