﻿module.exports = ['$location', '$routeParams', 'searchDataService', searchResultController];

function searchResultController($location, $routeParams, searchData) {
    var vm = {
        results: [],
        loadResults: loadResults,
        showDetails: showDetails
    };

    return vm;

    function loadResults() {
        vm.results = [];
        searchText = $routeParams.searchText;
        searchData.find(searchText)
            .then(function (result) {
                vm.results = result;
            });
    }

    function showDetails(searchResult) {
        if (searchResult.type === 'users')
            $location.path('/customers/' + searchResult.id.split(':')[0] + '/user/' + searchResult.id.split(':')[1]);
        else if (searchResult.type === 'fleet')
            $location.path('/customers/' + searchResult.id.split(':')[0] + '/ship/' + searchResult.id.split(':')[1]);

        else
            $location.path("/" + searchResult.type + "/" + searchResult.id);
    }
}