﻿module.exports = ['$rootScope','common', datePeriodPickerDirective];

function datePeriodPickerDirective($rootScope, common, context) {
    var directive = {
        bindToController: true,
        controller: datePeriodPickerController,
        controllerAs: 'ctrlDatePeriodPicker',
        link: function (scope) {
            scope.ctrlDatePeriodPicker.display.dateFrom = moment.isMoment(scope.ctrlDatePeriodPicker.datePickerFrom) ? scope.ctrlDatePeriodPicker.datePickerFrom : moment(scope.ctrlDatePeriodPicker.datePickerFrom);
            scope.ctrlDatePeriodPicker.display.dateTo = moment.isMoment(scope.ctrlDatePeriodPicker.datePickerTo) ? scope.ctrlDatePeriodPicker.datePickerTo : moment(scope.ctrlDatePeriodPicker.datePickerTo);

            scope.ctrlDatePeriodPicker.display.dateCalendarFrom = scope.ctrlDatePeriodPicker.display.dateFrom;
            scope.ctrlDatePeriodPicker.display.dateCalendarTo = scope.ctrlDatePeriodPicker.display.dateTo;
        },
        restrict: 'E',
        scope: {
            datePickerCallback: '&',
            datePickerFrom: '=',
            datePickerTo: '='
        },
        templateUrl: 'templates/widgets/directives/date-period-picker.html'
    };

    return directive;

    function datePeriodPickerController() {
        var vm = this;
        vm.popOverTemplateUrl = 'datePeriodPickerPopover.html';
        vm.popOverVisible = false;

        vm.display = {
            dateDisplay: dateDisplay,
            dateFrom: null,
            dateCalendarFrom: null,
            dateTo: null,
            dateCalendarTo: null,
            dayDifference: 7
        };

        function dateDisplay() {
            if (!vm.display.dateFrom.isSame)
                return vm.display.dateFrom;

            // Today
            if (vm.display.dateFrom.isSame(moment(), 'day') && vm.display.dateTo.isSame(moment().add(6, 'days'), 'day'))
                return 'This week';
            else if (vm.display.dateFrom.isSame(moment().add(7, 'days'), 'day') && vm.display.dateTo.isSame(moment().add(13, 'days'), 'day'))
                return 'Next week';
            else if (vm.display.dateFrom.isSame(moment().subtract(7, 'days'), 'day') && vm.display.dateTo.isSame(moment().subtract(1, 'days'), 'day'))
                return 'Last week';

            return vm.display.dateFrom.format('DD-MM-YYYY') + ' - ' + vm.display.dateTo.format('DD-MM-YYYY');
        }

        vm.fromDateChanged = function () {
            vm.display.dateCalendarTo = moment(vm.display.dateCalendarFrom).add(vm.display.dayDifference, 'days').toDate();
        };

        vm.toDateChanged = function () {
            vm.display.dayDifference = moment(vm.display.dateCalendarTo).diff(moment(vm.display.dateCalendarFrom), 'days');
        };

        vm.next = function () {
            var totalDays = vm.display.dateTo.diff(vm.display.dateFrom, 'days') + 1;
            vm.display.dateFrom.add(totalDays, 'days');
            vm.display.dateTo.add(totalDays, 'days');

            vm.return();
        };

        vm.previous = function () {
            var totalDays = vm.display.dateTo.diff(vm.display.dateFrom, 'days') + 1;
            vm.display.dateFrom.subtract(totalDays, 'days');
            vm.display.dateTo.subtract(totalDays, 'days');

            vm.return();
        };

        vm.return = function () {
            vm.datePickerFrom = vm.display.dateFrom;
            vm.datePickerTo = vm.display.dateTo;

            callBack();
        };

        vm.setFromCalendar = function () {
            vm.popOverVisible = false;

            if (!moment.isMoment(vm.display.dateCalendarFrom)) {
                var fromDate = moment(vm.display.dateCalendarFrom);
                vm.display.dateFrom = fromDate;
                vm.datePickerFrom = fromDate;
            }
            if (!moment.isMoment(vm.display.dateCalendarTo)) {
                var toDate = moment(vm.display.dateCalendarTo);
                vm.display.dateTo = toDate;
                vm.datePickerTo = toDate;
            }
            callBack();
        };

        vm.setPeriod = function (period) {
            vm.popOverVisible = false;
            var fromDate, toDate = null;

            switch (period) {
                case 'next-week':
                    fromDate = moment().add(7, 'days');
                    toDate = moment().add(13, 'days');
                    break;
                case 'previous-week':
                    fromDate = moment().subtract(7, 'days');
                    toDate = moment().subtract(1, 'days');
                    break;
                case 'this-week':
                    fromDate = moment();
                    toDate = moment().add(6, 'days');
                    break;
            }

            vm.display.dateFrom = fromDate;
            vm.datePickerFrom = fromDate;

            vm.display.dateTo = toDate;
            vm.datePickerTo = toDate;
            callBack();
        };

        function callBack(){
            if (vm.datePickerCallback !== null && vm.datePickerCallback !== undefined){
                common.$timeout(function () {
                        vm.datePickerCallback();
                }, 200);
            }
        }
    }

}

