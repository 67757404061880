﻿module.exports = ['tariffDataService', 'coreDataService', 'widgetModals', 'constantsService', tariffGroupFormDirective];

function tariffGroupFormDirective(tariffDataService, coreDataService, widgetModals, constantsService) {
    var directive = {
        bindToController: true,
        controller: tariffGroupFormController,
        controllerAs: 'ctrlTariffGroupForm',
        restrict: 'E',
        scope: {
            tariffGroupData: '=',
            onTariffGroupSaved: '&',
            onTariffGroupDeleted: '&'
        },
        link: tariffGroupFormLink,
        templateUrl: 'templates/tariff/directives/tariff-group/tariff-group-form.html'
    };

    return directive;

    function tariffGroupFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () {
            return ctrl.tariffGroupData;
        }, function (newValue, oldValue) {
                if (!newValue) return;

                var tariffGroupId = newValue.id || 0;

                ctrl.isNewTariffGroup = tariffGroupId <= 0;

                if (newValue.invoiceTypes && newValue.invoiceTypes.length > 0) {
                    ctrl.selectedInvoiceTypeId = newValue.invoiceTypes[0].id;
                } else {
                    ctrl.selectedInvoiceTypeId = null;
                }
                ctrl.businessLogicDisplay = !newValue.businessLogic ? null : JSON.stringify(newValue.businessLogic);
        }, true);
    }

    function tariffGroupFormController() {
        var vm = {
            deleteTariffGroup: deleteTariffGroup,
            saveTariffGroup: saveTariffGroup,
            isNewTariffGroup: true,
            invoiceTypes: [],
            shipTypes: [],
            selectedInvoiceTypeId: null,
            businessLogicDisplay: null
        };

        init();

        return vm;

        function init() {

            constantsService.preloaded.then(function () {
                vm.invoiceTypes = constantsService.invoiceTypes;
            });
        }

        function saveTariffGroup() {
            //var isNewTariffGroup = !vm.berthData.id;
            if (vm.businessLogicDisplay && vm.businessLogicDisplay.length > 0) {
                vm.tariffGroupData.businessLogic = JSON.parse(vm.businessLogicDisplay);
            } else {
                vm.tariffGroupData.businessLogic = null;
            }

            vm.tariffGroupData.invoiceTypes = _.filter(vm.invoiceTypes, (i) => { return i.id === vm.selectedInvoiceTypeId; });
            tariffDataService.saveTariffGroup(vm.tariffGroupData)
                .then(successResult)
                .catch(errorResult);
            function errorResult(response) {
            }

            function successResult(result) {
                vm.onTariffGroupSaved({ savedTariffGroup: result, isNew: vm.isNewTariffGroup });
            }
        }

        function deleteTariffGroup() {
            widgetModals.areYouSure('You want to delete this tariff group?').result
                .then(function (result) {
                    if (result === true) {
                        tariffDataService.deleteTariffGroup(vm.tariffGroupData)
                            .then(successResult)
                            .catch(errorResult);
                    }
                });

            function errorResult(response) {
            }

            function successResult(result) {
                vm.onTariffGroupDeleted({ deletedTariffGroup: result });
            }
        }
    }
}
