﻿module.exports = ['common', hasPermissionForBusinessUnitDirective];

function hasPermissionForBusinessUnitDirective(common) {
    return {
        link: function (scope, element, attrs) {
            var show = false;
            if (attrs && attrs.hasPermissionForBusinessUnit) {
                var jsonString = attrs.hasPermissionForBusinessUnit;
                // Remove the starting and ending '"'
                jsonString = jsonString.replace(/'/g, '"');

                var attrValue = JSON.parse(jsonString.toString());
                for (var property in attrValue) {
                    if (_.some(common.identity.getBusinessUnitsForPermission(property), function (bu) { return attrValue[property].indexOf(bu) > -1; }))
                        show = true;
                }
            }

            element.removeClass('ng-hide');
            if (!show)
                element.addClass('ng-hide');
        }
    };
}
