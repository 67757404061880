﻿module.exports = ['common', 'reservationDataService', reservationReceiptDirective];

function reservationReceiptDirective(common, reservationDataService) {

    var directive = {
        bindToController: true,
        controller: reservationReceiptController,
        controllerAs: 'ctrlReservationReceipt',
        link: function (scope, elm, attrs, ctrl) {
            scope.$watch(function () { return ctrl.reservationData; },
                function (newReservationData, oldReservationData) {
                    if (!newReservationData) return;

                    // get history onlu when the customer changes something after confirmed the berth
                    if (newReservationData.reservationStatus && newReservationData.reservationStatus.isChangedButNotConfirmed === true && newReservationData.communicatedStatus) {
                        var lastConfirmedOnIndex = _.findLastIndex(newReservationData.communicatedStatus, function (comm) {
                            return comm.status === 3; // berth confirmed
                        });

                        if (lastConfirmedOnIndex !== -1)
                            ctrl.getReservationHistory(newReservationData.id, newReservationData.communicatedStatus[lastConfirmedOnIndex].communicatedOn);
                    }

                    if (newReservationData.ship) {
                        newReservationData.ship.greenawards = null;

                        if (newReservationData.ship.shipProperties) {
                            var greenawards = _.filter(newReservationData.ship.shipProperties, function (prop) {
                                return prop.property === 3 && (prop.endsOn === null || moment(prop.endsOn) >= moment(newReservationData.eta)) && moment(prop.startsOn) <= moment(newReservationData.eta);
                            });

                            if (greenawards && greenawards.length > 0) {
                                if (greenawards.length === 1) {
                                    newReservationData.ship.greenawards = greenawards[0].value;
                                } else {
                                    var sortedGA = _.sortBy(greenawards, function (g) { return g.startsOn; });
                                    newReservationData.ship.greenawards = sortedGA[sortedGA.length - 1].value;
                                }
                            }
                        }
                    }
                });
            //ctrl.reservationData.eta = new Date(ctrl.reservationData.eta);
        },
        restrict: 'E',
        scope: {
            allowEdit: '=',
            reservationData: '=',
            communicatedStatus: '=',
            titleText: '@',
            showReservationStatus: '=',
            showCreatedOn: '='
        },
        templateUrl: 'templates/planning/directives/reservation-receipt.html'
    };
    return directive;

    function reservationReceiptController() {
        var ctrlReservationReceipt = this;
        ctrlReservationReceipt.datePickerOptions = {
            altInputFormats: [
                'd-M-yyyy',
                'd-M-yyyy H:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yyyy',
                'dd-MM-yyyy H:mm',
                'dd-MM-yyyy HH:mm',
                'yyyy-MM-ddTHH:mm:ss'
            ]
        };

        ctrlReservationReceipt.getFacilitiesDescription = function (facilities) {
            return _.map(facilities, function (item) {
                return item.facility.name;
            }).join(', ');
        };

        ctrlReservationReceipt.compareDates = function (str) {
            if (ctrlReservationReceipt.reservationData && ctrlReservationReceipt.reservationData.etaOriginal && ctrlReservationReceipt.reservationData.etdOriginal) {
                let etaCompare = reservationDataService.compareHours(ctrlReservationReceipt.reservationData.etaOriginal, ctrlReservationReceipt.reservationData.eta);
                let etdCompare = reservationDataService.compareHours(ctrlReservationReceipt.reservationData.etdOriginal, ctrlReservationReceipt.reservationData.etd);
                if (str === 'eta') {
                    if (etaCompare) {
                        return false;
                    } else {
                        return true;
                    }
                } else if (str === 'etd') {
                    if (etdCompare) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    if (etaCompare && etdCompare) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        };
        
        ctrlReservationReceipt.getReservationHistory = function (reservationId, lastConfirmedOn) {
            ctrlReservationReceipt.reservationData.changes = null;
            reservationDataService.getReservationHistory(reservationId, lastConfirmedOn)
                .then(function (result) {
                    if (result) {
                        ctrlReservationReceipt.reservationData.changes = _.object(_.map(result, function (item) {
                            return [item.fieldName, item];
                        }));
                    }
                }, function () { });
        };
    }
}