﻿module.exports = ['$routeParams', 'coreDataModels', berthgroupOverviewDirective];

function berthgroupOverviewDirective($routeParams, coreDataModels) {
    var directive = {
        bindToController: true,
        controller: berthgroupOverviewController,
        controllerAs: 'ctrlBG',
        restrict: 'E',
        scope: {
            activeBerthgroup: '=',
            berthgroupsData: '='

        },
        templateUrl: 'templates/berthgroup/directives/berthgroup-overview.html'
    };

    return directive;

    function berthgroupOverviewController() {
        var vm = {
            setActiveBerthgroup: setActiveBerthgroup,
            selectedLink: selectedLink,
            currentlySelected: false
        };

        return vm;

        function setActiveBerthgroup(berthgroup) {
            //if new clicked!!!
            if (!berthgroup)
                berthgroup = new coreDataModels.berthgroupModel();

            vm.activeBerthgroup = berthgroup;
        }
        function selectedLink(id) {
            if (vm.activeBerthgroup) {
                if (parseInt(id) === parseInt(vm.activeBerthgroup.id)) {
                    vm.currentlySelected = true;
                    return vm.currentlySelected;
                }
            }
        }
    }

}